import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  STATUS_DELETADO,
} from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const ActionsField = ({
  handleEdit,
  // handleEfetivar,
  handleShow,
  // handleDelete,
  handleCotacoes,
  handleRelatorioEstoque,
  handleAtivar = () => {},
  handleDesativar = () => {},
  handleDeletar = () => {},
  changeStatusSearch = () => {},
  changeStatusRuptura = () => {},
  handlePrecificar = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);
  const classes = useStyles();
  const statusValue = record ? record.record.ativo : false;

  const status = record ? record.record.status : '';

  const statusSearch = record ? record.record.is_search : false;

  const statusRuptura = record ? record.record.is_ruptura : false;

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {/* <Tooltip title="Precificar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handlePrecificar(record.record)}
        >
          <TrendingUpIcon />
        </IconButton>
      </Tooltip> */}
      <Tooltip title="Cotações" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleCotacoes(record.record)}
        >
          <TrendingUpIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Relatório Estoque" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleRelatorioEstoque(record.record)}
        >
          <AssignmentIcon />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title="Status" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEfetivar(record.record)}
        >
          {!statusValue ? <CheckCircleOutlineIcon /> : <CancelIcon />}
        </IconButton>
      </Tooltip> */}

      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title="Excluir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleDelete(record.record)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip> */}

      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>

      {status === STATUS_ATIVO && statusSearch ? (
        <Tooltip
          title="Incluir na lista"
          placement="bottom"
          aria-label="desativar2"
        >
          <Avatar
            className={classes.confirmado}
            onClick={() => changeStatusSearch(record.record)}
          >
            <SearchIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      ) : (
        <Tooltip
          title="Incluir na lista"
          placement="bottom"
          aria-label="desativar2"
        >
          <Avatar
            className={classes.pendente}
            onClick={() => changeStatusSearch(record.record)}
          >
            <SearchIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      )}

      {statusRuptura ? (
        <Tooltip title="Em Ruptura" placement="bottom" aria-label="desativar2">
          <Avatar
            className={classes.pendente}
            onClick={() => changeStatusRuptura(record.record)}
          >
            <BlockIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      ) : (
        <Tooltip title="Não Ruptura" placement="bottom" aria-label="desativar2">
          <Avatar
            className={classes.confirmado}
            onClick={() => changeStatusRuptura(record.record)}
          >
            <BlockIcon style={{ fontSize: 15 }} />
          </Avatar>
        </Tooltip>
      )}

      {status === STATUS_INATIVO && (
        <Tooltip title="Ativar" placement="bottom" aria-label="ativar2">
          <IconButton
            aria-label="ativar"
            // classes={{ root: classes.root }}
            size="small"
            onClick={() => handleAtivar(record.record)}
            // disabled={gerenciado}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip title="Desativar" placement="bottom" aria-label="desativar2">
          <IconButton
            aria-label="desativar"
            // classes={{ root: classes.root }}
            size="small"
            onClick={() => handleDesativar(record.record)}
            // disabled={gerenciado}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_INATIVO && (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            // classes={{ root: classes.root }}
            size="small"
            onClick={() => handleDeletar(record.record)}
            // disabled={gerenciado}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionsField;
