import React, { useEffect, useRef, useState } from 'react';
import { Title, useVersion } from 'react-admin';

import {
  withStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { api, getListAPI } from '../../../services';
import { createQueryURL, formatMoeda } from '../../../utils';
import PeriodoInput from '../IndicadoresKPI/PeriodoFilter';
import MobillsInput from '../RelatorioVendaMensal/DateInputMobills';
import DialogoItens from './DialogoItens';
import DialogoPDF from './DialogoPDF';
import { agruparPorData, agruparPorFornecedor } from './utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    //width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '7px',
    //border: '1px solid black',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //width: 'min-content',
    border: 'none',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '7px',
    //border: '1px solid black',
    //width: 'min-content',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //textAlign: 'center',
    border: 'none',
  },
}))(TableCell);

const tamanhoFontTotais = '20px';

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

function getTotal(dados, campo) {
  let total = 0;

  for (let i = 0; i < dados.length; i++) {
    total += dados[i].dadosOriginais[campo];
  }

  return total;
}

function Grupo({
  label,
  rows,
  totais,
  campos,
  prefixo = '',
  handleShow = () => {},
}) {
  const [open, setOpen] = useState(true);

  const totalFrete = formatMoeda(totais.totalFrete);
  const totalDesconto = formatMoeda(totais.totalDesconto);
  const totalIcms = formatMoeda(totais.totalIcms);
  const totalIcmsSt = formatMoeda(totais.totalIcmsSt);
  const totalPis = formatMoeda(totais.totalPis);
  const totalCofins = formatMoeda(totais.totalCofins);
  const totalIpi = formatMoeda(totais.totalIpi);
  const totalValorNfe = formatMoeda(totais.totalValorNfe);
  const totalBcCalculoIcms = formatMoeda(totais.totalBcCalculoIcms);
  const totalOutrasDespesas = formatMoeda(totais.totalOutrasDespesas);
  const totalSeguros = formatMoeda(totais.totalSeguros);

  const tamanhoFontCabecalho = '17px';
  const tamanhoFontBody = '15px';
  const tamanhoFontTotais = '17px';

  return (
    <>
      <TableRow>
        <StyledTableCell
          align="left"
          style={{
            fontWeight: 'bold',
            fontSize: tamanhoFontCabecalho,
            backgroundColor: '#dae1f3',
          }}
          colSpan={19}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => setOpen(!open)}
            size="small"
            style={{
              marginRight: '15px',
              color: 'white',
              backgroundColor: 'red',
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {`${prefixo} - ${label}`}
        </StyledTableCell>
      </TableRow>
      {open ? (
        <>
          {rows.map((item) => (
            <TableRow>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.data}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.numero_nfe}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.uf}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
                colSpan={3}
              >
                {item.fornecedor}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.ipi}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.icms_st}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.outras_despesas}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.frete}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.seguros}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.desconto_em_nfe}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.pis}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.cofins}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.icms_normal}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.bc_calculo_icms}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.isento ? 'SIM' : 'NÃO'}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                {item.total_nfe}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontSize: tamanhoFontBody,
                }}
              >
                <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
                  <IconButton
                    aria-label="check"
                    size="small"
                    onClick={() => handleShow(item.id)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={6}
            >
              SubTotal:
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalIpi}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalIcmsSt}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalOutrasDespesas}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalFrete}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalSeguros}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalDesconto}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalPis}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalCofins}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalIcms}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalBcCalculoIcms}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            />
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            >
              {totalValorNfe}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontWeight: 'bold',
                fontSize: tamanhoFontTotais,
              }}
              colSpan={1}
            />
          </TableRow>
        </>
      ) : null}
    </>
  );
}

function createIntervaloMes(mes, ano) {
  const newData = new Date();
  newData.setFullYear(ano);
  newData.setMonth(mes);
  const dataStart = new Date(moment(newData).startOf('month').format());
  const dataEnd = new Date(moment(newData).endOf('month').format());
  return {
    start: dataStart,
    end: dataEnd,
  };
}

const RelatorioComprasFiscais = () => {
  /*  const [periodo, setPeriodo] = useState({
    start: new Date(),
    end: new Date(),
  }); */
  const [periodo2, setPeriodo2] = useState({
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
  });

  const [dados, setDados] = useState(null);

  const [ordenacao, setOrdenacao] = useState(0);

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const refDialogoPDF = useRef(null);

  const refDialogoItens = useRef(null);

  const version = useVersion();

  async function getNotasCompras() {
    setLoading(true);
    try {
      const periodo = createIntervaloMes(periodo2.mes, periodo2.ano);
      const response = await api.get(
        `/relatorio-compras-fiscais?${createQueryURL(
          null,
          null,
          { periodo },
          null,
        )}`,
      );
      const { notas, fornecedores } = response.data;
      setDados({
        notas,
        fornecedores,
      });
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [] });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    getNotasCompras();
  }, [version, periodo2]);

  let dadosUtilizaveis = [];
  switch (ordenacao) {
    case 0:
      dadosUtilizaveis = dados
        ? agruparPorData(dados.notas, dados.fornecedores)
        : [];
      break;
    case 1:
      dadosUtilizaveis = dados
        ? agruparPorFornecedor(dados.notas, dados.fornecedores)
        : [];
      break;
    default:
      dadosUtilizaveis = [];
      break;
  }

  function openDetalhesNota(id) {
    if (refDialogoItens.current) {
      refDialogoItens.current.handleOpen(id);
    }
  }

  async function handleData({ ano, mes }) {
    //setLoading(true);
    try {
      //const dados = await api.get(`/relatorio/mensal/${ano}/${mes}`);
      setPeriodo2({ mes, ano });
      //setLoading(false);
    } catch (e) {
      //setDados({ linhas: [], mes, ano });
      /* setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      }); */
    }
  }

  const periodo = createIntervaloMes(periodo2.mes, periodo2.ano);

  function getTotais(dados) {
    const dados2 = {
      totalFrete: 0,
      totalDesconto: 0,
      totalIcms: 0,
      totalIcmsSt: 0,
      totalPis: 0,
      totalCofins: 0,
      totalIpi: 0,
      totalValorNfe: 0,
      totalBcCalculoIcms: 0,
      totalOutrasDespesas: 0,
      totalSeguros: 0,
    };
    for (let i = 0; i < dados.length; i++) {
      const totais = dados[i][2];
      dados2.totalFrete = totais.totalFrete;
      dados2.totalDesconto = totais.totalDesconto;
      dados2.totalIcms = totais.totalIcms;
      dados2.totalIcmsSt = totais.totalIcmsSt;
      dados2.totalPis = totais.totalPis;
      dados2.totalCofins = totais.totalCofins;
      dados2.totalIpi = totais.totalIpi;
      dados2.totalValorNfe = totais.totalValorNfe;
      dados2.totalBcCalculoIcms = totais.totalBcCalculoIcms;
      dados2.totalOutrasDespesas = totais.totalOutrasDespesas;
      dados2.totalSeguros = totais.totalSeguros;
    }

    dados2.totalFrete = formatMoeda(dados2.totalFrete);
    dados2.totalDesconto = formatMoeda(dados2.totalDesconto);
    dados2.totalIcms = formatMoeda(dados2.totalIcms);
    dados2.totalIcmsSt = formatMoeda(dados2.totalIcmsSt);
    dados2.totalPis = formatMoeda(dados2.totalPis);
    dados2.totalCofins = formatMoeda(dados2.totalCofins);
    dados2.totalIpi = formatMoeda(dados2.totalIpi);
    dados2.totalValorNfe = formatMoeda(dados2.totalValorNfe);
    dados2.totalBcCalculoIcms = formatMoeda(dados2.totalBcCalculoIcms);
    dados2.totalOutrasDespesas = formatMoeda(dados2.totalOutrasDespesas);
    dados2.totalSeguros = formatMoeda(dados2.totalSeguros);

    return dados2;
  }

  const totais = getTotais(dadosUtilizaveis);

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        Relatório Compras Fiscais
      </div>
      <Title title="Relatório Compras Fiscais" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormControl variant="outlined">
          <InputLabel id="ordenacao">Ordenação</InputLabel>
          <Select
            labelId="ordenacao"
            id="ordenacao"
            value={ordenacao}
            onChange={(event) => setOrdenacao(event.target.value)}
            label="Ordenação"
          >
            <MenuItem value={0}>Data</MenuItem>
            <MenuItem value={1}>Fornecedor</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/*  <PeriodoInput
            onChange={(e) => setPeriodo(e.target.value)}
            value={periodo}
          /> */}
          <MobillsInput handleData={handleData} />
        </div>

        <Tooltip
          title="Imprimir relatório"
          placement="top"
          aria-label="Imprimir"
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              if (refDialogoPDF.current) {
                refDialogoPDF.current.handleOpen();
              }
            }}
          >
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div style={{ /* width: 'min-content', */ margin: '0 auto' }}>
        <Table
          aria-label="a dense table"
          style={
            {
              /* width: 'min-content' */
            }
          }
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}
                colSpan={6}
                rowSpan={2}
              >
                Dados de Identificação
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#ddebf6',
                  textAlign: 'center',
                }}
                colSpan={6}
                rowSpan={2}
              >
                Repasses e Descontos
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#fff2cd',
                  textAlign: 'center',
                }}
                colSpan={3}
              >
                Impotos
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
                colSpan={3}
                rowSpan={2}
              />
            </TableRow>
            <TableRow>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#fff2cd',
                }}
              >
                1,65%
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#fff2cd',
                }}
              >
                7,6%
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#fff2cd',
                }}
              >
                20,50%
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                Data
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                Nº NF
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                UF
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
                colSpan={3}
              >
                Fornecedor
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#ddebf6',
                }}
              >
                IPI
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#ddebf6',
                }}
              >
                ICMS ST
              </StyledTableCell>

              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#ddebf6',
                }}
              >
                OD
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#ddebf6',
                }}
              >
                Frete
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#ddebf6',
                }}
              >
                Seguros
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#ddebf6',
                }}
              >
                Desconto em Nfe
              </StyledTableCell>

              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#fff2cd',
                }}
              >
                PIS
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#fff2cd',
                }}
              >
                COFINS
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#fff2cd',
                }}
              >
                ICMS Normal
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#d0cfcb',
                }}
              >
                BC ICMS
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#d0cfcb',
                }}
              >
                Isenta?
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#d0cfcb',
                }}
              >
                Valor da Nfe
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  backgroundColor: '#d0cfcb',
                }}
              >
                Ações
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dadosUtilizaveis.map((item) => (
              <Grupo
                label={item[0]}
                rows={item[1]}
                totais={item[2]}
                campos={[
                  'data',
                  'numero_nfe',
                  'fornecedor',
                  'frete',
                  'desconto_em_nfe',
                  'icms_normal',
                  'icms_st',
                  'pis',
                  'cofins',
                  'ipi',
                  'total_nfe',
                  'outras_despesas',
                  'seguros',
                  'bc_calculo_icms',
                ]}
                prefixo={ordenacao === 0 ? 'Data de Emissão' : 'Fornecedor'}
                handleShow={openDetalhesNota}
              />
            ))}
            <TableRow style={{ borderTop: '2px solid black' }}>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={6}
              />
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalIpi}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalIcmsSt}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalOutrasDespesas}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalFrete}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalSeguros}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalDesconto}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalPis}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalCofins}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalIcms}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalBcCalculoIcms}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              />
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              >
                {totais.totalValorNfe}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontWeight: 'bold',
                  fontSize: tamanhoFontTotais,
                }}
                colSpan={1}
              />
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <DialogoPDF
        ref={refDialogoPDF}
        dados={dadosUtilizaveis}
        ordenacao={ordenacao}
        periodo={periodo}
        totais={totais}
      />
      <DialogoItens ref={refDialogoItens} handleClose={() => {}} />
    </div>
  );
};

export default RelatorioComprasFiscais;
