import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  AutoCompleteRemoto,
  DialogoConfirmacao,
  TextInput,
  DateInputBonito,
  SelectInput,
} from '../../../Components';
import DialogoGrafico from '../../../Components/Dialogos/DialogoCompra/Pages/InicioFrenteCaixaCompra/DialogoGrafico';
import {
  api,
  getUserId,
  getListAllAPI,
  createAPI,
  updateAPI,
} from '../../../services';
import {
  formatMoeda,
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  MALOTE_MALOTE_OPTION,
  MALOTE_SANGRIA_OPTION,
  MALOTE_SUBMALOTE_OPTION,
  MALOTE_TROCO_OPTION,
  STATUS_ATIVO,
  ceil10,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../../utils';
import { formatarPacote } from '../../../utils/malote';
import DialogoDeletar from '../DialogoDeletar';
import DialogoTipoOferta from '../DialogoTipoOferta';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: 'white',
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableCellDestaque = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: 'white',
    fontSize: 12,
  },
  body: {
    fontSize: 15,
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function renameItensUIDD(itens) {
  const arrayNew = itens.slice();
  for (let i = 0; i < itens.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].produto.nome}${i}`;
  }
  return arrayNew;
}

function formatEscalasOutput(ofertas) {
  const ofertas2 = [];
  for (let i = 0; i < ofertas.length; i++) {
    const esc = ofertas[i];
    ofertas2.push({
      idIndicadorChange: esc.id,
      valor: esc.valor,
      tipo_unidade:
        esc.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
          ? TIPO_UNIDADE_OFERTA_KG
          : TIPO_UNIDADE_OFERTA_UN,
      produto_id: esc.produto.id,
      dadosPrecificacao: esc.dadosPrecificacao,
      isEdited: esc.isEdited,
      nImpressoes: esc.nImpressoes ? esc.nImpressoes : 0,
    });
  }
  return ofertas2;
}

function formatEscalasInput(ofertas) {
  const ofertas2 = [];
  for (let i = 0; i < ofertas.length; i++) {
    const esc = ofertas[i];
    if (esc.status) {
      ofertas2.push({
        id: esc.id,
        valor: esc.valor,
        tipo_unidade: esc.tipo_unidade,
        produto: esc.produto,
        dadosPrecificacao: esc.dadosPrecificacao,
        isEdited: false,
        nImpressoes: 0,
      });
    }
  }
  return renameItensUIDD(ofertas2);
}

const TIPO_ORDENACAO_ADICAO = 'tipo_ordenacao_adicao';
const TIPO_ORDENACAO_ALFABETICO_ASC = 'tipo_ordenacao_alfabetico_asc';
const TIPO_ORDENACAO_ALFABETICO_DESC = 'tipo_ordenacao_alfabetico_desc';

const optionsTipoOrdenacao = [
  { value: TIPO_ORDENACAO_ADICAO, label: 'Natural' },
  {
    value: TIPO_ORDENACAO_ALFABETICO_ASC,
    label: 'Alfabética Crescente',
  },
  {
    value: TIPO_ORDENACAO_ALFABETICO_DESC,
    label: 'Alfabética Decrescente',
  },
];

const DialogoContadorCedulas = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [etapa, setEtapa] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [carregando, setCarregando] = useState(false);
  const [dataMinima, setDataMinima] = useState(new Date());
  const [dataMaxima, setDataMaxima] = useState(new Date());
  const [ofertas, setOfertas] = useState([]);

  const refDialogoConfirmacao = useRef(null);
  const refDialogoOferta = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoGrafico = useRef(null);

  const [tipoOrdenacao, setTipoOrdenacao] = useState(TIPO_ORDENACAO_ADICAO);
  const [search, setSearch] = useState('');

  const handleCloseDialog = (status, idProduto = -1) => {
    setOpen(false);
    handleClose(status, idProduto);
  };

  async function reiniciar() {
    setId(-1);
    setDataMinima(new Date());
    setDataMaxima(new Date());
    setOfertas([]);
    setEtapa(0);
    //
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'jornais_de_ofertas',
        ['id', 'asc'],
        { id: [id] },
        ['ofertas.produto.unidade', 'dadosPrecificacao'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setDataMinima(new Date(data.data[0].data_minima));
        setDataMaxima(new Date(data.data[0].data_maxima));
        setOfertas(formatEscalasInput(data.data[0].ofertas));
        setEtapa(0);
        //
        setCarregando(false);
      } else {
        throw 'Esta Oferta de Jornal não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('jornais_de_ofertas', {
          data_minima: dataMinima,
          data_maxima: dataMaxima,
          user_id: getUserId(),
          ofertas: formatEscalasOutput(ofertas),
        });
        setCarregando(false);
        enqueueSnackbar(`Oferta de Jornal criada com sucesso!`, {
          variant: 'success',
        });
      } else {
        await updateAPI('jornais_de_ofertas', id, {
          data_minima: dataMinima,
          data_maxima: dataMaxima,
          user_id: getUserId(),
          ofertas: formatEscalasOutput(ofertas),
        });
        setCarregando(false);
        enqueueSnackbar(`Oferta de Jornal atualizada com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog(0);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setCarregando(true);
      reiniciar();
      setOpen(true);
      setSearch('');
      setTipoOrdenacao(TIPO_ORDENACAO_ADICAO);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
      setSearch('');
      setTipoOrdenacao(TIPO_ORDENACAO_ADICAO);
    },
  }));

  function handleCloseLocal() {
    setOpen(false);
  }

  async function getJornaisConflito() {
    const response = await api.post('/get_jornais_complito', {
      data_minima: dataMinima,
      data_maxima: dataMaxima,
    });
    return response.data;
  }

  async function proximaEtapa() {
    if (etapa === 0) {
      const jornaisConflitos = await getJornaisConflito();
      let isLiberado = false;
      if (jornaisConflitos.length === 0) {
        isLiberado = true;
      } else if (jornaisConflitos.length === 1) {
        if (jornaisConflitos[0].id === id) {
          isLiberado = true;
        }
      }
      if (isLiberado) {
        setEtapa(1);
      } else {
        enqueueSnackbar(
          'Existe um jornal que interpõe esse intervalo que você esta definindo!!!',
          {
            variant: 'error',
          },
        );
      }
    } else if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Finalização do Jornal de Ofertas',
        'Deseja realmente finalizar a configuração deste Jornal de Ofertas?',
        {
          code: 1,
          data: null,
        },
      );
    }
  }

  function etapaAnterior() {
    if (etapa === 0) handleCloseLocal();
    else setEtapa(0);
  }

  const disabledNextEtapa1 = false;

  async function handleConfirma({ code, data }) {
    switch (code) {
      case 1:
        handleSalvar();
        break;
      default:
        break;
    }
  }

  function nextDay(x) {
    const now = new Date();
    now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7));
    return now;
  }

  function dataMaximaMaxima() {
    return new Date(moment(dataMinima).add(3, 'weeks').format());
  }

  function setProximoDomingo() {
    const nextData = nextDay(0);
    if (
      new Date(moment(dataMinima).startOf('day').format()).getTime() <
      new Date(moment(nextData).endOf('day').format()).getTime()
    ) {
      setDataMaxima(nextData);
    }
  }

  function handleCriarOferta() {
    if (refDialogoOferta.current) {
      refDialogoOferta.current.handleCreate();
    }
  }

  function handleEditarOferta(dados) {
    if (refDialogoOferta.current) {
      refDialogoOferta.current.handleEdit(
        dados.uidd,
        dados.valor,
        dados.produto.id,
      );
    }
  }

  function handleDeletarOferta(dados) {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(dados);
    }
  }

  function handleSlavar(dados) {
    if (!dados.isEdited) {
      setOfertas(
        renameItensUIDD([
          ...ofertas,
          {
            ...dados,
          },
        ]),
      );
    } else {
      const ofertasNew = [...ofertas];
      const index = ofertas.findIndex((item) => item.uidd === dados.id);
      if (index >= 0) {
        const antigo = ofertasNew[index];
        ofertasNew[index] = {
          ...dados,
          id: antigo.id,
          tipo_unidade: antigo.tipo_unidade,
        };
      }
      setOfertas(renameItensUIDD(ofertasNew));
    }
  }

  function deletar(uidd) {
    const copia = renameItensUIDD(ofertas.filter((item) => item.uidd !== uidd));
    setOfertas(copia);
  }

  function abrirGrafico(dados) {
    if (refDialogoGrafico.current) {
      if (dados.dadosPrecificacao) {
        refDialogoGrafico.current.handleOpen(
          [
            {
              label: 'Valor Negociado com a indústria',
              backgroundColor: 'rgba(68, 114, 196, 1)',
              borderColor: 'rgba(68, 114, 196, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.valor_negociado_com_a_industria,
                -2,
              ),
            },
            {
              label: 'Margem de Custo Operacional',
              backgroundColor: 'rgba(237, 125, 49, 1)',
              borderColor: 'rgba(237, 125, 49, 1)',
              valor: ceil10(dados.dadosPrecificacao.custo_operacional, -2),
            },
            {
              label: 'Diferença de ICMS',
              backgroundColor: 'rgba(165, 165, 165, 1)',
              borderColor: 'rgba(165, 165, 165, 1)',
              valor: ceil10(dados.dadosPrecificacao.diferenca_de_icms, -2),
            },
            {
              label: 'Lucro Líquido',
              backgroundColor: 'rgba(91, 155, 213, 1)',
              borderColor: 'rgba(91, 155, 213, 1)',
              valor: ceil10(dados.dadosPrecificacao.lucro_bruto, -2),
            },
            {
              label: 'Diferença de COFINS',
              backgroundColor: 'rgba(112, 173, 71, 1)',
              borderColor: 'rgba(112, 173, 71, 1)',
              valor: ceil10(dados.dadosPrecificacao.diferenca_de_cofins, -2),
            },
            {
              label: 'Margem de Custo de Cartão',
              backgroundColor: 'rgba(10, 224, 196, 1)',
              borderColor: 'rgba(10, 224, 196, 1)',
              valor: ceil10(dados.dadosPrecificacao.custo_de_cartao, -2),
            },
            {
              label: 'Diferença de PIS',
              backgroundColor: 'rgba(7, 15, 49, 1)',
              borderColor: 'rgba(7, 15, 49, 1)',
              valor: ceil10(dados.dadosPrecificacao.difereca_de_pis, -2),
            },
            {
              label: 'Repasse do IPI',
              backgroundColor: 'rgba(200, 165, 165, 1)',
              borderColor: 'rgba(200, 165, 165, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_do_ipi, -2),
            },
            {
              label: 'Repasse do Frete',
              backgroundColor: 'rgba(90, 1, 213, 1)',
              borderColor: 'rgba(90, 1, 213, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_do_frete, -2),
            },
            {
              label: 'Repasse do ST',
              backgroundColor: 'rgba(200, 0, 0, 1)',
              borderColor: 'rgba(200, 0, 0, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_de_st, -2),
            },
            {
              label: 'Repasse do Seguro',
              backgroundColor: 'rgba(60, 60, 6, 1)',
              borderColor: 'rgba(60, 60, 6, 1)',
              valor: ceil10(dados.dadosPrecificacao.repasse_do_seguro, -2),
            },
            {
              label: 'Repasse de Outras Despesas',
              backgroundColor: 'rgba(5, 5, 5, 1)',
              borderColor: 'rgba(5, 5, 5, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_de_outras_despesas,
                -2,
              ),
            },
            {
              label: 'ICMS de Fronteira',
              backgroundColor: 'rgba(100, 50, 100, 1)',
              borderColor: 'rgba(100, 50, 100)',
              valor: ceil10(dados.dadosPrecificacao.icms_fronteira, -2),
            },
            {
              label: 'ICMS de Antecipação',
              backgroundColor: 'rgba(10, 20, 250, 1)',
              borderColor: 'rgba(10, 20, 250, 1)',
              valor: ceil10(dados.dadosPrecificacao.icms_antecipacao, -2),
            },
            {
              label: 'Desconto Total',
              backgroundColor: 'rgba(37, 205, 200, 1)',
              borderColor: 'rgba(37, 205, 200, 1)',
              valor: ceil10(
                -1 *
                  (dados.dadosPrecificacao.desconto_em_nfe +
                    dados.dadosPrecificacao.desconto_em_boleto +
                    dados.dadosPrecificacao.desconto_em_bonificacao +
                    dados.dadosPrecificacao
                      .desconto_em_backlight_e_ponto_extra +
                    dados.dadosPrecificacao.desconto_em_jornal_de_ofertas +
                    dados.dadosPrecificacao
                      .desconto_em_descargo_de_mercadorias),
                -2,
              ),
            },
          ],
          dados.dadosPrecificacao.preco_de_venda,
          dados.produto.nome,
        );
      } else {
        enqueueSnackbar(`Este lote não tem dados de precificação salvos!`, {
          variant: 'error',
        });
      }
    }
  }

  const ofertas2 = ofertas
    .slice()
    .filter((item) => {
      const search2 = search.toLocaleLowerCase();
      const nome2 = item.produto.nome.toLocaleLowerCase();
      if (nome2.includes(search2)) {
        return true;
      }
      return false;
    })
    .reverse();

  switch (tipoOrdenacao) {
    case TIPO_ORDENACAO_ADICAO:
      break;
    case TIPO_ORDENACAO_ALFABETICO_ASC:
      ofertas2.sort((a, b) => {
        if (a.produto.nome > b.produto.nome) {
          return 1;
        }

        if (a.produto.nome < b.produto.nome) {
          return -1;
        }

        return 0;
      });
      break;
    case TIPO_ORDENACAO_ALFABETICO_DESC:
      ofertas2.sort((a, b) => {
        if (a.produto.nome > b.produto.nome) {
          return -11;
        }

        if (a.produto.nome < b.produto.nome) {
          return +1;
        }

        return 0;
      });
      break;
    default:
      break;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullWidth
        maxWidth={etapa === 0 ? 'xs' : 'md'}
        fullScreen={etapa !== 0}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
        >
          {id > 0
            ? `Editar Jornal de Oferta ${
                etapa === 0 ? '- Etapa 1' : '- Etapa 2'
              }`
            : `Novo Jornal de Oferta  ${
                etapa === 0 ? '- Etapa 1' : '- Etapa 2'
              }`}
        </DialogTitle>
        <DialogContent dividers>
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {etapa === 0 ? (
                <div>
                  <div style={{ marginTop: '15px' }}>
                    <DateInputBonito
                      showTodayButton
                      todayLabel="Hoje"
                      name="dataMinima"
                      handleEnter={() => {}}
                      label="Data de Início"
                      handleKey={() => {}}
                      value={dataMinima}
                      onChange={(value) => setDataMinima(value)}
                      format="dd MMMM yyyy"
                      fullWidth
                      variant="standard"
                      maxDate={dataMaxima}
                      disablePast
                    />
                  </div>
                  <div style={{ marginTop: '15px', display: 'flex' }}>
                    <DateInputBonito
                      showTodayButton
                      todayLabel="Hoje"
                      name="dataLimite"
                      handleEnter={() => {}}
                      label="Data Término"
                      handleKey={() => {}}
                      value={dataMaxima}
                      onChange={(value) => setDataMaxima(value)}
                      format="dd MMMM yyyy"
                      fullWidth
                      variant="standard"
                      minDate={
                        new Date().getTime() > dataMinima.getTime()
                          ? new Date()
                          : dataMinima
                      }
                      maxDate={dataMaximaMaxima()}
                      disablePast
                    />
                    {/*<Tooltip
                      title="próximo domingo"
                      placement="bottom"
                      aria-label="add2"
                    >
                      <IconButton
                        aria-label="editar"
                        size="small"
                        onClick={() => setProximoDomingo()}
                      >
                        <AlarmIcon />
                      </IconButton>
                    </Tooltip>*/}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '20px 0',
                    }}
                  >
                    <h2>{`Ofertas (${ofertas.length})`}</h2>
                    <Tooltip
                      title="Nova Oferta"
                      placement="bottom"
                      aria-label="add2"
                    >
                      <IconButton
                        aria-label="criar"
                        size="small"
                        onClick={() => {
                          handleCriarOferta();
                        }}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '50px',
                    }}
                  >
                    <Box>
                      <TextInput
                        name="search"
                        handleEnter={() => {}}
                        label="Pesquisar"
                        handleKey={() => {}}
                        value={search}
                        onChange={(value) => setSearch(value.target.value)}
                        fullWidth
                      />
                    </Box>
                    <Box>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="tipo-ordenacao">Ordenação</InputLabel>
                        <SelectInput
                          labelId="tipo-ordenacao"
                          name="tipo-ordenacao"
                          handleEnter={() => {}}
                          handleKey={() => {}}
                          value={tipoOrdenacao}
                          onChange={(value) =>
                            setTipoOrdenacao(value.target.value)
                          }
                          options={optionsTipoOrdenacao}
                          fullWidth
                          label="Ordenação"
                        />
                      </FormControl>
                    </Box>
                  </div>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Produto</StyledTableCell>
                          <StyledTableCell>
                            Preço Atual de Venda
                          </StyledTableCell>
                          <StyledTableCell>Preço de Oferta</StyledTableCell>
                          <StyledTableCell>Impressões</StyledTableCell>
                          <StyledTableCell align="right">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ofertas2.map((row) => (
                          <StyledTableRow key={row.uidd}>
                            <StyledTableCellDestaque>
                              {row.produto.nome}
                            </StyledTableCellDestaque>
                            <StyledTableCell>
                              {formatMoeda(row.produto.preco_venda)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {formatMoeda(row.valor)}
                            </StyledTableCell>
                            <StyledTableCell>{row.nImpressoes}</StyledTableCell>
                            <StyledTableCell align="right">
                              <Tooltip
                                title="Editar"
                                placement="bottom"
                                aria-label="add2"
                              >
                                <IconButton
                                  aria-label="editar"
                                  size="small"
                                  onClick={() => {
                                    handleEditarOferta(row);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Editar"
                                placement="bottom"
                                aria-label="add2"
                              >
                                <IconButton
                                  aria-label="editar"
                                  size="small"
                                  onClick={() => {
                                    handleDeletarOferta(row);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Gráfico de pizza">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => abrirGrafico(row)}
                                  size="small"
                                >
                                  <DataUsageIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Button
            onClick={etapaAnterior}
            color="secondary"
            variant="contained"
            tabindex="-1"
            disabled={carregando}
          >
            {etapa === 0 ? `Fechar` : `Voltar `}
          </Button>
          <Button
            onClick={proximaEtapa}
            color="secondary"
            variant="contained"
            tabindex="-1"
            disabled={
              carregando
                ? true
                : etapa === 0
                ? disabledNextEtapa1
                : ofertas.length === 0
            }
          >
            {etapa === 0 ? `Próximo` : `Finalizar`}
          </Button>
        </DialogActions>
        <DialogoTipoOferta
          ref={refDialogoOferta}
          ofertas={ofertas}
          handleSlavar={handleSlavar}
        />
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
        <DialogoDeletar ref={refDialogoDeletar} handleSalvar={deletar} />
        <DialogoGrafico ref={refDialogoGrafico} />
      </Dialog>
    </div>
  );
});

export default DialogoContadorCedulas;
