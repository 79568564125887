import React, { forwardRef } from 'react';

import {
  TextField,
} from '@material-ui/core';

const DateInput = forwardRef(({
  handleEnter, handleKey, variant = 'outlined', ...rest
}, ref) => (
  <TextField
    margin="normal"
    type="date"
    {...rest}
    autoComplete="off"
    inputRef={ref}
    variant={variant}
    autoFocus={false}
    onKeyDown={(e) => {
      if (e.keyCode === 13) handleEnter();
      else handleKey(e.keyCode, e.key);
    }}
  />
));

export default DateInput;
