import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, getListAPI, getUserId, api } from '../../../services';
import {
  formatCNPJ,
  formatCPF,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  POR_PESO_FIXO,
  formatPreco,
  getPrecoUnitarioVerdadeiroCaixa2,
  getEstoqueLabel,
  TIPO_PRECIFICACAO_NORMAL,
  TIPO_PRECIFICACAO_COMPRA,
  TIPO_PRECIFICACAO_LIBERACAO_PRECO,
  TIPO_PRECIFICACAO_OFERTA,
  TIPO_PRECIFICACAO_ZERAR_ESTOQUE,
  formatPorcentagem,
  ESTADO_FISICO_CONGELADO,
  ESTADO_FISICO_RESFRIADO,
  ESTADO_FISICO_SECO,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_UN,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_CX1,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_CX2,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_CX3,
  TIPO_UNIDADE_PRECO_PRE_COMPRA_KG,
  TIPO_IMPRESSAO_KG,
  TIPO_IMPRESSAO_UN,
} from '../../../utils';
import DialogoDetalhesItens from '../../HistoricoPrecificacoes/DialogoDetalhesItens';
import DialogoCreateAvaria from '../DialogoCreateAvaria';
import DialogoCreateCorrecao from '../DialogoCreateCorrecao';
import DialogoDeletarAvaria from '../DialogoDeletarAvaria';
import DialogoDeletarCorrecao from '../DialogoDeletarCorrecao';
import DialogoReprecificar from './ContainerDialogoPrecificar';

function getLabelEstadoFisico(value) {
  switch (value) {
    case ESTADO_FISICO_CONGELADO:
      return 'Congelado';
    case ESTADO_FISICO_RESFRIADO:
      return 'Resfriado';
    case ESTADO_FISICO_SECO:
      return 'Seco';
    default:
      return '';
  }
}

function getLabelFatorConversaoCaixaria(value) {
  switch (value) {
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM:
      return 'Nenhum';
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1:
      return 'Caixa Nível 1';
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2:
      return 'Caixa Nível 2';
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3:
      return 'Caixa Nível 3';
    default:
      return '-';
  }
}

function getLabelTipoUnidadePrecoPreCompra(value) {
  switch (value) {
    case TIPO_UNIDADE_PRECO_PRE_COMPRA_UN:
      return 'Un';
    case TIPO_UNIDADE_PRECO_PRE_COMPRA_KG:
      return 'Kg';
    case TIPO_UNIDADE_PRECO_PRE_COMPRA_CX1:
      return 'Caixa Nível 1';
    case TIPO_UNIDADE_PRECO_PRE_COMPRA_CX2:
      return 'Caixa Nível 2';
    case TIPO_UNIDADE_PRECO_PRE_COMPRA_CX3:
      return 'Caixa Nível 3';
    default:
      return '-';
  }
}

function getLabelTipoImpressao(value) {
  switch (value) {
    case TIPO_IMPRESSAO_KG:
      return 'KG';
    case TIPO_IMPRESSAO_UN:
      return 'UN';
    default:
      return '';
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DEFAULT_ID = -1;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const perPageAvaria = 10;
const perPageCorrecao = 2;
const perPagePrecificacao = 2;

const optionsCfop = {
  5102: '5102 - Venda de mercadoria de terceiros',
  5405: '5405 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído',
};

const optionsCstIcms = {
  '00': '00 - Tributada integralmente',
  20: '20 - Tributação com redução de base de cálculo',
  40: '40 - Tributação Isenta',
  41: '41 - Tributação Não tributada',
  50: '50 - Tributação Suspensão',
  60: '60 - Tributação ICMS cobrado anteriormente por substituição tributária',
};

const optionsCstPis = {
  '01':
    '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
  '02':
    '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
  '04': '04 - Operação Tributável (tributação monofásica (alíquota zero))',
  '05': '05 - Operação Tributável (Substituição Tributária)',
  '06': '06 - Operação Tributável (alíquota zero)',
  '07': '07 - Operação Isenta da Contribuição',
  '08': '08 - Operação Sem Incidência da Contribuição',
  '09': '09 - Operação com Suspensão da Contribuição',
};

const optionsCstCofins = {
  '01':
    '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
  '02':
    '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
  '04': '04 - Operação Tributável (tributação monofásica (alíquota zero))',
  '05': '05 - Operação Tributável (Substituição Tributária)',
  '06': '06 - Operação Tributável (alíquota zero)',
  '07': '07 - Operação Isenta da Contribuição',
  '08': '08 - Operação Sem Incidência da Contribuição',
  '09': '09 - Operação com Suspensão da Contribuição',
};

const optionsOrigIcms = {
  0: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8;',
  1: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
  2: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
  3: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
  4: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
  5: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
  6: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natura',
  7: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural',
  8: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
};

function FieldIMposto({ label, value }) {
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        fontSize: '20px',
      }}
    >
      <span
        style={{
          fontWeight: 'bold',
        }}
      >{`${label}:`}</span>
      <span>{`${value}`}</span>
    </div>
  );
}

function getLabelOption(value, options) {
  if (!value) return '-';
  const v1 = options[value];
  return v1 ? v1 : '-';
}

async function getPermissaoAbaComprador() {
  try {
    const response = await api.get(`/obter-cargo-usuario/${getUserId()}`);
    const { status, cargo } = response.data;
    if (status) {
      return cargo.sistema_aba_comprador_no_produto ? true : false;
    }
    return false;
  } catch (e) {
    return false;
  }
}

const DialogoShowProduto = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [produto, setProduto] = useState({
    nome: '',
    peso_medio: 0,
    codigo_interno: '',
    codigo: '',
    preco_venda: 0,
    preco_oferta: 0,
    unidadesDisponivel: 0,
    pesoDisponivel: 0,
    precoCompraMedio: 0,
    precoCompraMedioImpostos: 0,
    estoque_minimo: 0,
    estoque: '',
    unidade: {
      nome: '',
      tipo: '',
    },
    familia: {
      nome: '',
    },
    fornecedorPadrao: {
      nome: '',
      nome_fantasia: '',
      razao_social: '',
      logradouro: '',
      bairro: '',
      cidade: '',
      cnpj: '',
      cpf: '',
    },
    tecla: '',
    desconto_maximo_preco_venda: '',
    permitir_criacao_ofertas: true,
    permitir_criacao_liberacoes: true,
    descricao_pesquisa_xml: '',
    //
    pallet_base: '-',
    pallet_altura: '-',
    nomenclatura_no_fornecedor: '-',
    tipo_unidade_preco_pre_compra: '-',
    peso_unitario_pu: '-',
    volume_unitario_vu: '-',
    estado_fisico: '-',
    pertencente_ao_mix: false,

    //
    fator_conversao_importacao_xml_compra: 1,
    is_fator_conversao_up: false,
    is_fator_conversao_caixaria: false,
    fator_conversao_tipo_caixaria: '-',
    is_invencivel: false,
    //
    tipo_impressao: '-',
  });
  const [produtoBase, setProdutoBase] = useState({
    nome: '',
    codigo_interno: '',
    codigo: '',
  });
  const [caixarias, setCaixarias] = useState({
    nivel_1: {
      codigo_caixa_nivel: '',
      unidades_caixa_nivel: 0,
      peso_caixa_nivel: 0,
      preco_venda_caixa_nivel: 0,
      preco_oferta_caixa_nivel: 0,
    },

    nivel_2: {
      codigo_caixa_nivel: '',
      unidades_caixa_nivel: 0,
      peso_caixa_nivel: 0,
      preco_venda_caixa_nivel: 0,
      preco_oferta_caixa_nivel: 0,
    },

    nivel_3: {
      codigo_caixa_nivel: '',
      unidades_caixa_nivel: 0,
      peso_caixa_nivel: 0,
      preco_venda_caixa_nivel: 0,
      preco_oferta_caixa_nivel: 0,
    },
  });
  const [locais, setLocais] = useState([]);
  const [categoria, setCategoria] = useState({
    nome: '',
  });
  const [fornecedores, setFornecedores] = useState([]);
  const [impostos, setImpostos] = useState({
    lbv: 0,
    lba: 0,
    ipi: 0,
    pis: 0,
    cofins: 0,
    icms_ai: 0,
    icms_int: 0,
    df: 0,
    cst: '',
    cfop: '',
    rbc: 0,
    ncm: '',
    cst_pis_entrada: '',
    cst_pis_saida: '',
    cst_cofins_entrada: '',
    cst_cofins_saida: '',
    cest: '',
    //
    fcp: 0,
    orig: '',
    pauta_fiscal: 0,
    cst_icms_entrada: '',
    cst_icms_saida: '',
    mva: 0,
    icms_fronteira: 0,
    is_caso_especial_icms_fronteira: false,
    icms_antecipacao: 0,
    is_caso_especial_icms_antecipacao: false,
  });
  const [balanca, setBalanca] = useState({
    d1: '',
    dias_validade: 0,
    exibir_dias_validade: false,
    exibir_data_embalagem: false,
    tara: {
      nome: '',
      valor: 0,
      codigo_interno: '',
    },
  });
  const [custos, setCustos] = useState([]);
  const [avarias, setAvarias] = useState([]);
  const [correcoes, setCorrecoes] = useState([]);

  const [precificacoes, setPrecificacoes] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const [configs, setConfigs] = useState(0);

  const [isComprador, setIsComprador] = useState(false);

  const refDialogoCreateAvaria = useRef(null);
  const refDialogoCreateCorrecao = useRef(null);

  const refDialogoDeleteAvaria = useRef(null);
  const refDialogoDeleteCorrecao = useRef(null);

  const refDialogoDetalhesPrecificacao = useRef(null);

  const refDialogoReprecificar = useRef(null);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function formatDadosProduto(produto) {
    const dadosFormatados = {};

    dadosFormatados.desconto_maximo_preco_venda = `${formatPreco(
      produto.desconto_maximo_preco_venda,
    )} %`;
    dadosFormatados.permitir_criacao_ofertas = produto.permitir_criacao_ofertas;
    dadosFormatados.permitir_criacao_liberacoes =
      produto.permitir_criacao_liberacoes;

    dadosFormatados.nome = produto.nome;
    dadosFormatados.codigo = produto.codigo;
    dadosFormatados.codigo_interno = produto.codigo_interno;

    dadosFormatados.preco_venda = formatMoeda(produto.preco_venda);

    dadosFormatados.preco_oferta = formatMoeda(
      getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 0 }, 0, 0),
    );

    dadosFormatados.precoCompraMedio = formatMoeda(produto.precoCompraMedio);

    dadosFormatados.precoCompraMedioImpostos = formatMoeda(
      produto.precoCompraMedioImpostos,
    );

    dadosFormatados.tecla = produto.tecla ? produto.tecla : '-';

    dadosFormatados.estoque = getEstoqueLabel(produto);

    dadosFormatados.peso_medio =
      produto.unidade.tipo === POR_PESO_FIXO
        ? `${formatPeso(dadosFormatados.peso_medio)} Kg`
        : '-';

    dadosFormatados.estoque_minimo =
      produto.unidade.tipo === POR_UNIDADE ||
      produto.unidade.tipo === POR_PESO_FIXO
        ? parseInt(produto.estoque_minimo, 10)
        : formatPeso(produto.estoque_minimo);

    if (produto.familia) {
      dadosFormatados.familia = {
        nome: produto.familia.nome,
      };
    } else {
      dadosFormatados.familia = null;
    }

    if (produto.unidade) {
      dadosFormatados.unidade = {
        nome: produto.unidade.nome,
        tipo: produto.unidade.tipo,
      };
    } else {
      dadosFormatados.unidade = null;
    }

    if (produto.fornecedorPadrao) {
      dadosFormatados.fornecedorPadrao = {
        nome: produto.fornecedorPadrao.nome
          ? produto.fornecedorPadrao.nome
          : '-',
        nome_fantasia: produto.fornecedorPadrao.nome_fantasia
          ? produto.fornecedorPadrao.nome_fantasia
          : '-',
        razao_social: produto.fornecedorPadrao.razao_social
          ? produto.fornecedorPadrao.razao_social
          : '-',
        logradouro: produto.fornecedorPadrao.logradouro
          ? produto.fornecedorPadrao.logradouro
          : '-',
        bairro: produto.fornecedorPadrao.bairro
          ? produto.fornecedorPadrao.bairro
          : '-',
        cidade: produto.fornecedorPadrao.cidade
          ? `${produto.fornecedorPadrao.cidade.nome} - ${produto.fornecedorPadrao.cidade.estado.sigla}`
          : '-',
        cnpj: produto.fornecedorPadrao.cnpj
          ? formatCNPJ(produto.fornecedorPadrao.cnpj)
          : '-',
        cpf: produto.fornecedorPadrao.cpf
          ? formatCPF(produto.fornecedorPadrao.cpf)
          : '-',
      };
    } else {
      dadosFormatados.fornecedorPadrao = null;
    }

    dadosFormatados.unidades_caixa_nivel_1 = produto.unidades_caixa_nivel_1;
    dadosFormatados.peso_caixa_nivel_1 = produto.peso_caixa_nivel_1;
    dadosFormatados.unidades_caixa_nivel_2 = produto.unidades_caixa_nivel_2;
    dadosFormatados.unidades_caixa_nivel_3 = produto.unidades_caixa_nivel_3;
    dadosFormatados.descricao_pesquisa_xml = produto.descricao_pesquisa_xml;

    dadosFormatados.pallet_base = produto.pallet_base
      ? produto.pallet_base
      : '-';
    dadosFormatados.pallet_altura = produto.pallet_altura
      ? produto.pallet_altura
      : '-';

    dadosFormatados.total_pallet =
      produto.pallet_base * produto.pallet_altura
        ? produto.pallet_base * produto.pallet_altura
        : '-';

    dadosFormatados.nomenclatura_no_fornecedor =
      produto.nomenclatura_no_fornecedor;
    dadosFormatados.tipo_unidade_preco_pre_compra = produto.tipo_unidade_preco_pre_compra
      ? getLabelTipoUnidadePrecoPreCompra(produto.tipo_unidade_preco_pre_compra)
      : '-';
    dadosFormatados.peso_unitario_pu = produto.peso_unitario_pu
      ? formatPeso(produto.peso_unitario_pu)
      : '-';
    dadosFormatados.volume_unitario_vu = produto.volume_unitario_vu
      ? `${produto.volume_unitario_vu} m3`
      : '-';
    dadosFormatados.estado_fisico = produto.estado_fisico
      ? getLabelEstadoFisico(produto.estado_fisico)
      : '-';
    dadosFormatados.pertencente_ao_mix = produto.pertencente_ao_mix;
    dadosFormatados.fator_conversao_importacao_xml_compra =
      produto.fator_conversao_importacao_xml_compra;
    dadosFormatados.is_fator_conversao_up = produto.is_fator_conversao_up;
    dadosFormatados.is_fator_conversao_caixaria =
      produto.is_fator_conversao_caixaria;
    dadosFormatados.fator_conversao_tipo_caixaria = produto.fator_conversao_tipo_caixaria
      ? getLabelFatorConversaoCaixaria(produto.fator_conversao_tipo_caixaria)
      : '-';
    dadosFormatados.is_invencivel = produto.is_invencivel;
    dadosFormatados.tipo_impressao = produto.tipo_impressao
      ? getLabelTipoImpressao(produto.tipo_impressao)
      : '-';

    return dadosFormatados;
  }

  function formatDadosProdutoBase(produtoBase) {
    let dadosFormatados = {};

    if (produtoBase) {
      dadosFormatados = {
        nome: produtoBase.nome,
        codigo_interno: produtoBase.codigo_interno,
        codigo: produtoBase.codigo,
      };
    } else {
      dadosFormatados = null;
    }

    return dadosFormatados;
  }

  function formatCaixarias(produto) {
    const dadosFormatados = {};

    if (produto.unidades_caixa_nivel_1 > 0 || produto.peso_caixa_nivel_1 > 0) {
      dadosFormatados.nivel_1 = {
        codigo_caixa_nivel: produto.codigo_caixa_nivel_1
          ? produto.codigo_caixa_nivel_1
          : '-',
        unidades_caixa_nivel:
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? `${formatPeso(produto.peso_caixa_nivel_1)} Kg`
            : `${produto.unidades_caixa_nivel_1}`,
        peso_caixa_nivel: produto.peso_caixa_nivel_1,
        preco_venda_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, ofertasProdutos: [], nivelCaixa: 1 },
            0,
            0,
          ),
        ),
        preco_oferta_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 1 }, 0, 0),
        ),
      };
    } else {
      dadosFormatados.nivel_1 = null;
    }

    if (produto.unidades_caixa_nivel_2 > 0) {
      dadosFormatados.nivel_2 = {
        codigo_caixa_nivel: produto.codigo_caixa_nivel_2
          ? produto.codigo_caixa_nivel_2
          : '-',
        unidades_caixa_nivel: produto.unidades_caixa_nivel_2,
        peso_caixa_nivel: produto.peso_caixa_nivel_2,
        preco_venda_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, ofertasProdutos: [], nivelCaixa: 2 },
            0,
            0,
          ),
        ),
        preco_oferta_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 2 }, 0, 0),
        ),
      };
    } else {
      dadosFormatados.nivel_2 = null;
    }

    if (produto.unidades_caixa_nivel_3 > 0) {
      dadosFormatados.nivel_3 = {
        codigo_caixa_nivel: produto.codigo_caixa_nivel_3
          ? produto.codigo_caixa_nivel_3
          : '-',
        unidades_caixa_nivel: produto.unidades_caixa_nivel_3,
        peso_caixa_nivel: produto.peso_caixa_nivel_3,
        preco_venda_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, ofertasProdutos: [], nivelCaixa: 3 },
            0,
            0,
          ),
        ),
        preco_oferta_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 3 }, 0, 0),
        ),
      };
    } else {
      dadosFormatados.nivel_3 = null;
    }

    return dadosFormatados;
  }

  function formatLocais(locais) {
    const dadosFormatados = [];

    if (locais) {
      if (locais.length > 0) {
        for (let i = 0; i < locais.length; i++) {
          dadosFormatados.push({
            descricao: locais[i].nome,
            area: locais[i].area ? `${locais[i].area} m²` : '-',
            setor: locais[i].setor ? locais[i].setor.nome : '-',
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatCategoria(categoria) {
    let dadosFormatados = {};

    if (categoria) {
      dadosFormatados = {
        nome: categoria.nome,
      };
    } else {
      categoria = null;
    }

    return dadosFormatados;
  }

  function formatImpostos(dadosImpostos, config) {
    let dadosFormatados = {};

    if (dadosImpostos) {
      if (dadosImpostos.length > 0) {
        const dados = dadosImpostos[0];
        dadosFormatados = {
          lbv: dados.lbv,
          lba: dados.lba,
          ipi: `${formatPreco(dados.ipi)} %`,
          pis: `${formatPreco(dados.pis)} %`,
          cofins: `${formatPreco(dados.cofins)} %`,
          icms_ai: `${formatPreco(dados.icms_ai)} %`,
          icms_int: `${formatPreco(dados.icms_int)} %`,
          df: `${formatPreco(dados.df)} %`,
          cst: dados.cst || '-',
          cfop: getLabelOption(dados.cfop, optionsCfop),
          orig: getLabelOption(dados.orig, optionsOrigIcms),
          rbc: `${formatPorcentagem(dados.rbc)}`,
          mva: `${formatPorcentagem(dados.mva)}`,
          fcp: `${formatPorcentagem(dados.fcp)}`,
          pauta_fiscal: `${formatPreco(dados.pauta_fiscal)}`,
          ncm: dados.itemNcm
            ? `${dados.ncm || '-'} (${dados.itemNcm.descricao})`
            : dados.ncm || '-',
          cst_pis_entrada: getLabelOption(dados.cst_pis_entrada, optionsCstPis),
          cst_pis_saida: getLabelOption(dados.cst_pis_saida, optionsCstPis),
          cst_cofins_entrada: getLabelOption(
            dados.cst_cofins_entrada,
            optionsCstCofins,
          ),
          cst_cofins_saida: getLabelOption(
            dados.cst_cofins_saida,
            optionsCstCofins,
          ),
          cst_icms_entrada: getLabelOption(
            dados.cst_icms_entrada,
            optionsCstIcms,
          ),
          cst_icms_saida: getLabelOption(dados.cst_icms_saida, optionsCstIcms),
          cest: dados.cest || '-',
          //
          pis_credito: dados.is_caso_especial_pis_credito
            ? `${formatPorcentagem(dados.pis_credito)}`
            : `${formatPorcentagem(config.pis)}`,
          //is_caso_especial_pis_credito: dados.is_caso_especial_pis_credito,
          pis_debito: dados.is_caso_especial_pis_debito
            ? `${formatPorcentagem(dados.pis_debito)}`
            : `${formatPorcentagem(config.pis)}`,
          //is_caso_especial_pis_debito: dados.is_caso_especial_pis_debito,
          cofins_credito: dados.is_caso_especial_cofins_credito
            ? `${formatPorcentagem(dados.cofins_credito)}`
            : `${formatPorcentagem(config.cofins)}`,
          //is_caso_especial_cofins_credito: dados.is_caso_especial_cofins_credito,
          cofins_debito: dados.is_caso_especial_cofins_debito
            ? `${formatPorcentagem(dados.cofins_debito)}`
            : `${formatPorcentagem(config.cofins)}`,
          //is_caso_especial_cofins_debito: dados.is_caso_especial_cofins_debito,
          ipi_credito: dados.is_caso_especial_ipi_credito
            ? `${formatPorcentagem(dados.ipi_credito)}`
            : `${formatPorcentagem(config.ipi)}`,
          //is_caso_especial_ipi_credito: dados.is_caso_especial_ipi_credito,
          ipi_debito: dados.is_caso_especial_ipi_debito
            ? `${formatPorcentagem(dados.ipi_debito)}`
            : `${formatPorcentagem(config.ipi)}`,
          //is_caso_especial_ipi_debito: dados.is_caso_especial_ipi_debito,
          icms_normal_credito: dados.is_caso_especial_icms_normal_credito
            ? `${formatPorcentagem(dados.icms_normal_credito)}`
            : `${formatPorcentagem(config.cidade.estado.icms_ai_compra)}`,
          //is_caso_especial_icms_normal_credito: dados.is_caso_especial_icms_normal_credito,
          icms_normal_debito: dados.is_caso_especial_icms_normal_debito
            ? `${formatPorcentagem(dados.icms_normal_debito)}`
            : `${formatPorcentagem(config.cidade.estado.icms_ai_compra)}`,
          //is_caso_especial_icms_normal_debito: dados.is_caso_especial_icms_normal_debito,
          icms_fronteira: dados.is_caso_especial_icms_fronteira
            ? `${formatPorcentagem(dados.icms_fronteira)} % (Caso Especial)`
            : ` - `,
          //is_caso_especial_icms_fronteira: dados.is_caso_especial_icms_fronteira,
          icms_antecipacao: dados.is_caso_especial_icms_antecipacao
            ? `${formatPorcentagem(dados.icms_antecipacao)} % (Caso Especial)`
            : `${formatPorcentagem(config.icms_antecipacao)} % (Padrão)`,
          //is_caso_especial_icms_antecipacao: dados.is_caso_especial_icms_antecipacao,
          cst_icms_entrada_original: dados.cst_icms_entrada,
        };
      } else {
        dadosFormatados = null;
      }
    } else {
      dadosFormatados = null;
    }

    return dadosFormatados;
  }

  function formatBalanca(dadosBalanca) {
    let dadosFormatados = {};

    if (dadosBalanca) {
      if (dadosBalanca.length > 0) {
        const dados = dadosBalanca[0];
        dadosFormatados = {
          d1: dados.d1,
          dias_validade: dados.dias_validade,
          exibir_dias_validade: dados.exibir_dias_validade ? 'Sim' : 'Não',
          exibir_data_embalagem: dados.exibir_data_embalagem ? 'Sim' : 'Não',
          tara: {
            nome: dados.taraobj ? dados.taraobj.nome : '',
            valor: dados.taraobj ? `${dados.taraobj.valor}g` : '',
            codigo_interno: dados.taraobj ? dados.taraobj.codigo_interno : '',
          },
        };
      } else {
        dadosFormatados = null;
      }
    } else {
      dadosFormatados = null;
    }

    return dadosFormatados;
  }

  function formatCustos(custos) {
    const dadosFormatados = [];

    if (custos) {
      if (custos.length > 0) {
        for (let i = 0; i < custos.length; i++) {
          dadosFormatados.push({
            id: custos[i].id,
            nome: custos[i].nome,
            preco: formatMoeda(custos[i].preco),
            unidades: custos[i].unidades,
            peso: custos[i].peso,
            total: formatMoeda(custos[i].preco * custos[i].peso),
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatAvarias(avarias, produto) {
    const dadosFormatados = [];

    if (avarias) {
      if (avarias.length > 0) {
        for (let i = 0; i < avarias.length; i++) {
          dadosFormatados.push({
            id: avarias[i].id,
            data: moment(new Date(avarias[i].created_at)).format(
              'DD/MM/YYYY - HH:mm',
            ),
            unidades: avarias[i].unidades,
            peso: avarias[i].peso,
            user: avarias[i].user ? avarias[i].user.username : '-',
            quantidade: getEstoqueLabel(
              produto,
              avarias[i].unidades,
              avarias[i].peso,
            ),
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatCorrecoes(correcoes, produto) {
    const dadosFormatados = [];

    if (correcoes) {
      if (correcoes.length > 0) {
        for (let i = 0; i < correcoes.length; i++) {
          dadosFormatados.push({
            id: correcoes[i].id,
            data: moment(new Date(correcoes[i].created_at)).format(
              'DD/MM/YYYY - HH:mm',
            ),
            user: correcoes[i].user ? correcoes[i].user.username : '-',
            unidades: correcoes[i].unidades,
            peso: correcoes[i].peso,
            quantidade: getEstoqueLabel(
              produto,
              correcoes[i].unidades,
              correcoes[i].peso,
            ),
            unidades_antes: correcoes[i].unidades_antes,
            peso_antes: correcoes[i].peso_antes,
            quantidade_antes: correcoes[i].usar_sistema_novo
              ? getEstoqueLabel(
                  produto,
                  correcoes[i].unidades_antes,
                  correcoes[i].peso_antes,
                )
              : '-',
            unidades_depois: correcoes[i].unidades_depois,
            peso_depois: correcoes[i].peso_depois,
            quantidade_depois: correcoes[i].usar_sistema_novo
              ? getEstoqueLabel(
                  produto,
                  correcoes[i].unidades_depois,
                  correcoes[i].peso_depois,
                )
              : '-',
            usar_sistema_novo: correcoes[i].usar_sistema_novo,
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatFornecedores(fornecedores) {
    const dadosFormatados = [];

    if (fornecedores) {
      if (fornecedores.length > 0) {
        for (let i = 0; i < fornecedores.length; i++) {
          const fornecedor = fornecedores[i];
          dadosFormatados.push({
            nome: fornecedor.nome ? fornecedor.nome : '-',
            nome_fantasia: fornecedor.nome_fantasia
              ? fornecedor.nome_fantasia
              : '-',
            razao_social: fornecedor.razao_social
              ? fornecedor.razao_social
              : '-',
            logradouro: fornecedor.logradouro ? fornecedor.logradouro : '-',
            bairro: fornecedor.bairro ? fornecedor.bairro : '-',
            cidade: fornecedor.cidade
              ? `${fornecedor.cidade.nome} - ${fornecedor.cidade.estado.sigla}`
              : '-',
            cnpj: fornecedor.cnpj ? formatCNPJ(fornecedor.cnpj) : '-',
            cpf: fornecedor.cpf ? formatCPF(fornecedor.cpf) : '-',
          });
        }
      }
    }

    return dadosFormatados;
  }

  function getTipoPrecificacao(value) {
    switch (value) {
      case TIPO_PRECIFICACAO_NORMAL:
        return 'Edição Normal';
      case TIPO_PRECIFICACAO_COMPRA:
        return 'Compra';
      case TIPO_PRECIFICACAO_LIBERACAO_PRECO:
        return 'Liberação de Preço';
      case TIPO_PRECIFICACAO_OFERTA:
        return 'Oferta Normal';
      case TIPO_PRECIFICACAO_ZERAR_ESTOQUE:
        return 'Oferta de Zerar Estoque';
      default:
        return '-';
    }
  }

  function formatPrecificacoes(precificacoes) {
    const dadosFormatados = [];

    if (precificacoes) {
      if (precificacoes.length > 0) {
        for (let i = 0; i < precificacoes.length; i++) {
          dadosFormatados.push({
            id: precificacoes[i].id,
            data: moment(new Date(precificacoes[i].created_at)).format(
              'DD/MM/YYYY - HH:mm',
            ),
            preco_de_venda: formatMoeda(precificacoes[i].preco_de_venda),
            produto: precificacoes[i].produto.nome,
            responsavel: precificacoes[i].user.username,
            tipo: getTipoPrecificacao(precificacoes[i].tipo),
            dadosOriginais: precificacoes[i],
          });
        }
      }
    }

    return dadosFormatados;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [id], ofertasProdutos: true },
        [
          'custos',
          'locais.setor',
          'locais.colaboradores',
          'fornecedores.cidade.estado',
          'categoria.setor',
          'unidade',
          'familia',
          'dadosBalanca.taraobj',
          'fornecedorPadrao',
          'produtoBase',
          'dadosImposto.itemNcm',
        ],
      );

      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        ['cidade.estado'],
      );

      if (data.data.length > 0) {
        setId(data.data[0].id);
        setProduto(formatDadosProduto(data.data[0]));
        setProdutoBase(formatDadosProdutoBase(data.data[0].produtoBase));
        setCaixarias(formatCaixarias(data.data[0]));
        setLocais(formatLocais(data.data[0].locais));
        setCategoria(formatCategoria(data.data[0].categoria));
        setImpostos(
          formatImpostos(data.data[0].dadosImposto, dataConfig.data[0]),
        );
        setBalanca(formatBalanca(data.data[0].dadosBalanca));
        setCustos(formatCustos(data.data[0].custos));
        setFornecedores(formatFornecedores(data.data[0].fornecedores));
        setValue(0);
        setPage(0);
        setCarregando(false);

        setIsComprador(await getPermissaoAbaComprador());
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getAvarias(page) {
    const response = await getListAPI(
      'avarias',
      ['created_at', 'desc'],
      [page + 1, perPageAvaria],
      { produto_id: id },
      ['user'],
    );
    setTotal(response.total);
    setAvarias(formatAvarias(response.data, produto));
  }

  async function getCorrecoes(page) {
    const response = await getListAPI(
      'correcoes_estoque',
      ['created_at', 'desc'],
      [page + 1, perPageCorrecao],
      { produto_id: id },
      ['user'],
    );
    setTotal(response.total);
    setCorrecoes(formatCorrecoes(response.data, produto));
  }

  async function getPrecificacoes(page) {
    const response = await getListAPI(
      'historico_precificacoes',
      ['created_at', 'desc'],
      [page + 1, perPagePrecificacao],
      { produto_id: id },
      [],
    );
    setTotal(response.total);
    setPrecificacoes(formatPrecificacoes(response.data, produto));
  }

  useEffect(() => {
    switch (value) {
      case 7:
        getAvarias(page);
        setPage(0);
        break;
      case 8:
        getCorrecoes(page);
        setPage(0);
        break;
      case 9:
        getPrecificacoes(page);
        setPage(0);
        break;
    }
  }, [value]);

  useEffect(() => {
    switch (value) {
      case 7:
        getAvarias(page);
        break;
      case 8:
        getCorrecoes(page);
        break;
      case 9:
        getPrecificacoes(page);
        break;
    }
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleCreateAvaria() {
    if (refDialogoCreateAvaria.current) {
      refDialogoCreateAvaria.current.handleOpen(id);
    }
  }

  function handleCreateCorrecao() {
    if (refDialogoCreateCorrecao.current) {
      refDialogoCreateCorrecao.current.handleOpen(id);
    }
  }

  function handleDeletarAvaria(id) {
    if (refDialogoDeleteAvaria.current) {
      refDialogoDeleteAvaria.current.handleOpen(id);
    }
  }

  function handleDeletarCorrecao(id) {
    if (refDialogoDeleteCorrecao.current) {
      refDialogoDeleteCorrecao.current.handleOpen(id);
    }
  }

  function handleDetalhesPrecificacao(dados) {
    if (refDialogoDetalhesPrecificacao.current) {
      refDialogoDetalhesPrecificacao.current.handleOpen(dados.dadosOriginais);
    }
  }

  function openDialogoReprecificacao() {
    if (refDialogoReprecificar.current) {
      refDialogoReprecificar.current.handleOpen(id);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">{`Produto ${produto.nome}`}</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Caixarias" {...a11yProps(1)} />
                  <Tab label="Locais" {...a11yProps(2)} />
                  <Tab label="Fornecedores" {...a11yProps(3)} />
                  <Tab label="Impostos" {...a11yProps(4)} />
                  <Tab label="Balança" {...a11yProps(5)} />
                  <Tab label="Custos" {...a11yProps(6)} />
                  <Tab label="Avarias" {...a11yProps(7)} />
                  <Tab label="Correções" {...a11yProps(8)} />
                  <Tab label="Precificações" {...a11yProps(9)} />
                  <Tab label="Comprador" {...a11yProps(10)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box>
                        <Box mt="1em" />
                        <Divider />
                        <Box mt="1em" />
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Geral
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Descrição:
                            </span>
                            {produto.nome}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Código:
                            </span>
                            {produto.codigo}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço de Venda:
                            </span>
                            {produto.preco_venda}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço de Oferta:
                            </span>
                            {produto.preco_oferta}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço Médio de Compra:
                            </span>
                            {produto.precoCompraMedio}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço Médio de Compra Com Impostos:
                            </span>
                            {produto.precoCompraMedioImpostos}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Família:
                            </span>
                            {produto.familia ? produto.familia.nome : '-'}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Estoque:
                            </span>
                            {produto.estoque}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Peso Médio:
                            </span>
                            {produto.peso_medio}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Tecla:
                            </span>
                            {produto.tecla}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Categoria:
                            </span>
                            {categoria.nome}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Estoque Mínimo:
                            </span>
                            {produto.estoque_minimo}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Permitir Inclusão no Jornal de Ofertas:
                            </span>
                            {produto.permitir_criacao_ofertas ? 'SIM' : 'NÃO'}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Permitir Inclusão na Liberação de Preço:
                            </span>
                            {produto.permitir_criacao_liberacoes
                              ? 'SIM'
                              : 'NÃO'}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Desconto máximo:
                            </span>
                            {produto.desconto_maximo_preco_venda}
                          </Box>
                          <Box flex={1} />
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Produto UP:
                            </span>
                            {produto.is_fator_conversao_up ? 'SIM' : 'NÃO'}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Produto Invencível:
                            </span>
                            {produto.is_invencivel ? 'SIM' : 'NÃO'}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Pertencente ao MIX:
                            </span>
                            {produto.pertencente_ao_mix ? 'SIM' : 'NÃO'}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Estado Físico:
                            </span>
                            {produto.estado_fisico}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Tipo de Impressão:
                            </span>
                            {produto.tipo_impressao}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Fator Conversão de Caixaria:
                            </span>
                            {produto.fator_conversao_tipo_caixaria}
                          </Box>
                        </Box>
                      </Box>
                      {produtoBase && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Produto Base
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Descrição:
                              </span>
                              {produtoBase.nome}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {produtoBase.codigo}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {produto.fornecedorPadrao && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Fornecedor Padrão
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Responsável:
                              </span>
                              {produto.fornecedorPadrao.nome}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Nome Fantasia:
                              </span>
                              {produto.fornecedorPadrao.nome_fantasia}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Razão Social:
                              </span>
                              {produto.fornecedorPadrao.razao_social}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Cidade:
                              </span>
                              {produto.fornecedorPadrao.cidade}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CNPJ:
                              </span>
                              {produto.fornecedorPadrao.cnpj}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CPF:
                              </span>
                              {produto.fornecedorPadrao.cpf}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {caixarias.nivel_1 && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Caixa Nível 1
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {caixarias.nivel_1.codigo_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Quantidade:
                              </span>
                              {caixarias.nivel_1.unidades_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Venda:
                              </span>
                              {caixarias.nivel_1.preco_venda_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Oferta:
                              </span>
                              {caixarias.nivel_1.preco_oferta_caixa_nivel}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {caixarias.nivel_2 && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Caixa Nível 2
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {caixarias.nivel_2.codigo_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Quantidade:
                              </span>
                              {caixarias.nivel_2.unidades_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Venda:
                              </span>
                              {caixarias.nivel_2.preco_venda_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Oferta:
                              </span>
                              {caixarias.nivel_2.preco_oferta_caixa_nivel}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {caixarias.nivel_3 && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Caixa Nível 3
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {caixarias.nivel_3.codigo_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Quantidade:
                              </span>
                              {caixarias.nivel_3.unidades_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Venda:
                              </span>
                              {caixarias.nivel_3.preco_venda_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Oferta:
                              </span>
                              {caixarias.nivel_3.preco_oferta_caixa_nivel}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Descrição</StyledTableCell>
                              <StyledTableCell>Área</StyledTableCell>
                              <StyledTableCell>Setor</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {locais.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.descricao}
                                </StyledTableCell>
                                <StyledTableCell>{row.area}</StyledTableCell>
                                <StyledTableCell>{row.setor}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Responsável</StyledTableCell>
                              <StyledTableCell>Nome Fantasia</StyledTableCell>
                              <StyledTableCell>Razão Social</StyledTableCell>
                              <StyledTableCell>CNPJ</StyledTableCell>
                              <StyledTableCell>CPF</StyledTableCell>
                              <StyledTableCell>Cidade</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {fornecedores.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.nome}</StyledTableCell>
                                <StyledTableCell>
                                  {row.nome_fantasia}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.razao_social}
                                </StyledTableCell>
                                <StyledTableCell>{row.cnpj}</StyledTableCell>
                                <StyledTableCell>{row.cpf}</StyledTableCell>
                                <StyledTableCell>{row.cidade}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <Box display="flex">
                        <Box
                          flex={2}
                          mr="1em"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                          }}
                        >
                          <Box
                            flex={1}
                            style={{
                              padding: '10px',
                              border: '3px solid red',
                              borderRadius: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              Geral
                            </Typography>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                              }}
                            >
                              <FieldIMposto label="NCM" value={impostos.ncm} />
                              <FieldIMposto
                                label="Origem da Mercadoria"
                                value={impostos.orig}
                              />
                              <FieldIMposto
                                label="CEST"
                                value={impostos.cest}
                              />
                              <FieldIMposto
                                label="CFOP"
                                value={impostos.cfop}
                              />
                            </div>
                          </Box>
                          <Box
                            flex={1}
                            style={{
                              padding: '10px',
                              border: '3px solid red',
                              borderRadius: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              ICMS
                            </Typography>

                            <Box
                              style={{
                                padding: '10px',
                                border: '3px solid blue',
                                borderRadius: '10px',
                                marginBottom: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'blue',
                                  fontWeight: 'bold',
                                  //textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                ENTRADA
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <FieldIMposto
                                  label="CST"
                                  value={impostos.cst_icms_entrada}
                                />
                                <FieldIMposto
                                  label="Aliquota"
                                  value={impostos.icms_normal_credito}
                                />
                              </div>
                            </Box>
                            <Box
                              style={{
                                padding: '10px',
                                border: '3px solid blue',
                                borderRadius: '10px',
                                marginBottom: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'blue',
                                  fontWeight: 'bold',
                                  //textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                SAÍDA
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <FieldIMposto
                                  label="CST"
                                  value={impostos.cst_icms_saida}
                                />
                                <FieldIMposto
                                  label="Aliquota"
                                  value={impostos.icms_normal_debito}
                                />
                                {impostos.cst_icms_entrada_original === '60' ? (
                                  <FieldIMposto
                                    label="RBC (Redução da Base de Cálculo)"
                                    value={impostos.rbc}
                                  />
                                ) : null}
                                {impostos.cst_icms_entrada_original === '60' ? (
                                  <FieldIMposto
                                    label="MVA (Margem de Valor Agregado)"
                                    value={impostos.mva}
                                  />
                                ) : null}
                                {impostos.cst_icms_entrada_original === '60' ? (
                                  <FieldIMposto
                                    label="FCP (Fundo de Combate a Pobreza)"
                                    value={impostos.fcp}
                                  />
                                ) : null}
                                {impostos.cst_icms_entrada_original === '60' ? (
                                  <FieldIMposto
                                    label="Pauta Fiscal"
                                    value={impostos.pauta_fiscal}
                                  />
                                ) : null}
                              </div>
                            </Box>
                          </Box>

                          <Box
                            flex={1}
                            style={{
                              padding: '10px',
                              border: '3px solid red',
                              borderRadius: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              PIS
                            </Typography>

                            <Box
                              style={{
                                padding: '10px',
                                border: '3px solid blue',
                                borderRadius: '10px',
                                marginBottom: '10px',
                              }}
                              //gutterBottom
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'blue',
                                  fontWeight: 'bold',
                                  //textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                ENTRADA
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <FieldIMposto
                                  label="CST"
                                  value={impostos.cst_pis_entrada}
                                />
                                <FieldIMposto
                                  label="Aliquota"
                                  value={impostos.pis_credito}
                                />
                              </div>
                            </Box>
                            <Box
                              style={{
                                padding: '10px',
                                border: '3px solid blue',
                                borderRadius: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'blue',
                                  fontWeight: 'bold',
                                  //textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                SAÍDA
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <FieldIMposto
                                  label="CST"
                                  value={impostos.cst_pis_saida}
                                />
                                <FieldIMposto
                                  label="Aliquota"
                                  value={impostos.pis_debito}
                                />
                              </div>
                            </Box>
                          </Box>

                          <Box
                            flex={1}
                            style={{
                              padding: '10px',
                              border: '3px solid red',
                              borderRadius: '10px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textDecoration: 'underline',
                              }}
                            >
                              COFINS
                            </Typography>

                            <Box
                              style={{
                                padding: '10px',
                                border: '3px solid blue',
                                borderRadius: '10px',
                                marginBottom: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'blue',
                                  fontWeight: 'bold',
                                  //textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                ENTRADA
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <FieldIMposto
                                  label="CST"
                                  value={impostos.cst_cofins_entrada}
                                />
                                <FieldIMposto
                                  label="Aliquota"
                                  value={impostos.cofins_credito}
                                />
                              </div>
                            </Box>

                            <Box
                              style={{
                                padding: '10px',
                                border: '3px solid blue',
                                borderRadius: '10px',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                  color: 'blue',
                                  fontWeight: 'bold',
                                  //textAlign: 'center',
                                  textDecoration: 'underline',
                                }}
                              >
                                SAÍDA
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <FieldIMposto
                                  label="CST"
                                  value={impostos.cst_cofins_saida}
                                />
                                <FieldIMposto
                                  label="Aliquota"
                                  value={impostos.cofins_debito}
                                />
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      {balanca && (
                        <>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Descrição Reduzida:
                              </span>
                              {balanca.d1}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Dias de Validade:
                              </span>
                              {balanca.dias_validade}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Exibir dias de validade:
                              </span>
                              {balanca.exibir_dias_validade}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Exibir data de validade:
                              </span>
                              {balanca.exibir_data_embalagem}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Tara:
                              </span>
                              {balanca.tara.valor}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código de Balança:
                              </span>
                              {produto.codigo_interno}
                            </Box>
                          </Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                        </>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Descrição</StyledTableCell>
                              <StyledTableCell>Preço</StyledTableCell>
                              <StyledTableCell>Quantidade</StyledTableCell>
                              <StyledTableCell>Total</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {custos.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.descricao}
                                </StyledTableCell>
                                <StyledTableCell>{row.preco}</StyledTableCell>
                                <StyledTableCell>{row.peso}</StyledTableCell>
                                <StyledTableCell>{row.total}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleCreateAvaria()}
                        >
                          Novo
                        </Button>
                      </div>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data</StyledTableCell>
                              <StyledTableCell>Usuário</StyledTableCell>
                              <StyledTableCell>Quantidade</StyledTableCell>
                              <StyledTableCell>Ações</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {avarias.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.data}</StyledTableCell>
                                <StyledTableCell>{row.user}</StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <IconButton
                                    aria-label="excluir"
                                    size="small"
                                    onClick={() => handleDeletarAvaria(row.id)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[perPageAvaria]}
                          count={total}
                          rowsPerPage={perPageAvaria}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={() => {}}
                          labelRowsPerPage="Por página"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${
                              count !== -1 ? count : `more than ${to}`
                            }`
                          }
                        />
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleCreateCorrecao()}
                        >
                          Novo
                        </Button>
                      </div>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data</StyledTableCell>
                              <StyledTableCell>Usuário</StyledTableCell>
                              <StyledTableCell>Qtde Antes</StyledTableCell>
                              <StyledTableCell>Qtde Depois</StyledTableCell>
                              <StyledTableCell>Correção</StyledTableCell>
                              <StyledTableCell>Ações</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {correcoes.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.data}</StyledTableCell>
                                <StyledTableCell>{row.user}</StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade_antes}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade_depois}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <IconButton
                                    aria-label="excluir"
                                    size="small"
                                    onClick={() =>
                                      handleDeletarCorrecao(row.id)
                                    }
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[perPageCorrecao]}
                          count={total}
                          rowsPerPage={perPageCorrecao}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={() => {}}
                          labelRowsPerPage="Por página"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${
                              count !== -1 ? count : `more than ${to}`
                            }`
                          }
                        />
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={9}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      {isComprador ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => openDialogoReprecificacao()}
                          >
                            Novo
                          </Button>
                        </div>
                      ) : null}
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data</StyledTableCell>
                              <StyledTableCell>Tipo</StyledTableCell>
                              <StyledTableCell>Responsável</StyledTableCell>
                              <StyledTableCell>Preço de Venda</StyledTableCell>
                              <StyledTableCell>Ações</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {precificacoes.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.data}</StyledTableCell>
                                <StyledTableCell>{row.tipo}</StyledTableCell>
                                <StyledTableCell>
                                  {row.responsavel}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.preco_de_venda}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Tooltip title="Detalhes">
                                    <IconButton
                                      aria-label="excluir"
                                      size="small"
                                      onClick={() =>
                                        handleDetalhesPrecificacao(row)
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[perPageAvaria]}
                          count={total}
                          rowsPerPage={perPagePrecificacao}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={() => {}}
                          labelRowsPerPage="Por página"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${
                              count !== -1 ? count : `more than ${to}`
                            }`
                          }
                        />
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={10}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Pallets
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Mesa (lastro) do Pallet:
                          </span>
                          {produto.pallet_base}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Altura do Pallet:
                          </span>
                          {produto.pallet_altura}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Quantidade Total no Pallet:
                          </span>
                          {produto.total_pallet}
                        </Box>
                      </Box>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Fornecedor
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Referência do Fornecedor:
                          </span>
                          {produto.nomenclatura_no_fornecedor}
                        </Box>
                        <Box flex={1} />
                      </Box>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Pré Compra
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Unidade do Preço negociodo na Pré Compra:
                          </span>
                          {produto.tipo_unidade_preco_pre_compra}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Peso Unitário (PU):
                          </span>
                          {produto.peso_unitario_pu}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Volume Unitário (VU):
                          </span>
                          {produto.volume_unitario_vu}
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
      <DialogoCreateAvaria
        ref={refDialogoCreateAvaria}
        handleClose={(status) => {
          if (status) getAvarias(page);
        }}
      />
      <DialogoCreateCorrecao
        ref={refDialogoCreateCorrecao}
        handleClose={(status) => {
          if (status) getCorrecoes(page);
        }}
      />
      <DialogoDeletarAvaria
        ref={refDialogoDeleteAvaria}
        handleClose={() => getAvarias(page)}
      />
      <DialogoDeletarCorrecao
        ref={refDialogoDeleteCorrecao}
        handleClose={() => getCorrecoes(page)}
      />
      <DialogoDetalhesItens ref={refDialogoDetalhesPrecificacao} />
      <DialogoReprecificar
        ref={refDialogoReprecificar}
        handleClose={() => {
          getPrecificacoes(0);
        }}
      />
    </Dialog>
  );
});

export default DialogoShowProduto;
