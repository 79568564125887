import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
  api,
  getManyAPI,
} from '../../../services';
import {
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
} from '../../../utils';
import DialogoReprecificar from './DialogoReprecificar';

function getPrecosOfertas(produto) {
  let precoOfertaJornal = 0;
  let precoOfertaZerarEstoque = 0;

  if (produto.ofertasProdutos.length > 0) {
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo === TIPO_OFERTA_PRODUTO_JORNAL) {
        precoOfertaJornal = oferta.valor;
      } else if (oferta.tipo === TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE) {
        precoOfertaZerarEstoque = oferta.valor;
      }
    }
  }

  return {
    precoOfertaJornal,
    precoOfertaZerarEstoque,
  };
}

const ContainerDialogoPrecificar = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const refDialogoPrecificar = useRef(null);
    const [dados, setDados] = useState(null);
    const [carregando, setCarregando] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    async function getConfig() {
      const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
        'cidade.estado',
      ]);
      if (config.data.length <= 0) {
        throw 'Configurações inacessíveis!';
      }
      return config.data[0];
    }

    async function getProduto(id) {
      const data = await getManyAPI(
        'produtos/search/autocomplete',
        { id: [id], ofertasProdutos: true },
        ['dadosImposto', 'unidade'],
      );
      if (data.data.length > 0) {
        const produto = data.data[0];
        const { precoOfertaJornal, precoOfertaZerarEstoque } = getPrecosOfertas(
          produto,
        );
        return {
          ...produto,
          precoOfertaJornal,
          precoOfertaZerarEstoque,
        };
      }
      throw 'Este produto não existe!';
    }

    async function getDadosPrecificacaoAnterior(produto_id) {
      const dados = await api.get(`/get_ultimo_lote_precificado/${produto_id}`);
      return dados.data;
    }

    async function reiniciar(produtoId) {
      try {
        setCarregando(true);
        const produto = await getProduto(produtoId);
        const config = await getConfig();
        const {
          fornecedor,
          dadosPrecificacaoAnterior,
          quantidadeComprada,
          valorNegociadoComAIndustria,
        } = await getDadosPrecificacaoAnterior(produto.id);

        setDados({
          fornecedor,
          dadosPrecificacaoAnterior,
          quantidadeComprada,
          valorNegociadoComAIndustria,
          config,
          produto,
        });

        setCarregando(false);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleClose(null);
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(produtoId) {
        reiniciar(produtoId);
      },
    }));

    useEffect(() => {
      if (dados) {
        if (refDialogoPrecificar.current) {
          refDialogoPrecificar.current.handleOpen();
        }
      }
    }, [dados]);

    async function salvarPrecificacaoNormal(dadosPrecificacao) {
      const response = await api.post(
        `/registrar-reprecificacao/${getUserId()}/${dados.produto.id}`,
        dadosPrecificacao,
      );
      const { status, message } = response.data;

      if (status) {
        enqueueSnackbar(`Produto Reprecificado com Sucesso`, {
          variant: 'success',
        });
        handleClose();
      } else {
        enqueueSnackbar(`${message}`, {
          variant: 'error',
        });
      }
    }

    return (
      <DialogoReprecificar
        ref={refDialogoPrecificar}
        handleClose={(dados) => {
          if (!carregando) {
            salvarPrecificacaoNormal(dados);
            setDados(null);
          }
        }}
        quantidadeComprada={dados ? dados.quantidadeComprada : 0}
        produto={dados ? dados.produto : null}
        fornecedor={dados ? dados.fornecedor : null}
        config={dados ? dados.config : null}
        dadosPrecificacaoAnterior={
          dados ? dados.dadosPrecificacaoAnterior : null
        }
        valorNegociadoComAIndustria={
          dados ? dados.valorNegociadoComAIndustria : 0
        }
      />
    );
  },
);

export default ContainerDialogoPrecificar;
