import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import { formatMoeda, formatDateDDMMYYYY } from '../../../../../utils';

const styles = StyleSheet.create({
  page: {},
  body: {
    width: '100%',
    fontSize: '7',
    padding: '10px',
  },
  title: {
    /* display: 'flex',
    alignItems: 'center',
    width: '100%', */
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    //backgroundColor: '#E4E4E4',
  },
  header2: {
    display: 'flex',
    //backgroundColor: '#E4E4E4',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10',
    fontWeight: 'bold',
    borderBottom: '1 solid black',
    padding: '2px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#ffff00',
  },
  contentTotaisTotais: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid black',
    backgroundColor: '#f96c25',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    /* justifyContent: 'center', */
  },
  item2: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    /* justifyContent: 'center', */
  },
  item3: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
  grupo: {
    border: '1 solid black',
  },
});

function getPeriodoLabel(periodo) {
  if (periodo) {
    return `${formatDateDDMMYYYY(periodo.start)} a ${formatDateDDMMYYYY(
      periodo.end,
    )}`;
  }
  return '-';
}

function getTotal(dados, campo) {
  let total = 0;

  for (let i = 0; i < dados.length; i++) {
    total += dados[i].dadosOriginais[campo];
  }

  return total;
}
const stiloLinhaHeaderMaior = { fontSize: '9', paddingBottom: '3' };
const stiloLinhaHeader = {
  fontSize: '8',
  marginBottom: '3',
  paddingHorizontal: '2',
};
const stiloLinhaNormal = {
  paddingBottom: '1',
  paddingTop: '1',
  paddingHorizontal: '2',
};
const stiloLinhaAgrupamento = {
  backgroundColor: '#E4E4E4',
  fontWeight: '1200',
  borderBottom: '1 solid black',
  fontFamily: 'Roboto-Bold',
  paddingBottom: '2',
  paddingHorizontal: '2',
};
const stiloLinhaTotais = {
  fontWeight: 'bold',
  backgroundColor: '#E4E4E4',
  marginBottom: '3',
  fontFamily: 'Roboto-Bold',
  paddingBottom: '2',
  paddingHorizontal: '2',
};

const stiloLinhaTotaisTotais = {
  fontWeight: 'bold',
  backgroundColor: '#E4E4E4',
  marginTop: '3',
  fontFamily: 'Roboto-Bold',
  paddingBottom: '3',
  paddingHorizontal: '2',
  borderTop: '2 solid black',
  fontSize: '9',
};

function Grupo({ label, rows, prefixo = '' }) {
  const totalFrete = formatMoeda(getTotal(rows, 'frete'));
  const totalDesconto = formatMoeda(getTotal(rows, 'desconto_em_nfe'));
  const totalIcms = formatMoeda(getTotal(rows, 'icms_normal'));
  const totalIcmsSt = formatMoeda(getTotal(rows, 'icms_st'));
  const totalPis = formatMoeda(getTotal(rows, 'pis'));
  const totalCofins = formatMoeda(getTotal(rows, 'cofins'));
  const totalIpi = formatMoeda(getTotal(rows, 'ipi'));
  const totalValorNfe = formatMoeda(getTotal(rows, 'total_nfe'));
  const totalBcCalculoIcms = formatMoeda(getTotal(rows, 'bc_calculo_icms'));
  const totalOutrasDespesas = formatMoeda(getTotal(rows, 'outras_despesas'));
  const totalSeguros = formatMoeda(getTotal(rows, 'seguros'));

  return (
    <>
      <View style={styles.grupo}>
        <View style={styles.header}>
          <View style={[styles.item, { flex: 1, ...stiloLinhaAgrupamento }]}>
            <Text>{`${prefixo} - ${label}`}</Text>
          </View>
        </View>
        {rows.map((item) => (
          <View style={styles.header}>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.data}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.numero_nfe}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.uf}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.fornecedor}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.ipi}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.icms_st}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.outras_despesas}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.frete}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.seguros}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.desconto_em_nfe}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.pis}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.cofins}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.icms_normal}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.bc_calculo_icms}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.isento ? 'SIM' : 'NÃO'}</Text>
            </View>
            <View style={[styles.item, { flex: 1, ...stiloLinhaNormal }]}>
              <Text>{item.total_nfe}</Text>
            </View>
          </View>
        ))}
      </View>
      <View style={styles.header}>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>SubTotal:</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalIpi}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalIcmsSt}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalOutrasDespesas}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalFrete}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalSeguros}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalDesconto}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalPis}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalCofins}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalIcms}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalBcCalculoIcms}</Text>
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text />
        </View>
        <View style={[styles.item, { flex: 1, ...stiloLinhaTotais }]}>
          <Text>{totalValorNfe}</Text>
        </View>
      </View>
    </>
  );
}

const ListaProdutos = ({ dados, ordenacao, periodo, totais }) => {
  const periodoLabel = getPeriodoLabel(periodo);
  return (
    <Document
      author="anderson"
      title={`CARUARU FRIOS - RELATÓRIO COMPRAS FISCAIS - ${periodoLabel}`}
    >
      <Page size="A4" style={styles.page} wrap orientation="landscape">
        <View style={styles.body}>
          <View
            style={[
              styles.title,
              { margin: '0 0', fontWeight: 'bold', fontSize: '12' },
            ]}
          >
            <Text style={{ marginTop: '0' }}>CARUARU FRIOS LTDA</Text>
            <Text style={{ marginTop: '5', fontSize: '10', marginBottom: '5' }}>
              {`Relação de Compras - Periodo de ${periodoLabel}.`}
            </Text>
          </View>

          <View style={styles.container}>
            <View style={styles.header}>
              <View
                style={[
                  styles.item2,
                  {
                    flex: 4,
                    backgroundColor: '#dfdeb8',
                    ...stiloLinhaHeaderMaior,
                  },
                ]}
              >
                <Text>Dados de Identificação </Text>
              </View>
              <View
                style={[
                  styles.item2,
                  {
                    flex: 6,
                    backgroundColor: '#ddebf6',
                    ...stiloLinhaHeaderMaior,
                  },
                ]}
              >
                <Text>Repasses e Descontos</Text>
              </View>
              <View
                style={[
                  styles.item2,
                  {
                    flex: 3,
                    backgroundColor: '#fff2cd',
                    ...stiloLinhaHeaderMaior,
                  },
                ]}
              >
                <Text>Impostos</Text>
              </View>
              <View
                style={[
                  styles.item2,
                  {
                    flex: 3,
                    backgroundColor: '#dfdeb8',
                    ...stiloLinhaHeaderMaior,
                  },
                ]}
              >
                <Text />
              </View>
            </View>
            <View style={styles.header}>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#dfdeb8', ...stiloLinhaHeader },
                ]}
              >
                <Text>Data</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#dfdeb8', ...stiloLinhaHeader },
                ]}
              >
                <Text>Nº NF</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#dfdeb8', ...stiloLinhaHeader },
                ]}
              >
                <Text>UF</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#dfdeb8', ...stiloLinhaHeader },
                ]}
              >
                <Text>Fornecedor</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#ddebf6', ...stiloLinhaHeader },
                ]}
              >
                <Text>IPI</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#ddebf6', ...stiloLinhaHeader },
                ]}
              >
                <Text>ICMS ST</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#ddebf6', ...stiloLinhaHeader },
                ]}
              >
                <Text>Frete</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#ddebf6', ...stiloLinhaHeader },
                ]}
              >
                <Text>OD</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#ddebf6', ...stiloLinhaHeader },
                ]}
              >
                <Text>Seguros</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#ddebf6', ...stiloLinhaHeader },
                ]}
              >
                <Text>Des. em Nfe</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#fff2cd', ...stiloLinhaHeader },
                ]}
              >
                <Text>PIS</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#fff2cd', ...stiloLinhaHeader },
                ]}
              >
                <Text>COFINS</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#fff2cd', ...stiloLinhaHeader },
                ]}
              >
                <Text>ICMS Normal</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#dfdeb8', ...stiloLinhaHeader },
                ]}
              >
                <Text>BC ICMS</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#dfdeb8', ...stiloLinhaHeader },
                ]}
              >
                <Text>Isenta?</Text>
              </View>
              <View
                style={[
                  styles.item,
                  { flex: 1, backgroundColor: '#dfdeb8', ...stiloLinhaHeader },
                ]}
              >
                <Text>Valor da Nfe</Text>
              </View>
            </View>
            {dados.map((item) => (
              <Grupo
                label={item[0]}
                rows={item[1]}
                prefixo={ordenacao === 0 ? 'Data de Emissão' : 'Fornecedor'}
              />
            ))}
            <View style={styles.header}>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text />
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text />
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text />
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text />
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalIpi}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalIcmsSt}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalOutrasDespesas}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalFrete}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalSeguros}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalDesconto}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalPis}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalCofins}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalIcms}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalBcCalculoIcms}</Text>
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text />
              </View>
              <View
                style={[styles.item, { flex: 1, ...stiloLinhaTotaisTotais }]}
              >
                <Text>{totais.totalValorNfe}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ListaProdutos;
