import React, { useRef, useState, useEffect, useContext } from 'react';

import { makeStyles, Box } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';

import {
  DialogoConfirmacao,
  SearchProduto,
  Actions,
  Label,
  LabelEstoque,
  LabelSubtotal,
  // DialogoProduto,
  DialogoProduto2 as DialogoProduto,
} from '../../../..';
import { getUsername, getListAllAPI } from '../../../../../services';
import {
  toStringLotes,
  POR_UNIDADE,
  POR_PESO_FIXO,
  getEstoqueLabel,
  formatMoeda,
  ceil10,
  STATUS_ATIVO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getTotalCompra,
  TIPO_IMPRESSAO_UN,
} from '../../../../../utils';
// import DialogoCarregarNota from './DialogoCarregarNota';

import {
  LabelTeclasAtalho,
  DialogoDeletarComSenha,
} from '../../../../DialogoEditarVenda/components';
import { useLotes } from '../../hooks';
import { CompraContext } from '../contextCompra';
//import DialogoPrecificacao from './DialogoPrecificacao';
import DialogoDetalhes from '../PreInicioFrenteCaixaCompra/DialogoDetalhes';
import AppBar from './AppBar';
import DialogoDetalhesItens from './DialogoDetalhesItens';
import DialogoFiltrosProduto from './DialogoFiltrosProduto';
import DialogoGrafico from './DialogoGrafico';
import DialogoPrecificacao from './DialogoPrecificacaoNova';
import FormLote from './FormLote';
import Tabela from './Tabela2/indexCompra';

function getTotal(peso, unidades, precoUnitario, produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      return unidades * precoUnitario;
    }
    return peso * precoUnitario;
  }
  return 0;
}

const useStyles = makeStyles(() => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  header: {
    /* height: '17%', */
  },
  body: {
    /* height: '83%', */
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
}));

const FECHAR_COMPRA_ACTION = 'F8';
const CANCELAR_COMPRA_ACTION = 'F4';
const DELETAR_LOTE_ACTION = 'F2';
const CRIAR_PRODUTO_ACTION = 'F9';
const EDITAR_PRODUTO_ACTION = 'F7';
const CARREGAR_NOTA_ACTION = 'F11';
const DETALHES_ACTION = 'DETALHES';
const FILTROS_PRODUTO_ACTION = 'FILTROS_PRODUTO';

function getTotalParcelas(parcelas) {
  let soma = 0;
  for (let i = 0; i < parcelas.length; i += 1) {
    soma += parcelas[i].valor;
  }
  return soma;
}

const FrenteCompra = ({
  irParaTelaInit,
  irParaTelaFinalizarCompra,
  showAtendente = true,
}) => {
  const {
    compra: {
      lotes,
      idEdit,
      fornecedor,
      config,
      numeroCompra,
      parcelas,
      repasse_frete,
      repasse_ipi,
      repasse_outras_despesas,
      repasse_seguros,
      repasse_st,
      //
      subTotalProdutos,
      total_repasse_frete,
      total_repasse_ipi,
      total_repasse_outras_despesas,
      total_repasse_seguros,
      total_repasse_st,
      //
      isProdutosSomenteFornecedor,
      isIncluiInativos,
      //isImportacaoXml,
    },
    dispatch,
  } = useContext(CompraContext);
  const [atendente, setAtendente] = useState('');
  const refDialogoConfirmacao = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoPrecificacao = useRef(null);
  const refDialogoCarregarNota = useRef(null);
  const refDialogoDetalhes = useRef(null);
  const refDialogoDetalhesItens = useRef(null);

  const refDialogoGrafico = useRef(null);

  const refDialogoFiltrosProduto = useRef(null);

  const {
    searchHandle,
    addNewItem,
    setSearch,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
  } = useLotes(
    lotes,
    updateItem,
    repasse_frete,
    repasse_ipi,
    repasse_outras_despesas,
    repasse_seguros,
    repasse_st,
  );

  const classes = useStyles();
  const refDialogoProduto = useRef(null);

  const nomeProduto =
    produto !== null
      ? produto.nome
      : idEdit > 0
      ? 'Editar Compra'
      : 'Nova Compra';

  function updateItem(body) {
    dispatch({
      type: 'ADD_LOTE',
      lote: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  async function getAtendente() {
    const username = getUsername();
    setAtendente(username || '');
  }

  useEffect(() => {
    getAtendente();
  }, []);

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_LOTE', indices });
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_COMPRA_ACTION:
        irParaTelaInit();
        break;
      default:
        break;
    }
  }

  function openDialogoCarregarNota() {
    if (refDialogoCarregarNota.current) {
      refDialogoCarregarNota.current.handleOpen();
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_COMPRA_ACTION:
        if (true) {
          irParaTelaFinalizarCompra();
        } else {
          enqueueSnackbar('É necessário ao menos um lote na compra!', {
            variant: 'warning',
          });
        }
        break;
      case CANCELAR_COMPRA_ACTION:
        /*if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Calcelamento da compra',
            'Tem certeza que deseja cancelar essa compra!',
            CANCELAR_COMPRA_ACTION,
          );
        }*/
        irParaTelaInit();
        break;
      case DELETAR_LOTE_ACTION:
        if (lotes.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case CRIAR_PRODUTO_ACTION:
        // if (refDialogoProduto.current) refDialogoProduto.current.handleOpen(0);
        if (refDialogoProduto.current) refDialogoProduto.current.handleCreate();
        break;
      case EDITAR_PRODUTO_ACTION:
        // if (refDialogoProduto.current) refDialogoProduto.current.handleOpen(1);
        if (refDialogoProduto.current) {
          const idProduto = produto ? produto.id : 0;
          if (idProduto > 0) {
            refDialogoProduto.current.handleEdit(idProduto);
          }
        }
        break;
      case DETALHES_ACTION:
        if (refDialogoDetalhes.current) refDialogoDetalhes.current.handleOpen();
        break;
      case FILTROS_PRODUTO_ACTION:
        if (refDialogoFiltrosProduto.current)
          refDialogoFiltrosProduto.current.handleOpen();
        break;
      /* case CARREGAR_NOTA_ACTION:
        openDialogoCarregarNota();
        break; */
      default:
        break;
    }
  }

  async function updateProduto(idUpdate) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['unidade', 'categoria', 'familia'],
      );
      if (data.data.length > 0) {
        setProduto(data.data[0]);
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      // nada
    }
  }

  function middlewareSearchHandle(codigo, id) {
    if (codigo || id) {
      if (refDialogoPrecificacao.current)
        refDialogoPrecificacao.current.handleOpen(
          codigo,
          config,
          fornecedor,
          id,
        );
    }
    searchHandle(codigo, id);
  }

  const totalParcelas = getTotalParcelas(parcelas);

  function getUnidade(produto) {
    if (!produto) return '';
    if (produto.unidade.tipo === POR_UNIDADE) return 'Un';
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 'Kg';
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) {
        return 'Un';
      }
      return 'Kg';
    }
    return '';
  }

  function abrirGrafico(dados) {
    if (refDialogoGrafico.current) {
      //refDialogoGrafico.current.handleOpen(data);
      if (dados.dadosPrecificacao) {
        let fatorPesoMedio = 1;

        const unidadeTrabalhada = getUnidade(dados.dadosOriginais.produto);

        if (dados.dadosOriginais.produto) {
          if (dados.dadosOriginais.produto.unidade.tipo === POR_PESO_FIXO) {
            fatorPesoMedio = dados.dadosOriginais.produto.peso_medio;
          }
        }

        refDialogoGrafico.current.handleOpen(
          [
            {
              label: 'Valor Negociado com a indústria',
              backgroundColor: 'rgba(68, 114, 196, 1)',
              borderColor: 'rgba(68, 114, 196, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.valor_negociado_com_a_industria *
                  fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Margem de Custo Operacional',
              backgroundColor: 'rgba(237, 125, 49, 1)',
              borderColor: 'rgba(237, 125, 49, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.custo_operacional * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Diferença de ICMS',
              backgroundColor: 'rgba(165, 165, 165, 1)',
              borderColor: 'rgba(165, 165, 165, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.diferenca_de_icms * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Lucro Líquido',
              backgroundColor: 'rgba(91, 155, 213, 1)',
              borderColor: 'rgba(91, 155, 213, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.lucro_bruto * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Diferença de COFINS',
              backgroundColor: 'rgba(112, 173, 71, 1)',
              borderColor: 'rgba(112, 173, 71, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.diferenca_de_cofins * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Margem de Custo de Cartão',
              backgroundColor: 'rgba(10, 224, 196, 1)',
              borderColor: 'rgba(10, 224, 196, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.custo_de_cartao * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Diferença de PIS',
              backgroundColor: 'rgba(7, 15, 49, 1)',
              borderColor: 'rgba(7, 15, 49, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.difereca_de_pis * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do IPI',
              backgroundColor: 'rgba(200, 165, 165, 1)',
              borderColor: 'rgba(200, 165, 165, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_do_ipi * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do Frete',
              backgroundColor: 'rgba(90, 1, 213, 1)',
              borderColor: 'rgba(90, 1, 213, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_do_frete * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do ST',
              backgroundColor: 'rgba(200, 0, 0, 1)',
              borderColor: 'rgba(200, 0, 0, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_de_st * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do Seguro',
              backgroundColor: 'rgba(60, 60, 6, 1)',
              borderColor: 'rgba(60, 60, 6, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_do_seguro * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse de Outras Despesas',
              backgroundColor: 'rgba(5, 5, 5, 1)',
              borderColor: 'rgba(5, 5, 5, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_de_outras_despesas *
                  fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'ICMS de Fronteira',
              backgroundColor: 'rgba(100, 50, 100, 1)',
              borderColor: 'rgba(100, 50, 100)',
              valor: ceil10(
                dados.dadosPrecificacao.icms_fronteira * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'ICMS de Antecipação',
              backgroundColor: 'rgba(10, 20, 250, 1)',
              borderColor: 'rgba(10, 20, 250, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.icms_antecipacao * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Desconto Total',
              backgroundColor: 'rgba(37, 205, 200, 1)',
              borderColor: 'rgba(37, 205, 200, 1)',
              valor: ceil10(
                -1 *
                  (dados.dadosPrecificacao.desconto_em_nfe +
                    dados.dadosPrecificacao.desconto_em_boleto +
                    dados.dadosPrecificacao.desconto_em_bonificacao +
                    dados.dadosPrecificacao
                      .desconto_em_backlight_e_ponto_extra +
                    dados.dadosPrecificacao.desconto_em_jornal_de_ofertas +
                    dados.dadosPrecificacao
                      .desconto_em_descargo_de_mercadorias) *
                  fatorPesoMedio,
                -2,
              ),
            },
          ],
          `${formatMoeda(
            dados.dadosPrecificacao.preco_de_venda * fatorPesoMedio,
          )} / ${unidadeTrabalhada}`,
          dados.produto,
        );
      } else {
        enqueueSnackbar(`Este lote não tem dados de precificação salvos!`, {
          variant: 'error',
        });
      }
    }
  }

  function handleDetalhes(dados) {
    if (refDialogoDetalhesItens.current) {
      refDialogoDetalhesItens.current.handleOpen(dados);
    }
  }

  function updateFiltros(isProdutosSomenteFornecedor, isIncluiInativos) {
    dispatch({
      type: 'UPDATE_FILTROS_PESQUISA',
      isProdutosSomenteFornecedor,
      isIncluiInativos,
    });
  }

  function getFiltros() {
    const filters = {};
    if (isProdutosSomenteFornecedor) {
      filters.fornecedor_id = fornecedor.id;
    }
    if (!isIncluiInativos) {
      filters.status = STATUS_ATIVO;
    }
    return filters;
  }

  function distribuiRepasses() {}

  function reprecificar({
    valor_negociado_com_a_industria,
    repasse_do_ipi,
    repasse_do_frete,
    repasse_de_st,
    repasse_do_seguro,
    repasse_de_outras_despesas,
    pis_compra,
    cofins_compra,
    icms_compra,
    taxa_icms_venda,
    taxa_pis_venda,
    taxa_cofins_venda,
    taxa_lucro_bruto,
    taxa_custo_operacional,
    taxa_custo_de_cartao,
  }) {
    //
  }

  function atualizarItemExistente(body) {
    dispatch({
      type: 'UPDATE_LOTE',
      lote: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function getQuantidade(dados) {
    switch (dados.produto.unidade.tipo) {
      case POR_UNIDADE:
        return dados.unidades;
      case POR_PESO_FIXO:
        return dados.unidades;
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        return dados.peso;
      default:
        return dados.peso;
    }
  }

  function format10(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  function formatDate(date) {
    if (date) {
      const dateReal = new Date(date);
      const ano = dateReal.getFullYear();
      const mes = format10(dateReal.getMonth() + 1);
      const dia = format10(dateReal.getDate());
      return `${ano}-${mes}-${dia}`;
    }
    return '';
  }

  function handleEditarPrecificacao(dados2, index) {
    const dados = dados2.dadosOriginais;
    if (refDialogoPrecificacao.current) {
      const quantidade = getQuantidade(dados);
      refDialogoPrecificacao.current.handleEdit(
        dados.produto.id,
        config,
        fornecedor,
        formatDate(dados.validade),
        dados.nImpressoes,
        dados.unitario,
        quantidade,
        dados.dadosPrecificacao.repasse_do_ipi,
        dados.dadosPrecificacao.repasse_do_frete,
        dados.dadosPrecificacao.repasse_de_st,
        dados.dadosPrecificacao.repasse_do_seguro,
        dados.dadosPrecificacao.repasse_de_outras_despesas,
        index,
        dados.dadosPrecificacao.desconto_em_nfe,
        dados.dadosPrecificacao.desconto_em_boleto,
        dados.dadosPrecificacao.desconto_em_bonificacao,
        dados.dadosPrecificacao.desconto_em_backlight_e_ponto_extra,
        dados.dadosPrecificacao.desconto_em_jornal_de_ofertas,
        dados.dadosPrecificacao.desconto_em_descargo_de_mercadorias,
        null,
        dados.validades,
      );
    }
  }

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={!(lotes.length > 0)}
        disabledDeleteLote={lotes.length === 0 /*|| isImportacaoXml*/}
        //disabledCriarProduto={isImportacaoXml}
        disabledEditarProduto={!produto /*|| isImportacaoXml*/}
        //disabledFiltrosProduto={isImportacaoXml}
        onClick={handleActions}
        FECHAR_COMPRA_ACTION={FECHAR_COMPRA_ACTION}
        CANCELAR_COMPRA_ACTION={CANCELAR_COMPRA_ACTION}
        DELETAR_LOTE_ACTION={DELETAR_LOTE_ACTION}
        CRIAR_PRODUTO_ACTION={CRIAR_PRODUTO_ACTION}
        EDITAR_PRODUTO_ACTION={EDITAR_PRODUTO_ACTION}
        numeroCompra={numeroCompra}
        idEdit={idEdit}
        subtotal={subTotal}
        resto={subTotal - totalParcelas}
        totalParcelas={totalParcelas}
        DETALHES_ACTION={DETALHES_ACTION}
        FILTROS_PRODUTO_ACTION={FILTROS_PRODUTO_ACTION}
      />
      <Box padding="10px" className={classes.header}>
        {/*<Box margin="0px 0px 10px">
          <Label
            label={`SUBTOTAL: ${formatMoeda(subTotal)}`}
          />
  </Box>*/}
        <SearchProduto
          label="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e)}
          fullwidth
          searchHandle={middlewareSearchHandle}
          ref={refSearch}
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
          //filters={{ ativo: true, fornecedor_id: fornecedor.id }}
          filters={getFiltros()}
          mantemFiltroPadrao={false}
          //disabled={isImportacaoXml}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        {/*<Box flex={1.5} display="flex" flexDirection="column">
          <div style={{ marginBottom: '15px' }}>
            <Actions
              opcoes={[
                {
                  text: `Voltar (${CANCELAR_COMPRA_ACTION})`,
                  value: CANCELAR_COMPRA_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Fechar(${FECHAR_COMPRA_ACTION})`,
                  value: FECHAR_COMPRA_ACTION,
                  hide: false,
                },
                {
                  text: `Del. Lote (${DELETAR_LOTE_ACTION})`,
                  value: DELETAR_LOTE_ACTION,
                  hide: false,
                  disabled: !(lotes.length > 0),
                },
                {
                  text: `Novo Prod. (${CRIAR_PRODUTO_ACTION})`,
                  value: CRIAR_PRODUTO_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Editar Prod. (${EDITAR_PRODUTO_ACTION})`,
                  value: EDITAR_PRODUTO_ACTION,
                  hide: false,
                  disabled: !produto,
                },
              ]}
              onClick={handleActions}
            />
          </div>

          {produto && (
            <LabelSubtotal valor={subTotal} isOpaco={produto !== null} />
          )}
          {produto && <LabelEstoque produto={produto} />}
          </Box>*/}
        {lotes.length > 0 ? (
          <Box padding="0 0px" flex={4}>
            <Tabela
              handleGrafico={abrirGrafico}
              handleDetalhes={handleDetalhes}
              rows={toStringLotes(lotes)}
              handleEditar={handleEditarPrecificacao}
              headCells={[
                {
                  field: 'numeroLote',
                  label: 'Cód.',
                },
                /*  {
                field: 'produto',
                label: 'Produto',
              }, */
                {
                  field: 'validade',
                  label: 'Validade',
                  right: true,
                },
                {
                  field: 'unidades',
                  label: 'Unidades',
                  right: true,
                },
                {
                  field: 'peso',
                  label: 'Peso (Kg)',
                  right: true,
                },
                {
                  field: 'unitario',
                  label: 'Unitário',
                  right: true,
                },
                {
                  field: 'unitarioImposto',
                  label: 'Unitário Imposto',
                  right: true,
                },
                {
                  field: 'precoVenda',
                  label: 'Precificação',
                  right: true,
                },
                {
                  field: 'nImpressoes',
                  label: 'Nº Impressões',
                  right: true,
                },
                /*{
                  field: 'cg',
                  label: 'CG',
                  right: true,
                },*/
                {
                  field: 'total',
                  label: 'SubTotal',
                  right: true,
                },
              ]}
            />
          </Box>
        ) : null}
        {/*<Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <FormLote
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            produto={produto}
          />
          {!produto && (
            <LabelSubtotal valor={subTotal} isOpaco={produto === null} />
          )}
          </Box>*/}
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoProduto
        ref={refDialogoProduto}
        /* handleCriarProduto={(produtoAtualizado) => {
          setProduto(produtoAtualizado);
        }}
        handleCloseDialogo={() => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
        }}
        produto={produto} */
        handleClose={(idUpdate) => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
          if (idUpdate > 0) {
            updateProduto(idUpdate);
          }
        }}
        isImprimir={false}
      />
      {/* <DialogoDeletarItemTabela
        ref={refDialogoDeletar}
        itens={lotes}
        labelResource="Lotes"
        handleDelete={(indices) => removeItens(indices)}
        headerDialogo={(
          <div style={{
            display: 'flex', justifyContent: 'space-between', paddingLeft: '56px', marginBottom: '20px', color: 'red',
          }}
          >
            <div style={{ flex: 3 }}>
              Produto
            </div>
            <div style={{ flex: 1 }}>
              Unidades
            </div>
            <div style={{ flex: 1 }}>
              Peso
            </div>
            <div style={{ flex: 1 }}>
              Unitário
            </div>
            <div style={{ flex: 1 }}>
              Unitário Imposto
            </div>
            <div style={{ flex: 1 }}>
              Validade
            </div>
            <div style={{ flex: 1 }}>
              CG
            </div>
            <div style={{ flex: 1 }}>
              SubTotal
            </div>
          </div>
            )}
        getListItemContent={(value, labelId) => (
          <ListItemText
            id={labelId}
            primary={(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 4 }}>
                  {value.produto.nome}
                </div>
                <div style={{ flex: 1 }}>
                  {disableUnidades(value.produto) ? value.unidades : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {disablePeso(value.produto) ? formatPeso(value.peso) : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.unitario)}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.unitarioImposto)}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.cg)}
                </div>
                <div style={{ flex: 1 }}>
                  {moment(new Date(value.validade)).format('DD/MM/YYYY')}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.total)}
                </div>
              </div>
              )}
          />
        )}
      /> */}

      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={lotes}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([lotes[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
        usarSenha={false}
      />
      <DialogoPrecificacao
        ref={refDialogoPrecificacao}
        estoque={getEstoqueLabel(produto)}
        handleClose={(dados) => {
          if (dados) {
            const { index, produto: produto2, ...dados2 } = dados;

            if (index < 0) {
              let unidades = 0;
              let peso = 0;

              if (produto.unidade.tipo === POR_PESO_FIXO) {
                unidades = dados.qtde;
                peso = unidades * produto.peso_medio;
              } else if (produto.unidade.tipo === POR_UNIDADE) {
                unidades = dados.qtde;
                peso = 0;
              } else {
                unidades = 0;
                peso = dados.qtde;
              }

              addNewItem({
                unidades,
                peso,
                unitario: dados2.preco_compra,
                unitarioImposto: dados2.preco_compra_impostos,
                validade: dados2.validade,
                total: getTotal(peso, unidades, dados2.preco_compra, produto),
                cg: dados2.cg,
                dadosPrecificacao: dados2.dadosPrecificacao,
                nImpressoes: dados2.nImpressoes,
                validades: dados2.validades,
              });
            } else {
              let unidades = 0;
              let peso = 0;

              if (produto2.unidade.tipo === POR_PESO_FIXO) {
                unidades = dados.qtde;
                peso = unidades * produto2.peso_medio;
              } else if (produto2.unidade.tipo === POR_UNIDADE) {
                unidades = dados.qtde;
                peso = 0;
              } else {
                unidades = 0;
                peso = dados.qtde;
              }

              atualizarItemExistente({
                produto: produto2,
                peso: produto2.unidade.tipo === POR_UNIDADE ? 0 : peso,
                total: getTotalCompra(
                  peso,
                  unidades,
                  dados2.preco_compra_impostos,
                  produto2,
                ),
                unidades:
                  produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                    ? 0
                    : unidades,
                unitario: dados2.preco_compra,
                uidd: `${produto2.nome}${lotes.length}`,
                idIndicadorChange: -1,
                validade: dados2.validade,
                unitarioImposto: dados2.preco_compra_impostos,
                cg: dados2.cg,
                dadosPrecificacao: dados2.dadosPrecificacao,
                nImpressoes: dados2.nImpressoes,
                index,
                validades: dados2.validades,
              });
            }
            /*if (refSidebarInputs.current) {
              refSidebarInputs.current.valuesPrecificacao(dados);
            }*/
          }
        }}
        idCompra={idEdit}
        hasRepasseNaoUnitarioIPI={repasse_ipi > 0}
        hasRepasseNaoUnitarioFrete={repasse_frete > 0}
        hasRepasseNaoUnitarioST={repasse_st > 0}
        hasRepasseNaoUnitarioSeguros={repasse_seguros > 0}
        hasRepasseNaoUnitarioOutrasDespesas={repasse_outras_despesas > 0}
      />
      {/* <DialogoCarregarNota
        ref={refDialogoCarregarNota}
        handleClose={() => {}}
        handleSalvarItensFormatados={(data) => }
      /> */}
      <DialogoGrafico ref={refDialogoGrafico} />
      <DialogoFiltrosProduto
        isProdutosSomenteFornecedor={isProdutosSomenteFornecedor}
        isIncluiInativos={isIncluiInativos}
        ref={refDialogoFiltrosProduto}
        handleClose={updateFiltros}
      />
      <DialogoDetalhesItens ref={refDialogoDetalhesItens} />
      <DialogoDetalhes
        ref={refDialogoDetalhes}
        subTotalProdutos={subTotalProdutos}
        //total_repasse_frete={total_repasse_frete}
        //total_repasse_ipi={total_repasse_ipi}
        //total_repasse_outras_despesas={total_repasse_outras_despesas}
        //total_repasse_seguros={total_repasse_seguros}
        //total_repasse_st={total_repasse_st}
        repasse_frete={repasse_frete}
        repasse_ipi={repasse_ipi}
        repasse_outras_despesas={repasse_outras_despesas}
        repasse_seguros={repasse_seguros}
        repasse_st={repasse_st}
        subTotal={subTotal}
        lotes={lotes}
      />
    </>
  );
};

export default FrenteCompra;
