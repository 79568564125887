import {
  createQueryURL,
  formatMoeda,
  formatDateDDMMYYYY,
} from '../../../utils';

function getMapa(prop, dados) {
  const mapa = {};
  for (let i = 0; i < dados.length; i++) {
    mapa[dados[i][prop]] = dados[i];
  }
  return mapa;
}

function formataDado(dado) {
  return {
    data: formatDateDDMMYYYY(contruirData(dado.data)),
    numero_nfe: dado.numero_nfe,
    fornecedor: dado.fornecedor.nome,
    frete: formatMoeda(dado.frete),
    desconto_em_nfe: formatMoeda(dado.desconto_em_nfe),
    icms_normal: formatMoeda(dado.icms_normal),
    icms_st: formatMoeda(dado.icms_st),
    pis: formatMoeda(dado.pis),
    cofins: formatMoeda(dado.cofins),
    ipi: formatMoeda(dado.ipi),
    total_nfe: formatMoeda(dado.total_nfe),
    outras_despesas: formatMoeda(dado.outras_despesas),
    seguros: formatMoeda(dado.seguros),
    bc_calculo_icms: formatMoeda(dado.bc_calculo_icms),
    uf: dado.uf,
    isento: dado.isento,
    dadosOriginais: dado,
    id: dado.id,
  };
}

function contruirData(data) {
  const data2 = new Date();

  const dividido = data.split('-');

  const dia = parseInt(dividido[2], 10);
  const mes = parseInt(dividido[1], 10);
  const ano = parseInt(dividido[0], 10);

  data2.setFullYear(ano);
  data2.setMonth(mes - 1);
  data2.setDate(dia);
  data2.setMonth(mes - 1);
  data2.setFullYear(ano);
  data2.setDate(dia);

  return data2;
}

function getTotal(dados, campo) {
  let total = 0;

  for (let i = 0; i < dados.length; i++) {
    total += dados[i].dadosOriginais[campo];
  }

  return total;
}

function agruparPorData(notasOriginal, fornecedores) {
  const mapaDatas = {};
  const mapaFornecedores = getMapa('id', fornecedores);

  const notas = notasOriginal.slice();

  notas.sort((a, b) => new Date(a.data).getTime() - new Date(b.data).getTime());

  for (let i = 0; i < notas.length; i++) {
    const data = notas[i].data;
    if (mapaDatas[data]) {
      mapaDatas[data].push(
        formataDado({
          ...notas[i],
          fornecedor: mapaFornecedores[notas[i].fornecedor_id],
        }),
      );
    } else {
      mapaDatas[data] = [
        formataDado({
          ...notas[i],
          fornecedor: mapaFornecedores[notas[i].fornecedor_id],
        }),
      ];
    }
  }

  const dadosFinais = Object.entries(mapaDatas);

  return dadosFinais.map((item) => [
    formatDateDDMMYYYY(contruirData(item[0])),
    item[1],
    {
      totalFrete: getTotal(item[1], 'frete'),
      totalDesconto: getTotal(item[1], 'desconto_em_nfe'),
      totalIcms: getTotal(item[1], 'icms_normal'),
      totalIcmsSt: getTotal(item[1], 'icms_st'),
      totalPis: getTotal(item[1], 'pis'),
      totalCofins: getTotal(item[1], 'cofins'),
      totalIpi: getTotal(item[1], 'ipi'),
      totalValorNfe: getTotal(item[1], 'total_nfe'),
      totalBcCalculoIcms: getTotal(item[1], 'bc_calculo_icms'),
      totalOutrasDespesas: getTotal(item[1], 'outras_despesas'),
      totalSeguros: getTotal(item[1], 'seguros'),
    },
  ]);
}

function agruparPorFornecedor(notasOriginal, fornecedoresOriginal) {
  const notas = notasOriginal.slice();

  notas.sort((a, b) => new Date(a.data).getTime() - new Date(b.data).getTime());

  const fornecedores = fornecedoresOriginal.slice();

  fornecedores.sort((a, b) => {
    if (a.nome > b.nome) {
      return -1;
    }

    if (a.nome < b.nome) {
      return 1;
    }

    return 0;
  });

  const mapaNotasFornecedores = {};
  for (let i = 0; i < notas.length; i++) {
    const fornecedor_id = notas[i].fornecedor_id;
    if (mapaNotasFornecedores[fornecedor_id]) {
      mapaNotasFornecedores[fornecedor_id].push({
        ...notas[i],
      });
    } else {
      mapaNotasFornecedores[fornecedor_id] = [
        {
          ...notas[i],
        },
      ];
    }
  }

  const mapaFornecedores = {};

  for (let i = 0; i < fornecedores.length; i++) {
    mapaFornecedores[fornecedores[i].nome] = mapaNotasFornecedores[
      fornecedores[i].id
    ].map((item) => formataDado({ ...item, fornecedor: fornecedores[i] }));
  }

  const dadosFinais = Object.entries(mapaFornecedores);

  return dadosFinais.map((item) => [
    item[0],
    item[1],
    {
      totalFrete: getTotal(item[1], 'frete'),
      totalDesconto: getTotal(item[1], 'desconto_em_nfe'),
      totalIcms: getTotal(item[1], 'icms_normal'),
      totalIcmsSt: getTotal(item[1], 'icms_st'),
      totalPis: getTotal(item[1], 'pis'),
      totalCofins: getTotal(item[1], 'cofins'),
      totalIpi: getTotal(item[1], 'ipi'),
      totalValorNfe: getTotal(item[1], 'total_nfe'),
      totalBcCalculoIcms: getTotal(item[1], 'bc_calculo_icms'),
      totalOutrasDespesas: getTotal(item[1], 'outras_despesas'),
      totalSeguros: getTotal(item[1], 'seguros'),
    },
  ]);
}

export { agruparPorData, agruparPorFornecedor };
