function transforma(dadosPrecificacao) {
  return {
    valor_negociado_com_a_industria:
      dadosPrecificacao.valorNegociadoComAIndustria,
    repasse_do_frete: dadosPrecificacao.repasseDoFreteUnitarioPorcentagemVenda,
    repasse_do_seguro: dadosPrecificacao.repasseDeSegurosPorcentagemVenda,
    repasse_de_outras_despesas:
      dadosPrecificacao.repasseDeOutrasDespesasPorcentagemVenda,
    desconto_em_nfe: dadosPrecificacao.descontoNfe,
    repasse_do_ipi: dadosPrecificacao.repasseDoIpiPorcentagemVenda,
    repasse_de_st: dadosPrecificacao.repasseDeStPorcentagemVenda,
    desconto_em_boleto: dadosPrecificacao.descontoBoleto,
    desconto_em_bonificacao: dadosPrecificacao.descontoBonificacao,
    desconto_em_backlight_e_ponto_extra: dadosPrecificacao.descontoBacklight,
    desconto_em_jornal_de_ofertas: dadosPrecificacao.descontoJornalOfertas,
    desconto_em_descargo_de_mercadorias:
      dadosPrecificacao.descontoDescargoMercadorias,
    icms_fronteira: dadosPrecificacao.icmsDeFronteiraAdotado,
    icms_antecipacao: dadosPrecificacao.icmsAntecipacao,
  };
}

function getCustoFinal(dadosPrecificacao, isTransformar = true) {
  if (dadosPrecificacao) {
    const dados = isTransformar
      ? transforma(dadosPrecificacao)
      : dadosPrecificacao;

    const {
      valor_negociado_com_a_industria,
      repasse_do_frete,
      repasse_do_seguro,
      repasse_de_outras_despesas,
      desconto_em_nfe,
      repasse_do_ipi,
      repasse_de_st,
      desconto_em_boleto,
      desconto_em_bonificacao,
      desconto_em_backlight_e_ponto_extra,
      desconto_em_jornal_de_ofertas,
      desconto_em_descargo_de_mercadorias,
      icms_fronteira,
      icms_antecipacao,
    } = dados;

    const total =
      valor_negociado_com_a_industria +
      repasse_do_frete +
      repasse_do_seguro +
      repasse_de_outras_despesas -
      desconto_em_nfe +
      repasse_do_ipi +
      repasse_de_st -
      desconto_em_boleto -
      desconto_em_bonificacao -
      desconto_em_backlight_e_ponto_extra -
      desconto_em_jornal_de_ofertas -
      desconto_em_descargo_de_mercadorias +
      icms_fronteira +
      icms_antecipacao;

    return total;
  }
  return 0;
}

export { getCustoFinal };
