import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  PesoInput,
  PrecoInput,
  DialogoProduto2 as DialogoProduto,
  PorcentagemInput,
  DateInput,
  SelectInput,
} from '../../../../..';
import { updateAPI, getListAllAPI } from '../../../../../../services';
import {
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_FIXO,
  ceil10,
  round10,
  REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
  REGIME_TRIBUTARIO_LUCRO_REAL,
  REGIME_TRIBUTARIO_MEI,
  REGIME_TRIBUTARIO_PRODUTOR_RURAL,
  REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
  REGIME_TRIBUTARIO_SEM_NFE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_IMPRESSAO_UN,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
  SECONDARY_COLOR,
  POR_PESO_VARIAVEL,
  getCustoFinal,
} from '../../../../../../utils';
import DialogoCreateImpressao from '../../../../DialogoProduto2/DialogoCreateImpressao';
import DialogoGrafico from '../DialogoGrafico';
import TabelaPrecos from './TabelaPrecos';

function verificaDiferencaNula(v1, v2, config = {}) {
  const configDefault = { diferencaAlvo: 0, tolerancia: 0.01 };

  const configFinal = { ...configDefault, ...config };

  const { diferencaAlvo, tolerancia } = configFinal;

  const diferencaCalculada = Math.abs(v1 - v2);
  if (
    diferencaCalculada >= diferencaAlvo - tolerancia &&
    diferencaCalculada <= diferencaAlvo + tolerancia
  )
    return true;
  return false;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
    padding: 3,
  },
  body: {
    fontSize: 14,
    padding: 3,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}))(TableCell);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

function calculaValorNegociadoComAIndustria(bc, qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return bc / qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return bc / (qtde * pesoMedio);
  }

  return bc / qtde;
}

function calculaQuantidadeComprada(valorNegociado, bc, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return Math.round(bc / valorNegociado);
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return Math.round(bc / (valorNegociado * pesoMedio));
  }

  return round10(bc / valorNegociado, -3);
}

function calculaBaseDeCalculo(valorNegociado, qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return valorNegociado * qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return valorNegociado * (qtde * pesoMedio);
  }

  return valorNegociado * qtde;
}

function getQuantidadeRepasse(qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return qtde * pesoMedio;
  }

  return qtde;
}

const optionsAlvo = [
  {
    label: 'Descobrir Valor negociado com a indústria',
    value: 'descobrir_valor_unitario',
  },
  {
    label: 'Descobrir Base de Cálculo',
    value: 'descobrir_valor_total',
  },
  {
    label: 'Descobrir Quantidade Comprada',
    value: 'descobrir_quantidade',
  },
];

/* const optionsRepassesUnitarios = [
  {
    label: 'Unitário',
    value: 0,
  },
  {
    label: 'Total',
    value: 1,
  },
  {
    label: 'Porcentagem',
    value: 2,
  },
];

const optionsDescontos = [
  {
    label: 'Unitário',
    value: 0,
  },
  {
    label: 'Total',
    value: 1,
  },
]; */

function InputRepasse({
  tipoRepasse,
  value,
  onChange,
  errorMessage,
  disabled,
  onChangeTipo,
  label,
  name,
  options,
}) {
  switch (tipoRepasse) {
    case options[0].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={6}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={options}
              />
            ),
          }}
        />
      );
    case options[1].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={6}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={options}
              />
            ),
          }}
        />
      );
    case options[2].value:
      return (
        <PorcentagemInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={3}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={options}
              />
            ),
          }}
        />
      );
    default:
      return <div>Problema</div>;
  }
}

function InputDesconto({
  tipoRepasse,
  value,
  onChange,
  errorMessage,
  disabled = false,
  onChangeTipo,
  label,
  name,
  options,
}) {
  switch (tipoRepasse) {
    case options[0].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={6}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={options}
              />
            ),
          }}
        />
      );
    case options[1].value:
      return (
        <PrecoInput
          name={name}
          label={label}
          handleEnter={() => {}}
          handleKey={() => {}}
          value={Number.isNaN(value) ? '' : String(value)}
          onChange={(value) => onChange(parseFloat(value.target.value))}
          error={errorMessage !== ''}
          helperText={errorMessage}
          fullWidth
          escala={2}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <SelectInput
                variant="standard"
                labelId="demo-simple-select-label-2"
                name="tipo"
                handleEnter={() => {}}
                label="Tipo"
                handleKey={() => {}}
                value={tipoRepasse}
                onChange={(value) => {
                  onChangeTipo(value.target.value);
                  onChange(0);
                }}
                options={options}
              />
            ),
          }}
        />
      );
    default:
      return <div>Problema</div>;
  }
}

function formatDate(date) {
  if (date === '') {
    return null;
  }
  const dataQuebrada = date.split('-');
  const ano = parseInt(dataQuebrada[0], 10);
  const mes = parseInt(dataQuebrada[1], 10) - 1;
  const dia = parseInt(dataQuebrada[2], 10);
  const dataNova = new Date();
  dataNova.setFullYear(ano);
  dataNova.setMonth(mes);
  dataNova.setDate(dia);
  return dataNova;
}

function getLabelTipoUnidade(tipo) {
  if (tipo === POR_UNIDADE)
    return (
      <p
        style={{
          display: 'flex',
          gap: '5px',
          padding: '5px',
          fontSize: '25px',
        }}
      >
        <span>Item do tipo</span>
        <span style={{ color: 'red' }}>UNIDADE</span>
        <span>, trabalhado na</span>
        <span style={{ color: 'red' }}>UN</span>
      </p>
    );
  if (tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
    return (
      <p
        style={{
          display: 'flex',
          gap: '5px',
          padding: '5px',
          fontSize: '25px',
        }}
      >
        <span>Item do tipo</span>
        <span style={{ color: 'red' }}>PESO VARIÁVEL</span>
        <span>, trabalhado no</span>
        <span style={{ color: 'red' }}>KG</span>
      </p>
    );
  if (tipo === POR_PESO_FIXO)
    return (
      <p
        style={{
          display: 'flex',
          gap: '5px',
          padding: '5px',
          fontSize: '25px',
        }}
      >
        <span>Item do tipo</span>
        <span style={{ color: 'red' }}>PESO PADRÃO</span>
        <span>, comprado no KG e vendido na</span>
        <span style={{ color: 'red' }}>UN</span>
      </p>
    );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const COLOR_RED = '#ff0000';
const BACKGROUND_PREVISAO_LUCRO = '#92d050';
const BACKGROUND_PRECO_VENDA = '#ffff00';
const BACKGROUND_CREDITO_COMPRA = '#e2efd9';
const BACKGROUND_DEBITO_VENDA = '#fef2cb';
const BACKGROUND_ANALISE_FLUXO_CAIXA = '#fbe4d5';
const BACKGROUND_CUSTOS_ADICIONAIS = '#d6dce4';
const BACKGROUND_DESCONTOS = '#82ae55';

const DialogoBoletos = forwardRef(
  (
    {
      handleClose,
      estoque,
      hasRepasseNaoUnitarioIPI = false,
      hasRepasseNaoUnitarioFrete = false,
      hasRepasseNaoUnitarioST = false,
      hasRepasseNaoUnitarioSeguros = false,
      hasRepasseNaoUnitarioOutrasDespesas = false,
      label1 = 'Cancelar',
      label2 = 'Salvar',
      prefixoTitle = '',
      isCloseX = true,
      isEmitMessage = true,
      handleUpdateProduto = null,
    },
    ref,
  ) => {
    const [index, setIndex] = React.useState(-1);
    const [open, setOpen] = React.useState(false);
    const [carregando, setCarregando] = useState(false);
    const classes = useStyles();
    const [produto, setProduto] = useState(null);
    const [fornecedor, setFornecedor] = useState(null);
    const [config, setConfig] = useState(null);
    const [
      valorNegociadoComAIndustria,
      setValorNegociadoComAIndustria,
    ] = useState(0);
    const [quantidadeComprada, setQuantidadeComprada] = useState(0);
    //
    const [repasseDoIpi, setRepasseDoIpi] = useState(0);
    const [repasseDoFreteUnitario, setRepasseDoFreteUnitario] = useState(0);
    const [repasseDeSt, setRepasseDeSt] = useState(0);
    const [repasseDeSeguros, setRepasseDeSeguros] = useState(0);
    const [repasseDeOutrasDespesas, setRepasseDeOutrasDespesas] = useState(0);
    const [manterPrecoAnterior, setManterPrecoAnterior] = useState(false);
    const [validade, setValidade] = useState('');
    const [margemLucroBrutoEstado, setMargemLucroBrutoEstado] = useState(0);

    const [baseDeCalculo, setBaseDeCalculo] = useState(0);
    const [alvo, setAlvo] = useState(optionsAlvo[1].value);

    const [tipoRepasseDoIpi, setTipoRepasseDoIpi] = useState(0);

    const [tipoRepasseDoFrete, setTipoRepasseDoFrete] = useState(0);

    const [tipoRepasseDoSt, setTipoRepasseDoSt] = useState(0);

    const [tipoRepasseDoSeguros, setTipoRepasseDoSeguros] = useState(0);

    const [
      tipoRepasseDoOutrasDespesas,
      setTipoRepasseDoOutrasDespesas,
    ] = useState(0);

    const [nImpressoes, setNImpressoes] = useState(0);
    const [nImpressoesCartazes, setNImpressoesCartazes] = useState(0);

    const refDialogoImpressao = useRef(null);
    const refDialogoProduto = useRef(null);
    const refDialogoGrafico = useRef(null);

    const { enqueueSnackbar } = useSnackbar();

    //////////////////////////////

    const [descontoNfe, setDescontoNfe] = useState(0);
    const [descontoBoleto, setDescontoBoleto] = useState(0);
    const [descontoBonificacao, setDescontoBonificacao] = useState(0);
    const [descontoBacklight, setDescontoBacklight] = useState(0);
    const [descontoJornalOfertas, setDescontoJornalOfertas] = useState(0);
    const [
      descontoDescargoMercadorias,
      setDescontoDescargoMercadorias,
    ] = useState(0);

    //////////////////////////////

    const [tipoDescontoNfe, setTipoDescontoNfe] = useState(0);

    const [tipoDescontoBoleto, setTipoDescontoBoleto] = useState(0);

    const [tipoDescontoBonificacao, setTipoDescontoBonificacao] = useState(0);

    const [tipoDescontoBacklight, setTipoDescontoBacklight] = useState(0);

    const [tipoDescontoJornalOfertas, setTipoDescontoJornalOfertas] = useState(
      0,
    );

    const [
      tipoDescontoDescargoMercadorias,
      setTipoDescontoDescargoMercadorias,
    ] = useState(0);

    const [dadosComplementaresNota, setDadosComplementaresNota] = useState(
      null,
    );

    const [validades, setValidades] = useState([]);

    const [value, setValue] = React.useState(0);

    //////////////////////////////

    useImperativeHandle(ref, () => ({
      handleOpen(codigo, config, fornecedor, id) {
        getDataResource(codigo, config, fornecedor, id);
        //setOpen(true);
        setIndex(-1);
      },
      handleEdit(
        produtoId,
        config,
        fornecedor,
        validade,
        nImpressoes,
        valorNegociadoComAIndustria,
        quantidadeComprada,
        repasseDoIpi,
        repasseDoFreteUnitario,
        repasseDeSt,
        repasseDeSeguros,
        repasseDeOutrasDespesas,
        index,
        descontoNfe = 0,
        descontoBoleto = 0,
        descontoBonificacao = 0,
        descontoBacklight = 0,
        descontoJornalOfertas = 0,
        descontoDescargoMercadorias = 0,
        dadosComplementaresNota = null,
        validades = [],
      ) {
        //setOpen(true);
        getDataResourceEditar(
          produtoId,
          config,
          fornecedor,
          validade,
          nImpressoes,
          valorNegociadoComAIndustria,
          quantidadeComprada,
          repasseDoIpi,
          repasseDoFreteUnitario,
          repasseDeSt,
          repasseDeSeguros,
          repasseDeOutrasDespesas,
          //
          descontoNfe,
          descontoBoleto,
          descontoBonificacao,
          descontoBacklight,
          descontoJornalOfertas,
          descontoDescargoMercadorias,
          dadosComplementaresNota,
          validades,
        );
        setIndex(index);
      },
    }));

    const handleCloseDialog = (dadosRetorno = null) => {
      setOpen(false);
      handleClose(dadosRetorno);

      setProduto(null);
      setConfig(null);
      setFornecedor(null);
      setManterPrecoAnterior(false);

      //
      setValidade('');
      setValorNegociadoComAIndustria(0);
      setQuantidadeComprada(0);
      setRepasseDoIpi(0);
      setRepasseDoFreteUnitario(0);
      setRepasseDeSt(0);
      setRepasseDeSeguros(0);
      setRepasseDeOutrasDespesas(0);
      setBaseDeCalculo(0);
      setAlvo(optionsAlvo[0].value);
      //

      setTipoRepasseDoIpi(0);
      setTipoRepasseDoFrete(0);
      setTipoRepasseDoSt(0);
      setTipoRepasseDoSeguros(0);
      setTipoRepasseDoOutrasDespesas(0);

      setNImpressoes(0);

      setDescontoNfe(0);
      setDescontoBoleto(0);
      setDescontoBonificacao(0);
      setDescontoBacklight(0);
      setDescontoJornalOfertas(0);
      setDescontoDescargoMercadorias(0);

      setTipoDescontoNfe(0);
      setTipoDescontoBoleto(0);
      setTipoDescontoBonificacao(0);
      setTipoDescontoBacklight(0);
      setTipoDescontoJornalOfertas(0);
      setTipoDescontoDescargoMercadorias(0);

      setMargemLucroBrutoEstado(0);

      setCarregando(false);
    };

    /*useEffect(() => {
      setQuantidadeComprada(0);
      setValorNegociadoComAIndustria(0);
      setBaseDeCalculo(0);
    }, [alvo]);*/

    function calculaDados({
      estadoOrigem, //fornecedor
      estadoDestino, // config
      quantidadeComprada, // input
      valorNegociadoComAIndustria, // input
      irpjPorcentagemLb, // config
      csllPorcentagemLb, // config
      lucroBrutoPorcentagem, // produto
      aliquotaInternaCompraPorcentagem, //fornecedor
      pisCompraPorcentagem, // config
      cofinsCompraPorcentagem, // config
      aliquotaInternaVendaPorcentagem, //fornecedor
      agregadoDoCnaePorcentagem, // produto
      mvaDaPauta, // produto
      margemDeCustoOperacionalPorcentagem, // config
      margemDeCustoDeCartaoPorcentagem, // config
      repasseDoIpi, // input
      repasseDoFreteUnitario, // input
      repasseDeSt, // input
      repasseDeSeguros, // input
      repasseDeOutrasDespesas, // input
      pisVendaPorcentagem, // input
      cofinsVendaPorcentagem, // input
      isAntecipacao,
      isFronteira,
      incluirIcmsDeFronteira,
      incluirIcmsDeAntecipacao,

      //
      icmsAntecipacaoPorcentagemInput,
      icmsFronteiraPorcentagemInput,
      //

      //
      pis_credito,
      is_caso_especial_pis_credito,
      pis_debito,
      is_caso_especial_pis_debito,
      cofins_credito,
      is_caso_especial_cofins_credito,
      cofins_debito,
      is_caso_especial_cofins_debito,
      ipi_credito,
      is_caso_especial_ipi_credito,
      ipi_debito,
      is_caso_especial_ipi_debito,
      icms_normal_credito,
      is_caso_especial_icms_normal_credito,
      icms_normal_debito,
      is_caso_especial_icms_normal_debito,
      icms_fronteira,
      is_caso_especial_icms_fronteira,
      icms_antecipacao,
      is_caso_especial_icms_antecipacao,

      ///

      descontoBacklight,
      descontoBoleto,
      descontoBonificacao,
      descontoDescargoMercadorias,
      descontoJornalOfertas,
      descontoNfe,

      //

      margemDeAvariaPorcentagem,
    }) {
      //const estadoOrigem = 'Alagoas';
      //const estadoDestino = 'Pernambuco';
      //const quantidadeComprada = 1000; //entrada
      //const valorNegociadoComAIndustria = 100; //entrada
      //const irpjPorcentagemLb = 25; //entrada
      //const csllPorcentagemLb = 9; //entrada
      //const lucroBrutoPorcentagem = 3; //entrada
      //const estado = 'Alagoas'; // entrada
      //const aliquotaInternaCompraPorcentagem = 12; // encontrar de acordo com o estado
      //const pisCompraPorcentagem = 1.65; // entrada
      //const cofinsCompraPorcentagem = 7.6; // entrada
      //const aliquotaInternaVendaPorcentagem = 18; //entrada
      //const agregadoDoCnaePorcentagem = 30; //entrada
      //const mvaDaPauta = 0; //entrada
      //const margemDeCustoOperacionalPorcentagem = 5; //entrada
      //const margemDeCustoDeCartaoPorcentagem = 3; //entrada
      //const repasseDoIpi = 0; //entrada
      //const repasseDoFreteUnitario = 0; //entrada
      //const repasseDeSt = 0; //entrada
      //const repasseDeSeguros = 0; //entrada
      //const repasseDeOutrasDespesas = 0; //entrada

      const icmsAntecipacaoPorcentagem =
        estadoOrigem === estadoDestino
          ? isAntecipacao
            ? icmsAntecipacaoPorcentagemInput
            : 0
          : 0; //entrada

      const baseCalculoIcmsUnitaria =
        valorNegociadoComAIndustria +
        repasseDoFreteUnitario +
        repasseDeSeguros +
        repasseDeOutrasDespesas -
        descontoNfe;

      const custoFinalDoProduto =
        baseCalculoIcmsUnitaria + repasseDoIpi + repasseDeSt;

      const aliquotaInternaCompra =
        baseCalculoIcmsUnitaria * (aliquotaInternaCompraPorcentagem / 100);

      const pisCompra = baseCalculoIcmsUnitaria * (pisCompraPorcentagem / 100);

      const cofinsCompra =
        baseCalculoIcmsUnitaria * (cofinsCompraPorcentagem / 100);

      const custoLivreDeImpostoPorcentagem =
        custoFinalDoProduto - aliquotaInternaCompra - pisCompra - cofinsCompra;

      //const pisVendaPorcentagem = pisCompraPorcentagem;

      //const cofinsVendaPorcentagem = cofinsCompraPorcentagem;

      let custosAMais = 0;

      //if (isAntecipacao && incluirIcmsDeAntecipacao) {
      /*if (false) {
        custosAMais += icmsAntecipacaoPorcentagem;
      }*/

      const calculoDaPautaDeIcmsDeFronteira =
        mvaDaPauta > 0
          ? mvaDaPauta * aliquotaInternaVendaPorcentagem - aliquotaInternaCompra
          : 0;

      const icmsAntecipacao =
        (icmsAntecipacaoPorcentagem / 100) * baseCalculoIcmsUnitaria;
      const icmsAntecipacaoTotal = icmsAntecipacao * quantidadeComprada;

      let icmsAntecipacaoVenda = 0;

      if (isAntecipacao && incluirIcmsDeAntecipacao) {
        custosAMais += icmsAntecipacao;
        icmsAntecipacaoVenda = icmsAntecipacao;
      }

      let fronteira = 0;
      let fronteiraPorcentagem = 0;
      let icmsDeFronteiraAdotado = 0;
      let icmsDeFronteiraAdotadoPorcentagem = 0;
      let icmsDeFronteiraAdotadoTotal = 0;

      if (icmsFronteiraPorcentagemInput < 0) {
        fronteira = isFronteira
          ? estadoOrigem === estadoDestino
            ? 0
            : ((custoFinalDoProduto - aliquotaInternaCompra) /
                (1 - aliquotaInternaVendaPorcentagem / 100)) *
                (1 + agregadoDoCnaePorcentagem / 100) *
                (aliquotaInternaVendaPorcentagem / 100) -
              aliquotaInternaCompra
          : 0;

        fronteiraPorcentagem =
          estadoOrigem === estadoDestino
            ? 0
            : (fronteira / baseCalculoIcmsUnitaria) * 100;

        icmsDeFronteiraAdotado = isFronteira
          ? fronteira > calculoDaPautaDeIcmsDeFronteira
            ? fronteira
            : calculoDaPautaDeIcmsDeFronteira
          : 0;

        icmsDeFronteiraAdotadoPorcentagem =
          (icmsDeFronteiraAdotado / baseCalculoIcmsUnitaria) * 100;
        icmsDeFronteiraAdotadoTotal =
          icmsDeFronteiraAdotado * quantidadeComprada;
      } else {
        fronteiraPorcentagem =
          estadoOrigem === estadoDestino ? 0 : icmsFronteiraPorcentagemInput;

        fronteira = (fronteiraPorcentagem / 100) * baseCalculoIcmsUnitaria;

        icmsDeFronteiraAdotado = fronteira;

        icmsDeFronteiraAdotadoPorcentagem = fronteiraPorcentagem;

        icmsDeFronteiraAdotadoTotal =
          icmsDeFronteiraAdotado * quantidadeComprada;
      }

      let icmsDeFronteiraVenda = 0;

      if (isFronteira && incluirIcmsDeFronteira) {
        custosAMais += icmsDeFronteiraAdotado;
        icmsDeFronteiraVenda = icmsDeFronteiraAdotado;
      }

      //if (isFronteira && incluirIcmsDeFronteira) {
      /*if (false) {
        custosAMais += icmsDeFronteiraAdotadoPorcentagem;
      }*/

      const markup =
        (aliquotaInternaVendaPorcentagem +
          pisVendaPorcentagem +
          cofinsVendaPorcentagem +
          lucroBrutoPorcentagem +
          margemDeCustoOperacionalPorcentagem +
          margemDeCustoDeCartaoPorcentagem +
          margemDeAvariaPorcentagem) /
        100;

      const precoVenda =
        (custoLivreDeImpostoPorcentagem -
          (descontoBacklight +
            descontoBoleto +
            descontoBonificacao +
            descontoDescargoMercadorias +
            descontoJornalOfertas) +
          custosAMais) /
        (1 - markup);
      const precoVendaTotal = precoVenda * quantidadeComprada;

      const valorNegociadoComAIndustriaPorcentagem =
        (valorNegociadoComAIndustria / precoVenda) * 100;
      const valorNegociadoComAIndustriaTotal =
        valorNegociadoComAIndustria * quantidadeComprada;

      const lucroLiquidoPorcentagem =
        lucroBrutoPorcentagem *
        (1 - (irpjPorcentagemLb + csllPorcentagemLb) / 100);
      const lucroLiquido = precoVenda * (lucroLiquidoPorcentagem / 100);
      const lucroLiquidoTotal = lucroLiquido * quantidadeComprada;

      const lucroBruto = (lucroBrutoPorcentagem / 100) * precoVenda;

      const irpj = (irpjPorcentagemLb / 100) * lucroBruto;
      const irpjPorcentagemPv = (irpj / precoVenda) * 100;
      const irpjTotal = irpj * quantidadeComprada;

      const csll = (csllPorcentagemLb / 100) * lucroBruto;
      const csllPorcentagemPv = (csll / precoVenda) * 100;
      const csllTotal = csll * quantidadeComprada;

      const lucroBrutoTotal = lucroBruto * quantidadeComprada;

      const aliquotaInternaCompraTotal =
        aliquotaInternaCompra * quantidadeComprada;

      const pisCompraTotal = pisCompra * quantidadeComprada;

      const cofinsCompraTotal = cofinsCompra * quantidadeComprada;

      const totalDeCreditosCompra =
        aliquotaInternaCompra + pisCompra + cofinsCompra;
      const totalDeCreditosCompraPorcentagem =
        (totalDeCreditosCompra / precoVenda) * 100;
      const totalDeCreditosCompraTotal =
        totalDeCreditosCompra * quantidadeComprada;

      const aliquotaInternaVenda =
        (aliquotaInternaVendaPorcentagem / 100) * precoVenda;
      const aliquotaInternaVendaTotal =
        aliquotaInternaVenda * quantidadeComprada;

      const pisVenda = (pisVendaPorcentagem / 100) * precoVenda;
      const pisVendaTotal = pisVenda * quantidadeComprada;

      const cofinsVenda = (cofinsVendaPorcentagem / 100) * precoVenda;
      const cofinsVendaTotal = cofinsVenda * quantidadeComprada;

      const totalDeDebitosVenda = aliquotaInternaVenda + pisVenda + cofinsVenda;
      const totalDeDebitosVendaPorcentagem =
        (totalDeDebitosVenda / precoVenda) * 100;
      const totalDeDebitosVendaTotal = totalDeDebitosVenda * quantidadeComprada;

      const totalDeIcmsAntecipado = icmsAntecipacao + icmsDeFronteiraAdotado;
      const totalDeIcmsAntecipadoPorcentagem =
        (totalDeIcmsAntecipado / precoVenda) * 100;
      const totalDeIcmsAntecipadoTotal =
        totalDeIcmsAntecipado * quantidadeComprada;

      const diferencaDeIcms = aliquotaInternaVenda - aliquotaInternaCompra;
      const diferencaDeIcmsPorcentagem = (diferencaDeIcms / precoVenda) * 100;
      const diferencaDeIcmsTotal = diferencaDeIcms * quantidadeComprada;

      const diferencaDePis = pisVenda - pisCompra;
      const diferencaDePisPorcentagem = (diferencaDePis / precoVenda) * 100;
      const diferencaDePisTotal = diferencaDePis * quantidadeComprada;

      const diferencaDeCofins = cofinsVenda - cofinsCompra;
      const diferencaDeCofinsPorcentagem =
        (diferencaDeCofins / precoVenda) * 100;
      const diferencaDeCofinsTotal = diferencaDeCofins * quantidadeComprada;

      const tributacaoEfetiva = totalDeDebitosVenda - totalDeCreditosCompra;
      const tributacaoEfetivaPorcentagem =
        (tributacaoEfetiva / precoVenda) * 100;
      const tributacaoEfetivaTotal = tributacaoEfetiva * quantidadeComprada;

      const resultadoDoFluxoDeCaixa = tributacaoEfetiva + totalDeIcmsAntecipado;
      const resultadoDoFluxoDeCaixaPorcentagem =
        (resultadoDoFluxoDeCaixa / precoVenda) * 100;
      const resultadoDoFluxoDeCaixaTotal =
        resultadoDoFluxoDeCaixa * quantidadeComprada;

      const margemDeCustoOperacional =
        (margemDeCustoOperacionalPorcentagem / 100) * precoVenda;
      const margemDeCustoOperacionalTotal =
        margemDeCustoOperacional * quantidadeComprada;

      const margemDeCustoDeCartao =
        (margemDeCustoDeCartaoPorcentagem / 100) * precoVenda;
      const margemDeCustoDeCartaoTotal =
        margemDeCustoDeCartao * quantidadeComprada;

      const margemDeAvaria = (margemDeAvariaPorcentagem / 100) * precoVenda;
      const margemDeAvariaTotal = margemDeAvaria * quantidadeComprada;

      const repasseDoIpiPorcentagem =
        (repasseDoIpi / valorNegociadoComAIndustria) * 100;
      const repasseDoIpiTotal = repasseDoIpi * quantidadeComprada;

      const repasseDoFreteUnitarioPorcentagem =
        (repasseDoFreteUnitario / valorNegociadoComAIndustria) * 100;
      const repasseDoFreteUnitarioTotal =
        repasseDoFreteUnitario * quantidadeComprada;

      const repasseDeStPorcentagem =
        (repasseDeSt / valorNegociadoComAIndustria) * 100;
      const repasseDeStTotal = repasseDeSt * quantidadeComprada;

      const repasseDeSegurosPorcentagem =
        (repasseDeSeguros / valorNegociadoComAIndustria) * 100;
      const repasseDeSegurosTotal = repasseDeSeguros * quantidadeComprada;

      const repasseDeOutrasDespesasPorcentagem =
        (repasseDeOutrasDespesas / valorNegociadoComAIndustria) * 100;
      const repasseDeOutrasDespesasTotal =
        repasseDeOutrasDespesas * quantidadeComprada;

      ///////////////////////////////////////////

      const descontoNfePorcentagem =
        (descontoNfe / valorNegociadoComAIndustria) * 100;
      const descontoNfeTotal = descontoNfe * quantidadeComprada;

      const descontoBoletoPorcentagem =
        (descontoBoleto / valorNegociadoComAIndustria) * 100;
      const descontoBoletoTotal = descontoBoleto * quantidadeComprada;

      const descontoBonificacaoPorcentagem =
        (descontoBonificacao / valorNegociadoComAIndustria) * 100;
      const descontoBonificacaoTotal = descontoBonificacao * quantidadeComprada;

      const descontoJornalOfertasPorcentagem =
        (descontoJornalOfertas / valorNegociadoComAIndustria) * 100;
      const descontoJornalOfertasTotal =
        descontoJornalOfertas * quantidadeComprada;

      const descontoDescargoMercadoriasPorcentagem =
        (descontoDescargoMercadorias / valorNegociadoComAIndustria) * 100;
      const descontoDescargoMercadoriasTotal =
        descontoDescargoMercadorias * quantidadeComprada;

      const descontoBacklightPorcentagem =
        (descontoBacklight / valorNegociadoComAIndustria) * 100;
      const descontoBacklightTotal = descontoBacklight * quantidadeComprada;

      ///////////////////////////////////////////

      const totalDeCustosDeRepasses =
        repasseDoIpi +
        repasseDoFreteUnitario +
        repasseDeSt +
        repasseDeSeguros +
        repasseDeOutrasDespesas;
      const totalDeCustosDeRepassesPorcentagem =
        (totalDeCustosDeRepasses / precoVenda) * 100;
      const totalDeCustosDeRepassesTotal =
        totalDeCustosDeRepasses * quantidadeComprada;

      const custoFinalDoProdutoPorcentagem =
        (custoFinalDoProduto / precoVenda) * 100;
      const custoFinalDoProdutoTotal = custoFinalDoProduto * quantidadeComprada;

      ///

      const icmsAntecipacaoPorcentagemVenda =
        (icmsAntecipacaoVenda / precoVenda) * 100;

      const icmsDeFronteiraAdotadoPorcentagemVenda =
        (icmsDeFronteiraVenda / precoVenda) * 100;

      ///

      const conferenciaDoGraficoDeSecaoCircular =
        valorNegociadoComAIndustriaPorcentagem +
        margemDeCustoOperacionalPorcentagem +
        diferencaDeIcmsPorcentagem +
        lucroBrutoPorcentagem +
        diferencaDeCofinsPorcentagem +
        margemDeCustoDeCartaoPorcentagem +
        diferencaDePisPorcentagem +
        totalDeCustosDeRepassesPorcentagem +
        icmsAntecipacaoPorcentagemVenda +
        icmsDeFronteiraAdotadoPorcentagemVenda;

      const margemTotal = precoVenda - valorNegociadoComAIndustria;
      const margemTotalPorcentagem =
        (margemTotal / valorNegociadoComAIndustria) * 100;
      const margemTotalTotal = margemTotal * quantidadeComprada;

      const dataDeValidade = '';

      ////////////

      const repasseDoIpiPorcentagemVenda = repasseDoIpi;

      const repasseDoFreteUnitarioPorcentagemVenda = repasseDoFreteUnitario;

      const repasseDeStPorcentagemVenda = repasseDeSt;

      const repasseDeSegurosPorcentagemVenda = repasseDeSeguros;

      const repasseDeOutrasDespesasPorcentagemVenda = repasseDeOutrasDespesas;

      /////////////

      return {
        baseCalculoIcmsUnitaria,
        estadoOrigem,
        estadoDestino,
        valorNegociadoComAIndustriaPorcentagem,
        valorNegociadoComAIndustria,
        valorNegociadoComAIndustriaTotal,
        custoLivreDeImpostoPorcentagem,
        lucroLiquidoPorcentagem,
        lucroLiquido,
        lucroLiquidoTotal,
        irpjPorcentagemPv,
        irpjPorcentagemLb,
        irpj,
        irpjTotal,
        csllPorcentagemPv,
        csllPorcentagemLb,
        csll,
        csllTotal,
        lucroBrutoPorcentagem,
        lucroBruto,
        lucroBrutoTotal,
        /* precoVenda:
          precoVenda -
          (descontoBacklight +
            descontoBoleto +
            descontoBonificacao +
            descontoDescargoMercadorias +
            descontoJornalOfertas +
            descontoNfe), */
        precoVenda,
        precoVendaTotal,
        aliquotaInternaCompraPorcentagem,
        aliquotaInternaCompra,
        aliquotaInternaCompraTotal,
        pisCompraPorcentagem,
        pisCompra,
        pisCompraTotal,
        cofinsCompraPorcentagem,
        cofinsCompra,
        cofinsCompraTotal,
        totalDeCreditosCompraPorcentagem,
        totalDeCreditosCompra,
        totalDeCreditosCompraTotal,
        aliquotaInternaVendaPorcentagem,
        aliquotaInternaVenda,
        aliquotaInternaVendaTotal,
        pisVendaPorcentagem,
        pisVenda,
        pisVendaTotal,
        cofinsVendaPorcentagem,
        cofinsVenda,
        cofinsVendaTotal,
        totalDeDebitosVendaPorcentagem,
        totalDeDebitosVenda,
        totalDeDebitosVendaTotal,
        icmsAntecipacaoPorcentagem,
        icmsAntecipacao,
        icmsAntecipacaoTotal,
        agregadoDoCnaePorcentagem,
        mvaDaPauta,
        calculoDaPautaDeIcmsDeFronteira,
        fronteiraPorcentagem,
        fronteira,
        icmsDeFronteiraAdotadoPorcentagem,
        icmsDeFronteiraAdotado,
        icmsDeFronteiraAdotadoTotal,
        totalDeIcmsAntecipadoPorcentagem,
        totalDeIcmsAntecipado,
        totalDeIcmsAntecipadoTotal,
        diferencaDeIcmsPorcentagem,
        diferencaDeIcms,
        diferencaDeIcmsTotal,
        diferencaDePisPorcentagem,
        diferencaDePis,
        diferencaDePisTotal,
        diferencaDeCofinsPorcentagem,
        diferencaDeCofins,
        diferencaDeCofinsTotal,
        tributacaoEfetivaPorcentagem,
        tributacaoEfetiva,
        tributacaoEfetivaTotal,
        resultadoDoFluxoDeCaixaPorcentagem,
        resultadoDoFluxoDeCaixa,
        resultadoDoFluxoDeCaixaTotal,
        margemDeCustoOperacionalPorcentagem,
        margemDeCustoOperacional,
        margemDeCustoOperacionalTotal,
        margemDeCustoDeCartaoPorcentagem,
        margemDeCustoDeCartao,
        margemDeCustoDeCartaoTotal,
        repasseDoIpiPorcentagem,
        repasseDoIpi,
        repasseDoIpiTotal,
        repasseDoFreteUnitarioPorcentagem,
        repasseDoFreteUnitario,
        repasseDoFreteUnitarioTotal,
        repasseDeStPorcentagem,
        repasseDeSt,
        repasseDeStTotal,
        repasseDeSegurosPorcentagem,
        repasseDeSeguros,
        repasseDeSegurosTotal,
        repasseDeOutrasDespesasPorcentagem,
        repasseDeOutrasDespesas,
        repasseDeOutrasDespesasTotal,
        totalDeCustosDeRepassesPorcentagem,
        totalDeCustosDeRepasses,
        totalDeCustosDeRepassesTotal,
        custoFinalDoProdutoPorcentagem,
        custoFinalDoProduto,
        custoFinalDoProdutoTotal,
        conferenciaDoGraficoDeSecaoCircular,
        margemTotalPorcentagem,
        margemTotal,
        margemTotalTotal,
        dataDeValidade,
        quantidadeComprada,
        //
        repasseDoIpiPorcentagemVenda,
        repasseDoFreteUnitarioPorcentagemVenda,
        repasseDeStPorcentagemVenda,
        repasseDeSegurosPorcentagemVenda,
        repasseDeOutrasDespesasPorcentagemVenda,
        //
        icmsAntecipacaoPorcentagemVenda: icmsAntecipacaoVenda,
        icmsDeFronteiraAdotadoPorcentagemVenda: icmsDeFronteiraVenda,
        descontoBacklight,
        descontoBoleto,
        descontoBonificacao,
        descontoDescargoMercadorias,
        descontoJornalOfertas,
        descontoNfe,
        //
        descontoNfePorcentagem,
        descontoNfeTotal,
        descontoBoletoPorcentagem,
        descontoBoletoTotal,
        descontoBonificacaoPorcentagem,
        descontoBonificacaoTotal,
        descontoJornalOfertasPorcentagem,
        descontoJornalOfertasTotal,
        descontoDescargoMercadoriasPorcentagem,
        descontoDescargoMercadoriasTotal,
        descontoBacklightPorcentagem,
        descontoBacklightTotal,
        descontoTotal:
          descontoBacklight +
          descontoBoleto +
          descontoBonificacao +
          descontoDescargoMercadorias +
          descontoJornalOfertas +
          descontoNfe,
        ////
        descontoTotalTotal:
          descontoNfeTotal +
          descontoBoletoTotal +
          descontoBonificacaoTotal +
          descontoJornalOfertasTotal +
          descontoDescargoMercadoriasTotal +
          descontoBacklightTotal,
        //
        descontoPorcentagem:
          descontoNfePorcentagem +
          descontoBoletoPorcentagem +
          descontoBonificacaoPorcentagem +
          descontoJornalOfertasPorcentagem +
          descontoDescargoMercadoriasPorcentagem +
          descontoBacklightPorcentagem,
        //
        margemDeAvaria,
        margemDeAvariaTotal,
        margemDeAvariaPorcentagem,
      };
    }

    let fatorPesoMedio = 1;

    if (produto) {
      if (produto.unidade.tipo === POR_PESO_FIXO) {
        if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) {
          fatorPesoMedio = produto.peso_medio;
        }
      }
    }

    function getUnidade() {
      if (!produto) return '';
      if (produto.unidade.tipo === POR_UNIDADE) return 'Un';
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 'Kg';
      if (produto.unidade.tipo === POR_PESO_FIXO) {
        if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) {
          return 'Un';
        }
        return 'Kg';
      }
      return '';
    }

    const unidadeTrabalhada = getUnidade();

    const optionsRepassesUnitarios = [
      {
        label: `Unitário (${unidadeTrabalhada})`,
        value: 0,
      },
      {
        label: 'Total',
        value: 1,
      },
      {
        label: 'Porcentagem',
        value: 2,
      },
    ];

    const optionsDescontos = [
      {
        label: `Unitário (${unidadeTrabalhada})`,
        value: 0,
      },
      {
        label: 'Total',
        value: 1,
      },
    ];

    function formataDados(entradas) {
      const dados = calculaDados(entradas);
      return {
        estadoOrigem: dados.estadoOrigem,
        estadoDestino: dados.estadoDestino,
        valorNegociadoComAIndustriaPorcentagem: formatPorcentagem(
          dados.valorNegociadoComAIndustriaPorcentagem,
        ),
        valorNegociadoComAIndustria: formatMoeda(
          dados.valorNegociadoComAIndustria * fatorPesoMedio,
        ),
        valorNegociadoComAIndustriaTotal: formatMoeda(
          dados.valorNegociadoComAIndustriaTotal * fatorPesoMedio,
        ),
        custoLivreDeImpostoPorcentagem: formatMoeda(
          dados.custoLivreDeImpostoPorcentagem,
        ),
        lucroLiquidoPorcentagem: formatPorcentagem(
          dados.lucroLiquidoPorcentagem,
        ),
        lucroLiquido: formatMoeda(dados.lucroLiquido * fatorPesoMedio),
        lucroLiquidoTotal: formatMoeda(
          dados.lucroLiquidoTotal * fatorPesoMedio,
        ),
        irpjPorcentagemPv: formatPorcentagem(dados.irpjPorcentagemPv),
        irpjPorcentagemLb: formatPorcentagem(dados.irpjPorcentagemLb),
        irpj: formatMoeda(dados.irpj * fatorPesoMedio),
        irpjTotal: formatMoeda(dados.irpjTotal * fatorPesoMedio),
        csllPorcentagemPv: formatPorcentagem(dados.csllPorcentagemPv),
        csllPorcentagemLb: formatPorcentagem(dados.csllPorcentagemLb),
        csll: formatMoeda(dados.csll * fatorPesoMedio),
        csllTotal: formatMoeda(dados.csllTotal * fatorPesoMedio),
        lucroBrutoPorcentagem: formatPorcentagem(dados.lucroBrutoPorcentagem),
        lucroBruto: formatMoeda(dados.lucroBruto * fatorPesoMedio),
        lucroBrutoTotal: formatMoeda(dados.lucroBrutoTotal * fatorPesoMedio),
        precoVenda: formatMoeda(dados.precoVenda * fatorPesoMedio),
        precoVendaTotal: formatMoeda(dados.precoVendaTotal * fatorPesoMedio),
        aliquotaInternaCompraPorcentagem: formatPorcentagem(
          dados.aliquotaInternaCompraPorcentagem,
        ),
        aliquotaInternaCompra: formatMoeda(
          dados.aliquotaInternaCompra * fatorPesoMedio,
        ),
        aliquotaInternaCompraTotal: formatMoeda(
          dados.aliquotaInternaCompraTotal * fatorPesoMedio,
        ),
        pisCompraPorcentagem: formatPorcentagem(dados.pisCompraPorcentagem),
        pisCompra: formatMoeda(dados.pisCompra * fatorPesoMedio),
        pisCompraTotal: formatMoeda(dados.pisCompraTotal * fatorPesoMedio),
        cofinsCompraPorcentagem: formatPorcentagem(
          dados.cofinsCompraPorcentagem,
        ),
        cofinsCompra: formatMoeda(dados.cofinsCompra * fatorPesoMedio),
        cofinsCompraTotal: formatMoeda(
          dados.cofinsCompraTotal * fatorPesoMedio,
        ),
        totalDeCreditosCompraPorcentagem: formatPorcentagem(
          dados.totalDeCreditosCompraPorcentagem,
        ),
        totalDeCreditosCompra: formatMoeda(
          dados.totalDeCreditosCompra * fatorPesoMedio,
        ),
        totalDeCreditosCompraTotal: formatMoeda(
          dados.totalDeCreditosCompraTotal * fatorPesoMedio,
        ),
        aliquotaInternaVendaPorcentagem: formatPorcentagem(
          dados.aliquotaInternaVendaPorcentagem,
        ),
        aliquotaInternaVenda: formatMoeda(
          dados.aliquotaInternaVenda * fatorPesoMedio,
        ),
        aliquotaInternaVendaTotal: formatMoeda(
          dados.aliquotaInternaVendaTotal * fatorPesoMedio,
        ),
        pisVendaPorcentagem: formatPorcentagem(dados.pisVendaPorcentagem),
        pisVenda: formatMoeda(dados.pisVenda * fatorPesoMedio),
        pisVendaTotal: formatMoeda(dados.pisVendaTotal * fatorPesoMedio),
        cofinsVendaPorcentagem: formatPorcentagem(dados.cofinsVendaPorcentagem),
        cofinsVenda: formatMoeda(dados.cofinsVenda * fatorPesoMedio),
        cofinsVendaTotal: formatMoeda(dados.cofinsVendaTotal * fatorPesoMedio),
        totalDeDebitosVendaPorcentagem: formatPorcentagem(
          dados.totalDeDebitosVendaPorcentagem,
        ),
        totalDeDebitosVenda: formatMoeda(
          dados.totalDeDebitosVenda * fatorPesoMedio,
        ),
        totalDeDebitosVendaTotal: formatMoeda(
          dados.totalDeDebitosVendaTotal * fatorPesoMedio,
        ),
        icmsAntecipacaoPorcentagem: formatPorcentagem(
          dados.icmsAntecipacaoPorcentagem,
        ),
        icmsAntecipacao: formatMoeda(dados.icmsAntecipacao * fatorPesoMedio),
        icmsAntecipacaoTotal: formatMoeda(
          dados.icmsAntecipacaoTotal * fatorPesoMedio,
        ),
        agregadoDoCnaePorcentagem: formatPorcentagem(
          dados.agregadoDoCnaePorcentagem,
        ),
        mvaDaPauta: formatMoeda(dados.mvaDaPauta),
        calculoDaPautaDeIcmsDeFronteira: formatMoeda(
          dados.calculoDaPautaDeIcmsDeFronteira,
        ),
        fronteiraPorcentagem: formatPorcentagem(dados.fronteiraPorcentagem),
        fronteira: formatMoeda(dados.fronteira * fatorPesoMedio),
        icmsDeFronteiraAdotadoPorcentagem: formatPorcentagem(
          dados.icmsDeFronteiraAdotadoPorcentagem,
        ),
        icmsDeFronteiraAdotado: formatMoeda(
          dados.icmsDeFronteiraAdotado * fatorPesoMedio,
        ),
        icmsDeFronteiraAdotadoTotal: formatMoeda(
          dados.icmsDeFronteiraAdotadoTotal * fatorPesoMedio,
        ),
        totalDeIcmsAntecipadoPorcentagem: formatPorcentagem(
          dados.totalDeIcmsAntecipadoPorcentagem,
        ),
        totalDeIcmsAntecipado: formatMoeda(
          dados.totalDeIcmsAntecipado * fatorPesoMedio,
        ),
        totalDeIcmsAntecipadoTotal: formatMoeda(
          dados.totalDeIcmsAntecipadoTotal * fatorPesoMedio,
        ),
        diferencaDeIcmsPorcentagem: formatPorcentagem(
          dados.diferencaDeIcmsPorcentagem,
        ),
        diferencaDeIcms: formatMoeda(dados.diferencaDeIcms * fatorPesoMedio),
        diferencaDeIcmsTotal: formatMoeda(
          dados.diferencaDeIcmsTotal * fatorPesoMedio,
        ),
        diferencaDePisPorcentagem: formatPorcentagem(
          dados.diferencaDePisPorcentagem,
        ),
        diferencaDePis: formatMoeda(dados.diferencaDePis * fatorPesoMedio),
        diferencaDePisTotal: formatMoeda(
          dados.diferencaDePisTotal * fatorPesoMedio,
        ),
        diferencaDeCofinsPorcentagem: formatPorcentagem(
          dados.diferencaDeCofinsPorcentagem,
        ),
        diferencaDeCofins: formatMoeda(
          dados.diferencaDeCofins * fatorPesoMedio,
        ),
        diferencaDeCofinsTotal: formatMoeda(
          dados.diferencaDeCofinsTotal * fatorPesoMedio,
        ),
        tributacaoEfetivaPorcentagem: formatPorcentagem(
          dados.tributacaoEfetivaPorcentagem,
        ),
        tributacaoEfetiva: formatMoeda(
          dados.tributacaoEfetiva * fatorPesoMedio,
        ),
        tributacaoEfetivaTotal: formatMoeda(
          dados.tributacaoEfetivaTotal * fatorPesoMedio,
        ),
        resultadoDoFluxoDeCaixaPorcentagem: formatPorcentagem(
          dados.resultadoDoFluxoDeCaixaPorcentagem,
        ),
        resultadoDoFluxoDeCaixa: formatMoeda(
          dados.resultadoDoFluxoDeCaixa * fatorPesoMedio,
        ),
        resultadoDoFluxoDeCaixaTotal: formatMoeda(
          dados.resultadoDoFluxoDeCaixaTotal * fatorPesoMedio,
        ),
        margemDeCustoOperacionalPorcentagem: formatPorcentagem(
          dados.margemDeCustoOperacionalPorcentagem,
        ),
        margemDeCustoOperacional: formatMoeda(
          dados.margemDeCustoOperacional * fatorPesoMedio,
        ),
        margemDeCustoOperacionalTotal: formatMoeda(
          dados.margemDeCustoOperacionalTotal * fatorPesoMedio,
        ),
        margemDeCustoDeCartaoPorcentagem: formatPorcentagem(
          dados.margemDeCustoDeCartaoPorcentagem,
        ),
        margemDeCustoDeCartao: formatMoeda(
          dados.margemDeCustoDeCartao * fatorPesoMedio,
        ),
        margemDeCustoDeCartaoTotal: formatMoeda(
          dados.margemDeCustoDeCartaoTotal * fatorPesoMedio,
        ),
        repasseDoIpiPorcentagem: formatPorcentagem(
          dados.repasseDoIpiPorcentagem,
        ),
        repasseDoIpi: formatMoeda(dados.repasseDoIpi * fatorPesoMedio),
        repasseDoIpiTotal: formatMoeda(
          dados.repasseDoIpiTotal * fatorPesoMedio,
        ),
        repasseDoFreteUnitarioPorcentagem: formatPorcentagem(
          dados.repasseDoFreteUnitarioPorcentagem,
        ),
        repasseDoFreteUnitario: formatMoeda(
          dados.repasseDoFreteUnitario * fatorPesoMedio,
        ),
        repasseDoFreteUnitarioTotal: formatMoeda(
          dados.repasseDoFreteUnitarioTotal * fatorPesoMedio,
        ),
        repasseDeStPorcentagem: formatPorcentagem(dados.repasseDeStPorcentagem),
        repasseDeSt: formatMoeda(dados.repasseDeSt * fatorPesoMedio),
        repasseDeStTotal: formatMoeda(dados.repasseDeStTotal * fatorPesoMedio),
        repasseDeSegurosPorcentagem: formatPorcentagem(
          dados.repasseDeSegurosPorcentagem,
        ),
        repasseDeSeguros: formatMoeda(dados.repasseDeSeguros * fatorPesoMedio),
        repasseDeSegurosTotal: formatMoeda(
          dados.repasseDeSegurosTotal * fatorPesoMedio,
        ),
        repasseDeOutrasDespesasPorcentagem: formatPorcentagem(
          dados.repasseDeOutrasDespesasPorcentagem,
        ),
        repasseDeOutrasDespesas: formatMoeda(
          dados.repasseDeOutrasDespesas * fatorPesoMedio,
        ),
        repasseDeOutrasDespesasTotal: formatMoeda(
          dados.repasseDeOutrasDespesasTotal * fatorPesoMedio,
        ),
        totalDeCustosDeRepassesPorcentagem: formatPorcentagem(
          dados.totalDeCustosDeRepassesPorcentagem,
        ),
        totalDeCustosDeRepasses: formatMoeda(
          dados.totalDeCustosDeRepasses * fatorPesoMedio,
        ),
        totalDeCustosDeRepassesTotal: formatMoeda(
          dados.totalDeCustosDeRepassesTotal * fatorPesoMedio,
        ),
        custoFinalDoProdutoPorcentagem: formatPorcentagem(
          dados.custoFinalDoProdutoPorcentagem,
        ),
        custoFinalDoProduto: formatMoeda(
          dados.custoFinalDoProduto * fatorPesoMedio,
        ),
        custoFinalDoProdutoTotal: formatMoeda(
          dados.custoFinalDoProdutoTotal * fatorPesoMedio,
        ),
        conferenciaDoGraficoDeSecaoCircular: formatPorcentagem(
          dados.conferenciaDoGraficoDeSecaoCircular,
        ),
        margemTotalPorcentagem: formatPorcentagem(dados.margemTotalPorcentagem),
        margemTotal: formatMoeda(dados.margemTotal * fatorPesoMedio),
        margemTotalTotal: formatMoeda(dados.margemTotalTotal * fatorPesoMedio),
        dataDeValidade: dados.dataDeValidade,
        quantidadeComprada: dados.quantidadeComprada,
        descontoTotal: formatMoeda(dados.descontoTotal * fatorPesoMedio),
        descontoPorcentagem: formatPorcentagem(dados.descontoPorcentagem),
        descontoTotalTotal: formatMoeda(
          dados.descontoTotalTotal * fatorPesoMedio,
        ),
        dadosOriginais: dados,
        //
        descontoBacklight: formatMoeda(
          dados.descontoBacklight * fatorPesoMedio,
        ),
        descontoBoleto: formatMoeda(dados.descontoBoleto * fatorPesoMedio),
        descontoBonificacao: formatMoeda(
          dados.descontoBonificacao * fatorPesoMedio,
        ),
        descontoDescargoMercadorias: formatMoeda(
          dados.descontoDescargoMercadorias * fatorPesoMedio,
        ),
        descontoJornalOfertas: formatMoeda(
          dados.descontoJornalOfertas * fatorPesoMedio,
        ),
        descontoNfe: formatMoeda(dados.descontoNfe * fatorPesoMedio),
        //
        descontoNfePorcentagem: formatPorcentagem(dados.descontoNfePorcentagem),
        descontoNfeTotal: formatMoeda(dados.descontoNfeTotal * fatorPesoMedio),
        descontoBoletoPorcentagem: formatPorcentagem(
          dados.descontoBoletoPorcentagem,
        ),
        descontoBoletoTotal: formatMoeda(
          dados.descontoBoletoTotal * fatorPesoMedio,
        ),
        descontoBonificacaoPorcentagem: formatPorcentagem(
          dados.descontoBonificacaoPorcentagem,
        ),
        descontoBonificacaoTotal: formatMoeda(
          dados.descontoBonificacaoTotal * fatorPesoMedio,
        ),
        descontoJornalOfertasPorcentagem: formatPorcentagem(
          dados.descontoJornalOfertasPorcentagem,
        ),
        descontoJornalOfertasTotal: formatMoeda(
          dados.descontoJornalOfertasTotal * fatorPesoMedio,
        ),
        descontoDescargoMercadoriasPorcentagem: formatPorcentagem(
          dados.descontoDescargoMercadoriasPorcentagem,
        ),
        descontoDescargoMercadoriasTotal: formatMoeda(
          dados.descontoDescargoMercadoriasTotal * fatorPesoMedio,
        ),
        descontoBacklightPorcentagem: formatPorcentagem(
          dados.descontoBacklightPorcentagem,
        ),
        descontoBacklightTotal: formatMoeda(
          dados.descontoBacklightTotal * fatorPesoMedio,
        ),
      };
    }

    const linhas = [];
    /*linhas.push(
    formataDados({
      estadoOrigem: 'Alagoas',
      estadoDestino: 'Pernambuco',
      quantidadeComprada: 1000,
      valorNegociadoComAIndustria: 100,
      irpjPorcentagemLb: 25,
      csllPorcentagemLb: 9,
      lucroBrutoPorcentagem: 3,
      aliquotaInternaCompraPorcentagem: 12,
      pisCompraPorcentagem: 1.65,
      cofinsCompraPorcentagem: 7.6,
      aliquotaInternaVendaPorcentagem: 18,
      agregadoDoCnaePorcentagem: 30,
      mvaDaPauta: 0,
      margemDeCustoOperacionalPorcentagem: 5,
      margemDeCustoDeCartaoPorcentagem: 3,
      repasseDoIpi: 0,
      repasseDoFreteUnitario: 0,
      repasseDeSt: 0,
      repasseDeSeguros: 0,
      repasseDeOutrasDespesas: 0,
    }),
  );*/

    function getValorRepasse(
      tipoRepasse,
      repasseValue,
      qtdeRepasse,
      baseDeCalculoComputado,
    ) {
      switch (tipoRepasse) {
        case optionsRepassesUnitarios[0].value:
          return repasseValue;
        case optionsRepassesUnitarios[1].value:
          return repasseValue / qtdeRepasse;
        case optionsRepassesUnitarios[2].value:
          return ((repasseValue / 100) * baseDeCalculoComputado) / qtdeRepasse;
        default:
          return 0;
      }
    }

    function getDadosRepassesComputado() {
      switch (alvo) {
        case optionsAlvo[0].value: {
          const baseDeCalculoComputado = baseDeCalculo;
          const qtdeRepasse = getQuantidadeRepasse(
            quantidadeComprada,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          return {
            valorNegociadoComAIndustriaComputado: calculaValorNegociadoComAIndustria(
              baseDeCalculo,
              quantidadeComprada,
              produto ? produto.peso_medio : 0,
              produto ? produto.unidade.tipo : '',
            ),
            quantidadeCompradaConputado: quantidadeComprada,
            baseDeCalculoComputado,
            repasseDoIpiComputado: getValorRepasse(
              tipoRepasseDoIpi,
              repasseDoIpi,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDoFreteUnitarioComputado: getValorRepasse(
              tipoRepasseDoFrete,
              repasseDoFreteUnitario,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeStComputado: getValorRepasse(
              tipoRepasseDoSt,
              repasseDeSt,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeSegurosComputado: getValorRepasse(
              tipoRepasseDoSeguros,
              repasseDeSeguros,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeOutrasDespesasComputado: getValorRepasse(
              tipoRepasseDoOutrasDespesas,
              repasseDeOutrasDespesas,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
          };
        }
        case optionsAlvo[1].value: {
          const baseDeCalculoComputado = calculaBaseDeCalculo(
            valorNegociadoComAIndustria,
            quantidadeComprada,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          const qtdeRepasse = getQuantidadeRepasse(
            quantidadeComprada,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          return {
            valorNegociadoComAIndustriaComputado: valorNegociadoComAIndustria,
            quantidadeCompradaConputado: quantidadeComprada,
            baseDeCalculoComputado,
            repasseDoIpiComputado: getValorRepasse(
              tipoRepasseDoIpi,
              repasseDoIpi,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDoFreteUnitarioComputado: getValorRepasse(
              tipoRepasseDoFrete,
              repasseDoFreteUnitario,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeStComputado: getValorRepasse(
              tipoRepasseDoSt,
              repasseDeSt,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeSegurosComputado: getValorRepasse(
              tipoRepasseDoSeguros,
              repasseDeSeguros,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeOutrasDespesasComputado: getValorRepasse(
              tipoRepasseDoOutrasDespesas,
              repasseDeOutrasDespesas,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
          };
        }
        case optionsAlvo[2].value: {
          const baseDeCalculoComputado = baseDeCalculo;
          const quantidadeCompradaConputado = calculaQuantidadeComprada(
            valorNegociadoComAIndustria,
            baseDeCalculo,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          const qtdeRepasse = getQuantidadeRepasse(
            quantidadeCompradaConputado,
            produto ? produto.peso_medio : 0,
            produto ? produto.unidade.tipo : '',
          );
          return {
            valorNegociadoComAIndustriaComputado: valorNegociadoComAIndustria,
            quantidadeCompradaConputado,
            baseDeCalculoComputado,
            repasseDoIpiComputado: getValorRepasse(
              tipoRepasseDoIpi,
              repasseDoIpi,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDoFreteUnitarioComputado: getValorRepasse(
              tipoRepasseDoFrete,
              repasseDoFreteUnitario,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeStComputado: getValorRepasse(
              tipoRepasseDoSt,
              repasseDeSt,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeSegurosComputado: getValorRepasse(
              tipoRepasseDoSeguros,
              repasseDeSeguros,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
            repasseDeOutrasDespesasComputado: getValorRepasse(
              tipoRepasseDoOutrasDespesas,
              repasseDeOutrasDespesas,
              qtdeRepasse,
              baseDeCalculoComputado,
            ),
          };
        }
        default: {
          return {
            valorNegociadoComAIndustriaComputado: 0,
            quantidadeCompradaConputado: 0,
            baseDeCalculoComputado: 0,
            repasseDoIpiComputado: 0,
            repasseDoFreteUnitarioComputado: 0,
            repasseDeStComputado: 0,
            repasseDeSegurosComputado: 0,
            repasseDeOutrasDespesasComputado: 0,
          };
        }
      }
    }

    function getValorDesconto(tipo, value, qtde) {
      switch (tipo) {
        case optionsDescontos[0].value:
          return value;
        case optionsDescontos[1].value:
          return value / qtde;
        default:
          return 0;
      }
    }

    function getDescontosComputados() {
      const qtdeRepasse = getQuantidadeRepasse(
        quantidadeComprada,
        produto ? produto.peso_medio : 0,
        produto ? produto.unidade.tipo : '',
      );

      return {
        descontoNfeComputado: getValorDesconto(
          tipoDescontoNfe,
          descontoNfe,
          qtdeRepasse,
        ),
        descontoBoletoComputado: getValorDesconto(
          tipoDescontoBoleto,
          descontoBoleto,
          qtdeRepasse,
        ),
        descontoBonificacaoComputado: getValorDesconto(
          tipoDescontoBonificacao,
          descontoBonificacao,
          qtdeRepasse,
        ),
        descontoBacklightComputado: getValorDesconto(
          tipoDescontoBacklight,
          descontoBacklight,
          qtdeRepasse,
        ),
        descontoJornalOfertasComputado: getValorDesconto(
          tipoDescontoJornalOfertas,
          descontoJornalOfertas,
          qtdeRepasse,
        ),
        descontoDescargoMercadoriasComputado: getValorDesconto(
          tipoDescontoDescargoMercadorias,
          descontoDescargoMercadorias,
          qtdeRepasse,
        ),
      };
    }

    function getMessageAviso() {
      if (fornecedor && produto && config) {
        const isSt = repasseDeStComputado > 0;
        switch (fornecedor.regime_tributario) {
          case REGIME_TRIBUTARIO_PRODUTOR_RURAL: {
            return 'Devido ao fato deste fornecedor ser "PRODUTOR RURAL" os créditos de PIS, COFINS e ICMS são nulos (exceto para os casos particulares, quando especificado no produto)!';
          }
          case REGIME_TRIBUTARIO_MEI: {
            return 'Devido ao fato deste fornecedor ser "MEI" o crédito de ICMS é nulo (exceto para os casos particulares, quando especificado no produto)!';
          }
          case REGIME_TRIBUTARIO_SIMPLES_NACIONAL: {
            return 'Devido ao fato deste fornecedor ser "SIMPLES NACIONAL" o crédito de ICMS é nulo (exceto para os casos particulares, quando especificado no produto)!';
          }
          case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO: {
            return '';
          }
          case REGIME_TRIBUTARIO_LUCRO_REAL: {
            return '';
          }
          case REGIME_TRIBUTARIO_SEM_NFE: {
            return 'Devido ao fato deste fornecedor ser "SEM NFE" todos os impostos são nulos (exceto para os casos particulares, quando especificado no produto)!';
          }
          default: {
            return '';
          }
        }
      } else {
        return '';
      }
    }

    function getMessageAviso2() {
      const isSt = repasseDeStComputado > 0;
      if (isSt) {
        return 'Devido ao fato de haver "Repasse Unitário de Substituição Tributária" o crédito e débito de ICMS são nulos (exceto para os casos particulares, quando especificado no produto)!';
      }
      return '';
    }

    const {
      baseDeCalculoComputado,
      quantidadeCompradaConputado,
      valorNegociadoComAIndustriaComputado,
      repasseDeOutrasDespesasComputado,
      repasseDeSegurosComputado,
      repasseDeStComputado,
      repasseDoFreteUnitarioComputado,
      repasseDoIpiComputado,
    } = getDadosRepassesComputado();

    const {
      descontoBacklightComputado,
      descontoBoletoComputado,
      descontoBonificacaoComputado,
      descontoDescargoMercadoriasComputado,
      descontoJornalOfertasComputado,
      descontoNfeComputado,
    } = getDescontosComputados();

    function getDadosEspeciais(produto) {
      return {
        pis_credito: produto.dadosImposto[0].pis_credito,
        is_caso_especial_pis_credito:
          produto.dadosImposto[0].is_caso_especial_pis_credito,
        pis_debito: produto.dadosImposto[0].pis_debito,
        is_caso_especial_pis_debito:
          produto.dadosImposto[0].is_caso_especial_pis_debito,
        cofins_credito: produto.dadosImposto[0].cofins_credito,
        is_caso_especial_cofins_credito:
          produto.dadosImposto[0].is_caso_especial_cofins_credito,
        cofins_debito: produto.dadosImposto[0].cofins_debito,
        is_caso_especial_cofins_debito:
          produto.dadosImposto[0].is_caso_especial_cofins_debito,
        ipi_credito: produto.dadosImposto[0].ipi_credito,
        is_caso_especial_ipi_credito:
          produto.dadosImposto[0].is_caso_especial_ipi_credito,
        ipi_debito: produto.dadosImposto[0].ipi_debito,
        is_caso_especial_ipi_debito:
          produto.dadosImposto[0].is_caso_especial_ipi_debito,
        icms_normal_credito: produto.dadosImposto[0].icms_normal_credito,
        is_caso_especial_icms_normal_credito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_credito,
        icms_normal_debito: produto.dadosImposto[0].icms_normal_debito,
        is_caso_especial_icms_normal_debito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_debito,
        icms_fronteira: produto.dadosImposto[0].icms_fronteira,
        is_caso_especial_icms_fronteira:
          produto.dadosImposto[0].is_caso_especial_icms_fronteira,
        icms_antecipacao: produto.dadosImposto[0].icms_antecipacao,
        is_caso_especial_icms_antecipacao:
          produto.dadosImposto[0].is_caso_especial_icms_antecipacao,
      };
    }

    function getDadosImpostosComputado() {
      if (fornecedor && produto && config) {
        const isSt = repasseDeStComputado > 0;
        const {
          pis_credito,
          is_caso_especial_pis_credito,
          pis_debito,
          is_caso_especial_pis_debito,
          cofins_credito,
          is_caso_especial_cofins_credito,
          cofins_debito,
          is_caso_especial_cofins_debito,
          icms_normal_credito,
          is_caso_especial_icms_normal_credito,
          icms_normal_debito,
          is_caso_especial_icms_normal_debito,
          icms_fronteira,
          is_caso_especial_icms_fronteira,
          icms_antecipacao,
          is_caso_especial_icms_antecipacao,
        } = getDadosEspeciais(produto);
        switch (fornecedor.regime_tributario) {
          case REGIME_TRIBUTARIO_PRODUTOR_RURAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : config.cidade.estado.icms_ai_compra,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : 0,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : 0,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : false,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : false,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_MEI: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_SIMPLES_NACIONAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,

              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_REAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_SEM_NFE: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : 0,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : 0,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : 0,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : 0,
              isFronteira: is_caso_especial_icms_fronteira ? true : false,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : false,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          default: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado: 0,
              pisCompraPorcentagemComputado: 0,
              pisVendaPorcentagemComputado: 0,
              cofinsCompraPorcentagemComputado: 0,
              cofinsVendaPorcentagemComputado: 0,
            };
          }
        }
      } else {
        return {
          aliquotaInternaCompraPorcentagemComputado: 0,
          aliquotaInternaVendaPorcentagemComputado: 0,
          pisCompraPorcentagemComputado: 0,
          pisVendaPorcentagemComputado: 0,
          cofinsCompraPorcentagemComputado: 0,
          cofinsVendaPorcentagemComputado: 0,
        };
      }
    }

    const {
      aliquotaInternaCompraPorcentagemComputado,
      aliquotaInternaVendaPorcentagemComputado,
      cofinsCompraPorcentagemComputado,
      cofinsVendaPorcentagemComputado,
      pisCompraPorcentagemComputado,
      pisVendaPorcentagemComputado,
      isAntecipacao,
      isFronteira,
      icmsFronteiraPorcentagemInput,
      icmsAntecipacaoPorcentagemInput,
    } = getDadosImpostosComputado();

    function getDescontosUnitarios() {}

    function getPorcentagemCustoOperacional(produto) {
      if (produto) {
        return produto.porcentagem_custo_operacional / 100;
      }
      return 1;
    }

    linhas.push(
      formataDados({
        estadoOrigem: fornecedor ? fornecedor.estado.nome : '',
        estadoDestino: config ? config.cidade.estado.nome : '',
        quantidadeComprada: quantidadeCompradaConputado,
        valorNegociadoComAIndustria: valorNegociadoComAIndustriaComputado,
        irpjPorcentagemLb: config ? config.irpj : 0,
        csllPorcentagemLb: config ? config.csll : 0,
        //lucroBrutoPorcentagem: produto ? produto.taxa_de_lucro : 0,
        lucroBrutoPorcentagem: margemLucroBrutoEstado,
        //aliquotaInternaCompraPorcentagem: fornecedor ? fornecedor.estado.icms_ai_compra : 0,
        aliquotaInternaCompraPorcentagem: aliquotaInternaCompraPorcentagemComputado,
        //pisCompraPorcentagem: produto ? produto.dadosImposto[0].pis : 0,
        pisCompraPorcentagem: pisCompraPorcentagemComputado,
        //cofinsCompraPorcentagem: produto ? produto.dadosImposto[0].cofins : 0,
        cofinsCompraPorcentagem: cofinsCompraPorcentagemComputado,
        //aliquotaInternaVendaPorcentagem: config ? config.cidade.estado.icms_ai_compra : 0,
        aliquotaInternaVendaPorcentagem: aliquotaInternaVendaPorcentagemComputado,
        agregadoDoCnaePorcentagem: 0,
        mvaDaPauta: 0,
        margemDeCustoOperacionalPorcentagem: config
          ? config.pcf * getPorcentagemCustoOperacional(produto)
          : 0,
        margemDeCustoDeCartaoPorcentagem: config ? config.ppc : 0,
        repasseDoIpi: repasseDoIpiComputado,
        repasseDoFreteUnitario: repasseDoFreteUnitarioComputado,
        repasseDeSt: repasseDeStComputado,
        repasseDeSeguros: repasseDeSegurosComputado,
        repasseDeOutrasDespesas: repasseDeOutrasDespesasComputado,
        //pisVendaPorcentagem: produto ? produto.dadosImposto[0].pis : 0,
        pisVendaPorcentagem: pisVendaPorcentagemComputado,
        //cofinsVendaPorcentagem: produto ? produto.dadosImposto[0].cofins : 0,
        cofinsVendaPorcentagem: cofinsVendaPorcentagemComputado,
        isAntecipacao,
        isFronteira,
        incluirIcmsDeFronteira: config
          ? config.incluir_icms_fronteira_na_precificao
          : false,
        incluirIcmsDeAntecipacao: config
          ? config.incluir_icms_antecipacao_na_precificacao
          : false,
        //
        /*pis_credito: produto.dadosImposto[0].pis_credito,
        is_caso_especial_pis_credito:
          produto.dadosImposto[0].is_caso_especial_pis_credito,
        pis_debito: produto.dadosImposto[0].pis_debito,
        is_caso_especial_pis_debito:
          produto.dadosImposto[0].is_caso_especial_pis_debito,
        cofins_credito: produto.dadosImposto[0].cofins_credito,
        is_caso_especial_cofins_credito:
          produto.dadosImposto[0].is_caso_especial_cofins_credito,
        cofins_debito: produto.dadosImposto[0].cofins_debito,
        is_caso_especial_cofins_debito:
          produto.dadosImposto[0].is_caso_especial_cofins_debito,
        ipi_credito: produto.dadosImposto[0].ipi_credito,
        is_caso_especial_ipi_credito:
          produto.dadosImposto[0].is_caso_especial_ipi_credito,
        ipi_debito: produto.dadosImposto[0].ipi_debito,
        is_caso_especial_ipi_debito:
          produto.dadosImposto[0].is_caso_especial_ipi_debito,
        icms_normal_credito: produto.dadosImposto[0].icms_normal_credito,
        is_caso_especial_icms_normal_credito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_credito,
        icms_normal_debito: produto.dadosImposto[0].icms_normal_debito,
        is_caso_especial_icms_normal_debito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_debito,
        icms_fronteira: produto.dadosImposto[0].icms_fronteira,
        is_caso_especial_icms_fronteira:
          produto.dadosImposto[0].is_caso_especial_icms_fronteira,
        icms_antecipacao: produto.dadosImposto[0].icms_antecipacao,
        is_caso_especial_icms_antecipacao:
          produto.dadosImposto[0].is_caso_especial_icms_antecipacao,*/
        icmsAntecipacaoPorcentagemInput,
        icmsFronteiraPorcentagemInput,
        ///
        descontoBacklight: descontoBacklightComputado,
        descontoBoleto: descontoBoletoComputado,
        descontoBonificacao: descontoBonificacaoComputado,
        descontoDescargoMercadorias: descontoDescargoMercadoriasComputado,
        descontoJornalOfertas: descontoJornalOfertasComputado,
        descontoNfe: descontoNfeComputado,
        margemDeAvariaPorcentagem: config ? config.pparf : 0,
      }),
    );

    const {
      estadoOrigem,
      estadoDestino,
      valorNegociadoComAIndustriaPorcentagem,
      valorNegociadoComAIndustria: valorNegociadoComAIndustria2,
      valorNegociadoComAIndustriaTotal,
      custoLivreDeImpostoPorcentagem,
      lucroLiquidoPorcentagem,
      lucroLiquido,
      lucroLiquidoTotal,
      irpjPorcentagemPv,
      irpjPorcentagemLb,
      irpj,
      irpjTotal,
      csllPorcentagemPv,
      csllPorcentagemLb,
      csll,
      csllTotal,
      lucroBrutoPorcentagem,
      lucroBruto,
      lucroBrutoTotal,
      precoVenda,
      precoVendaTotal,
      aliquotaInternaCompraPorcentagem,
      aliquotaInternaCompra,
      aliquotaInternaCompraTotal,
      pisCompraPorcentagem,
      pisCompra,
      pisCompraTotal,
      cofinsCompraPorcentagem,
      cofinsCompra,
      cofinsCompraTotal,
      totalDeCreditosCompraPorcentagem,
      totalDeCreditosCompra,
      totalDeCreditosCompraTotal,
      aliquotaInternaVendaPorcentagem,
      aliquotaInternaVenda,
      aliquotaInternaVendaTotal,
      pisVendaPorcentagem,
      pisVenda,
      pisVendaTotal,
      cofinsVendaPorcentagem,
      cofinsVenda,
      cofinsVendaTotal,
      totalDeDebitosVendaPorcentagem,
      totalDeDebitosVenda,
      totalDeDebitosVendaTotal,
      icmsAntecipacaoPorcentagem,
      icmsAntecipacao,
      icmsAntecipacaoTotal,
      agregadoDoCnaePorcentagem,
      mvaDaPauta,
      calculoDaPautaDeIcmsDeFronteira,
      fronteiraPorcentagem,
      fronteira,
      icmsDeFronteiraAdotadoPorcentagem,
      icmsDeFronteiraAdotado,
      icmsDeFronteiraAdotadoTotal,
      totalDeIcmsAntecipadoPorcentagem,
      totalDeIcmsAntecipado,
      totalDeIcmsAntecipadoTotal,
      diferencaDeIcmsPorcentagem,
      diferencaDeIcms,
      diferencaDeIcmsTotal,
      diferencaDePisPorcentagem,
      diferencaDePis,
      diferencaDePisTotal,
      diferencaDeCofinsPorcentagem,
      diferencaDeCofins,
      diferencaDeCofinsTotal,
      tributacaoEfetivaPorcentagem,
      tributacaoEfetiva,
      tributacaoEfetivaTotal,
      resultadoDoFluxoDeCaixaPorcentagem,
      resultadoDoFluxoDeCaixa,
      resultadoDoFluxoDeCaixaTotal,
      margemDeCustoOperacionalPorcentagem,
      margemDeCustoOperacional,
      margemDeCustoOperacionalTotal,
      margemDeCustoDeCartaoPorcentagem,
      margemDeCustoDeCartao,
      margemDeCustoDeCartaoTotal,
      repasseDoIpiPorcentagem,
      repasseDoIpi: repasseDoIpi2,
      repasseDoIpiTotal,
      repasseDoFreteUnitarioPorcentagem,
      repasseDoFreteUnitario: repasseDoFreteUnitario2,
      repasseDoFreteUnitarioTotal,
      repasseDeStPorcentagem,
      repasseDeSt: repasseDeSt2,
      repasseDeStTotal,
      repasseDeSegurosPorcentagem,
      repasseDeSeguros: repasseDeSeguros2,
      repasseDeSegurosTotal,
      repasseDeOutrasDespesasPorcentagem,
      repasseDeOutrasDespesas: repasseDeOutrasDespesas2,
      repasseDeOutrasDespesasTotal,
      totalDeCustosDeRepassesPorcentagem,
      totalDeCustosDeRepasses,
      totalDeCustosDeRepassesTotal,
      custoFinalDoProdutoPorcentagem,
      custoFinalDoProduto,
      custoFinalDoProdutoTotal,
      conferenciaDoGraficoDeSecaoCircular,
      margemTotalPorcentagem,
      margemTotal,
      margemTotalTotal,
      dataDeValidade,
      descontoTotal,
      descontoTotalTotal,
      descontoPorcentagem,
      //quantidadeComprada,
      dadosOriginais,
      //
      descontoBacklight: descontoBacklight2,
      descontoBoleto: descontoBoleto2,
      descontoBonificacao: descontoBonificacao2,
      descontoDescargoMercadorias: descontoDescargoMercadorias2,
      descontoJornalOfertas: descontoJornalOfertas2,
      descontoNfe: descontoNfe2,
      //
      descontoNfePorcentagem,
      descontoNfeTotal,
      descontoBoletoPorcentagem,
      descontoBoletoTotal,
      descontoBonificacaoPorcentagem,
      descontoBonificacaoTotal,
      descontoJornalOfertasPorcentagem,
      descontoJornalOfertasTotal,
      descontoDescargoMercadoriasPorcentagem,
      descontoDescargoMercadoriasTotal,
      descontoBacklightPorcentagem,
      descontoBacklightTotal,
    } = linhas[0];

    function abrirGrafico(dados) {
      if (refDialogoGrafico.current) {
        //refDialogoGrafico.current.handleOpen(data);
        refDialogoGrafico.current.handleOpen(
          [
            {
              label: 'Valor Negociado com a indústria',
              backgroundColor: 'rgba(68, 114, 196, 1)',
              borderColor: 'rgba(68, 114, 196, 1)',
              valor: ceil10(
                dados.valorNegociadoComAIndustria * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Margem de Custo Operacional',
              backgroundColor: 'rgba(237, 125, 49, 1)',
              borderColor: 'rgba(237, 125, 49, 1)',
              valor: ceil10(
                dados.margemDeCustoOperacional * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Diferença de ICMS',
              backgroundColor: 'rgba(165, 165, 165, 1)',
              borderColor: 'rgba(165, 165, 165, 1)',
              valor: ceil10(dados.diferencaDeIcms * fatorPesoMedio, -2),
            },
            {
              label: 'Lucro Líquido',
              backgroundColor: 'rgba(91, 155, 213, 1)',
              borderColor: 'rgba(91, 155, 213, 1)',
              valor: ceil10(dados.lucroBruto * fatorPesoMedio, -2),
            },
            {
              label: 'Diferença de COFINS',
              backgroundColor: 'rgba(112, 173, 71, 1)',
              borderColor: 'rgba(112, 173, 71, 1)',
              valor: ceil10(dados.diferencaDeCofins * fatorPesoMedio, -2),
            },
            {
              label: 'Margem de Custo de Cartão',
              backgroundColor: 'rgba(10, 224, 196, 1)',
              borderColor: 'rgba(10, 224, 196, 1)',
              valor: ceil10(dados.margemDeCustoDeCartao * fatorPesoMedio, -2),
            },
            {
              label: 'Diferença de PIS',
              backgroundColor: 'rgba(7, 15, 49, 1)',
              borderColor: 'rgba(7, 15, 49, 1)',
              valor: ceil10(dados.diferencaDePis * fatorPesoMedio, -2),
            },
            {
              label: 'Repasse do IPI',
              backgroundColor: 'rgba(200, 165, 165, 1)',
              borderColor: 'rgba(200, 165, 165, 1)',
              valor: ceil10(
                dados.repasseDoIpiPorcentagemVenda * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do Frete',
              backgroundColor: 'rgba(90, 1, 213, 1)',
              borderColor: 'rgba(90, 1, 213, 1)',
              valor: ceil10(
                dados.repasseDoFreteUnitarioPorcentagemVenda * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do ST',
              backgroundColor: 'rgba(200, 0, 0, 1)',
              borderColor: 'rgba(200, 0, 0, 1)',
              valor: ceil10(
                dados.repasseDeStPorcentagemVenda * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do Seguro',
              backgroundColor: 'rgba(60, 60, 6, 1)',
              borderColor: 'rgba(60, 60, 6, 1)',
              valor: ceil10(
                dados.repasseDeSegurosPorcentagemVenda * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse de Outras Despesas',
              backgroundColor: 'rgba(5, 5, 5, 1)',
              borderColor: 'rgba(5, 5, 5, 1)',
              valor: ceil10(
                dados.repasseDeOutrasDespesasPorcentagemVenda * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'ICMS de Fronteira',
              backgroundColor: 'rgba(100, 50, 100, 1)',
              borderColor: 'rgba(100, 50, 100)',
              valor: ceil10(
                dados.icmsDeFronteiraAdotadoPorcentagemVenda * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'ICMS de Antecipação',
              backgroundColor: 'rgba(10, 20, 250, 1)',
              borderColor: 'rgba(10, 20, 250, 1)',
              valor: ceil10(
                dados.icmsAntecipacaoPorcentagemVenda * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Desconto Total',
              backgroundColor: 'rgba(37, 205, 200, 1)',
              borderColor: 'rgba(37, 205, 200, 1)',
              valor: ceil10(-1 * dados.descontoTotal * fatorPesoMedio, -2),
            },
          ],
          `${formatMoeda(
            dados.precoVenda * fatorPesoMedio,
          )} / ${unidadeTrabalhada}`,
        );
      }
    }

    async function handleSalvar() {
      if (fornecedor || config || produto) {
        const erro1 = validateTotalProduto();
        const erro2 = validateTotalProdutoFinal();
        if (erro1 === '' && erro2 === '') {
          try {
            setCarregando(true);
            /*await updateAPI(
            'update_produto_preco_venda_precificacao',
            produto.id,
            {
              preco_venda: linhas[0].dadosOriginais.precoVenda,
              manterPrecoAnterior,
            },
          );
          if (true) {
            if (refDialogoImpressao.current) {
              refDialogoImpressao.current.handleCreate(produto.id);
            }
          }*/

            if (isEmitMessage) {
              enqueueSnackbar('Produto atualizado com sucesso!', {
                variant: 'success',
              });
            }
            handleCloseDialog({
              produto,
              index,
              cg: repasseDoFreteUnitario,
              preco_compra:
                linhas[0].dadosOriginais.valorNegociadoComAIndustria,
              /*preco_compra_repasses:
              linhas[0].dadosOriginais.valorNegociadoComAIndustria,*/
              preco_compra_impostos:
                linhas[0].dadosOriginais.custoFinalDoProduto,
              qtde: quantidadeCompradaConputado,
              validade: formatDate(validade),
              //validade,
              nImpressoes,
              validades,
              dadosPrecificacao: {
                valor_negociado_com_a_industria:
                  linhas[0].dadosOriginais.valorNegociadoComAIndustria,
                custo_operacional:
                  linhas[0].dadosOriginais.margemDeCustoOperacional,
                diferenca_de_icms: linhas[0].dadosOriginais.diferencaDeIcms,
                lucro_bruto: linhas[0].dadosOriginais.lucroBruto,
                diferenca_de_cofins: linhas[0].dadosOriginais.diferencaDeCofins,
                custo_de_cartao: linhas[0].dadosOriginais.margemDeCustoDeCartao,
                difereca_de_pis: linhas[0].dadosOriginais.diferencaDePis,
                repasse_do_ipi: hasRepasseNaoUnitarioIPI
                  ? 0
                  : linhas[0].dadosOriginais.repasseDoIpiPorcentagemVenda,
                repasse_do_frete: hasRepasseNaoUnitarioFrete
                  ? 0
                  : linhas[0].dadosOriginais
                      .repasseDoFreteUnitarioPorcentagemVenda,
                repasse_de_st: hasRepasseNaoUnitarioST
                  ? 0
                  : linhas[0].dadosOriginais.repasseDeStPorcentagemVenda,
                repasse_do_seguro: hasRepasseNaoUnitarioSeguros
                  ? 0
                  : linhas[0].dadosOriginais.repasseDeSegurosPorcentagemVenda,
                repasse_de_outras_despesas: hasRepasseNaoUnitarioOutrasDespesas
                  ? 0
                  : linhas[0].dadosOriginais
                      .repasseDeOutrasDespesasPorcentagemVenda,
                custo_final_do_produto:
                  linhas[0].dadosOriginais.custoFinalDoProduto,
                preco_de_venda: round10(
                  linhas[0].dadosOriginais.precoVenda,
                  -2,
                ),
                repasse_do_ipi_distribuido: 0,
                repasse_do_frete_distribuido: 0,
                repasse_de_st_distribuido: 0,
                repasse_do_seguro_distribuido: 0,
                repasse_de_outras_despesas_distribuido: 0,
                //
                taxa_irpj: linhas[0].dadosOriginais.irpjPorcentagemLb, //
                taxa_csll: linhas[0].dadosOriginais.csllPorcentagemLb, //
                taxa_lucro_bruto:
                  linhas[0].dadosOriginais.lucroBrutoPorcentagem, //
                taxa_lucro_liquido:
                  linhas[0].dadosOriginais.lucroLiquidoPorcentagem, //
                taxa_pis_compra: linhas[0].dadosOriginais.pisCompraPorcentagem, //
                taxa_pis_venda: linhas[0].dadosOriginais.pisVendaPorcentagem, //
                taxa_cofins_compra:
                  linhas[0].dadosOriginais.cofinsCompraPorcentagem, //
                taxa_cofins_venda:
                  linhas[0].dadosOriginais.cofinsVendaPorcentagem, //
                taxa_icms_antecipacao:
                  linhas[0].dadosOriginais.icmsAntecipacaoPorcentagem, //
                taxa_icms_compra:
                  linhas[0].dadosOriginais.aliquotaInternaCompraPorcentagem, //
                taxa_icms_venda:
                  linhas[0].dadosOriginais.aliquotaInternaVendaPorcentagem, //
                agregado_do_cnae:
                  linhas[0].dadosOriginais.agregadoDoCnaePorcentagem, //
                mva_da_pauta: linhas[0].dadosOriginais.mvaDaPauta, //
                icms_compra: linhas[0].dadosOriginais.aliquotaInternaCompra, //
                icms_venda: linhas[0].dadosOriginais.aliquotaInternaVenda,
                icms_fronteira: linhas[0].dadosOriginais.icmsDeFronteiraAdotado, //
                icms_antecipacao: linhas[0].dadosOriginais.icmsAntecipacao, //
                pis_compra: linhas[0].dadosOriginais.pisCompra, //
                pis_venda: linhas[0].dadosOriginais.pisVenda, //
                cofins_compra: linhas[0].dadosOriginais.cofinsCompra, //
                cofins_venda: linhas[0].dadosOriginais.cofinsVenda, //
                lucro_liquido: linhas[0].dadosOriginais.lucroLiquido, //
                irpj: linhas[0].dadosOriginais.irpj, //
                csll: linhas[0].dadosOriginais.csll, //
                //
                taxa_custo_operacional:
                  linhas[0].dadosOriginais.margemDeCustoOperacionalPorcentagem,
                taxa_custo_de_cartao:
                  linhas[0].dadosOriginais.margemDeCustoDeCartaoPorcentagem,
                ///////
                desconto_em_nfe: descontoNfeComputado,
                desconto_em_boleto: descontoBoletoComputado,
                desconto_em_bonificacao: descontoBonificacaoComputado,
                desconto_em_backlight_e_ponto_extra: descontoBacklightComputado,
                desconto_em_jornal_de_ofertas: descontoJornalOfertasComputado,
                desconto_em_descargo_de_mercadorias: descontoDescargoMercadoriasComputado,
                ////////////////
                taxa_avarias:
                  linhas[0].dadosOriginais.margemDeAvariaPorcentagem,
                custo_avarias: linhas[0].dadosOriginais.margemDeAvaria,
              },
            });
          } catch (erros) {
            enqueueSnackbar(`${erros}`, {
              variant: 'error',
            });
            handleCloseDialog();
          } finally {
            setCarregando(false);
          }
        } else {
          if (erro1) {
            enqueueSnackbar(erro1, {
              variant: 'error',
            });
          }
          if (erro2) {
            enqueueSnackbar(erro2, {
              variant: 'error',
            });
          }
        }
      } else {
        enqueueSnackbar('Ocorreu um erro ao carregar os dados', {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    function getPrecosOfertas(produto) {
      let precoOfertaJornal = 0;
      let precoOfertaZerarEstoque = 0;

      if (produto.ofertasProdutos.length > 0) {
        for (let i = 0; i < produto.ofertasProdutos.length; i++) {
          const oferta = produto.ofertasProdutos[i];
          if (oferta.tipo === TIPO_OFERTA_PRODUTO_JORNAL) {
            precoOfertaJornal = oferta.valor;
          } else if (oferta.tipo === TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE) {
            precoOfertaZerarEstoque = oferta.valor;
          }
        }
      }

      return {
        precoOfertaJornal,
        precoOfertaZerarEstoque,
      };
    }

    async function getDataResourceEditar(
      produtoId,
      config,
      fornecedor,
      validade,
      nImpressoes,
      valorNegociadoComAIndustria,
      quantidadeComprada,
      repasseDoIpi,
      repasseDoFreteUnitario,
      repasseDeSt,
      repasseDeSeguros,
      repasseDeOutrasDespesas,
      //
      descontoNfe,
      descontoBoleto,
      descontoBonificacao,
      descontoBacklight,
      descontoJornalOfertas,
      descontoDescargoMercadorias,
      dadosComplementaresNota,
      validades,
    ) {
      try {
        setCarregando(true);

        const data = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { id: produtoId, ofertasProdutos: true },
          ['dadosImposto', 'unidade'],
        );

        if (data.data.length > 0) {
          const {
            precoOfertaJornal,
            precoOfertaZerarEstoque,
          } = getPrecosOfertas(data.data[0]);
          setProduto({
            ...data.data[0],
            precoOfertaJornal,
            precoOfertaZerarEstoque,
          });
          setConfig(config);
          setFornecedor(fornecedor);
          setManterPrecoAnterior(false);

          //
          setValidade('');
          setValorNegociadoComAIndustria(valorNegociadoComAIndustria);
          setQuantidadeComprada(quantidadeComprada);
          setRepasseDoIpi(repasseDoIpi);
          setRepasseDoFreteUnitario(repasseDoFreteUnitario);
          setRepasseDeSt(repasseDeSt);
          setRepasseDeSeguros(repasseDeSeguros);
          setRepasseDeOutrasDespesas(repasseDeOutrasDespesas);
          setBaseDeCalculo(0);
          setAlvo(optionsAlvo[1].value);
          //
          setTipoRepasseDoIpi(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoFrete(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSt(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSeguros(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoOutrasDespesas(optionsRepassesUnitarios[0].value);

          setDescontoNfe(descontoNfe);
          setDescontoBoleto(descontoBoleto);
          setDescontoBonificacao(descontoBonificacao);
          setDescontoBacklight(descontoBacklight);
          setDescontoJornalOfertas(descontoJornalOfertas);
          setDescontoDescargoMercadorias(descontoDescargoMercadorias);

          setTipoDescontoNfe(optionsDescontos[0].value);
          setTipoDescontoBoleto(optionsDescontos[0].value);
          setTipoDescontoBonificacao(optionsDescontos[0].value);
          setTipoDescontoBacklight(optionsDescontos[0].value);
          setTipoDescontoJornalOfertas(optionsDescontos[0].value);
          setTipoDescontoDescargoMercadorias(optionsDescontos[0].value);

          setNImpressoes(nImpressoes);

          setNImpressoesCartazes(0);

          setDadosComplementaresNota(dadosComplementaresNota);

          setMargemLucroBrutoEstado(data.data[0].taxa_de_lucro);

          setValidades(validades);

          setOpen(true);

          setValue(0);
        } else {
          throw 'Este produto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      } finally {
        setCarregando(false);
      }
    }

    async function getDataResource(codigo, config, fornecedor, id) {
      try {
        setCarregando(true);
        const data = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          id
            ? { id, ofertasProdutos: true }
            : { codigo, ofertasProdutos: true },
          ['dadosImposto', 'unidade'],
        );

        if (data.data.length > 0) {
          const {
            precoOfertaJornal,
            precoOfertaZerarEstoque,
          } = getPrecosOfertas(data.data[0]);
          setProduto({
            ...data.data[0],
            precoOfertaJornal,
            precoOfertaZerarEstoque,
          });
          setConfig(config);
          setFornecedor(fornecedor);
          setManterPrecoAnterior(false);

          //
          setValidade('');
          setValorNegociadoComAIndustria(0);
          setQuantidadeComprada(0);
          setRepasseDoIpi(0);
          setRepasseDoFreteUnitario(0);
          setRepasseDeSt(0);
          setRepasseDeSeguros(0);
          setRepasseDeOutrasDespesas(0);
          setBaseDeCalculo(0);
          setAlvo(optionsAlvo[0].value);
          //

          setTipoRepasseDoIpi(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoFrete(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSt(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoSeguros(optionsRepassesUnitarios[0].value);
          setTipoRepasseDoOutrasDespesas(optionsRepassesUnitarios[0].value);

          setDescontoNfe(0);
          setDescontoBoleto(0);
          setDescontoBonificacao(0);
          setDescontoBacklight(0);
          setDescontoJornalOfertas(0);
          setDescontoDescargoMercadorias(0);

          setTipoDescontoNfe(optionsDescontos[1].value);
          setTipoDescontoBoleto(optionsDescontos[1].value);
          setTipoDescontoBonificacao(optionsDescontos[1].value);
          setTipoDescontoBacklight(optionsDescontos[1].value);
          setTipoDescontoJornalOfertas(optionsDescontos[1].value);
          setTipoDescontoDescargoMercadorias(optionsDescontos[1].value);

          setNImpressoes(0);

          setDadosComplementaresNota(null);

          setMargemLucroBrutoEstado(data.data[0].taxa_de_lucro);

          setValidades([]);

          setNImpressoesCartazes(0);

          setOpen(true);
        } else {
          throw 'Este produto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      } finally {
        setCarregando(false);
      }
    }

    async function getProduto() {
      if (produto) {
        try {
          const data = await getListAllAPI(
            'produtos',
            ['id', 'asc'],
            { id: produto.id, ofertasProdutos: true },
            ['dadosImposto', 'unidade'],
          );

          if (data.data.length > 0) {
            const {
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            } = getPrecosOfertas(data.data[0]);
            setProduto({
              ...data.data[0],
              precoOfertaJornal,
              precoOfertaZerarEstoque,
            });
            //setCarregando(false);
          } else {
            throw 'Este produto não existe!';
          }
        } catch (erros) {
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        enqueueSnackbar(`Produto não encontrado!!!`, {
          variant: 'error',
        });
      }
    }

    const tipoUnidade = produto ? produto.unidade.tipo : '';

    function validateQuantidadeComprada() {
      let error = '';
      if (alvo !== optionsAlvo[2].value) {
        if (quantidadeComprada <= 0 || Number.isNaN(quantidadeComprada)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateValorNegociadoComAIndustria() {
      let error = '';
      if (alvo !== optionsAlvo[0].value) {
        if (
          valorNegociadoComAIndustria <= 0 ||
          Number.isNaN(valorNegociadoComAIndustria)
        ) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseIpi() {
      let error = '';
      if (!hasRepasseNaoUnitarioIPI) {
        if (repasseDoIpi < 0 || Number.isNaN(repasseDoIpi)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseFreteUitario() {
      let error = '';
      if (!hasRepasseNaoUnitarioFrete) {
        if (
          repasseDoFreteUnitario < 0 ||
          Number.isNaN(repasseDoFreteUnitario)
        ) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseSt() {
      let error = '';
      if (!hasRepasseNaoUnitarioST) {
        if (repasseDeSt < 0 || Number.isNaN(repasseDeSt)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseSeguros() {
      let error = '';
      if (!hasRepasseNaoUnitarioSeguros) {
        if (repasseDeSeguros < 0 || Number.isNaN(repasseDeSeguros)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateRepasseOutrasDespesas() {
      let error = '';
      if (!hasRepasseNaoUnitarioOutrasDespesas) {
        if (
          repasseDeOutrasDespesas < 0 ||
          Number.isNaN(repasseDeOutrasDespesas)
        ) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateValidade() {
      const error = '';
      //if (validade === null || validade === '') error = 'Data Inválida!';
      return error;
    }

    function validateBaseDeCalculo() {
      let error = '';
      if (alvo !== optionsAlvo[1].value) {
        if (baseDeCalculo <= 0 || Number.isNaN(baseDeCalculo)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateNImpressoes() {
      let error = '';
      if (nImpressoes < 0 || Number.isNaN(nImpressoes)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateDescontoNfe() {
      let error = '';
      if (descontoNfe < 0 || Number.isNaN(descontoNfe)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoBoleto() {
      let error = '';
      if (descontoBoleto < 0 || Number.isNaN(descontoBoleto)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoBonificacao() {
      let error = '';
      if (descontoBonificacao < 0 || Number.isNaN(descontoBonificacao)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoBacklight() {
      let error = '';
      if (descontoBacklight < 0 || Number.isNaN(descontoBacklight)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoJornalOfertas() {
      let error = '';
      if (descontoJornalOfertas < 0 || Number.isNaN(descontoJornalOfertas)) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function validateDescontoDescargoMercadorias() {
      let error = '';
      if (
        descontoDescargoMercadorias < 0 ||
        Number.isNaN(descontoDescargoMercadorias)
      ) {
        error = 'Este campo deve ser um número válido maior ou igual a zero';
      }
      return error;
    }

    function getUnidadeMedida(produto) {
      if (produto) {
        if (produto.unidade.tipo === POR_UNIDADE) {
          return 'Un';
        }
        return 'Kg';
      }

      return '';
    }

    const unidadeMedia = getUnidadeMedida(produto);

    function validatePrecoJornalOfertas() {
      let error = '';
      if (produto) {
        if (produto.precoOfertaJornal) {
          if (produto.precoOfertaJornal > dadosOriginais.precoVenda) {
            error = `O Preço de Jornal de Ofertas (${produto.precoOfertaJornal} / ${unidadeMedia}) não pode ser maior que o Preço de Venda Precificado Agora (${dadosOriginais.precoVenda} / ${unidadeMedia})`;
          }
        }
      }
      return error;
    }

    function validateMargemLucroBruto() {
      let error = '';
      if (
        margemLucroBrutoEstado < 0 ||
        Number.isNaN(margemLucroBrutoEstado) ||
        margemLucroBrutoEstado > 100
      ) {
        error =
          'Este campo deve ser um número válido maior ou igual a zero e menor que 100 ';
      }
      return error;
    }

    function validatePrecoVenda() {
      let error = '';
      if (
        dadosOriginais.precoVenda <= 0 ||
        Number.isNaN(dadosOriginais.precoVenda)
      ) {
        error =
          'O PREÇO DE VENDA PRECIFICADO AGORA deve ser um número válido maior que 0';
      }
      return error;
    }

    const precoVendaMinimoAceitavel = getCustoFinal(dadosOriginais);

    function validateDescontoTotal() {
      let error = '';
      /*       const descontoTotal =
        descontoBacklightComputado +
        descontoBoletoComputado +
        descontoBonificacaoComputado +
        descontoDescargoMercadoriasComputado +
        descontoJornalOfertasComputado +
        descontoNfeComputado;

      if (!Number.isNaN(descontoTotal)) {
        if (dadosOriginais) {
          const precoVenda = dadosOriginais.precoVenda;
          if (!Number.isNaN(precoVenda)) {
            const precoVendaOriginal = precoVenda + descontoTotal;
            if (descontoTotal / precoVendaOriginal > 0.5) {
              error =
                'O desconto total não pode ser maior que 50% do preço de venda';
            }
          }
        }
      } */
      if (linhas[0].dadosOriginais.precoVenda < precoVendaMinimoAceitavel) {
        error = `O preço de venda (${formatMoeda(
          linhas[0].dadosOriginais.precoVenda,
        )} / ${unidadeMedia}) não pode ser menor que o mínimo aceitável (${formatMoeda(
          precoVendaMinimoAceitavel,
        )} / ${unidadeMedia})!`;
      }
      return error;
    }

    function validateValidades() {
      let error = '';
      if (produto) {
        if (!produto.is_invencivel) {
          if (validades.length === 0) {
            error = 'É necessário o cadastro de pelo menos 1 Validade';
          }
        }
      }
      return error;
    }

    function validateNImpressoesCartazes() {
      let error = '';
      if (nImpressoesCartazes < 0 || Number.isNaN(nImpressoesCartazes)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getTotalKpi(dados) {
      const { valor, produto, qtde } = dados;

      if (produto) {
        switch (produto.unidade.tipo) {
          case POR_UNIDADE: {
            const total = valor * qtde;

            return {
              total,
            };
          }
          case POR_PESO_VARIAVEL_SEM_UNIDADE: {
            const total = valor * qtde;

            return {
              total,
            };
          }
          case POR_PESO_VARIAVEL: {
            return {
              total: 0,
            };
          }
          case POR_PESO_FIXO: {
            const total = valor * produto.peso_medio * qtde;

            return {
              total,
            };
          }
        }
      }
      return {
        total: 0,
      };
    }

    function validateTotalProduto() {
      let error = '';

      if (dadosComplementaresNota) {
        const vProdCalculado = getTotalKpi({
          valor: valorNegociadoComAIndustriaComputado,
          produto,
          qtde: quantidadeCompradaConputado,
        });
        if (
          verificaDiferencaNula(vProdCalculado, dadosComplementaresNota.vProd)
        ) {
          error = `O total deste item (${formatMoeda(
            vProdCalculado,
          )}) não corresponde ao mesmo valor na nota fiscal (${formatMoeda(
            dadosComplementaresNota.vProd,
          )})`;
        }
      }
      return error;
    }

    function validateTotalProdutoFinal() {
      let error = '';
      if (dadosComplementaresNota) {
        let totalItemNota = 0;
        totalItemNota += dadosComplementaresNota.vProd;
        totalItemNota += dadosComplementaresNota.vFrete;
        totalItemNota += dadosComplementaresNota.vSeg;
        totalItemNota += dadosComplementaresNota.vOutro;
        totalItemNota -= dadosComplementaresNota.vDesc;
        totalItemNota += dadosComplementaresNota.vIPI;
        totalItemNota += dadosComplementaresNota.vICMSST;

        let totalItemCalculadoUnitario = 0;
        totalItemCalculadoUnitario += valorNegociadoComAIndustriaComputado;
        totalItemCalculadoUnitario += dadosOriginais.repasseDoFreteUnitario;
        totalItemCalculadoUnitario += dadosOriginais.repasseDeSeguros;
        totalItemCalculadoUnitario += dadosOriginais.repasseDeOutrasDespesas;
        totalItemCalculadoUnitario -= dadosOriginais.descontoNfe;
        totalItemCalculadoUnitario += dadosOriginais.repasseDoIpi;
        totalItemCalculadoUnitario += dadosOriginais.repasseDeSt;

        const totalCalculado = getTotalKpi({
          valor: totalItemCalculadoUnitario,
          produto,
          qtde: quantidadeCompradaConputado,
        });

        if (verificaDiferencaNula(totalItemNota, totalCalculado)) {
          error = `O total final deste item (${formatMoeda(
            totalCalculado,
          )}) não corresponde ao mesmo valor final na nota fiscal (${formatMoeda(
            totalItemNota,
          )})`;
        }
      }
      return error;
    }

    function getErros() {
      const errosImpostos = ['', ''];
      errosImpostos[0] = validateQuantidadeComprada();
      errosImpostos[1] = validateValorNegociadoComAIndustria();

      errosImpostos[2] = validateRepasseIpi();
      errosImpostos[3] = validateRepasseFreteUitario();
      errosImpostos[4] = validateRepasseSt();
      errosImpostos[5] = validateRepasseSeguros();
      errosImpostos[6] = validateRepasseOutrasDespesas();
      errosImpostos[7] = validateValidade();
      errosImpostos[8] = validateBaseDeCalculo();
      errosImpostos[9] = validateNImpressoes();

      errosImpostos[10] = validateDescontoNfe();
      errosImpostos[11] = validateDescontoBoleto();
      errosImpostos[12] = validateDescontoBonificacao();
      errosImpostos[13] = validateDescontoBacklight();
      errosImpostos[14] = validateDescontoJornalOfertas();
      errosImpostos[15] = validateDescontoDescargoMercadorias();
      errosImpostos[16] = validateDescontoTotal();
      errosImpostos[17] = validatePrecoJornalOfertas();
      errosImpostos[18] = validateMargemLucroBruto();
      errosImpostos[19] = validatePrecoVenda();
      errosImpostos[20] = validateValidades();
      errosImpostos[21] = validateNImpressoesCartazes();
      //errosImpostos[22] = validateTotalProduto();
      //errosImpostos[23] = validateTotalProdutoFinal();

      return errosImpostos;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function getNErrosEdicao() {
      let nErrosEdicao = 0;
      for (let i = 0; i < erros.length; i += 1) {
        if (i !== 20 && erros[i] !== '') {
          nErrosEdicao++;
        }
      }
      return nErrosEdicao;
    }

    const nErrosEdicao = getNErrosEdicao();

    function handleEditProduto() {
      if (refDialogoProduto.current) {
        refDialogoProduto.current.handleEdit(produto.id);
      }
    }

    function validarDataValidade(data) {
      const dataValidade = new Date(
        moment(new Date(data)).endOf('day').format(),
      );
      const dataAtual = new Date(moment(new Date()).endOf('day').format());
      const dataFutura = new Date(
        moment(new Date()).add('years', 3).endOf('day').format(),
      );

      if (dataValidade.getTime() < dataAtual.getTime()) {
        return {
          status: false,
          message:
            'A data de Validade não pode ser uma data anterior ao dia de Hoje',
        };
      }

      if (dataValidade.getTime() > dataFutura.getTime()) {
        return {
          status: false,
          message: 'A data de validade não pode ser maior que 3 anos a frente',
        };
      }

      return {
        status: true,
        message: '',
      };
    }

    function verificaValidadeExistente(validade) {
      return validades.find((item) => item === validade);
    }

    function handleAdicionarValidade(validade) {
      if (validade) {
        const { status, message } = validarDataValidade(validade);
        if (status) {
          const isExistente = verificaValidadeExistente(validade);
          if (!isExistente) {
            setValidades([...validades, validade]);
            setValidade('');
          } else {
            enqueueSnackbar('Esta validade ja foi registrada!', {
              variant: 'error',
            });
          }
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('A validade dever ser um data válida!', {
          variant: 'error',
        });
      }
    }

    function deletarValidade(index) {
      const validades2 = validades.slice();
      validades2.splice(index);
      setValidades(validades2);
    }

    function formatarValidade(date) {
      if (date) {
        const arr = date.split('-');
        return arr.reverse().join('/');
      }

      return '-';
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <div>
        <Dialog
          open={open}
          onClose={() => handleCloseDialog()}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={isCloseX ? () => handleCloseDialog() : null}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`${prefixoTitle}Precificação - ${produto ? produto.nome : ''}`}
            <Tooltip
              title="Editar Produto"
              placement="bottom"
              aria-label="add2"
            >
              <IconButton
                aria-label="edit"
                classes={{ root: classes.root }}
                size="small"
                style={{ marginLeft: '10px', color: 'white' }}
                onClick={() => handleEditProduto()}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          {!carregando ? (
            <DialogContent dividers>
              <div className={classes.root}>
                <div
                  style={{
                    margin: '10px 0px',
                    color: 'red',
                  }}
                >
                  {getMessageAviso()}
                </div>
                <div
                  style={{
                    margin: '10px 0px',
                    color: 'red',
                  }}
                >
                  {getMessageAviso2()}
                </div>

                <Box>
                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                      border: '2px solid red',
                      padding: '5px',
                      fontSize: '25px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    {getLabelTipoUnidade(produto ? produto.unidade.tipo : '')}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}
                    >
                      {dadosComplementaresNota ? (
                        dadosComplementaresNota.fator_conversao_importacao_xml_compra !==
                        1 ? (
                          <p>
                            Fator de Conversão Produtos UP:{' '}
                            <span style={{ color: 'red' }}>
                              {
                                dadosComplementaresNota.fator_conversao_importacao_xml_compra
                              }
                            </span>
                          </p>
                        ) : null
                      ) : null}
                      {dadosComplementaresNota ? (
                        dadosComplementaresNota.fator_caixaria !== 1 ? (
                          <p>
                            Fator de Conversão Caixaria:{' '}
                            <span style={{ color: 'red' }}>
                              {dadosComplementaresNota.fator_caixaria}
                            </span>
                          </p>
                        ) : null
                      ) : null}
                      {dadosComplementaresNota ? (
                        dadosComplementaresNota.fator_peso_medio !== 1 ? (
                          <p>
                            Fator de Conversão Peso Médio:{' '}
                            <span style={{ color: 'red' }}>
                              {dadosComplementaresNota.fator_peso_medio}
                            </span>
                          </p>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </Box>

                {/* {erros[22] !== '' ? (
                  <div
                    style={{
                      color: 'red',
                      margin: '10px 0',
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    * {erros[22]}
                  </div>
                ) : null}

                {erros[23] !== '' ? (
                  <div
                    style={{
                      color: 'red',
                      margin: '10px 0',
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    * {erros[23]}
                  </div>
                ) : null} */}

                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Principal" {...a11yProps(0)} />
                  <Tab
                    label={
                      <Badge color="error" badgeContent={nErrosEdicao}>
                        <Typography>Edição</Typography>
                      </Badge>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <Badge
                        color="error"
                        badgeContent={erros[20] !== '' ? 1 : 0}
                      >
                        <Typography>Validades</Typography>
                      </Badge>
                    }
                    {...a11yProps(3)}
                  />
                  {/* <Tab label="Análise" {...a11yProps(3)} /> */}
                </Tabs>

                <TabPanel value={value} index={0}>
                  {erros[17] !== '' ? (
                    <div
                      style={{
                        color: 'red',
                        margin: '10px 0',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      * {erros[17]}
                    </div>
                  ) : null}
                  {erros[19] !== '' ? (
                    <div
                      style={{
                        color: 'red',
                        margin: '10px 0',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      * {erros[19]}
                    </div>
                  ) : null}
                  {erros[16] !== '' ? (
                    <div
                      style={{
                        color: 'red',
                        margin: '10px 0',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      * {erros[16]}
                    </div>
                  ) : null}
                  <Box
                    style={{
                      display: 'flex',
                      gap: '10px',
                      width: '50%',
                      margin: '0 auto',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <PorcentagemInput
                        name="taxa_lucro"
                        label="Margem de Lucro Bruto"
                        handleEnter={() => {}}
                        handleKey={() => {}}
                        value={
                          Number.isNaN(margemLucroBrutoEstado)
                            ? ''
                            : String(margemLucroBrutoEstado)
                        }
                        onChange={(value) => {
                          setMargemLucroBrutoEstado(
                            parseFloat(value.target.value, 10),
                          );
                        }}
                        error={erros[18] !== ''}
                        helperText={erros[18]}
                        fullWidth
                        escala={2}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <PorcentagemInput
                        name="taxa_lucro"
                        label="Margem de Lucro Bruto (Valor Padrão)"
                        handleEnter={() => {}}
                        handleKey={() => {}}
                        value={
                          Number.isNaN(produto ? produto.taxa_de_lucro : 0)
                            ? ''
                            : String(produto ? produto.taxa_de_lucro : 0)
                        }
                        onChange={(value) => {}}
                        fullWidth
                        escala={2}
                        disabled
                      />
                    </div>
                  </Box>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TabelaPrecos
                      precoMinimoAceitavel={precoVendaMinimoAceitavel}
                      produto={produto}
                      quantidade={quantidadeCompradaConputado}
                      precoCompra={valorNegociadoComAIndustriaComputado}
                      precoVendaPrecificado={dadosOriginais.precoVenda}
                      precoAnteriorVenda={produto ? produto.preco_venda : 0}
                      dadosComplementaresNota={dadosComplementaresNota}
                      fatorPesoMedio={fatorPesoMedio}
                      baseDeCalculo={baseDeCalculoComputado}
                      margemContribuicao={
                        dadosOriginais.precoVenda -
                        dadosOriginais.custoFinalDoProduto
                      }
                      custoFinalProduto={dadosOriginais.custoFinalDoProduto}
                      margemLucroBrutoPorecentagem={
                        dadosOriginais.lucroBrutoPorcentagem
                      }
                      margemLucroBruto={
                        (dadosOriginais.lucroBrutoPorcentagem *
                          dadosOriginais.precoVenda) /
                        100
                      }
                      ofertaZerarEstoque={0}
                      ofertaJornal={produto ? produto.precoOfertaJornal : 0}
                      repasseDoIpi={dadosOriginais.repasseDoIpi}
                      repasseDoFreteUnitario={
                        dadosOriginais.repasseDoFreteUnitario
                      }
                      repasseDeSt={dadosOriginais.repasseDeSt}
                      repasseDeSeguros={dadosOriginais.repasseDeSeguros}
                      repasseDeOutrasDespesas={
                        dadosOriginais.repasseDeOutrasDespesas
                      }
                      //
                      irpj={dadosOriginais.irpj} // ok
                      csll={dadosOriginais.csll} // ok
                      margemLucroLiquido={dadosOriginais.lucroLiquido} // ok
                      custoLivreDeImpostos={
                        dadosOriginais.custoLivreDeImpostoPorcentagem
                      } // ok
                      baseDeCalculoIcms={dadosOriginais.baseCalculoIcmsUnitaria} // ok
                      icmsNormalCredito={dadosOriginais.aliquotaInternaCompra} // ok
                      pisCredito={dadosOriginais.pisCompra} // ok
                      cofinsCredito={dadosOriginais.cofinsCompra} // ok
                      antecipacaoIcmsNormal={dadosOriginais.icmsAntecipacao} // ok
                      agredadoCnae={dadosOriginais.agregadoDoCnaePorcentagem} // ok
                      mvaPauta={dadosOriginais.mvaDaPauta} // ok
                      calculoPautaIcmsFronteira={
                        dadosOriginais.calculoDaPautaDeIcmsDeFronteira
                      } // ok
                      icmsFronteiraCalculado={dadosOriginais.fronteira} // ok
                      icmsFronteriaAdotado={
                        dadosOriginais.icmsDeFronteiraAdotado
                      } // ok
                      icmsNormalDebito={dadosOriginais.aliquotaInternaVenda} // ok
                      pisDebito={dadosOriginais.pisVenda} // ok
                      cofinsDebito={dadosOriginais.cofinsVenda} // ok
                      diferencaIcmsNormal={dadosOriginais.diferencaDeIcms} // ok
                      diferencaPis={dadosOriginais.diferencaDePis} // ok
                      diferencaCofins={dadosOriginais.diferencaDeCofins} // ok
                      tributacaoEfetiva={dadosOriginais.tributacaoEfetiva} // ok
                      resultadoFluxoCaixa={
                        dadosOriginais.resultadoDoFluxoDeCaixa
                      } // ok
                      custoOperacional={dadosOriginais.margemDeCustoOperacional} // ok
                      cartaoCredito={dadosOriginais.margemDeCustoDeCartao} // ok
                      margemCustoOperacional={
                        dadosOriginais.margemDeCustoOperacionalPorcentagem
                      } // ok
                      margemCartaoCredito={
                        dadosOriginais.margemDeCustoDeCartaoPorcentagem
                      } // ok
                      //
                      descontoNfe={dadosOriginais.descontoNfe} // ok
                      descontoBoleto={dadosOriginais.descontoBoleto} // ok
                      descontoBonificacao={dadosOriginais.descontoBonificacao} // ok
                      descontoBacklight={dadosOriginais.descontoBacklight} // ok
                      descontoJornalOfertas={
                        dadosOriginais.descontoJornalOfertas
                      } // ok
                      descontoDescargoMercadorias={
                        dadosOriginais.descontoDescargoMercadorias
                      } // ok
                      //
                      taxaIcmsEntrada={
                        dadosOriginais.aliquotaInternaCompraPorcentagem
                      } // ok
                      taxaPisEntrada={dadosOriginais.pisCompraPorcentagem} // ok
                      taxaCofinsEntrada={dadosOriginais.cofinsCompraPorcentagem} // ok
                      //
                      taxaIcmsAntecipacao={
                        dadosOriginais.icmsAntecipacaoPorcentagem
                      } // ok
                      taxaIcmsSaida={
                        dadosOriginais.aliquotaInternaVendaPorcentagem
                      } // ok
                      taxaPisSaida={dadosOriginais.pisVendaPorcentagem} // ok
                      taxaCofinsSaida={dadosOriginais.cofinsVendaPorcentagem} // ok
                      markup={10}
                      //
                      custoAvarias={dadosOriginais.margemDeAvaria} // ok
                      margemCustoAvarias={
                        dadosOriginais.margemDeAvariaPorcentagem
                      } // ok
                    />
                  </div>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Box>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: '10px' }}
                    >
                      Geral
                    </Typography>

                    <Box
                      display="flex"
                      style={{
                        gap: '10px',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <IntegerInput
                          name="impressoes"
                          label="Nº Impressões de Etiquetas"
                          handleEnter={() => {}}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(nImpressoes) ? '' : String(nImpressoes)
                          }
                          onChange={(value) => {
                            setNImpressoes(parseInt(value.target.value, 10));
                          }}
                          error={erros[9] !== ''}
                          helperText={erros[9]}
                          fullWidth
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <IntegerInput
                          name="impressoes"
                          label="Nº Impressões de Cartazes"
                          handleEnter={() => {}}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(nImpressoesCartazes)
                              ? ''
                              : String(nImpressoesCartazes)
                          }
                          onChange={(value) => {
                            setNImpressoesCartazes(
                              parseInt(value.target.value, 10),
                            );
                          }}
                          error={erros[21] !== ''}
                          helperText={erros[21]}
                          fullWidth
                        />
                      </div>
                      {/* <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FormControlLabel
                          value="end"
                          control={
                            <Switch
                              checked={manterPrecoAnterior}
                              onChange={(event) => {
                                setManterPrecoAnterior(event.target.checked);
                              }}
                              name="isNota"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          }
                          label="Manter Preço de Venda Anterior"
                          labelPlacement="end"
                        />
                      </div> */}

                      {!dadosComplementaresNota ? (
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          (
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                            style={{
                              marginTop: '16px',
                            }}
                          >
                            <InputLabel id="demo-simple-select-label-2">
                              Alvo
                            </InputLabel>
                            <SelectInput
                              labelId="demo-simple-select-label-2"
                              name="tipo"
                              handleEnter={() => {}}
                              label="Alvo"
                              handleKey={() => {}}
                              value={alvo}
                              onChange={(value) => {
                                setAlvo(value.target.value);
                                setQuantidadeComprada(0);
                                setValorNegociadoComAIndustria(0);
                                setBaseDeCalculo(0);
                              }}
                              options={optionsAlvo}
                            />
                          </FormControl>
                          )
                        </div>
                      ) : null}
                    </Box>
                    {!dadosComplementaresNota ? (
                      <Box
                        display="flex"
                        style={{
                          gap: '10px',
                        }}
                      >
                        <PrecoInput
                          name="valor_negociado_com_a_industria"
                          label={`Valor negociado com a indústria (${unidadeTrabalhada})`}
                          handleEnter={() => {}}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(
                              valorNegociadoComAIndustriaComputado *
                                fatorPesoMedio,
                            )
                              ? ''
                              : String(
                                  valorNegociadoComAIndustriaComputado *
                                    fatorPesoMedio,
                                )
                          }
                          onChange={(value) => {
                            if (alvo !== optionsAlvo[0].value) {
                              setValorNegociadoComAIndustria(
                                parseFloat(value.target.value) / fatorPesoMedio,
                              );
                            }
                          }}
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          disabled={alvo === optionsAlvo[0].value}
                          fullWidth
                          escala={6}
                        />
                        {tipoUnidade === POR_UNIDADE ||
                        tipoUnidade === POR_PESO_FIXO ? (
                          <IntegerInput
                            name="quantidade_comprada"
                            label="Quantidade Comprada (Un.)"
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(quantidadeCompradaConputado)
                                ? ''
                                : String(quantidadeCompradaConputado)
                            }
                            onChange={(value) => {
                              if (alvo !== optionsAlvo[2].value) {
                                setQuantidadeComprada(
                                  parseInt(value.target.value, 10),
                                );
                              }
                            }}
                            error={erros[0] !== ''}
                            helperText={erros[0]}
                            fullWidth
                            autoFocus
                            disabled={alvo === optionsAlvo[2].value}
                          />
                        ) : (
                          <PesoInput
                            name="quantidade_comprada"
                            label="Quantidade Comprada (Kg)"
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(quantidadeCompradaConputado)
                                ? ''
                                : String(quantidadeCompradaConputado)
                            }
                            onChange={(value) => {
                              if (alvo !== optionsAlvo[2].value) {
                                setQuantidadeComprada(
                                  parseFloat(value.target.value),
                                );
                              }
                            }}
                            error={erros[0] !== ''}
                            helperText={erros[0]}
                            fullWidth
                            escala={2}
                            autoFocus
                            disabled={alvo === optionsAlvo[2].value}
                          />
                        )}
                        <PrecoInput
                          name="valor_negociado_com_a_industria"
                          label="Base de Cálculo"
                          handleEnter={() => {}}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(baseDeCalculoComputado)
                              ? ''
                              : String(baseDeCalculoComputado)
                          }
                          onChange={(value) => {
                            if (alvo !== optionsAlvo[1].value) {
                              setBaseDeCalculo(parseFloat(value.target.value));
                            }
                          }}
                          error={erros[8] !== ''}
                          helperText={erros[8]}
                          fullWidth
                          escala={2}
                          disabled={alvo === optionsAlvo[1].value}
                        />
                      </Box>
                    ) : null}
                  </Box>

                  {!dadosComplementaresNota ? (
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginTop: '10px' }}
                      >
                        Repasses Unitários
                      </Typography>
                      <Box
                        display="flex"
                        style={{
                          gap: '10px',
                        }}
                      >
                        <InputRepasse
                          tipoRepasse={tipoRepasseDoIpi}
                          value={
                            tipoRepasseDoIpi === 0
                              ? repasseDoIpi * fatorPesoMedio
                              : repasseDoIpi
                          }
                          onChange={(value) => {
                            if (tipoRepasseDoIpi === 0) {
                              setRepasseDoIpi(value / fatorPesoMedio);
                            } else {
                              setRepasseDoIpi(value);
                            }
                          }}
                          errorMessage={erros[2]}
                          disabled={hasRepasseNaoUnitarioIPI}
                          onChangeTipo={setTipoRepasseDoIpi}
                          label="Repasse Unitário do IPI"
                          name="repasseDoIpi"
                          options={optionsRepassesUnitarios}
                        />
                        <InputRepasse
                          tipoRepasse={tipoRepasseDoFrete}
                          value={
                            tipoRepasseDoFrete === 0
                              ? repasseDoFreteUnitario * fatorPesoMedio
                              : repasseDoFreteUnitario
                          }
                          onChange={(value) => {
                            if (tipoRepasseDoFrete === 0) {
                              setRepasseDoFreteUnitario(value / fatorPesoMedio);
                            } else {
                              setRepasseDoFreteUnitario(value);
                            }
                          }}
                          errorMessage={erros[3]}
                          disabled={hasRepasseNaoUnitarioFrete}
                          onChangeTipo={setTipoRepasseDoFrete}
                          label="Repasse Unitário Frete Unitário"
                          name="repasseDoFreteUnitario"
                          options={optionsRepassesUnitarios}
                        />
                      </Box>
                      <Box
                        display="flex"
                        style={{
                          gap: '10px',
                        }}
                      >
                        <InputRepasse
                          tipoRepasse={tipoRepasseDoSt}
                          value={
                            tipoRepasseDoSt === 0
                              ? repasseDeSt * fatorPesoMedio
                              : repasseDeSt
                          }
                          onChange={(value) => {
                            if (tipoRepasseDoSt === 0) {
                              setRepasseDeSt(value / fatorPesoMedio);
                            } else {
                              setRepasseDeSt(value);
                            }
                          }}
                          errorMessage={erros[4]}
                          disabled={hasRepasseNaoUnitarioST}
                          onChangeTipo={setTipoRepasseDoSt}
                          label="Repasse Unitário Substituição Tributária"
                          name="repasseDeSt"
                          options={optionsRepassesUnitarios}
                        />
                        <InputRepasse
                          tipoRepasse={tipoRepasseDoSeguros}
                          value={
                            tipoRepasseDoSeguros === 0
                              ? repasseDeSeguros * fatorPesoMedio
                              : repasseDeSeguros
                          }
                          onChange={(value) => {
                            if (tipoRepasseDoSeguros === 0) {
                              setRepasseDeSeguros(value / fatorPesoMedio);
                            } else {
                              setRepasseDeSeguros(value);
                            }
                          }}
                          errorMessage={erros[5]}
                          disabled={hasRepasseNaoUnitarioSeguros}
                          onChangeTipo={setTipoRepasseDoSeguros}
                          label="Repasse Unitário Seguros"
                          name="repasseDeSeguros"
                          options={optionsRepassesUnitarios}
                        />
                      </Box>
                      <Box
                        display="flex"
                        style={{
                          gap: '10px',
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <InputRepasse
                            tipoRepasse={tipoRepasseDoOutrasDespesas}
                            value={
                              tipoRepasseDoOutrasDespesas === 0
                                ? repasseDeOutrasDespesas * fatorPesoMedio
                                : repasseDeOutrasDespesas
                            }
                            onChange={(value) => {
                              if (tipoRepasseDoOutrasDespesas === 0) {
                                setRepasseDeOutrasDespesas(
                                  value / fatorPesoMedio,
                                );
                              } else {
                                setRepasseDeOutrasDespesas(value);
                              }
                            }}
                            errorMessage={erros[6]}
                            disabled={hasRepasseNaoUnitarioOutrasDespesas}
                            onChangeTipo={setTipoRepasseDoOutrasDespesas}
                            label="Repasses de Outras Despesas"
                            name="repasseDeOutrasDespesas"
                            options={optionsRepassesUnitarios}
                          />
                        </div>
                        <div
                          style={{
                            flex: 1,
                          }}
                        />
                      </Box>
                    </Box>
                  ) : null}

                  <Box>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: '10px' }}
                    >
                      Verbas de Desconto
                    </Typography>

                    <Box
                      display="flex"
                      style={{
                        gap: '10px',
                      }}
                    >
                      <InputDesconto
                        tipoRepasse={tipoDescontoNfe}
                        value={
                          tipoDescontoNfe === 0
                            ? descontoNfe * fatorPesoMedio
                            : descontoNfe
                        }
                        onChange={(value) => {
                          if (tipoDescontoNfe === 0) {
                            setDescontoNfe(value / fatorPesoMedio);
                          } else {
                            setDescontoNfe(value);
                          }
                        }}
                        errorMessage={erros[10]}
                        onChangeTipo={setTipoDescontoNfe}
                        label="Desconto em NFe"
                        name="desconto_nfe"
                        options={optionsDescontos}
                        disabled
                      />
                      <InputDesconto
                        tipoRepasse={tipoDescontoBoleto}
                        value={
                          tipoDescontoBoleto === 0
                            ? descontoBoleto * fatorPesoMedio
                            : descontoBoleto
                        }
                        onChange={(value) => {
                          if (tipoDescontoBoleto === 0) {
                            setDescontoBoleto(value / fatorPesoMedio);
                          } else {
                            setDescontoBoleto(value);
                          }
                        }}
                        errorMessage={erros[11]}
                        onChangeTipo={setTipoDescontoBoleto}
                        label="Desconto em Boleto"
                        name="desconto_boleto"
                        options={optionsDescontos}
                      />
                    </Box>
                    <Box
                      display="flex"
                      style={{
                        gap: '10px',
                      }}
                    >
                      <InputDesconto
                        tipoRepasse={tipoDescontoBonificacao}
                        value={
                          tipoDescontoBonificacao === 0
                            ? descontoBonificacao * fatorPesoMedio
                            : descontoBonificacao
                        }
                        onChange={(value) => {
                          if (tipoDescontoBonificacao === 0) {
                            setDescontoBonificacao(value / fatorPesoMedio);
                          } else {
                            setDescontoBonificacao(value);
                          }
                        }}
                        errorMessage={erros[12]}
                        onChangeTipo={setTipoDescontoBonificacao}
                        label="Desconto em Bonificação"
                        name="desconto_bonificacao"
                        options={optionsDescontos}
                      />
                      <InputDesconto
                        tipoRepasse={tipoDescontoBacklight}
                        value={
                          tipoDescontoBacklight === 0
                            ? descontoBacklight * fatorPesoMedio
                            : descontoBacklight
                        }
                        onChange={(value) => {
                          if (tipoDescontoBacklight === 0) {
                            setDescontoBacklight(value / fatorPesoMedio);
                          } else {
                            setDescontoBacklight(value);
                          }
                        }}
                        errorMessage={erros[13]}
                        onChangeTipo={setTipoDescontoBacklight}
                        label="Desconto em Backlight"
                        name="desconto_backlight"
                        options={optionsDescontos}
                      />
                    </Box>
                    <Box
                      display="flex"
                      style={{
                        gap: '10px',
                      }}
                    >
                      <InputDesconto
                        tipoRepasse={tipoDescontoJornalOfertas}
                        value={
                          tipoDescontoJornalOfertas === 0
                            ? descontoJornalOfertas * fatorPesoMedio
                            : descontoJornalOfertas
                        }
                        onChange={(value) => {
                          if (tipoDescontoJornalOfertas === 0) {
                            setDescontoJornalOfertas(value / fatorPesoMedio);
                          } else {
                            setDescontoJornalOfertas(value);
                          }
                        }}
                        errorMessage={erros[14]}
                        onChangeTipo={setTipoDescontoJornalOfertas}
                        label="Desconto em Jornal de Ofertas"
                        name="desconto_jornal_ofertas"
                        options={optionsDescontos}
                      />
                      <InputDesconto
                        tipoRepasse={tipoDescontoDescargoMercadorias}
                        value={
                          tipoDescontoDescargoMercadorias === 0
                            ? descontoDescargoMercadorias * fatorPesoMedio
                            : descontoDescargoMercadorias
                        }
                        onChange={(value) => {
                          if (tipoDescontoDescargoMercadorias === 0) {
                            setDescontoDescargoMercadorias(
                              value / fatorPesoMedio,
                            );
                          } else {
                            setDescontoDescargoMercadorias(value);
                          }
                        }}
                        errorMessage={erros[15]}
                        onChangeTipo={setTipoDescontoDescargoMercadorias}
                        label="Desconto em Descargo de Mercadorias"
                        name="desconto_descargo_mercadorias"
                        options={optionsDescontos}
                      />
                    </Box>
                  </Box>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  {(produto ? !produto.is_invencivel : false) ? (
                    <div>
                      {erros[20] !== '' ? (
                        <div
                          style={{
                            color: 'red',
                            margin: '10px 0',
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                        >
                          * {erros[20]}
                        </div>
                      ) : null}
                      <div
                        style={{
                          color: 'blue',
                          margin: '10px 0',
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        * Para adicionar uma nova validade aperta a TECLA ENTER
                        no campo abaixo
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: '10px',
                          flexDirection: 'column',
                        }}
                      >
                        <DateInput
                          name="validade"
                          handleEnter={() => {
                            handleAdicionarValidade(validade);
                          }}
                          label="Validade"
                          handleKey={() => {}}
                          value={validade}
                          onChange={(value) => setValidade(value.target.value)}
                          error={erros[7] !== ''}
                          helperText={erros[7]}
                          fullWidth
                        />

                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell2>Ordem</StyledTableCell2>
                                <StyledTableCell2>Validade</StyledTableCell2>
                                <StyledTableCell2>Ações</StyledTableCell2>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validades.map((row, ind) => (
                                <StyledTableRow key={`${ind}-row`}>
                                  <StyledTableCell2>{ind + 1}</StyledTableCell2>
                                  <StyledTableCell2>
                                    {formatarValidade(row)}
                                  </StyledTableCell2>
                                  <StyledTableCell2>
                                    <IconButton
                                      aria-label="deletar"
                                      size="small"
                                      onClick={() => deletarValidade(ind)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </StyledTableCell2>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '30px',
                        fontSize: '35px',
                        color: 'blue',
                      }}
                    >
                      Este produto é INVENCÍVEL (não é necessário o cadastro de
                      validades)
                    </div>
                  )}
                </TabPanel>

                {/*              <TabPanel value={value} index={3}>
                  <div style={{ overflowX: 'scroll' }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{ marginTop: '10px' }}
                      >
                        Análise
                      </Typography>
                      {linhas.length > 0 ? (
                        <Tooltip title="Gráfico de pizza">
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              abrirGrafico(linhas[0].dadosOriginais)
                            }
                          >
                            <DataUsageIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>

                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={8}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PRECO_VENDA,
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            Geral
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={2}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PRECO_VENDA,
                              fontSize: '20px',
                              fontWeight: 'bold',
                              color: 'red',
                            }}
                          >
                            Preço de Venda Calculado
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PRECO_VENDA,
                            }}
                          >
                            Valor Negociado com a indústria
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PRECO_VENDA,
                              fontSize: '20px',
                              fontWeight: 'bold',
                              color: 'red',
                            }}
                          >
                            Custo final do produto
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'yellow',
                              fontSize: '30px',
                              fontWeight: 'bold',
                              color: 'red',
                            }}
                          >
                            {precoVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {precoVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {valorNegociadoComAIndustriaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {valorNegociadoComAIndustria2}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {valorNegociadoComAIndustriaTotal}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {custoFinalDoProdutoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'yellow',
                              fontSize: '30px',
                              fontWeight: 'bold',
                              color: 'red',
                            }}
                          >
                            {custoFinalDoProduto}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {custoFinalDoProdutoTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PRECO_VENDA,
                            }}
                          >
                            Conferência do Gráfico de Seção Circular
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PRECO_VENDA,
                            }}
                          >
                            Margem Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PRECO_VENDA,
                            }}
                          >
                            Quantidade Comprada
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {conferenciaDoGraficoDeSecaoCircular}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemTotalPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {margemTotalTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {quantidadeComprada}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={15}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            PREVISÃO DE LUCRO
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                            }}
                          >
                            <Tooltip title="Custo Livre de Impostos">
                              <div>CLI</div>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                            }}
                          >
                            Lucro Líquido
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={4}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                            }}
                          >
                            IRPJ
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={4}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                            }}
                          >
                            CSLL
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                            }}
                          >
                            Lucro Bruto
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            % PV
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            % do LB
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            % PV
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            % do LB
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            % do LB
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {custoLivreDeImpostoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroLiquidoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroLiquido}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroLiquidoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpjPorcentagemPv}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpjPorcentagemLb}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpj}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {irpjTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csllPorcentagemPv}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csllPorcentagemLb}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csll}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {csllTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroBrutoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroBruto}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {lucroBrutoTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={13}
                            rowSpan={1}
                            style={{
                              fontWeight: 'bold',
                              fontSize: '20px',
                              backgroundColor: BACKGROUND_CREDITO_COMPRA,
                            }}
                          >
                            Crédito de Compra
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={4}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_CREDITO_COMPRA,
                            }}
                          >
                            ALÍQUOTA INTERNA / ICMS NORMAL
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_CREDITO_COMPRA,
                            }}
                          >
                            PIS
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_CREDITO_COMPRA,
                            }}
                          >
                            COFINS
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_CREDITO_COMPRA,
                            }}
                          >
                            TOTAL DE CRÉDITOS
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Estado
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {estadoOrigem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaCompraTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisCompraTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsCompraTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCreditosCompraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCreditosCompra}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeCreditosCompraTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={13}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DEBITO_VENDA,
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            Débito de Venda
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={4}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DEBITO_VENDA,
                            }}
                          >
                            ALÍQUOTA INTERNA / ICMS NORMAL
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DEBITO_VENDA,
                            }}
                          >
                            PIS
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DEBITO_VENDA,
                            }}
                          >
                            CONFINS
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DEBITO_VENDA,
                            }}
                          >
                            TOTAL DE DÉBITOS
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            Estado
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {estadoDestino}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaVendaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {aliquotaInternaVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisVendaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {pisVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsVendaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {cofinsVendaTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeDebitosVendaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeDebitosVenda}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeDebitosVendaTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={15}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            Análise de Fluxo de Caixa
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={2}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            <Tooltip
                              title="Usado em caso de compras de dentro do
                        estado. Havendo Antecipação não pode existir Fronteira."
                            >
                              <div>ICMS ANTECIPAÇÃO</div>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={8}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            ICMS de Fronteira
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={2}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            <Tooltip
                              title="A SER COLOCADO COMO CRÉDITO NO
                          ICMS NORMAL DO PRÓXIMO MÊS"
                            >
                              <div>TOTAL DE ICMS ANTECIPADO</div>
                            </Tooltip>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            <Tooltip
                              title="30% para produtos normais e 35% para
                        derivados do leite"
                            >
                              <div>Agregado do CNAE</div>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            MVA da Pauta
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            Cálculo da Pauta de ICMS de Fronteira
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={2}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            <Tooltip
                              title="Usado em caso de compras de fora do estado.
                          Havendo Fronteira não pode haver Antecipação."
                            >
                              <div>FRONTEIRA</div>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            ICMS de Fronteira Adotado
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsAntecipacaoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsAntecipacao}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsAntecipacaoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {agregadoDoCnaePorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {mvaDaPauta}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {calculoDaPautaDeIcmsDeFronteira}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fronteiraPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {fronteira}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsDeFronteiraAdotadoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsDeFronteiraAdotado}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {icmsDeFronteiraAdotadoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeIcmsAntecipadoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeIcmsAntecipado}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {totalDeIcmsAntecipadoTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            Diferença de ICMS
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            Diferença de PIS
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            Diferença de COFINS
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            TRIBUTAÇÃO EFETIVA
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                            }}
                          >
                            RESULTADO DO FLUXO DE CAIXA
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeIcmsPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeIcms}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeIcmsTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDePisPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDePis}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDePisTotal}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeCofinsPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeCofins}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {diferencaDeCofinsTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {tributacaoEfetivaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {tributacaoEfetiva}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {tributacaoEfetivaTotal}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {resultadoDoFluxoDeCaixaPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {resultadoDoFluxoDeCaixa}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {resultadoDoFluxoDeCaixaTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={9}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            Descontos
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                            }}
                          >
                            Desconto em Nfe
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                            }}
                          >
                            Desconto em Boleto
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                            }}
                          >
                            Desconto em Bonificação
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoNfePorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoNfe2}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoNfeTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBoletoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBoleto2}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBoletoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBonificacaoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBonificacao2}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBonificacaoTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                            }}
                          >
                            Desconto em Backlight
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                            }}
                          >
                            Desconto em Jornal de Ofertas
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                            }}
                          >
                            Desconto em Descargo de Mercadorias
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            rowSpan={1}
                            style={{
                              backgroundColor: BACKGROUND_DESCONTOS,
                              fontWeight: 'bold',
                            }}
                          >
                            Descontos Totais
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            R$ Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBacklightPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBacklight2}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoBacklightTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoJornalOfertasPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoJornalOfertas2}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoJornalOfertasTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoDescargoMercadoriasPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoDescargoMercadorias2}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoDescargoMercadoriasTotal}
                          </StyledTableCell>

                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoPorcentagem}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoTotal}
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            rowSpan={1}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {descontoTotalTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </TabPanel> */}
              </div>
            </DialogContent>
          ) : (
            <DialogContent dividers>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '40px',
                  fontWeight: 'bold',
                }}
              >
                Carregando...
              </div>
            </DialogContent>
          )}
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleCloseDialog()}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              {label1}
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                {label2}
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
        <DialogoCreateImpressao
          handleClose={() => {}}
          ref={refDialogoImpressao}
        />
        <DialogoProduto
          ref={refDialogoProduto}
          handleClose={(idUpdate) => {
            if (!handleUpdateProduto) {
              getProduto(idUpdate);
            } else {
              handleUpdateProduto(index);
            }
          }}
          isImprimir={false}
        />
        <DialogoGrafico ref={refDialogoGrafico} />
      </div>
    );
  },
);

export default DialogoBoletos;
