const choices = [
  { id: 'resolver-agora', name: 'Resolver Agora (0 - 3 dias)' },
  { id: 'resolver-urgente', name: 'Resolver Urgente (4 a 7 dias)' },
  { id: 'resolver-logo', name: 'Resolver Logo (8 a 14 dias)' },
  { id: 'resolver', name: 'Resolver (15 a 21 dias)' },
  { id: 'atencao', name: 'Atenção (22 a 28 dias)' },
  { id: 'nao-e-data-critica', name: 'Não é data crítica (29 a 60 dias)' },
];

export default choices;
