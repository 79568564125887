import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
  },
}));

const DiasVencimentoField = ({ ...props }) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  let value = '-';

  if (record) {
    if (record.record.data) {
      const dataVencimento = new Date(
        moment(new Date(record.record.data)).endOf('day').format(),
      );

      const dataAtual = new Date(moment(new Date()).endOf('day').format());

      const delta = dataVencimento.getTime() - dataAtual.getTime();

      if (delta > 0) {
        value = parseInt(delta / (24 * 60 * 60 * 1000), 10);
      } else if (delta < 0) {
        value = `${parseInt(delta / (24 * 60 * 60 * 1000), 10)} (VENCEU)`;
      } else {
        value = `${parseInt(delta / (24 * 60 * 60 * 1000), 10)} (VENCE HOJE)`;
      }
    }
  }

  return (
    <Typography
      className={classes.text}
      style={{
        cursor: 'pointer',
      }}
    >
      {value}
    </Typography>
  );
};

export default DiasVencimentoField;
