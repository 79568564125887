import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { DialogoConfirmacao } from '../../../..';
import { getListAllAPI } from '../../../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getTotalCompra,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3,
} from '../../../../../utils';
import DialogoParcelaCompra from '../FinalizarFrenteCaixaCompra/DialogoParcelaCompra';
import DialogoPrecificacaoNova from '../InicioFrenteCaixaCompra/DialogoPrecificacaoNova';
import DialogoTotais from './DialogoTotais';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function getQuantidades(produto, quantidade, preco_compra) {
  switch (produto.unidade.tipo) {
    case POR_PESO_FIXO:
      return {
        unidades: quantidade,
        peso: quantidade * produto.peso_medio,
        preco_compra,
      };
    case POR_UNIDADE:
      return {
        unidades: quantidade,
        peso: 0,
        preco_compra,
      };
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return {
        unidades: 0,
        peso: quantidade,
        preco_compra,
      };
    default:
      return {
        unidades: 0,
        peso: 0,
        preco_compra,
      };
  }
}

function getRepassesItens(item, qtde) {
  const repasses = {
    repasseDoIpi: 0,
    repasseDoFreteUnitario: 0,
    repasseDeSt: 0,
    repasseDeSeguros: 0,
    repasseDeOutrasDespesas: 0,
  };

  if (item) {
    if (item.vIPI) {
      repasses.repasseDoIpi = item.vIPI.value / qtde;
    }

    if (item.vFrete) {
      repasses.repasseDoFreteUnitario = item.vFrete.value / qtde;
    }

    if (item.vICMSST) {
      repasses.repasseDeSt = item.vICMSST.value / qtde;
    }

    if (item.vSeg) {
      repasses.repasseDeSeguros = item.vSeg.value / qtde;
    }

    if (item.vOutro) {
      repasses.repasseDeOutrasDespesas = item.vOutro.value / qtde;
    }
  }

  return repasses;
}

function getTotaisItemNota(item) {
  const totais = {
    pis: 0, // ok
    cofins: 0, // ok
    icms: 0, // ok
    total: 0, // ok
    bc_icms: 0, // ok
    repasse_ipi: 0, // ok
    repasse_st: 0, // ok
    repasse_frete: 0, // ok
    repasse_seguro: 0, // ok
    repasse_outras_despesas: 0, // ok
    desconto_nfe: 0, // ok
    isento: false, // ok
    nItem: '', // ok
    indTot: 1, // ok
  };

  if (item) {
    if (item.nItem) {
      totais.nItem = item.nItem.value;
    }

    if (item.vPIS) {
      totais.pis = item.vPIS.value;
    }

    if (item.vCOFINS) {
      totais.cofins = item.vCOFINS.value;
    }

    if (item.vIPI) {
      totais.repasse_ipi = item.vIPI.value;
    }

    if (item.vICMSST) {
      totais.repasse_st = item.vICMSST.value;
    }

    if (item.vFrete) {
      totais.repasse_frete = item.vFrete.value;
    }

    if (item.vSeg) {
      totais.repasse_seguro = item.vSeg.value;
    }

    if (item.vOutro) {
      totais.repasse_outras_despesas = item.vOutro.value;
    }

    if (item.vDesc) {
      totais.desconto_nfe = item.vDesc.value;
    }

    if (item.vBC) {
      totais.bc_icms = item.vBC.value;
    } else {
      totais.isento = true;
    }

    if (item.vICMS) {
      totais.icms = item.vICMS.value;
    }

    if (item.vProd) {
      totais.total = item.vProd.value;
    }

    if (item.indTot) {
      totais.indTot = item.indTot.value;
    }
  }

  return totais;
}

function getDescontosItens(item, qtde) {
  const descontos = {
    descontoNfe: 0,
  };

  if (item) {
    if (item.vDesc) {
      descontos.descontoNfe = item.vDesc.value / qtde;
    }
  }

  return descontos;
}

function getFatorCaixaria(produto) {
  if (!produto) return 1;
  if (produto.unidade.tipo === POR_UNIDADE && produto.is_fator_conversao_up)
    return 1;
  if (produto.unidade.tipo === POR_PESO_FIXO) return 1;
  switch (produto.fator_conversao_tipo_caixaria) {
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM:
      return 1;
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (produto.peso_caixa_nivel_1) {
          return produto.peso_caixa_nivel_1;
        }
        return 1;
      }
      if (produto.unidades_caixa_nivel_1) {
        return produto.unidades_caixa_nivel_1;
      }
      return 1;
    }

    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (!produto.peso_caixa_nivel_1) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_2) {
          return produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
        }
        return 1;
      }
      if (!produto.unidades_caixa_nivel_1) {
        return 1;
      }
      if (produto.unidades_caixa_nivel_2) {
        return produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
      }
      return 1;
    }

    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (!produto.peso_caixa_nivel_1) {
          return 1;
        }
        if (!produto.unidades_caixa_nivel_2) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_3) {
          return (
            produto.peso_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        }
        return 1;
      }
      if (!produto.unidades_caixa_nivel_1) {
        return 1;
      }
      if (!produto.unidades_caixa_nivel_2) {
        return 1;
      }
      if (produto.unidades_caixa_nivel_3) {
        return (
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        );
      }
      return 1;
    }

    default:
      return 1;
  }
}

function getParcelasFormatadoInit(dados) {
  const { pag, fat, dup } = dados;

  const parcelasAjustadas = [];

  function getDesconto(fat) {
    if (fat) {
      return fat.vDesc.value;
    }

    return 0;
  }

  const desconto = getDesconto(fat);

  function distribuirDescontoParcelas(desconto, nParcelas) {
    if (nParcelas === 1) return [desconto];
    const descs = [];
    const multiplicadoPor100 = Math.round(desconto * 100);
    const parcelaNomral = parseInt(multiplicadoPor100 / nParcelas, 10);
    const resto = multiplicadoPor100 % nParcelas;
    for (let i = 0; i < nParcelas; i++) {
      if (i !== nParcelas - 2) {
        descs.push(parcelaNomral / 100);
      } else {
        descs.push(resto / 100);
      }
    }

    return descs;
  }

  if (dup) {
    const descontos = distribuirDescontoParcelas(desconto, dup.length);
    for (let i = 0; i < dup.length; i++) {
      parcelasAjustadas.push({
        valor: dup[i].vDup.value + descontos[i],
        numeroParcela: dup[i].nDup.value,
        dataPagamento: moment(dup[i].dVenc.value).format('YYYY-MM-DD'),
        desconto: descontos[i],
      });
    }
  }

  if (parcelasAjustadas.length === 0 && pag) {
    const descontos = distribuirDescontoParcelas(desconto, pag.length);
    for (let i = 0; i < pag.length; i++) {
      parcelasAjustadas.push({
        valor: pag[i].vPag.value + descontos[i],
        numeroParcela: i + 1,
        dataPagamento: '',
        desconto: descontos[i],
      });
    }
  }

  return parcelasAjustadas;
}

//function getParcelasFormatado(item, index) {}

function getParcelasFormatadoPosDialogo(dados, index) {
  const {
    valor,
    conta,
    data_pagamento,
    status,
    cartao,
    isCartao,
    fatura,
    codigo_boleto,
    problema,
    descricao_problema,
    agendamento_user_id,
    desconto,
  } = dados;
  return {
    data_pagamento,
    conta,
    valor,
    uidd: `${isCartao ? cartao.nome : conta.nome}${index}`,
    idIndicadorChange: -1,
    status: isCartao ? false : conta.id === 1 ? false : status,
    cartao,
    isCartao,
    fatura,
    codigo_boleto,
    problema,
    descricao_problema,
    agendamento_user_id,
    desconto,
  };
}

function getLoteFormatado(item, index) {
  const produto = item.correspondencia;

  const { preco_compra } = getQuantidades(
    item.correspondencia,
    item.qTrib.value,
    item.vUnTrib.value,
  );

  const {
    repasseDoIpi,
    repasseDoFreteUnitario,
    repasseDeSt,
    repasseDeSeguros,
    repasseDeOutrasDespesas,
  } = getRepassesItens(item, item.qTrib.value);

  const { descontoNfe } = getDescontosItens(item, item.qTrib.value);

  let fator_caixaria = 1;

  fator_caixaria = getFatorCaixaria(produto);

  let fator_conversao_importacao_xml_compra = 1;

  if (produto.unidade.tipo === POR_UNIDADE) {
    if (produto.is_fator_conversao_up) {
      if (produto.fator_conversao_importacao_xml_compra) {
        fator_conversao_importacao_xml_compra =
          produto.fator_conversao_importacao_xml_compra;
      }
    }
  }

  let fator_peso_medio = 1;

  if (produto.unidade.tipo === POR_PESO_FIXO) {
    fator_peso_medio = produto.peso_medio;
  }

  const fatorFinal = fator_conversao_importacao_xml_compra / fator_caixaria;

  return {
    produtoId: produto.id,
    validade: item.dVal ? item.dVal.value : null,
    unitario: preco_compra * fatorFinal,
    quantidade:
      item.qTrib.value /
      fatorFinal /
      (fator_caixaria !== 1 ? 1 : fator_peso_medio),
    repasseDoIpi: repasseDoIpi * fatorFinal,
    repasseDoFreteUnitario: repasseDoFreteUnitario * fatorFinal,
    repasseDeSt: repasseDeSt * fatorFinal,
    repasseDeSeguros: repasseDeSeguros * fatorFinal,
    repasseDeOutrasDespesas: repasseDeOutrasDespesas * fatorFinal,
    descontoNfe: descontoNfe * fatorFinal,
    index,
    dadosComplementaresNota: {
      qCom: item.qTrib.value,
      vUnCom: item.vUnTrib.value,
      uCom: item.uTrib.value,
      fator_caixaria,
      fator_conversao_importacao_xml_compra,
      fator_peso_medio,
      vIPI: item.vIPI ? item.vIPI.value : 0,
      vFrete: item.vFrete ? item.vFrete.value : 0,
      vICMSST: item.vICMSST ? item.vICMSST.value : 0,
      vSeg: item.vSeg ? item.vSeg.value : 0,
      vOutro: item.vOutro ? item.vOutro.value : 0,
      vDesc: item.vDesc ? item.vDesc.value : 0,
      //
      quantidadeCompradaFormatado: `${item.qCom.value} ${item.uCom.value} = ${item.qTrib.value} ${item.uTrib.value}`,
      valorUnitarioComprado: `${item.vUnCom.value} / ${item.uCom.value} = ${item.vUnTrib.value} / ${item.uTrib.value}`,
      //
      vProd: item.vProd ? item.vProd.value : 0,
    },
  };
}

const DialogoPassadorLotes = forwardRef(
  ({ handleClose, handleSalvar }, ref) => {
    const [open, setOpen] = useState(false);

    const dadosLocal = useRef(null);

    const position = useRef(0);

    const positionParcela = useRef(0);

    const tipoPassador = useRef(0);

    const [cont, setCont] = useState(0);

    const refDialogoPrecificacaoNova = useRef(null);

    const refDialogoParcelaCompra = useRef(null);

    const refDialogoTotais = useRef(null);

    useImperativeHandle(ref, () => ({
      handleOpen(
        hasRepasseNaoUnitarioIPI,
        hasRepasseNaoUnitarioFrete,
        hasRepasseNaoUnitarioST,
        hasRepasseNaoUnitarioSeguros,
        hasRepasseNaoUnitarioOutrasDespesas,
        fornecedor,
        config,
        lotes,
        parcelas,
      ) {
        dadosLocal.current = {
          hasRepasseNaoUnitarioIPI,
          hasRepasseNaoUnitarioFrete,
          hasRepasseNaoUnitarioST,
          hasRepasseNaoUnitarioSeguros,
          hasRepasseNaoUnitarioOutrasDespesas,
          lotes,
          fornecedor,
          config,
          parcelas: getParcelasFormatadoInit(parcelas),
        };
        position.current = 0;
        tipoPassador.current = 0;
        positionParcela.current = 0;
        setOpen(true);
      },
    }));

    useEffect(() => {
      if (open && dadosLocal.current) {
        handlePrecificar(dadosLocal.current.lotes[0], 0);
        position.current = 0;
      }
    }, [open]);

    function handleCloseDialog() {
      dadosLocal.current = null;
      position.current = 0;
      setOpen(false);
      handleClose();
    }

    function handleClosePrecificacao(dados) {
      if (dadosLocal.current) {
        if (dados) {
          const { index, produto: produto2, ...dados2 } = dados;

          if (index >= 0) {
            let unidades = 0;
            let peso = 0;

            if (produto2.unidade.tipo === POR_PESO_FIXO) {
              unidades = dados.qtde;
              peso = unidades * produto2.peso_medio;
            } else if (produto2.unidade.tipo === POR_UNIDADE) {
              unidades = dados.qtde;
              peso = 0;
            } else {
              unidades = 0;
              peso = dados.qtde;
            }

            const dadosFormatados = {
              produto: produto2,
              peso: produto2.unidade.tipo === POR_UNIDADE ? 0 : peso,
              total: getTotalCompra(
                peso,
                unidades,
                dados2.preco_compra_impostos,
                produto2,
              ),
              unidades:
                produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                  ? 0
                  : unidades,
              unitario: dados2.preco_compra,
              uidd: `${produto2.nome}${dadosLocal.current.lotes.length}`,
              idIndicadorChange: -1,
              validade: dados2.validade,
              unitarioImposto: dados2.preco_compra_impostos,
              cg: dados2.cg,
              dadosPrecificacao: dados2.dadosPrecificacao,
              nImpressoes: dados2.nImpressoes,
              index,
              validades: dados2.validades,
              totaisFiscais: getTotaisItemNota(
                dadosLocal.current.lotes[index],
                produto2,
              ),
            };

            const lotesCopy = dadosLocal.current.lotes.slice();
            lotesCopy[index].dadosFormatados = dadosFormatados;

            dadosLocal.current = {
              ...dadosLocal.current,
              lotes: lotesCopy,
            };

            if (index === lotesCopy.length - 1) {
              tipoPassador.current = 1;
              // ir para os itens
              /*  handleSalvar(
                dadosLocal.current.lotes.map((item) => item.dadosFormatados),
              );
              dadosLocal.current = null;
              position.current = 0; */
              if (dadosLocal.current.parcelas.length > 0) {
                positionParcela.current = 0;
                /* openDialogoParcelaCompra(
                  dadosLocal.current.parcelas[positionParcela.current],
                  positionParcela.current,
                ); */
                if (refDialogoTotais.current) {
                  refDialogoTotais.current.handleOpen();
                }
                setCont(cont + 1);
              } /*  else {
                handleSalvar(
                  dadosLocal.current.lotes.map((item) => item.dadosFormatados),
                  dadosLocal.current.parcelas.map(
                    (item) => item.dadosFormatados,
                  ),
                );
                dadosLocal.current = null;
                position.current = 0;
                positionParcela.current = 0;
                setOpen(false);
              } */ else if (
                refDialogoTotais.current
              ) {
                refDialogoTotais.current.handleOpen();
              }
              //setOpen(false);
            } else {
              position.current += 1;
              handlePrecificar(
                dadosLocal.current.lotes[position.current],
                position.current,
              );
              setCont(cont + 1);
            }
          }
        } else if (position.current === 0) {
          handleCloseDialog();
        } else {
          position.current -= 1;
          handlePrecificar(
            dadosLocal.current.lotes[position.current],
            position.current,
          );
          setCont(cont + 1);
        }
      }
    }

    function handleCloseDialogoParcelaCompra(dados, index) {
      if (dadosLocal.current) {
        if (dados) {
          if (index >= 0) {
            const dadosFormatados = getParcelasFormatadoPosDialogo(
              dados,
              index,
            );

            const parcelasCopy = dadosLocal.current.parcelas.slice();
            parcelasCopy[index].dadosFormatados = dadosFormatados;

            dadosLocal.current = {
              ...dadosLocal.current,
              parcelas: parcelasCopy,
            };

            if (index === parcelasCopy.length - 1) {
              handleSalvar(
                dadosLocal.current.lotes.map((item) => item.dadosFormatados),
                dadosLocal.current.parcelas.map((item) => item.dadosFormatados),
              );
              dadosLocal.current = null;
              position.current = 0;
              positionParcela.current = 0;
              setOpen(false);
            } else {
              positionParcela.current += 1;
              openDialogoParcelaCompra(
                dadosLocal.current.parcelas[positionParcela.current],
                positionParcela.current,
              );
              setCont(cont + 1);
            }
          }
        } else if (positionParcela.current === 0) {
          /* tipoPassador.current = 0;
          position.current = dadosLocal.current.lotes.length - 1;
          handlePrecificar(
            dadosLocal.current.lotes[position.current],
            position.current,
          );
          setCont(cont + 1); */
          if (refDialogoTotais.current) {
            refDialogoTotais.current.handleOpen();
          }
        } else {
          positionParcela.current -= 1;
          openDialogoParcelaCompra(
            dadosLocal.current.parcelas[positionParcela.current],
            positionParcela.current,
          );
          setCont(cont + 1);
        }
      }
    }

    function format10(value) {
      return value < 10 ? `0${value}` : `${value}`;
    }

    function formatDate(date) {
      if (date) {
        const dateReal = new Date(date);
        const ano = dateReal.getFullYear();
        const mes = format10(dateReal.getMonth() + 1);
        const dia = format10(dateReal.getDate());
        return `${ano}-${mes}-${dia}`;
      }
      return '';
    }

    function handlePrecificar(dados, index) {
      if (refDialogoPrecificacaoNova.current && dadosLocal.current) {
        const dadosFormatados = getLoteFormatado(dados, index);
        refDialogoPrecificacaoNova.current.handleEdit(
          dadosFormatados.produtoId,
          dadosLocal.current.config,
          dadosLocal.current.fornecedor,
          formatDate(dadosFormatados.validade),
          0,
          dadosFormatados.unitario,
          dadosFormatados.quantidade,
          dadosFormatados.repasseDoIpi,
          dadosFormatados.repasseDoFreteUnitario,
          dadosFormatados.repasseDeSt,
          dadosFormatados.repasseDeSeguros,
          dadosFormatados.repasseDeOutrasDespesas,
          dadosFormatados.index,
          dadosFormatados.descontoNfe,
          0,
          0,
          0,
          0,
          0,
          dadosFormatados.dadosComplementaresNota,
          [],
        );
      }
    }

    async function getProduto(produtoId) {
      try {
        const data = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { id: produtoId },
          ['unidade'],
        );

        if (data.data.length > 0) {
          return data.data[0];
        }
        throw 'Este produto não existe!';
      } catch (erros) {
        return null;
      }
    }

    async function handleUpdateProduto(index) {
      const produto = await getProduto(
        dadosLocal.current.lotes[index].correspondencia.id,
      );
      dadosLocal.current.lotes[index].correspondencia = produto;
      handlePrecificar(dadosLocal.current.lotes[index], index);
      setCont(cont + 1);
    }

    function openDialogoParcelaCompra(dados, index) {
      if (refDialogoParcelaCompra.current && dadosLocal.current) {
        refDialogoParcelaCompra.current.handleOpenImportacao(
          dados.dataPagamento,
          dados.valor,
          dados.desconto,
          index,
        );
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogContent dividers>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '40px',
                fontWeight: 'bold',
              }}
            >
              {`Carregando Item ${position.current + 1}/${
                dadosLocal.current ? dadosLocal.current.lotes.length : 0
              }... `}
            </div>
          </DialogContent>
        </Dialog>
        <DialogoPrecificacaoNova
          isEmitMessage={false}
          isCloseX={false}
          ref={refDialogoPrecificacaoNova}
          handleClose={handleClosePrecificacao}
          estoque="-"
          hasRepasseNaoUnitarioIPI={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioIPI
              : false
          }
          hasRepasseNaoUnitarioFrete={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioFrete
              : false
          }
          hasRepasseNaoUnitarioST={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioST
              : false
          }
          hasRepasseNaoUnitarioSeguros={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioSeguros
              : false
          }
          hasRepasseNaoUnitarioOutrasDespesas={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioOutrasDespesas
              : false
          }
          label1="Voltar"
          label2="Prosseguir"
          prefixoTitle={`Item ${position.current + 1}/${
            dadosLocal.current ? dadosLocal.current.lotes.length : 0
          } - `}
          handleUpdateProduto={handleUpdateProduto}
        />
        <DialogoParcelaCompra
          ref={refDialogoParcelaCompra}
          handleNewItem={handleCloseDialogoParcelaCompra}
          resto={0}
          label1="Voltar"
          label2="Prosseguir"
          isCloseX={false}
          handleClose={(index) => handleCloseDialogoParcelaCompra(null, index)}
        />
        <DialogoTotais
          ref={refDialogoTotais}
          handleClose={() => {
            tipoPassador.current = 0;
            position.current = dadosLocal.current.lotes.length - 1;
            handlePrecificar(
              dadosLocal.current.lotes[position.current],
              position.current,
            );
            setCont(cont + 1);
          }}
          handleSalvar={() => {
            if (dadosLocal.current.parcelas.length > 0) {
              positionParcela.current = 0;
              openDialogoParcelaCompra(
                dadosLocal.current.parcelas[positionParcela.current],
                positionParcela.current,
              );
            } else {
              handleSalvar(
                dadosLocal.current.lotes.map((item) => item.dadosFormatados),
                dadosLocal.current.parcelas.map((item) => item.dadosFormatados),
              );
              dadosLocal.current = null;
              position.current = 0;
              positionParcela.current = 0;
              setOpen(false);
            }
          }}
        />
      </div>
    );
  },
);

export default DialogoPassadorLotes;
