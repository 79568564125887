import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useContext,
} from 'react';

import { Box, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  PrecoInput,
  DateInput,
  SwitchInput,
  AutoCompleteLocal,
  AutoCompleteLocalCustomizado,
  TextInput,
  BoletoInput,
} from '../../../..';
import { getListAllAPI, api, getUserId } from '../../../../../services';
import { STATUS_ATIVO } from '../../../../../utils';
import { CompraContext } from '../contextCompra';
import DialogoVerificaData from './FormParcelaCompra/DialogoVerificaData';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStyles3 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
});

const ChipFatura = ({ option, injetado, campo }) => {
  const classes2 = useStyles3({ color: '#8e8e8e' });
  const { onDelete, ...rest } = injetado;
  return (
    <Chip
      classes={{ outlined: classes2.chip, deleteIcon: classes2.deleteIcon }}
      variant="outlined"
      label={option[campo]}
      {...rest}
    />
  );
};

const renderTagFatura = (option, injetado) => (
  <ChipFatura option={option} injetado={injetado} campo="nome" />
);

const renderOptionFatura = (option) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>{option.nome}</div>
);

function createDescricaoFatura(dia_pagamento, mes, ano) {
  const dataPagamentoFatura = new Date();
  dataPagamentoFatura.setDate(dia_pagamento);
  dataPagamentoFatura.setMonth(mes);
  dataPagamentoFatura.setFullYear(ano);
  const descricaoFatura = `${format(
    dataPagamentoFatura,
    "dd 'de' MMM 'de' yyyy",
    {
      locale: ptBR,
    },
  )}`;
  return descricaoFatura;
}

const Vazio = {
  id: -1,
  nome: 'Despesa Não problemática',
};

const DialogoCategoriaTransacao = forwardRef(
  (
    {
      handleClose = () => {},
      resto,
      handleNewItem,
      label1 = 'Cancelar',
      label2 = 'Salvar',
      prefixoTitle = '',
      isCloseX = true,
    },
    ref,
  ) => {
    const [index, setIndex] = React.useState(-1);
    const [open, setOpen] = useState(false);
    const [isCartao, setIsCartao] = useState(false);
    const [conta, setConta] = useState(null);
    const [cartao, setCartao] = useState(null);
    const [problema, setProblema] = useState(null);
    const [descricaoProblema, setDescricaoProblema] = useState('');
    const [valor, setValor] = useState(0);
    const [dataPagamento, setDataPagamento] = useState(
      moment().format('YYYY-MM-DD'),
    );
    const [status, setStatus] = useState(false);
    const [erros, setErros] = useState(['', '', '', '']);
    const [contasOptions, setContasOptions] = useState([]);
    const [cartaoOptions, setCartaoOptions] = useState([]);
    const [problemaOptions, setProblemasOptions] = useState([]);
    const [fatura, setFatura] = useState([]);
    const [faturasOptions, setFaturasOptions] = useState([]);
    const [boleto, setBoleto] = useState('');
    const [statusAgendamento, setStatusAgendamento] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const controle = useRef({ status: false });
    const refDialogoVerificaData = useRef(null);
    const [desconto, setDesconto] = useState(0);

    const {
      compra: { config },
      dispatch,
    } = useContext(CompraContext);

    const refs = useRef([]);
    const refButton = useRef(null);

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose(index);
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setIndex(-1);
        setOpen(true);
        let conta = null;
        if (config) {
          if (config.contaPadraoParcelaCompra) {
            conta = config.contaPadraoParcelaCompra;
          }
        }
        setConta(conta);
      },
      handleOpenImportacao(data, valor, desconto, index) {
        setDataPagamento(data);
        setValor(valor);
        setDesconto(desconto);
        setIndex(index);
        setOpen(true);
        let conta = null;
        if (config) {
          if (config.contaPadraoParcelaCompra) {
            conta = config.contaPadraoParcelaCompra;
          }
        }
        setConta(conta);
      },
    }));

    ////////////////////////

    useEffect(() => {
      async function getContas() {
        const dataContas = await getListAllAPI(
          'contas_normal',
          ['id', 'asc'],
          { is_caixa: false, status: STATUS_ATIVO },
          [],
        );
        //if (dataContas.data.length > 0) setConta(dataContas.data[0]);
        setContasOptions(dataContas.data);
      }
      getContas();
    }, []);

    useEffect(() => {
      async function getCartoes() {
        const dataCartoes = await getListAllAPI(
          'cartoes_credito',
          ['id', 'asc'],
          { ativo: true },
          [],
        );
        /* if (dataCartoes.data.length > 0) setCartao(dataCartoes.data[0]); */
        setCartaoOptions(dataCartoes.data);
      }
      getCartoes();
    }, []);

    useEffect(() => {
      async function getProblemas() {
        const dataProblemas = await getListAllAPI(
          'categorias-problemas-despesas',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        /* if (dataCartoes.data.length > 0) setCartao(dataCartoes.data[0]); */
        const newDat = [Vazio, ...dataProblemas.data];
        setProblemasOptions(newDat);
      }
      getProblemas();
    }, []);

    function validateConta() {
      let error = '';
      if (!conta) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateCartao() {
      let error = '';
      if (!cartao) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateProblema() {
      const error = '';
      // if (!problema) {
      //   error = 'Este campo é obrigatório';
      // }
      return error;
    }

    function validateDescricaoProblema() {
      let error = '';
      if (descricaoProblema.length > 200) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
      return error;
    }

    function validateFatura() {
      let error = '';
      if (fatura.length <= 0) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateValor() {
      let error = '';
      if (valor <= 0 || Number.isNaN(valor)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateDataPagamento() {
      let error = '';
      if (dataPagamento === null || dataPagamento === '')
        error = 'Data Inválida!';
      else {
        const data = new Date(dataPagamento);
        const dataAtual = new Date();
        const intervalo = 2 * 365 * 24 * 60 * 60 * 1000;
        if (
          data.getTime() > dataAtual.getTime() + intervalo ||
          data.getTime() < dataAtual.getTime() - intervalo
        ) {
          error = 'Data fora do intervalo aceitável!';
        }
      }
      return error;
    }

    function validateStatus() {
      const error = '';
      return error;
    }

    function validateBoleto() {
      let error = '';
      if (!isCartao) {
        if (boleto) {
          if (boleto.length < 47) {
            error = 'O boleto deve conter no mínimo 47 dígitos!';
          }
        }
      }
      return error;
    }

    function validateDesconto() {
      let error = '';
      if (!isCartao) {
        if (desconto < 0 || Number.isNaN(desconto)) {
          error = 'Este campo é obrigatório';
        } else if (desconto > valor) {
          error = 'O desconto não pode ser maior que a parcela!';
        }
      }
      return error;
    }

    function getErros() {
      const errosOld = erros.slice();
      errosOld[0] = '';
      errosOld[1] = isCartao ? validateCartao() : validateConta();
      errosOld[2] = validateDataPagamento();
      errosOld[3] = isCartao ? validateFatura() : '';
      errosOld[4] = validateValor();
      errosOld[5] = validateStatus();
      errosOld[6] = validateBoleto();
      errosOld[7] = validateProblema();
      errosOld[8] = validateDescricaoProblema();
      errosOld[9] = validateDesconto();
      return errosOld;
    }

    useEffect(() => {
      setErros(getErros());
    }, [
      conta,
      valor,
      dataPagamento,
      status,
      cartao,
      isCartao,
      fatura,
      boleto,
      problema,
      descricaoProblema,
      desconto,
    ]);

    const inputs = [
      {
        nome: 'is_cartao', // 0
        defaultValue: false,
        label: 'Despesa de cartão',
      },
      {
        nome: 'conta', // 1
        defaultValue: null,
        label: 'Conta',
      },
      {
        nome: 'data_pagamento', // 2
        defaultValue: moment().format('YYYY-MM-DD'),
        label: 'Data de Pagamento',
      },
      {
        nome: 'fatura', // 3
        defaultValue: [],
        label: 'Fatura',
      },
      {
        nome: 'valor', // 4
        defaultValue: 0,
        label: 'Valor',
      },
      {
        nome: 'status', // 5
        defaultValue: false,
        label: 'Foi Pago',
      },
      {
        nome: 'codigo_boleto', // 6
        defaultValue: '',
        label: 'Código Boleto',
      },
      {
        nome: 'problema', // 7
        defaultValue: null,
        label: 'Problema',
      },
      {
        nome: 'descricao_problema', // 8
        defaultValue: '',
        label: 'Descrição Problema',
      },
      {
        nome: 'status_agendamento', // 9
        defaultValue: '',
        label: 'Agendar',
      },
      {
        nome: 'desconto', // 10
        defaultValue: '',
        label: 'Desconto de Boleto',
      },
    ];

    refs.current = inputs.map(
      (ref, index) => (refs.current[index] = React.createRef()),
    );

    function resetValues() {
      setErros(['', '', '', '']);
      if (contasOptions.length > 0) setConta(contasOptions[0]);
      setValor(inputs[4].defaultValue);
      setDataPagamento(inputs[2].defaultValue);
      setStatus(false);
      setCartao(null);
      setIsCartao(false);
      setBoleto('');
      setFatura([]);
      setProblema(null);
      setDescricaoProblema('');
      setStatusAgendamento(false);
      setDesconto(0);
    }

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    function buildData(data) {
      const ano = parseInt(data.substring(0, 4), 10);
      const mes = parseInt(data.substring(5, 7), 10) - 1;
      const dia = parseInt(data.substring(8, 10), 10);

      const dataFormatada = new Date();
      dataFormatada.setFullYear(ano);
      dataFormatada.setDate(dia);
      dataFormatada.setMonth(mes);
      dataFormatada.setDate(dia);

      return dataFormatada;
    }

    async function getStatusDataPagamento(data) {
      const response = await api.post('/verifica-validade-data-pagamento', {
        data,
      });
      return response.data;
      //return { nextDate: null, status: true, message: '' };
    }

    function makeData2500() {
      const data = new Date();
      data.setFullYear(2500);
      data.setMonth(2);
      data.setDate(10);
      data.setHours(5);
      data.setMinutes(30);
      data.setSeconds(0);
      data.setMilliseconds(0);

      return data;
    }

    async function handleSubmit() {
      if (!hasError()) {
        if (isCartao) {
          const faturaStatus = await getFatura(
            cartao.id,
            fatura[0].mes,
            fatura[0].ano,
          );
          if (!faturaStatus) {
            if (refs.current[0].current) {
              refs.current[0].current.focus();
              refs.current[0].current.select();
            }
            const problemaReal = problema
              ? problema.id > 0
                ? problema
                : null
              : null;
            setOpen(false);
            const contaLocal = conta;
            const valorLocal = valor;
            const dataPagamentoLocal = dataPagamento;
            const statusLocal = status;
            const cartaoLocal = cartao;
            const faturaLocal = fatura[0];
            const boletoLocal = boleto;
            const problemaRealLocal = problemaReal;
            const descricaoProblemaLocal = descricaoProblema;
            const descontoLocal = desconto;
            const statusAgendamentoLocal = statusAgendamento;
            resetValues();
            handleNewItem(
              {
                conta: null,
                valor: valorLocal,
                data_pagamento: buildData(dataPagamentoLocal),
                status: statusLocal,
                cartao: cartaoLocal,
                isCartao: true,
                fatura: faturaLocal,
                codigo_boleto: '',
                problema: problemaRealLocal
                  ? problemaRealLocal.id === 1
                    ? null
                    : problemaRealLocal
                  : null,
                descricao_problema: problemaRealLocal
                  ? problemaRealLocal.id === 1
                    ? null
                    : descricaoProblemaLocal
                  : '',
                agendamento_user_id: null,
                desconto: 0,
              },
              index,
            );
          } else {
            enqueueSnackbar(`${'Esta fatura está fechada!'}`, {
              variant: 'error',
            });
          }
        } else if (!isProblemaSemData()) {
          const {
            status: statusDataPagamento,
            message,
            nextDate,
          } = await getStatusDataPagamento(buildData(dataPagamento));

          if (statusDataPagamento) {
            if (refs.current[0].current) {
              refs.current[0].current.focus();
              refs.current[0].current.select();
            }
            const problemaReal = problema
              ? problema.id > 0
                ? problema
                : null
              : null;
            setOpen(false);
            const contaLocal = conta;
            const valorLocal = valor;
            const dataPagamentoLocal = dataPagamento;
            const statusLocal = status;
            const cartaoLocal = cartao;
            const faturaLocal = fatura[0];
            const boletoLocal = boleto;
            const problemaRealLocal = problemaReal;
            const descricaoProblemaLocal = descricaoProblema;
            const descontoLocal = desconto;
            const statusAgendamentoLocal = statusAgendamento;
            resetValues();
            handleNewItem(
              {
                conta: contaLocal,
                valor: valorLocal,
                //data_pagamento: buildData(dataPagamento),
                data_pagamento: problemaRealLocal
                  ? problemaRealLocal.id === 1
                    ? makeData2500()
                    : buildData(dataPagamentoLocal)
                  : buildData(dataPagamentoLocal),
                status: problemaRealLocal
                  ? problemaRealLocal.id === 1
                    ? false
                    : statusLocal
                  : statusLocal,
                cartao: null,
                isCartao: false,
                fatura: null,
                codigo_boleto: boletoLocal,
                problema: problemaRealLocal,
                descricao_problema: problemaRealLocal
                  ? descricaoProblemaLocal
                  : '',
                agendamento_user_id: statusLocal
                  ? null
                  : statusAgendamentoLocal
                  ? getUserId()
                  : null,
                desconto: descontoLocal,
              },
              index,
            );
          } else if (refDialogoVerificaData.current) {
            refDialogoVerificaData.current.handleOpen(
              message,
              nextDate,
              dataPagamento,
            );
          }
        } else {
          if (refs.current[0].current) {
            refs.current[0].current.focus();
            refs.current[0].current.select();
          }
          const problemaReal = problema
            ? problema.id > 0
              ? problema
              : null
            : null;
          setOpen(false);
          const contaLocal = conta;
          const valorLocal = valor;
          const dataPagamentoLocal = dataPagamento;
          const statusLocal = status;
          const cartaoLocal = cartao;
          const faturaLocal = fatura[0];
          const boletoLocal = boleto;
          const problemaRealLocal = problemaReal;
          const descricaoProblemaLocal = descricaoProblema;
          const descontoLocal = desconto;
          const statusAgendamentoLocal = statusAgendamento;
          resetValues();
          handleNewItem(
            {
              conta: contaLocal,
              valor: valorLocal,
              //data_pagamento: buildData(dataPagamento),
              data_pagamento: problemaRealLocal
                ? problemaRealLocal.id === 1
                  ? makeData2500()
                  : buildData(dataPagamentoLocal)
                : buildData(dataPagamentoLocal),
              status: problemaRealLocal
                ? problemaRealLocal.id === 1
                  ? false
                  : statusLocal
                : statusLocal,
              cartao: null,
              isCartao: false,
              fatura: null,
              codigo_boleto: boletoLocal,
              problema: problemaRealLocal,
              descricao_problema: problemaRealLocal
                ? descricaoProblemaLocal
                : '',
              agendamento_user_id: statusLocal
                ? null
                : statusAgendamentoLocal
                ? getUserId()
                : null,
              desconto: descontoLocal,
            },
            index,
          );
        }
      }
    }

    function handleVerificaData(newDataPagamento) {
      if (refs.current[0].current) {
        refs.current[0].current.focus();
        refs.current[0].current.select();
      }
      const problemaReal = problema
        ? problema.id > 0
          ? problema
          : null
        : null;
      setOpen(false);
      const contaLocal = conta;
      const valorLocal = valor;
      const dataPagamentoLocal = dataPagamento;
      const statusLocal = status;
      const cartaoLocal = cartao;
      const faturaLocal = fatura[0];
      const boletoLocal = boleto;
      const problemaRealLocal = problemaReal;
      const descricaoProblemaLocal = descricaoProblema;
      const descontoLocal = desconto;
      const statusAgendamentoLocal = statusAgendamento;
      resetValues();
      handleNewItem(
        {
          conta: contaLocal,
          valor: valorLocal,
          data_pagamento: newDataPagamento || buildData(dataPagamentoLocal),
          status: statusLocal,
          cartao: null,
          isCartao: false,
          fatura: null,
          codigo_boleto: boletoLocal,
          problema: problemaRealLocal,
          descricao_problema: problemaRealLocal ? descricaoProblemaLocal : '',
          agendamento_user_id: statusLocal
            ? null
            : statusAgendamentoLocal
            ? getUserId()
            : null,
          desconto: descontoLocal,
        },
        index,
      );
    }

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          position = 1;
          break;
        case 1:
          if (isCartao) {
            position = 2;
          } else if (isProblemaSemData()) {
            position = 4;
          } else {
            position = 2;
          }
          break;
        case 2:
          position = isCartao ? 3 : 4;
          break;
        case 3:
          position = 4;
          break;
        case 4: {
          if (isCartao) {
            if (Number.isNaN(parseFloat(valor))) {
              //position = 4;
              position = 7;
              setValor(resto);
            } else {
              position = 7;
              //position = -1;
            }
            //position = 5;
          } else {
            // if (isProblemaSemData()) {
            //   position = 7;
            // } else {
            //   position = 5;
            // }
            position = 10;
            if (Number.isNaN(parseFloat(valor))) setValor(resto);
          }
          break;
        }
        case 10:
          if (isProblemaSemData()) {
            position = 7;
          } else {
            position = 5;
          }
          break;
        case 5:
          position = 7;
          break;
        // case 6:
        //   position = 7;
        //   break;
        case 8:
          position = 6;
          break;
        case 7:
          if (problema) {
            position = 8;
          } else {
            position = 6;
          }
          break;
        case 6:
          if (!isCartao && (!status || disabledDataPagamentoValue)) {
            position = 9;
          }
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position === -1) {
        if (refButton.current) {
          refButton.current.click();
        }
      } else if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    }

    const handleOnChangeCartao = (value) => {
      if (value) {
        setCartao(value);
        const faturaLocal = getFaturaAtual(value, buildData(dataPagamento));
        setFatura([faturaLocal]);
        setFaturasOptions(
          montaFaturasOptions(value, {
            mes: faturaLocal.mes,
            ano: faturaLocal.ano,
          }),
        );
      } else {
        setCartao([]);
        setFatura([]);
        setFaturasOptions([]);
      }
    };

    const handleOnChangeFatura = (value) => {
      if (value.length > 0) {
        if (value.length === 1) {
          setFatura([value[0]]);
        } else if (value.length === 2) {
          setFatura([value[1]]);
        }
      } else {
        setFatura([]);
      }
    };

    function montaFaturasOptions(cartao, fatura = null) {
      const options = [];

      const diaPagamento = cartao ? cartao.dia_pagamento : 1;

      let mesBackup = 0;
      let anoBackup = 0;

      if (fatura) {
        mesBackup = fatura.mes;
        anoBackup = fatura.ano;
      } else {
        const { mes, ano } = getFaturaAtual(cartao);
        mesBackup = mes;
        anoBackup = ano;
      }

      let mesIteracao = mesBackup;
      let anoIteracao = anoBackup;

      const faturasAntes = 3;
      const faturasDepois = 3;

      for (let i = 0; i < faturasAntes; i += 1) {
        const dataPagamentoFatura = new Date();
        const { mes, ano } = getMesAnterior(mesIteracao, anoIteracao);
        mesIteracao = mes;
        anoIteracao = ano;
        dataPagamentoFatura.setDate(diaPagamento);
        dataPagamentoFatura.setMonth(mesIteracao);
        dataPagamentoFatura.setFullYear(anoIteracao);
        const descricaoFatura = `${format(
          dataPagamentoFatura,
          "dd 'de' MMM 'de' yyyy",
          {
            locale: ptBR,
          },
        )}`;
        options.push({
          nome: descricaoFatura,
          ano: anoIteracao,
          mes: mesIteracao,
        });
      }

      options.reverse();

      mesIteracao = mesBackup;
      anoIteracao = anoBackup;

      const dataPagamentoFaturaSelecionada = new Date();
      dataPagamentoFaturaSelecionada.setDate(diaPagamento);
      dataPagamentoFaturaSelecionada.setMonth(mesIteracao);
      dataPagamentoFaturaSelecionada.setFullYear(anoIteracao);
      const descricaoFatura = `${format(
        dataPagamentoFaturaSelecionada,
        "dd 'de' MMM 'de' yyyy",
        {
          locale: ptBR,
        },
      )}`;
      options.push({
        nome: descricaoFatura,
        ano: anoIteracao,
        mes: mesIteracao,
      });

      for (let i = 0; i < faturasDepois; i += 1) {
        const dataPagamentoFatura = new Date();
        const { mes, ano } = getMesPosterior(mesIteracao, anoIteracao);
        mesIteracao = mes;
        anoIteracao = ano;
        dataPagamentoFatura.setDate(diaPagamento);
        dataPagamentoFatura.setMonth(mesIteracao);
        dataPagamentoFatura.setFullYear(anoIteracao);
        const descricaoFatura = `${format(
          dataPagamentoFatura,
          "dd 'de' MMM 'de' yyyy",
          {
            locale: ptBR,
          },
        )}`;
        options.push({
          nome: descricaoFatura,
          ano: anoIteracao,
          mes: mesIteracao,
        });
      }

      return options;
    }

    function getMesAnterior(mes, ano) {
      if (mes === 0) {
        return { mes: 11, ano: ano - 1 };
      }
      return { mes: mes - 1, ano };
    }

    function getMesPosterior(mes, ano) {
      if (mes === 11) {
        return { mes: 0, ano: ano + 1 };
      }
      return { mes: mes + 1, ano };
    }

    function getFaturaAtual(cartao, dataBase = new Date()) {
      const diaFechamento = cartao.dia_fechamento;
      const diaPagamento = cartao.dia_pagamento;

      const diaAtual = dataBase.getDate();
      const mesAtual = dataBase.getMonth();
      const anoAtual = dataBase.getFullYear();

      let infosFatura = null;

      if (diaFechamento < diaPagamento) {
        if (diaAtual > diaFechamento) {
          infosFatura = getMesPosterior(mesAtual, anoAtual);
        } else {
          infosFatura = { mes: mesAtual, ano: anoAtual };
        }
      } else if (diaAtual < diaFechamento) {
        infosFatura = getMesPosterior(mesAtual, anoAtual);
      } else {
        infosFatura = getMesPosterior(mesAtual, anoAtual);
        infosFatura = getMesPosterior(infosFatura.mes, infosFatura.ano);
      }
      infosFatura.nome = createDescricaoFatura(
        diaPagamento,
        infosFatura.mes,
        infosFatura.ano,
      );
      return infosFatura;
    }

    async function getFatura(cartao_credito_id, mes, ano) {
      try {
        const data = await getListAllAPI(
          'faturas',
          ['id', 'asc'],
          { cartao_credito_id, mes, ano },
          [],
        );
        if (data.data.length > 0) {
          return data.data[0].status;
        }
        throw 'Esta fatura não existe!';
      } catch (erros) {
        /* enqueueSnackbar(`${erros}`, {
      variant: 'error',
    }); */
        return true;
      }
    }

    function isProblemaSemData() {
      if (problema) {
        if (problema.id === 1) {
          return true;
        }
        return false;
      }
      return false;
    }

    function disabledDataPagamento() {
      if (isCartao) {
        return false;
      }
      if (isProblemaSemData()) {
        return true;
      }
      return false;
    }

    function disabledStatus() {
      if (isCartao) {
        return false;
      }
      if (isProblemaSemData()) {
        return true;
      }
      return false;
    }

    const disabledDataPagamentoValue = disabledDataPagamento();

    function handleKey() {}

    ///////////////////////

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          //fullWidth
          //maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
          fullScreen
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={isCloseX ? () => handleCloseDialog() : null}
            style={{
              backgroundColor: '#009FD4',
              color: 'white',
            }}
          >
            Nova Parcela de Compra
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <Paper elevation={3} style={{ opacity: '0.75' }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                padding={false ? '0px' : '15px'}
                overflow="auto"
              >
                <SwitchInput
                  name={inputs[0].nome}
                  ref={refs.current[0]}
                  handleEnter={() => handleNextInput(0)}
                  label={inputs[0].label}
                  handleKey={handleKey}
                  checked={isCartao}
                  onChange={(value) => {
                    if (value.target.checked) {
                      setProblema(null);
                    }
                    setIsCartao(value.target.checked);
                  }}
                />
                {isCartao ? (
                  <AutoCompleteLocal
                    name="cartao"
                    ref={refs.current[1]}
                    handleEnter={() => handleNextInput(1)}
                    label="Cartão"
                    handleKey={handleKey}
                    value={cartao}
                    onChangeLocal={(value) => handleOnChangeCartao(value)}
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                    campo="nome"
                    options={cartaoOptions}
                  />
                ) : (
                  <AutoCompleteLocal
                    name={inputs[1].nome}
                    ref={refs.current[1]}
                    handleEnter={() => handleNextInput(1)}
                    label={inputs[1].label}
                    handleKey={handleKey}
                    value={conta}
                    onChangeLocal={(value) => setConta(value)}
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                    campo="nome"
                    options={contasOptions}
                  />
                )}
                <DateInput
                  name={inputs[2].nome}
                  ref={refs.current[2]}
                  handleEnter={() => handleNextInput(2)}
                  label={inputs[2].label}
                  handleKey={handleKey}
                  // value={
                  //   !disabledDataPagamentoValue
                  //     ? dataPagamento
                  //     : moment(makeData2500()).format('YYYY-MM-DD')
                  // }
                  value={
                    !disabledDataPagamentoValue ? dataPagamento : new Date()
                  }
                  onChange={(value) => {
                    setDataPagamento(value.target.value);
                    if (cartao) {
                      const faturaLocal = getFaturaAtual(
                        cartao,
                        buildData(value.target.value),
                      );
                      setFatura([faturaLocal]);
                      setFaturasOptions(
                        montaFaturasOptions(cartao, {
                          mes: faturaLocal.mes,
                          ano: faturaLocal.ano,
                        }),
                      );
                    } else {
                      setFatura([]);
                      setFaturasOptions([]);
                    }
                  }}
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                  disabled={disabledDataPagamentoValue}
                />
                {isCartao && (
                  <AutoCompleteLocalCustomizado
                    name={inputs[3].nome}
                    ref={refs.current[3]}
                    handleEnter={() => handleNextInput(3)}
                    label={inputs[3].label}
                    handleKey={handleKey}
                    value={fatura}
                    disableClearable
                    onChangeLocal={(value) => handleOnChangeFatura(value)}
                    campo="nome"
                    options={faturasOptions}
                    variant="outlined"
                    renderTag={renderTagFatura}
                    renderOption={renderOptionFatura}
                    error={erros[3] !== ''}
                    helperText={erros[3]}
                    filterSelectedOptions={false}
                  />
                )}
                <PrecoInput
                  name={inputs[4].nome}
                  ref={refs.current[4]}
                  handleEnter={() => handleNextInput(4)}
                  label={inputs[4].label}
                  handleKey={handleKey}
                  value={Number.isNaN(valor) ? '' : String(valor)}
                  onChange={(value) => setValor(parseFloat(value.target.value))}
                  error={erros[4] !== ''}
                  helperText={erros[4]}
                />

                {!isCartao && (
                  <PrecoInput
                    name={inputs[10].nome}
                    ref={refs.current[10]}
                    handleEnter={() => handleNextInput(10)}
                    label={inputs[10].label}
                    handleKey={handleKey}
                    value={Number.isNaN(desconto) ? '' : String(desconto)}
                    onChange={(value) =>
                      setDesconto(parseFloat(value.target.value))
                    }
                    error={erros[9] !== ''}
                    helperText={erros[9]}
                  />
                )}

                <PrecoInput
                  name="valor_final"
                  handleEnter={() => {}}
                  label="Valor Líquido"
                  handleKey={() => {}}
                  value={
                    Number.isNaN(valor - desconto)
                      ? ''
                      : String(valor - desconto)
                  }
                  onChange={(value) => {}}
                  disabled
                />

                {!isCartao && (
                  <SwitchInput
                    name={inputs[5].nome}
                    ref={refs.current[5]}
                    handleEnter={() => handleNextInput(5)}
                    //label={inputs[5].label}
                    label={
                      status && !disabledDataPagamentoValue
                        ? inputs[5].label
                        : 'Não Pago'
                    }
                    handleKey={handleKey}
                    checked={!disabledDataPagamentoValue ? status : false}
                    onChange={(value) => setStatus(value.target.checked)}
                    error={erros[5] !== ''}
                    helperText={erros[5]}
                    disabled={disabledStatus()}
                  />
                )}

                <AutoCompleteLocal
                  name={inputs[7].nome}
                  ref={refs.current[7]}
                  handleEnter={() => handleNextInput(7)}
                  label={inputs[7].label}
                  handleKey={handleKey}
                  value={problema || Vazio}
                  //value={problema}
                  onChangeLocal={(value) => setProblema(value)}
                  error={erros[7] !== ''}
                  helperText={erros[7]}
                  campo="nome"
                  options={
                    isCartao
                      ? problemaOptions.filter((item) => item.id !== 1)
                      : problemaOptions
                  }
                />
                {problema && (
                  <TextInput
                    name={inputs[8].nome}
                    ref={refs.current[8]}
                    handleEnter={() => handleNextInput(8)}
                    label={inputs[8].label}
                    handleKey={handleKey}
                    value={descricaoProblema}
                    onChange={(value) =>
                      setDescricaoProblema(value.target.value)
                    }
                    error={erros[8] !== ''}
                    helperText={erros[8]}
                    fullWidth
                    multiline
                    maxRows={4}
                  />
                )}

                <TextInput
                  name={inputs[6].nome}
                  ref={refs.current[6]}
                  handleEnter={() => handleNextInput(6)}
                  label={inputs[6].label}
                  handleKey={handleKey}
                  value={isCartao ? '' : boleto}
                  onChange={(value) =>
                    setBoleto(value.target.value.replaceAll(/[^0-9]/g, ''))
                  }
                  error={erros[6] !== ''}
                  helperText={erros[6]}
                  multiline
                  maxRows={4}
                />

                {!isCartao && (!status || disabledDataPagamentoValue) && (
                  <SwitchInput
                    name={inputs[9].nome}
                    ref={refs.current[9]}
                    handleEnter={() => handleNextInput(9)}
                    //label={inputs[5].label}
                    label={statusAgendamento ? 'Agendado' : 'Não Agendado'}
                    handleKey={handleKey}
                    checked={statusAgendamento}
                    onChange={(value) =>
                      setStatusAgendamento(value.target.checked)
                    }
                  />
                )}
              </Box>
            </Paper>
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="outlined"
              color="primary"
            >
              {label1}
            </Button>
            <Button
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              ref={refButton}
              disabled={hasError()}
            >
              {label2}
            </Button>
          </DialogActions>
        </Dialog>
        <DialogoVerificaData
          ref={refDialogoVerificaData}
          handleData={handleVerificaData}
        />
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
