import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Relatorio from './Relatorio';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'black',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogoTrocoInicial = forwardRef(
  ({ dados, ordenacao, periodo, totais }, ref) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          scroll="paper"
          fullScreen
        >
          <DialogTitle id="form-dialog-title" onClose={handleClose}>
            Relatório Compras Fiscais
          </DialogTitle>
          <DialogContent dividers style={{ padding: 0, height: '100%' }}>
            <div style={{ height: '100%' }}>
              <Box
                display={{ xs: 'block', sm: 'flex' }}
                flexDirection="column"
                marginBottom="0px"
                height="100%"
              >
                <Relatorio
                  open={open}
                  dados={dados}
                  ordenacao={ordenacao}
                  periodo={periodo}
                  totais={totais}
                />
              </Box>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoTrocoInicial;
