import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { PrecoInput, DateInputBonito } from '../../../Components';
import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
  api,
  getManyAPI,
} from '../../../services';
import {
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  getNivelCaixaQtde,
  getLabelTipoUnidade,
  getQuantidadeCaixariaLabel,
  getTotalCaixaTipoOferta,
  getOptionsTipoUnidadeOferta,
  getLabelTipoUnidadeCaixaria,
  getTipoUnidade,
  getPrecoUnitarioVerdadeiroCaixa2,
  round10,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
} from '../../../utils';
import DialogoPrecificacaoNova from '../../JornaisDeOferta/DialogoPrecificacaoNovaOfertas';
import DialogoCreateEditOferta from '../DialogoCreateEditOferta';
import DialogoDeletarOferta from '../DialogoDeletarOferta';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#00796b',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TAMANHO_PEQUENO = 'sm';
const TAMANHO_GRANDE = 'lg';

function getDataFormatada(data2) {
  const data = new Date();
  data.setFullYear(2020);
  data.setMonth(2);
  data.setDate(10);
  data.setHours(5);
  data.setMinutes(30);
  data.setSeconds(0);
  data.setMilliseconds(0);

  data.setFullYear(data2.getFullYear());
  data.setDate(data2.getDate());
  data.setMonth(data2.getMonth());
  data.setDate(data2.getDate());

  return data;
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [produto, setProduto] = useState(null);
  const [valor, setValor] = useState(0);
  const [dataMinima, setDataMinima] = useState(new Date());
  const [dataMaxima, setDataMaxima] = useState(new Date());
  const [escalonadas, setEscalonadas] = useState([]);
  const [tipoUnidadeOferta, setTipoUnidadeUnidade] = useState(
    TIPO_UNIDADE_OFERTA_UN,
  );

  const classes = useStyles();
  const [tamanho, setTamanho] = useState(TAMANHO_PEQUENO);
  const [carregando, setCarregando] = useState(false);

  const refDialogoOferta = useRef(null);
  const refDialogoDeletarOferta = useRef(null);

  const refDialogoPrecificacaoLiberacao = useRef(null);
  const [dadosPrecificacao, setDadosPrecificacao] = useState(null);

  /////////////////////////////////////////

  const [config, setConfig] = useState(null);
  const [fornecedor, setFornecedor] = useState(null);
  const [dadosPrecificacaoAnterior, setDadosPrecificacaoAnterior] = useState(
    null,
  );
  const [quantidadeComprada, setQuantidadeComprada] = useState(0);
  const [
    valorNegociadoComAIndustria,
    setValorNegociadoComAIndustria,
  ] = useState(null);

  /////////////////////////////////////

  const handleChange = (event) => {
    setTipoUnidadeUnidade(event.target.value);
  };

  const handleCloseDialog = (status, idProduto = -1) => {
    setOpen(false);
    handleClose(status, idProduto);
  };

  function getPrecosOfertas(produto) {
    let precoOfertaJornal = 0;
    let precoOfertaZerarEstoque = 0;

    if (produto.ofertasProdutos.length > 0) {
      for (let i = 0; i < produto.ofertasProdutos.length; i++) {
        const oferta = produto.ofertasProdutos[i];
        if (oferta.tipo === TIPO_OFERTA_PRODUTO_JORNAL) {
          precoOfertaJornal = oferta.valor;
        } else if (oferta.tipo === TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE) {
          precoOfertaZerarEstoque = oferta.valor;
        }
      }
    }

    return {
      precoOfertaJornal,
      precoOfertaZerarEstoque,
    };
  }

  async function getProduto(id) {
    const data = await getManyAPI(
      'produtos/search/autocomplete',
      { id: [id], ofertasProdutos: true },
      ['dadosImposto', 'unidade'],
    );
    if (data.data.length > 0) {
      const produto = data.data[0];
      const { precoOfertaJornal, precoOfertaZerarEstoque } = getPrecosOfertas(
        produto,
      );
      return {
        ...produto,
        precoOfertaJornal,
      };
    }
    throw 'Este produto não existe!';
  }

  async function getConfig() {
    const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
      'cidade.estado',
    ]);
    if (config.data.length <= 0) {
      throw 'Configurações inacessíveis!';
    }
    return config.data[0];
  }

  async function getDadosPrecificacaoAnterior(produto_id) {
    const dados = await api.get(`/get_ultimo_lote_precificado/${produto_id}`);
    return dados.data;
  }

  async function reiniciar(produto) {
    try {
      setId(-1);
      setProduto(produto);
      setValor(0);
      setDataMinima(new Date());
      setDataMaxima(new Date());
      setTamanho(TAMANHO_PEQUENO);
      setEscalonadas([]);
      setTipoUnidadeUnidade(TIPO_UNIDADE_OFERTA_UN);
      const produto2 = await getProduto(produto.id);
      setProduto(produto2);
      setConfig(await getConfig());
      const {
        fornecedor,
        dadosPrecificacaoAnterior,
        quantidadeComprada,
        valorNegociadoComAIndustria,
      } = await getDadosPrecificacaoAnterior(produto.id);
      setFornecedor(fornecedor);
      setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
      setQuantidadeComprada(quantidadeComprada);
      setValorNegociadoComAIndustria(valorNegociadoComAIndustria);

      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'ofertas_zerar_estoque_produtos',
        ['id', 'asc'],
        { id: [id] },
        ['produto.unidade'],
      );
      if (data.data.length > 0) {
        setProduto(data.data[0].produto);
        setValor(data.data[0].valor);
        setDataMinima(new Date(data.data[0].data_minima));
        setDataMaxima(new Date(data.data[0].data_maxima));
        setTamanho(TAMANHO_PEQUENO);
        setEscalonadas(data.data[0].escalonadas);
        setTipoUnidadeUnidade(data.data[0].tipo_unidade);
        const produto = await getProduto(data.data[0].produto_id);
        setProduto(produto);
        setConfig(await getConfig());
        const {
          fornecedor,
          dadosPrecificacaoAnterior,
          quantidadeComprada,
          valorNegociadoComAIndustria,
        } = await getDadosPrecificacaoAnterior(produto.id);
        setFornecedor(fornecedor);
        setDadosPrecificacaoAnterior(dadosPrecificacaoAnterior);
        setQuantidadeComprada(quantidadeComprada);
        setValorNegociadoComAIndustria(valorNegociadoComAIndustria);

        setCarregando(false);
      } else {
        throw 'Esta Oferta de Zerar Estoque não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('ofertas_zerar_estoque_produtos', {
          cliente_id: null,
          produto_id: produto.id,
          data_minima: getDataFormatada(dataMinima),
          data_maxima: getDataFormatada(dataMaxima),
          user_id: getUserId(),
          valor,
          escalonadas,
          tipo_unidade: tipoUnidadeOferta,
          dadosPrecificacao,
        });
        setCarregando(false);
        enqueueSnackbar(`Oferta de Zerar Estoque com sucesso!`, {
          variant: 'success',
        });
      } else {
        await updateAPI('ofertas_zerar_estoque_produtos', id, {
          cliente_id: null,
          produto_id: produto.id,
          data_minima: getDataFormatada(dataMinima),
          data_maxima: getDataFormatada(dataMaxima),
          user_id: getUserId(),
          valor,
          escalonadas,
          tipo_unidade: tipoUnidadeOferta,
          dadosPrecificacao,
        });
        setCarregando(false);
        enqueueSnackbar(`Oferta de Zerar Estoque atualizada com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog(0, produto.id);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(produto = null) {
      setCarregando(true);
      reiniciar(produto);
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  function validateProduto() {
    let error = '';
    if (!produto) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getEscalonadaValorMaior() {
    const index = escalonadas.findIndex((el) => el.valor > valor);
    return index;
  }

  function getUnidadeMedida(produto) {
    if (produto) {
      if (produto.unidade.tipo === POR_UNIDADE) {
        return 'Un';
      }
      return 'Kg';
    }

    return '';
  }

  const unidadeMedia = getUnidadeMedida(produto);

  function validateValor() {
    let error = '';
    if (valor <= 0 || Number.isNaN(valor)) {
      error = 'Este campo é obrigatório';
    } else if (getEscalonadaValorMaior() !== -1) {
      error = 'Este valor não pode ser menor que as escalonadas!';
    } else if (produto) {
      if (valor >= produto.preco_venda) {
        error = `Este valor não pode ser maior ou igual que o preço original de venda (${formatMoeda(
          produto.preco_venda,
        )} / ${unidadeMedia})!`;
      } else if (produto.precoOfertaJornal) {
        if (valor >= produto.precoOfertaJornal) {
          error = `Este valor não pode ser maior ou igual que o Preço da Oferta de Jornal(${formatMoeda(
            produto.precoOfertaJornal,
          )} / ${unidadeMedia})!`;
        }
      } else {
        const porcentagem = (valor / produto.preco_venda) * 100;
        if (porcentagem < 5) {
          error =
            'O preço da Oferta de Zerar Estoque não pode ser menos que 5% do Preço de Venda';
        }
      }
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateProduto();
    errosOld[1] = validateValor();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const nomeProduto = produto ? produto.nome : '';

  function editarOferta(id, valor, quantidade) {
    if (refDialogoOferta.current) {
      refDialogoOferta.current.handleEdit(id, valor, quantidade);
    }
  }

  function recalcularIds(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i += 1) {
      newArray.push({
        ...arrayOld[i],
        id: i + 1,
      });
    }
    return newArray;
  }

  function deletarOferta(id, valor, quantidade) {
    if (refDialogoDeletarOferta.current) {
      refDialogoDeletarOferta.current.handleDelete(id, valor, quantidade);
    }
  }

  function handleDeletarOferta(id) {
    const newArray = escalonadas.slice().filter((item) => item.id !== id);
    setEscalonadas(recalcularIds(newArray));
  }

  function criarOferta() {
    if (refDialogoOferta.current) {
      refDialogoOferta.current.handleCreate();
    }
  }

  function handleSave(id, valor, quantidade) {
    const newArray = escalonadas.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          valor,
          quantidade,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: escalonadas.length + 1,
        valor,
        quantidade,
      });
    }
    setEscalonadas(recalcularIds(newArray));
  }

  const optionsTipoUnidadeOferta = getOptionsTipoUnidadeOferta(produto);

  const labelTipoUnidadeCaixaria = getLabelTipoUnidadeCaixaria(
    tipoUnidadeOferta,
    produto,
  );

  function nextDay(x) {
    const now = new Date();
    now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7));
    return now;
  }

  function setProximoDomingo() {
    const nextData = nextDay(0);
    if (
      new Date(moment(dataMinima).startOf('day').format()).getTime() <
      new Date(moment(nextData).endOf('day').format()).getTime()
    ) {
      setDataMaxima(nextData);
    }
  }

  const labelTipoUnidade = getLabelTipoUnidade(tipoUnidadeOferta);

  function editarPrecificacao() {
    if (refDialogoPrecificacaoLiberacao.current) {
      refDialogoPrecificacaoLiberacao.current.handleOpen(true);
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth={tamanho}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={() => handleCloseDialog(0)}
          style={{ backgroundColor: '#00796b', color: 'white' }}
        >
          {id <= 0
            ? `Nova Oferta de Zerar Estoque`
            : `Editar Oferta de Zerar Estoque`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '20px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {produto && (
                  <>
                    <div
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <h2
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {nomeProduto}
                      </h2>
                    </div>
                    <div>
                      <div style={{ marginTop: '15px' }}>
                        <PrecoInput
                          name="precoVenda"
                          handleEnter={() => {}}
                          label={`Preço de Venda / ${unidadeMedia}`}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(produto.preco_venda)
                              ? ''
                              : String(produto.preco_venda)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                      </div>
                      <div style={{ marginTop: '0px' }}>
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                          }}
                        >
                          <PrecoInput
                            name="precoOferta"
                            handleEnter={() => {}}
                            label={`Preço oferta / ${unidadeMedia}`}
                            handleKey={() => {}}
                            value={Number.isNaN(valor) ? '' : String(valor)}
                            onChange={(value) => {
                              setValor(parseFloat(value.target.value));
                            }}
                            error={erros[1] !== ''}
                            helperText={erros[1]}
                            fullWidth
                            variant="standard"
                            autoFocus
                            disabled
                          />
                          <Tooltip
                            title="Editar Preço"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="editar"
                              size="small"
                              onClick={() => editarPrecificacao()}
                              disabled={valorNegociadoComAIndustria <= 0}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <DateInputBonito
                          showTodayButton
                          todayLabel="Hoje"
                          name="dataMinima"
                          handleEnter={() => {}}
                          label="Data início da oferta"
                          handleKey={() => {}}
                          value={dataMinima}
                          onChange={(value) => setDataMinima(value)}
                          format="dd MMMM yyyy"
                          fullWidth
                          variant="standard"
                          maxDate={dataMaxima}
                        />
                      </div>
                      <div style={{ marginTop: '15px', display: 'flex' }}>
                        <DateInputBonito
                          showTodayButton
                          todayLabel="Hoje"
                          name="dataLimite"
                          handleEnter={() => {}}
                          label="Data limite da oferta"
                          handleKey={() => {}}
                          value={dataMaxima}
                          onChange={(value) => setDataMaxima(value)}
                          format="dd MMMM yyyy"
                          fullWidth
                          variant="standard"
                          minDate={
                            new Date().getTime() > dataMinima.getTime()
                              ? new Date()
                              : dataMinima
                          }
                        />
                        <Tooltip
                          title="próximo domingo"
                          placement="bottom"
                          aria-label="add2"
                        >
                          <IconButton
                            aria-label="editar"
                            size="small"
                            onClick={() => setProximoDomingo()}
                          >
                            <AlarmIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        margin: '24px 0px',
                      }}
                    >
                      <Button
                        onClick={() =>
                          tamanho === TAMANHO_PEQUENO
                            ? setTamanho(TAMANHO_GRANDE)
                            : setTamanho(TAMANHO_PEQUENO)
                        }
                        style={{
                          borderRadius: '25px',
                          fontWeight: 'bold',
                        }}
                        disabled
                      >
                        {tamanho === TAMANHO_PEQUENO ? (
                          <>
                            <div>Mais Detalhes</div>
                            <ChevronRightIcon />
                          </>
                        ) : (
                          <>
                            <div>Menos Detalhes</div>
                            <ChevronLeftIcon />
                          </>
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {tamanho === TAMANHO_GRANDE && (
                <div style={{ flex: 1, display: 'flex', marginLeft: '25px' }}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      backgroundColor: '#00796b',
                    }}
                  />
                  <div style={{ flex: 1, marginLeft: '25px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Ofertas Escaladas
                      </Typography>
                      <IconButton
                        aria-label="check"
                        onClick={() => criarOferta()}
                      >
                        <AddCircleOutlineIcon style={{ color: '#00796b' }} />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          fontSize: '18px',
                          marginRight: '10px',
                        }}
                      >
                        Tipo Unidade:
                      </Typography>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipoUnidadeOferta}
                        onChange={handleChange}
                      >
                        {optionsTipoUnidadeOferta.map(({ label, value }) => (
                          <MenuItem value={value}>{label}</MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Box mt="2em" />
                    <div
                      style={{
                        marginTop: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        style={{
                          borderRadius: '25px',
                        }}
                      >
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>{`Quantidade (${labelTipoUnidade})`}</StyledTableCell>
                              <StyledTableCell>{`Preço Unitário / ${getTipoUnidade(
                                produto,
                              )}`}</StyledTableCell>
                              {labelTipoUnidadeCaixaria !== '' && (
                                <StyledTableCell>{`Preço ${labelTipoUnidadeCaixaria}`}</StyledTableCell>
                              )}
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {escalonadas
                              .sort((a, b) => a.quantidade - b.quantidade)
                              .map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell>
                                    {row.quantidade}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {formatMoeda(row.valor)}
                                  </StyledTableCell>
                                  {labelTipoUnidadeCaixaria !== '' && (
                                    <StyledTableCell>{`${formatMoeda(
                                      getTotalCaixaTipoOferta(
                                        produto,
                                        row.valor,
                                        tipoUnidadeOferta,
                                      ),
                                    )}`}</StyledTableCell>
                                  )}
                                  <StyledTableCell align="right">
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          editarOferta(
                                            row.id,
                                            row.valor,
                                            row.quantidade,
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          deletarOferta(
                                            row.id,
                                            row.valor,
                                            row.quantidade,
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => handleCloseDialog(id)}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
        <DialogoDeletarOferta
          ref={refDialogoDeletarOferta}
          handleClose={() => {}}
          handleDelete={handleDeletarOferta}
        />
        <DialogoCreateEditOferta
          ref={refDialogoOferta}
          handleClose={() => {}}
          handleSave={handleSave}
          valorPrincipal={valor}
          escalonadas={escalonadas}
        />
        <DialogoPrecificacaoNova
          ref={refDialogoPrecificacaoLiberacao}
          quantidadeComprada={quantidadeComprada}
          produto={produto}
          fornecedor={fornecedor}
          config={config}
          dadosPrecificacaoAnterior={dadosPrecificacaoAnterior}
          valorNegociadoComAIndustria={valorNegociadoComAIndustria}
          handleClose={(dados, isMargemCartao) => {
            if (dados) {
              setDadosPrecificacao(dados);
              setValor(round10(dados.preco_de_venda, -2));
            }
          }}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
