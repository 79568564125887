import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  DateInput,
  TextInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  CepInput,
  IEInput,
  AutoCompleteRemoto,
  IntegerInput,
  PrecoInput,
  SelectInput,
  SwitchInput,
} from '../..';
import DialogoCreateEditContato from '../../../Resources/RepresentantesFornecedores/DialogoCreateEdit/DialogoCreateEditContato';
import DialogoDeletarContato from '../../../Resources/RepresentantesFornecedores/DialogoCreateEdit/DialogoDeletarContato';
import {
  createAPI,
  updateAPI,
  getListAllAPI,
  getUserId,
  api,
} from '../../../services';
import {
  formatTelefone,
  formatCNPJ,
  formatCPF,
  SECONDARY_COLOR,
  REGIME_TRIBUTARIO_MEI,
  REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
  REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
  REGIME_TRIBUTARIO_LUCRO_REAL,
  REGIME_TRIBUTARIO_PRODUTOR_RURAL,
  REGIME_TRIBUTARIO_SEM_NFE,
} from '../../../utils';
import DialogoSenha from '../DialogoTransacao/DialogoSenha';
import DialogoCreateEditConta from './DialogoCreateEditConta';
import DialogoCreateEditFuncionario from './DialogoCreateEditFuncionario';
import DialogoCreateEditPix from './DialogoCreateEditPix';
import DialogoCreateEditPrazoParcela from './DialogoCreateEditPrazoParcela';
import DialogoDeletarConta from './DialogoDeletarConta';
import DialogoDeletarFuncionario from './DialogoDeletarFuncionario';
import DialogoDeletarPix from './DialogoDeletarPix';
import DialogoDeletarPrazoParcela from './DialogoDeletarPrazoParcela';
import DicaPix from './DicaPix';
import {
  getLabelTipo,
  getValorFormatado,
  getLabelTipoContato,
  getValorFormatadoContato,
} from './utils';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';
const DESBLOQUEAR_EDICAO_TIPO_FORNECEDOR = 'F9';

function validateEmail2(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

const optionsRegimeTributario = [
  {
    value: REGIME_TRIBUTARIO_PRODUTOR_RURAL,
    label: 'Produtor Rural',
  },
  {
    value: REGIME_TRIBUTARIO_MEI,
    label: 'MEI (Micro Empreendedor Individual)',
  },
  {
    value: REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
    label: 'SIMPLES Nacional',
  },
  {
    value: REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
    label: 'Lucro Presumido',
  },
  {
    value: REGIME_TRIBUTARIO_LUCRO_REAL,
    label: 'Lucro Real',
  },
  {
    value: REGIME_TRIBUTARIO_SEM_NFE,
    label: 'Sem NFE',
  },
];

function getRegimeTributario(value) {
  switch (value) {
    case REGIME_TRIBUTARIO_PRODUTOR_RURAL:
      return optionsRegimeTributario[0].value;
    case REGIME_TRIBUTARIO_MEI:
      return optionsRegimeTributario[1].value;
    case REGIME_TRIBUTARIO_SIMPLES_NACIONAL:
      return optionsRegimeTributario[2].value;
    case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO:
      return optionsRegimeTributario[3].value;
    case REGIME_TRIBUTARIO_LUCRO_REAL:
      return optionsRegimeTributario[4].value;
    case REGIME_TRIBUTARIO_SEM_NFE:
      return optionsRegimeTributario[5].value;
    default:
      return null;
  }
}

/////////////////////////

const optionsTipoFornecedor = [
  {
    value: 1,
    label: 'Fornecedor de Mercadoria',
  },
  {
    value: 3,
    label: 'Fornecedor de Serviços',
  },
  {
    value: 2,
    label: 'Fornecedor de Insumos',
  },
  {
    value: 4,
    label: 'Fornecedor Qualquer',
  },
];

/////////////////////////

const NOME_POSITION = 0;
const CPF_POSITION = 1;
const TELEFONE_POSITION = 2;
const EMAIL_POSITION = 3;
const ANIVERSARIO_POSITION = 4;
const RG_POSITION = 5;
const RAZAO_SOCIAL_POSITION = 6;
const NOME_FANTASIA_POSITION = 7;
const CNPJ_POSITION = 8;
const IE_POSITION = 9;
const ESTADO_POSITION = 10;
const CIDADE_POSITION = 11;
const BAIRRO_POSITION = 12;
const LOGRADOURO_POSITION = 13;
const NUMERO_POSITION = 14;
const CEP_POSITION = 15;
const TEMPO_ENTREGA_POSITION = 16;
//const PIX_ALEATORIA_POSITION = 16;
const LIMITE_POSITION = 17;
const DESCRICAO_AVARIA_POSITION = 18;
const FRETE_VALOR_POR_TONELADA_POSITION = 19;

const DEFAULT_ID = -1;

function getTipo(type) {
  switch (type) {
    case 'conta_corrente':
      return 'Conta Corrente';
    case 'conta_poupanca':
      return 'Conta Poupança';
    case 'conta_pagamento':
      return 'Conta Pagamento';
    default:
      return '';
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

async function getPermissaoAbaComprador() {
  try {
    const response = await api.get(`/obter-cargo-usuario/${getUserId()}`);
    const { status, cargo } = response.data;
    if (status) {
      return cargo.sistema_aba_comprador_no_fornecedor ? true : false;
    }
    return false;
  } catch (e) {
    return false;
  }
}

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [rg, setRg] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [aniversario, setAniversario] = useState('');
  const [cidade, setCidade] = useState(null);
  const [tempoEntrega, setTempoEntrega] = useState(1);
  const [pixAleatoria, setPixAleatoria] = useState('');

  const [isPixEmail, setIsPixEmail] = useState(false);
  const [isPixTelefone, setIsPixTelefone] = useState(false);
  const [isPixCpf, setIsPixCpf] = useState(false);
  const [isPixCnpj, setIsPixCnpj] = useState(false);
  const [isPixAleatoria, setIsPixAleatoria] = useState(false);

  const [contas, setContas] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  const [dadosPix, setDadosPix] = useState([]);
  const [prazosParcelas, setPrazosParcelas] = useState([]);
  const [limite, setLimite] = useState(0);

  const [freteValorPorTonelada, setFreteValorPorTonelada] = useState(0);

  const [descricaoAvaria, setDescricaoAvaria] = useState('');

  const [estado, setEstado] = useState(null);

  const [regimeTributario, setRegimeTributario] = useState(
    optionsRegimeTributario[4].value,
  );

  const [isComprador, setIsComprador] = useState(false);

  const refDialogoContas = useRef(null);
  const refDialogoDeletarConta = useRef(null);

  const refDialogoFuncionario = useRef(null);
  const refDialogoDeletarFuncionario = useRef(null);

  const refDialogoPix = useRef(null);
  const refDialogoDeletarPix = useRef(null);

  const refDialogoPrazoParcela = useRef(null);
  const refDialogoDeletarPrazoParcela = useRef(null);
  const refDialogoSenha = useRef(null);

  const refs = useRef([]);
  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = React.useState(0);
  const [tipo, setTipo] = React.useState(1);
  const [isChangeTipo, setIsChangeTipo] = React.useState(false);

  const [contatos, setContatos] = useState([]);

  const [pagaDescarrego, setPagaDescarrego] = React.useState(false);
  const [pegarBoletoNaNfe, setPegarBoletoNaNfe] = React.useState(false);

  const refDialogoContato = useRef(null);
  const refDialogoDeletarContato = useRef(null);

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const inputs = [
    {
      nome: 'nome',
      defaultValue: '', //0
      label: 'Nome',
    },
    {
      nome: 'cpf',
      defaultValue: '', //1
      label: 'CPF',
    },
    {
      nome: 'telefone',
      defaultValue: '', //2
      label: 'Telefone',
    },
    {
      nome: 'bairro',
      defaultValue: '', //3
      label: 'Bairro',
    },
    {
      nome: 'logradouro',
      defaultValue: '', //4
      label: 'Logradouro',
    },
    {
      nome: 'numero',
      defaultValue: '', //5
      label: 'Número',
    },
    {
      nome: 'rg',
      defaultValue: '', //6
      label: 'RG',
    },
    {
      nome: 'razao_social',
      defaultValue: '', //7
      label: 'Razão Social',
    },
    {
      nome: 'nome_fantasia',
      defaultValue: '', //8
      label: 'Nome Fantasia',
    },
    {
      nome: 'cnpj',
      defaultValue: '', //9
      label: 'CNPJ',
    },
    {
      nome: 'email',
      defaultValue: '', //10
      label: 'Email',
    },
    {
      nome: 'cep',
      defaultValue: '', //11
      label: 'CEP',
    },
    {
      nome: 'aniversario',
      defaultValue: '', //12
      label: 'Aniversário',
    },
    {
      nome: 'cidade',
      defaultValue: null,
      label: 'Cidade', //13
      resource: 'cidades',
      nested: ['estado'],
      filters: { ativo: true },
    },
    {
      nome: 'ie',
      defaultValue: '', //14
      label: 'Inscrição Estadual',
    },
    {
      nome: 'tempo_entrega',
      defaultValue: 0, //15
      label: 'Tempo Entrega',
    },
    {
      nome: 'pix_aleatoria',
      defaultValue: '', //16
      label: 'Pix Chave Aleatória',
    },
    {
      nome: 'limite',
      defaultValue: '', //17
      label: 'Limite',
    },
    {
      nome: 'descricao-avaria',
      defaultValue: '', //18
      label: 'Em caso de Avaria, o que Fazer?',
    },
    {
      nome: 'frete_valor_por_tonelada',
      defaultValue: 0, //19
      label: 'Valor Descarrego por Tonelada',
    },
    {
      nome: 'estado',
      defaultValue: null,
      label: 'Estado de Compra', //20
      resource: 'estados',
      nested: [],
      filters: {},
    },
    {
      nome: 'regime_tributario',
      defaultValue: optionsRegimeTributario[4].value, //21
      label: 'Regime Tributário',
    },
  ];

  const fabs = [
    {
      key: 'bancos',
      value: 1,
      onClick: criarConta,
    },
    {
      key: 'funcionarios',
      value: 2,
      onClick: criarFuncionario,
    },
    {
      key: 'pix',
      value: 3,
      onClick: criarPix,
    },
    {
      key: 'prazos',
      value: 4,
      onClick: criarPrazoParcela,
    },
  ];

  refs.current = inputs.map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateCpf() {
    let error = '';
    if (cpf) {
      if (cpf.length < 11) error = 'O CPF deve ter 11 dígitos';
    }
    return error;
  }

  function validateTelefone() {
    let error = '';
    if (telefone) {
      if (telefone.length < 10) {
        error = 'O Telefone deve ter 10 ou 11 dígitos';
      }
    }
    return error;
  }

  function validateBairro() {
    let error = '';
    if (bairro) {
      if (bairro.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateLogradouro() {
    let error = '';
    if (logradouro) {
      if (logradouro.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateNumero() {
    let error = '';
    if (numero) {
      if (numero.length > 6) {
        error = 'Máximo de 6 letras';
      }
    }
    return error;
  }

  function validateRg() {
    let error = '';
    if (rg) {
      if (rg.length > 20) {
        error = 'Número de caracteres maior que o permitido (máximo 20)';
      }
    }
    return error;
  }

  function validateRazaoSocial() {
    let error = '';
    if (razaoSocial) {
      if (razaoSocial.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateNomeFantasia() {
    let error = '';
    if (nomeFantasia) {
      if (nomeFantasia.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateCnpj() {
    let error = '';
    if (cnpj) {
      if (cnpj.length < 14) error = 'O CNPJ deve ter 14 dígitos';
    }
    return error;
  }

  function validateEmail() {
    let error = '';
    if (email) {
      if (!validateEmail2(email)) error = 'Email Inválido';
      if (email.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
    }
    return error;
  }

  function validateCep() {
    let error = '';
    if (cep) {
      if (cep.length < 8) error = 'O CEP deve ter 8 dígitos';
    }
    return error;
  }

  function validateAniversario() {
    const error = '';
    return error;
  }

  function validateCidade() {
    const error = '';
    return error;
  }

  function validateIE() {
    let error = '';
    if (ie) {
      if (ie.length >= 20)
        error = 'A Inscrição Estadual deve ser menor que 20 dígitos';
    }
    return error;
  }

  function validateTempoEntrega() {
    let error = '';
    if (tempoEntrega <= 0 || Number.isNaN(tempoEntrega)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateLimite() {
    let error = '';
    if (limite < 0 || Number.isNaN(limite)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateDescricaoAvaria() {
    let error = '';
    if (descricaoAvaria) {
      if (descricaoAvaria.length > 500)
        error = 'Este campo deve ter no máximo 500 caracteres.';
    }
    return error;
  }

  function validateFreteValorPorTonelada() {
    let error = '';
    if (freteValorPorTonelada < 0 || Number.isNaN(freteValorPorTonelada)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateEstado() {
    let error = '';
    if (!estado) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateTipoRecorrencia() {
    let error = '';
    if (!regimeTributario) error = 'Este campo é obrigatório';
    return error;
  }

  function validatePrazosParcelas() {
    let error = '';
    if (prazosParcelas.length <= 0) error = 'Este campo é obrigatório';
    return error;
  }

  function validateFuncionarios() {
    let error = '';
    if (funcionarios.length <= 0) error = 'Este campo é obrigatório';
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    errosOld[0] = validateNome();
    errosOld[1] = validateCpf();
    errosOld[2] = validateTelefone();
    errosOld[3] = validateBairro();
    errosOld[4] = validateLogradouro();
    errosOld[5] = validateNumero();
    errosOld[6] = validateRg();
    errosOld[7] = validateRazaoSocial();
    errosOld[8] = validateNomeFantasia();
    errosOld[9] = validateCnpj();
    errosOld[10] = validateEmail();
    errosOld[11] = validateCep();
    errosOld[12] = validateAniversario();
    errosOld[13] = validateCidade();
    errosOld[14] = validateIE();
    errosOld[15] = validateTempoEntrega();
    errosOld[17] = validateLimite();
    errosOld[18] = validateDescricaoAvaria();
    errosOld[19] = validateFreteValorPorTonelada();
    errosOld[20] = validateEstado();
    errosOld[21] = validateTipoRecorrencia();
    errosOld[22] = validatePrazosParcelas();
    errosOld[23] = validateFuncionarios();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function decompoeData(data) {
    if (data === null || data === '') {
      return {
        dia_aniversario: null,
        mes_aniversario: null,
        ano_aniversario: null,
      };
    }
    const dataAniversarioObj = new Date(
      moment(new Date(data)).add(1, 'days').format(),
    );
    const dia_aniversario = dataAniversarioObj.getDate();
    const mes_aniversario = dataAniversarioObj.getMonth();
    const ano_aniversario = dataAniversarioObj.getFullYear();
    return {
      dia_aniversario,
      mes_aniversario,
      ano_aniversario,
    };
  }

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome(inputs[0].defaultValue);
    setCpf(inputs[1].defaultValue);
    setTelefone(inputs[2].defaultValue);
    setBairro(inputs[3].defaultValue);
    setLogradouro(inputs[4].defaultValue);
    setNumero(inputs[5].defaultValue);
    setRg(inputs[6].defaultValue);
    setRazaoSocial(inputs[7].defaultValue);
    setNomeFantasia(inputs[8].defaultValue);
    setCnpj(inputs[9].defaultValue);
    setEmail(inputs[10].defaultValue);
    setCep(inputs[11].defaultValue);
    setAniversario(inputs[12].defaultValue);
    setCidade(inputs[13].defaultValue);
    setIe(inputs[14].defaultValue);
    setTempoEntrega(inputs[15].defaultValue);
    setIsPixEmail(false);
    setIsPixTelefone(false);
    setIsPixCpf(false);
    setIsPixCnpj(false);
    setIsPixAleatoria(false);
    setPixAleatoria('');
    setContas([]);
    setFuncionarios([]);
    setDadosPix([]);
    setPrazosParcelas([]);
    setLimite(0);
    setDescricaoAvaria('');
    setFreteValorPorTonelada(0);
    setEstado(inputs[20].defaultValue);
    setRegimeTributario(optionsRegimeTributario[3].value);
    setValue(0);
    //setTipo(1);
    setCarregando(false);

    setContatos([]);

    setPegarBoletoNaNfe(false);

    setPagaDescarrego(false);

    setIsComprador(await getPermissaoAbaComprador());
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'fornecedores_todos_tipos',
        ['id', 'asc'],
        { id: [id] },
        [
          'cidade.estado',
          'dadosBancarios.banco',
          'funcionarios.funcao',
          'dadosPix',
          'prazosFornecedores',
          'estado',
          'contatos',
        ],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCpf(data.data[0].cpf);
        setTelefone(data.data[0].telefone);
        setBairro(data.data[0].bairro);
        setLogradouro(data.data[0].logradouro);
        setNumero(data.data[0].numero);
        setRg(data.data[0].rg);
        setRazaoSocial(data.data[0].razao_social);
        setNomeFantasia(data.data[0].nome_fantasia);
        setCnpj(data.data[0].cnpj);
        setEmail(data.data[0].email);
        setCep(data.data[0].cep);
        setAniversario(
          moment(new Date(data.data[0].dataAniversario)).format('YYYY-MM-DD'),
        );
        setCidade(data.data[0].cidade);
        setIe(data.data[0].inscricao_estadual);
        setTempoEntrega(data.data[0].tempo_entrega);
        setIsPixEmail(!!data.data[0].is_pix_email);
        setIsPixTelefone(!!data.data[0].is_pix_telefone);
        setIsPixCpf(!!data.data[0].is_pix_cpf);
        setIsPixCnpj(!!data.data[0].is_pix_cnpj);
        setIsPixAleatoria(!!data.data[0].is_pix_aleatoria);
        setPixAleatoria(data.data[0].pix_aleatoria);
        setContas(data.data[0].dadosBancarios);
        setFuncionarios(data.data[0].funcionarios);
        setDadosPix(data.data[0].dadosPix);
        setPrazosParcelas(data.data[0].prazosFornecedores);
        setLimite(data.data[0].limite);
        setDescricaoAvaria(data.data[0].o_que_fazer_avaria);
        setTipo(data.data[0].tipo);
        setFreteValorPorTonelada(data.data[0].frete_valor_por_tonelada);
        setEstado(data.data[0].estado);
        setRegimeTributario(
          getRegimeTributario(data.data[0].regime_tributario),
        );
        // setValue(0);
        setCarregando(false);
        setIsComprador(await getPermissaoAbaComprador());

        setPegarBoletoNaNfe(!!data.data[0].pegar_boleto_na_nfe);

        setPagaDescarrego(!!data.data[0].paga_descarrego);

        setContatos(data.data[0].contatos ? data.data[0].contatos : []);
      } else {
        throw 'Este fornecedor não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  function formatDados(dados) {
    return dados.map(({ banco, ...rest }) => ({
      ...rest,
      banco_fornecedor_id: banco.id,
    }));
  }

  function formatDadosFuncionarios(dados) {
    return dados.map(({ funcao, ...rest }) => ({
      ...rest,
      funcao_funcionario_fornecedor_id: funcao.id,
    }));
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const fornecedorCriado = await createAPI('fornecedores', {
            nome,
            cpf,
            telefone,
            bairro,
            logradouro,
            numero,
            rg,
            razao_social: razaoSocial,
            nome_fantasia: nomeFantasia,
            cnpj,
            email,
            cep,
            cidade_id: cidade ? cidade.id : null,
            tempo_entrega: tempoEntrega,
            is_pix_email: isPixEmail,
            is_pix_telefone: isPixTelefone,
            is_pix_cpf: isPixCpf,
            is_pix_cnpj: isPixCnpj,
            is_pix_aleatoria: isPixAleatoria,
            pix_aleatoria: pixAleatoria,
            contas: formatDados(contas),
            funcionarios: formatDadosFuncionarios(funcionarios),
            dadosPix,
            prazosParcelas,
            limite,
            o_que_fazer_avaria: descricaoAvaria,
            inscricao_estadual: ie,
            tipo,
            frete_valor_por_tonelada: freteValorPorTonelada,
            estado_id: estado ? estado.id : null,
            regime_tributario: regimeTributario ? regimeTributario : null,
            ...decompoeData(aniversario),
            contatos,
            paga_descarrego: pagaDescarrego,
            pegar_boleto_na_nfe: pegarBoletoNaNfe,
          });
          setCarregando(false);
          enqueueSnackbar('Fornecedor criado com sucesso!', {
            variant: 'success',
          });
          idLocal = fornecedorCriado.data ? fornecedorCriado.data.id : -1;
        } else {
          await updateAPI('fornecedores', id, {
            nome,
            cpf,
            telefone,
            bairro,
            logradouro,
            numero,
            rg,
            razao_social: razaoSocial,
            nome_fantasia: nomeFantasia,
            cnpj,
            email,
            cep,
            cidade_id: cidade ? cidade.id : null,
            tempo_entrega: tempoEntrega,
            is_pix_email: isPixEmail,
            is_pix_telefone: isPixTelefone,
            is_pix_cpf: isPixCpf,
            is_pix_cnpj: isPixCnpj,
            is_pix_aleatoria: isPixAleatoria,
            pix_aleatoria: pixAleatoria,
            contas: formatDados(contas),
            funcionarios: formatDadosFuncionarios(funcionarios),
            dadosPix,
            prazosParcelas,
            limite,
            o_que_fazer_avaria: descricaoAvaria,
            inscricao_estadual: ie,
            tipo,
            frete_valor_por_tonelada: freteValorPorTonelada,
            estado_id: estado ? estado.id : null,
            regime_tributario: regimeTributario ? regimeTributario : null,
            ...decompoeData(aniversario),
            contatos,
            paga_descarrego: pagaDescarrego,
            pegar_boleto_na_nfe: pegarBoletoNaNfe,
          });
          setCarregando(false);
          enqueueSnackbar('Fornecedor atualizado com sucesso!', {
            variant: 'success',
          });
          idLocal = id;
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(tipo = 1) {
      setOpen(true);
      setTipo(tipo);
      setIsChangeTipo(false);
      setCarregando(true);
      reiniciar();
    },
    handleEdit(id, value = 0) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      setValue(value);
      getDataResource(id);
      setIsChangeTipo(false);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      case DESBLOQUEAR_EDICAO_TIPO_FORNECEDOR:
        if (!isChangeTipo) {
          if (refDialogoSenha.current) {
            refDialogoSenha.current.handleOpen();
          }
        }
        break;
      default:
        break;
    }
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        position = 6;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      case 8:
        position = 9;
        break;
      case 9:
        position = 10;
        break;
      case 10:
        position = 11;
        break;
      case 11:
        position = 12;
        break;
      case 12:
        position = 13;
        break;
      case 13:
        position = 14;
        break;
      case 14:
        position = 15;
        break;
      case 15:
        position = 16;
        break;
      case 16:
        position = 17;
        break;
      case 17:
        position = 18;
        break;
      case 18:
        position = 19;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function criarConta() {
    if (refDialogoContas.current) {
      refDialogoContas.current.handleCreate();
    }
  }

  function criarFuncionario() {
    if (refDialogoFuncionario.current) {
      refDialogoFuncionario.current.handleCreate();
    }
  }

  function criarPix() {
    if (refDialogoPix.current) {
      refDialogoPix.current.handleCreate();
    }
  }

  function criarPrazoParcela() {
    if (refDialogoPrazoParcela.current) {
      refDialogoPrazoParcela.current.handleCreate();
    }
  }

  function editarConta(id, titular, banco, conta, agencia, tipo, cpf, cnpj) {
    if (refDialogoContas.current) {
      refDialogoContas.current.handleEdit(
        id,
        titular,
        banco,
        conta,
        agencia,
        tipo,
        cpf,
        cnpj,
      );
    }
  }

  function editarFuncionario(id, nome, funcao, telefone) {
    if (refDialogoFuncionario.current) {
      refDialogoFuncionario.current.handleEdit(id, nome, funcao, telefone);
    }
  }

  function editarPix(id, valor, tipo) {
    if (refDialogoPix.current) {
      refDialogoPix.current.handleEdit(id, valor, tipo);
    }
  }

  function editarPrazoParcela(id, dias) {
    if (refDialogoPrazoParcela.current) {
      refDialogoPrazoParcela.current.handleEdit(id, dias);
    }
  }

  function handleSave(id, titular, banco, conta, agencia, tipo, cpf, cnpj) {
    const newArray = contas.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          titular,
          banco,
          conta,
          agencia,
          tipo,
          cpf,
          cnpj,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: contas.length + 1,
        titular,
        banco,
        conta,
        agencia,
        tipo,
        cpf,
        cnpj,
      });
    }
    setContas(recalcularIds(newArray));
  }

  function handleSaveFuncionario(id, nome, funcao, telefone) {
    const newArray = funcionarios.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          nome,
          funcao,
          telefone,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: funcionarios.length + 1,
        nome,
        funcao,
        telefone,
      });
    }
    setFuncionarios(recalcularIds(newArray));
  }

  function handleSavePix(id, valor, tipo) {
    const newArray = dadosPix.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          valor,
          tipo,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: dadosPix.length + 1,
        valor,
        tipo,
      });
    }
    setDadosPix(recalcularIds(newArray));
  }

  function handleSavePrazoParcela(id, dias) {
    const index = prazosParcelas.find((item) => item.dias === dias);
    if (index === undefined) {
      const newArray = prazosParcelas.slice().map((item) => {
        if (item.id === id) {
          return {
            id,
            dias,
          };
        }
        return item;
      });
      if (id < 0) {
        newArray.push({
          id: prazosParcelas.length + 1,
          dias,
        });
      }
      setPrazosParcelas(recalcularIds(newArray));
      enqueueSnackbar('Prazo de parcela registrado com sucesso!', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(
        'Uma prazo com esse número de dias já foi registrado para este mesmo fornecedor!',
        {
          variant: 'error',
        },
      );
    }
  }

  function recalcularIds(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i += 1) {
      newArray.push({
        ...arrayOld[i],
        id: i + 1,
      });
    }
    return newArray;
  }

  function deletarConta(id, titular, banco, conta, agencia, tipo, cpf, cnpj) {
    if (refDialogoDeletarConta.current) {
      refDialogoDeletarConta.current.handleDelete(
        id,
        titular,
        banco,
        conta,
        agencia,
        tipo,
        cpf,
        cnpj,
      );
    }
  }

  function handleDeletarConta(id) {
    const newArray = contas.slice().filter((item) => item.id !== id);
    setContas(recalcularIds(newArray));
  }

  function deletarFuncionario(id, nome, funcao, telefone) {
    if (refDialogoDeletarFuncionario.current) {
      refDialogoDeletarFuncionario.current.handleDelete(
        id,
        nome,
        funcao,
        telefone,
      );
    }
  }

  function handleDeletarFuncionarios(id) {
    const newArray = funcionarios.slice().filter((item) => item.id !== id);
    setFuncionarios(recalcularIds(newArray));
  }

  function deletarPix(id, valor, tipo) {
    if (refDialogoDeletarPix.current) {
      refDialogoDeletarPix.current.handleDelete(id, valor, tipo);
    }
  }

  function handleDeletarPix(id) {
    const newArray = dadosPix.slice().filter((item) => item.id !== id);
    setDadosPix(recalcularIds(newArray));
  }

  function deletarPrazoParcela(id, dias) {
    if (refDialogoDeletarPrazoParcela.current) {
      refDialogoDeletarPrazoParcela.current.handleDelete(id, dias);
    }
  }

  function handleDeletarPrazoParcela(id) {
    const newArray = prazosParcelas.slice().filter((item) => item.id !== id);
    setPrazosParcelas(recalcularIds(newArray));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getLabelTipoFornecedor() {
    switch (tipo) {
      case 1:
        return 'Fornecedor Mercadorias';
      case 2:
        return 'Fornecedor Insumos';
      case 3:
        return 'Fornecedor Serviços';
      case 4:
        return 'Outro Contato';
      default:
        return '';
    }
  }

  const label = getLabelTipoFornecedor();

  const userId = getUserId();

  ////////////////////////

  function criarContato() {
    if (refDialogoContato.current) {
      refDialogoContato.current.handleCreate();
    }
  }

  function editarContato(id, valor, tipo) {
    if (refDialogoContato.current) {
      refDialogoContato.current.handleEdit(id, valor, tipo);
    }
  }

  function handleSaveContato(id, valor, tipo) {
    const newArray = contatos.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          valor,
          tipo,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: contatos.length + 1,
        valor,
        tipo,
      });
    }
    setContatos(recalcularIds(newArray));
  }

  function recalcularIds2(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i += 1) {
      newArray.push({
        ...arrayOld[i],
        id: i + 1,
      });
    }
    return newArray;
  }

  function deletarContato(id, valor, tipo) {
    if (refDialogoDeletarContato.current) {
      refDialogoDeletarContato.current.handleDelete(id, valor, tipo);
    }
  }

  function handleDeletarContato(id) {
    const newArray = contatos.slice().filter((item) => item.id !== id);
    setContatos(recalcularIds2(newArray));
  }

  ////////////////////////

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {id > 0 ? `Editar ${label}` : `Novo ${label}`}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                {!isChangeTipo && (
                  <div
                    style={{
                      margin: '10px 20px',
                    }}
                  >{`Editar Tipo Fornecedor (${DESBLOQUEAR_EDICAO_TIPO_FORNECEDOR})`}</div>
                )}

                {funcionarios.length <= 0 && (
                  <div
                    style={{
                      margin: '10px 20px',
                      color: 'red',
                    }}
                  >
                    É necessário registrar ao menos 1 Funcionário!
                  </div>
                )}

                {prazosParcelas.length <= 0 && (
                  <div
                    style={{
                      margin: '10px 20px',
                      color: 'red',
                    }}
                  >
                    É necessário registrar ao menos 1 Prazo!
                  </div>
                )}

                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Contas" {...a11yProps(1)} />
                  <Tab label="Funcionários" {...a11yProps(2)} />
                  <Tab label="Pix" {...a11yProps(3)} />
                  <Tab label="Prazos" {...a11yProps(4)} />
                  {isComprador ? (
                    <Tab label="Contatos" {...a11yProps(5)} />
                  ) : null}
                </Tabs>
                {/* sdsdsd */}

                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box mt="1em" />
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ color: SECONDARY_COLOR }}
                      >
                        Dados Pessoais
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[0].nome}
                            ref={refs.current[NOME_POSITION]}
                            handleEnter={() => handleNextInput(NOME_POSITION)}
                            label={inputs[0].label}
                            handleKey={handleKey}
                            value={nome}
                            onChange={(value) => setNome(value.target.value)}
                            error={erros[0] !== ''}
                            helperText={erros[0]}
                            fullWidth
                            autoFocus
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" display="flex">
                          <CpfInput
                            name={inputs[1].nome}
                            ref={refs.current[CPF_POSITION]}
                            handleEnter={() => handleNextInput(CPF_POSITION)}
                            label={inputs[1].label}
                            handleKey={handleKey}
                            value={cpf}
                            onChange={(value) => setCpf(value.target.value)}
                            error={erros[1] !== ''}
                            helperText={erros[1]}
                            fullWidth
                          />
                          {/* <Box ml="5px" mt="30px">
                            <DicaPix
                              title="Pix CPF"
                              value={isPixCpf}
                              onChange={setIsPixCpf}
                            />
                          </Box> */}
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em" display="flex">
                          <TelefoneInput
                            name={inputs[2].nome}
                            ref={refs.current[TELEFONE_POSITION]}
                            handleEnter={() =>
                              handleNextInput(TELEFONE_POSITION)
                            }
                            label={inputs[2].label}
                            handleKey={handleKey}
                            value={telefone}
                            onChange={(value) =>
                              setTelefone(value.target.value)
                            }
                            error={erros[2] !== ''}
                            helperText={erros[2]}
                            fullWidth
                          />
                          {/* <Box ml="5px" mt="30px">
                            <DicaPix
                              title="Pix Telefone"
                              value={isPixTelefone}
                              onChange={setIsPixTelefone}
                            />
                          </Box> */}
                        </Box>
                        <Box flex={1} ml="0.5em" display="flex">
                          <TextInput
                            name={inputs[10].nome}
                            ref={refs.current[EMAIL_POSITION]}
                            handleEnter={() => handleNextInput(EMAIL_POSITION)}
                            label={inputs[10].label}
                            handleKey={handleKey}
                            value={email}
                            onChange={(value) => setEmail(value.target.value)}
                            error={erros[10] !== ''}
                            helperText={erros[10]}
                            fullWidth
                          />
                          {/* <Box ml="5px" mt="30px">
                            <DicaPix
                              title="Pix Email"
                              value={isPixEmail}
                              onChange={setIsPixEmail}
                            />
                          </Box> */}
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <DateInput
                            name={inputs[12].nome}
                            ref={refs.current[ANIVERSARIO_POSITION]}
                            handleEnter={() =>
                              handleNextInput(ANIVERSARIO_POSITION)
                            }
                            label={inputs[12].label}
                            handleKey={handleKey}
                            value={aniversario}
                            onChange={(value) =>
                              setAniversario(value.target.value)
                            }
                            error={erros[12] !== ''}
                            helperText={erros[12]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[6].nome}
                            ref={refs.current[RG_POSITION]}
                            handleEnter={() => handleNextInput(RG_POSITION)}
                            label={inputs[6].label}
                            handleKey={handleKey}
                            value={rg}
                            onChange={(value) => setRg(value.target.value)}
                            error={erros[6] !== ''}
                            helperText={erros[6]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box mt="1em" />

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ color: SECONDARY_COLOR }}
                      >
                        Dados Empresariais
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[7].nome}
                            ref={refs.current[RAZAO_SOCIAL_POSITION]}
                            handleEnter={() =>
                              handleNextInput(RAZAO_SOCIAL_POSITION)
                            }
                            label={inputs[7].label}
                            handleKey={handleKey}
                            value={razaoSocial}
                            onChange={(value) =>
                              setRazaoSocial(value.target.value)
                            }
                            error={erros[7] !== ''}
                            helperText={erros[7]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[8].nome}
                            ref={refs.current[NOME_FANTASIA_POSITION]}
                            handleEnter={() =>
                              handleNextInput(NOME_FANTASIA_POSITION)
                            }
                            label={inputs[8].label}
                            handleKey={handleKey}
                            value={nomeFantasia}
                            onChange={(value) =>
                              setNomeFantasia(value.target.value)
                            }
                            error={erros[8] !== ''}
                            helperText={erros[8]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em" display="flex">
                          <CnpjInput
                            name={inputs[9].nome}
                            ref={refs.current[CNPJ_POSITION]}
                            handleEnter={() => handleNextInput(CNPJ_POSITION)}
                            label={inputs[9].label}
                            handleKey={handleKey}
                            value={cnpj}
                            onChange={(value) => setCnpj(value.target.value)}
                            error={erros[9] !== ''}
                            helperText={erros[9]}
                            fullWidth
                          />
                          {/* <Box ml="5px" mt="30px">
                            <DicaPix
                              title="Pix CNPJ"
                              value={isPixCnpj}
                              onChange={setIsPixCnpj}
                            />
                          </Box> */}
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <IEInput
                            name={inputs[14].nome}
                            ref={refs.current[IE_POSITION]}
                            handleEnter={() => handleNextInput(IE_POSITION)}
                            label={inputs[14].label}
                            handleKey={handleKey}
                            value={ie}
                            onChange={(value) => setIe(value.target.value)}
                            error={erros[14] !== ''}
                            helperText={erros[14]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em" display="flex">
                          <AutoCompleteRemoto
                            name={inputs[20].nome}
                            ref={refs.current[20]}
                            handleEnter={() => handleNextInput(ESTADO_POSITION)}
                            resource={inputs[20].resource}
                            nested={inputs[20].nested}
                            filters={inputs[20].filters}
                            label={inputs[20].label}
                            handleKey={handleKey}
                            value={estado}
                            onChange={(value) => setEstado(value)}
                            error={erros[20] !== ''}
                            helperText={erros[20]}
                            toString={false}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>
                      <Box mt="1em" />
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ color: SECONDARY_COLOR }}
                      >
                        Endereço
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <AutoCompleteRemoto
                            name={inputs[13].nome}
                            ref={refs.current[CIDADE_POSITION]}
                            handleEnter={() => handleNextInput(CIDADE_POSITION)}
                            resource={inputs[13].resource}
                            nested={inputs[13].nested}
                            filters={inputs[13].filters}
                            label={inputs[13].label}
                            handleKey={handleKey}
                            value={cidade}
                            onChange={(value) => setCidade(value)}
                            error={erros[13] !== ''}
                            helperText={erros[13]}
                            toString={false}
                            fullWidth
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) =>
                              `${option.nome} - ${option.estado.sigla}`
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[3].nome}
                            ref={refs.current[BAIRRO_POSITION]}
                            handleEnter={() => handleNextInput(BAIRRO_POSITION)}
                            label={inputs[3].label}
                            handleKey={handleKey}
                            value={bairro}
                            onChange={(value) => setBairro(value.target.value)}
                            error={erros[3] !== ''}
                            helperText={erros[3]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[4].nome}
                            ref={refs.current[LOGRADOURO_POSITION]}
                            handleEnter={() =>
                              handleNextInput(LOGRADOURO_POSITION)
                            }
                            label={inputs[4].label}
                            handleKey={handleKey}
                            value={logradouro}
                            onChange={(value) =>
                              setLogradouro(value.target.value)
                            }
                            error={erros[4] !== ''}
                            helperText={erros[4]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[5].nome}
                            ref={refs.current[NUMERO_POSITION]}
                            handleEnter={() => handleNextInput(NUMERO_POSITION)}
                            label={inputs[5].label}
                            handleKey={handleKey}
                            value={numero}
                            onChange={(value) => setNumero(value.target.value)}
                            error={erros[5] !== ''}
                            helperText={erros[5]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CepInput
                            name={inputs[11].nome}
                            ref={refs.current[CEP_POSITION]}
                            handleEnter={() => handleNextInput(CEP_POSITION)}
                            label={inputs[11].label}
                            handleKey={handleKey}
                            value={cep}
                            onChange={(value) => setCep(value.target.value)}
                            error={erros[11] !== ''}
                            helperText={erros[11]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>
                      <Box mt="1em" />
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ color: SECONDARY_COLOR }}
                      >
                        Dados Logísticos
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <IntegerInput
                            name={inputs[15].nome}
                            ref={refs.current[TEMPO_ENTREGA_POSITION]}
                            handleEnter={() =>
                              handleNextInput(TEMPO_ENTREGA_POSITION)
                            }
                            label={inputs[15].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(tempoEntrega)
                                ? ''
                                : String(tempoEntrega)
                            }
                            onChange={(value) =>
                              setTempoEntrega(parseInt(value.target.value, 10))
                            }
                            error={erros[15] !== ''}
                            helperText={erros[15]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" display="flex">
                          <PrecoInput
                            name={inputs[17].nome}
                            ref={refs.current[LIMITE_POSITION]}
                            handleEnter={() => handleNextInput(LIMITE_POSITION)}
                            label={inputs[17].label}
                            handleKey={handleKey}
                            value={Number.isNaN(limite) ? '' : String(limite)}
                            onChange={(value) =>
                              setLimite(parseFloat(value.target.value))
                            }
                            error={erros[17] !== ''}
                            helperText={erros[17]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <SwitchInput
                            name="paga_descarrego"
                            handleEnter={() => {}}
                            label="Paga Descarrego?"
                            handleKey={handleKey}
                            checked={pagaDescarrego}
                            onChange={(value) =>
                              setPagaDescarrego(value.target.checked)
                            }
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" display="flex">
                          <SwitchInput
                            name="pegar_boleto_na_nfe"
                            handleEnter={() => {}}
                            label="Pegar Boleto na Nfe?"
                            handleKey={handleKey}
                            checked={pegarBoletoNaNfe}
                            onChange={(value) =>
                              setPegarBoletoNaNfe(value.target.checked)
                            }
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[18].nome}
                            ref={refs.current[DESCRICAO_AVARIA_POSITION]}
                            handleEnter={() =>
                              handleNextInput(DESCRICAO_AVARIA_POSITION)
                            }
                            label={inputs[18].label}
                            handleKey={handleKey}
                            value={descricaoAvaria}
                            onChange={(value) =>
                              setDescricaoAvaria(value.target.value)
                            }
                            error={erros[18] !== ''}
                            helperText={erros[18]}
                            fullWidth
                            multiline
                            rows={4}
                            disabled={userId !== 1 && userId !== 2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" display="flex">
                          <PrecoInput
                            name={inputs[19].nome}
                            ref={
                              refs.current[FRETE_VALOR_POR_TONELADA_POSITION]
                            }
                            handleEnter={() =>
                              handleNextInput(FRETE_VALOR_POR_TONELADA_POSITION)
                            }
                            label={inputs[19].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(freteValorPorTonelada)
                                ? ''
                                : String(freteValorPorTonelada)
                            }
                            onChange={(value) =>
                              setFreteValorPorTonelada(
                                parseFloat(value.target.value),
                              )
                            }
                            error={erros[19] !== ''}
                            helperText={erros[19]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[18].nome}
                            ref={refs.current[DESCRICAO_AVARIA_POSITION]}
                            handleEnter={() =>
                              handleNextInput(DESCRICAO_AVARIA_POSITION)
                            }
                            label={inputs[18].label}
                            handleKey={handleKey}
                            value={descricaoAvaria}
                            onChange={(value) =>
                              setDescricaoAvaria(value.target.value)
                            }
                            error={erros[18] !== ''}
                            helperText={erros[18]}
                            fullWidth
                            multiline
                            rows={4}
                            disabled={userId !== 1 && userId !== 2}
                          />
                        </Box>
                        <Box
                          flex={1}
                          ml="0.5em"
                          display="flex"
                          style={{
                            marginTop: '16px',
                          }}
                        >
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel id="input-semana">
                              Regime Tributário
                            </InputLabel>
                            <SelectInput
                              labelId="input-semana"
                              name="regime_tributario"
                              handleEnter={() => {}}
                              handleKey={() => {}}
                              value={regimeTributario}
                              onChange={(value) =>
                                setRegimeTributario(value.target.value)
                              }
                              options={optionsRegimeTributario}
                              fullWidth
                              label="Dia da Semana"
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      {id > 0 ? (
                        <Box display="flex">
                          <Box
                            flex={1}
                            mr="0.5em"
                            display="flex"
                            style={{
                              marginTop: '16px',
                            }}
                          >
                            <FormControl
                              className={classes.formControl}
                              variant="outlined"
                              fullWidth
                            >
                              <InputLabel id="input-semana">
                                Tipo de Fornecedor
                              </InputLabel>
                              <SelectInput
                                labelId="input-semana"
                                name="tipo"
                                handleEnter={() => {}}
                                handleKey={() => {}}
                                value={tipo}
                                onChange={(value) =>
                                  setTipo(value.target.value)
                                }
                                options={optionsTipoFornecedor}
                                fullWidth
                                label="Tipo de Fornecedor"
                                disabled={!isChangeTipo}
                              />
                            </FormControl>
                          </Box>
                          <Box flex={1} ml="0.5em" />
                        </Box>
                      ) : null}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box mt="1em" />

                      {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Dados Bancários
                        </Typography>
                        <Button
                          onClick={() => criarConta()}
                          variant="contained"
                          color="primary"
                        >
                          Adicionar
                        </Button>
                      </div>
                      <Box mt="1em" /> */}

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Titular</StyledTableCell>
                              <StyledTableCell>Banco</StyledTableCell>
                              <StyledTableCell>Conta</StyledTableCell>
                              <StyledTableCell>Agência</StyledTableCell>
                              <StyledTableCell>Tipo</StyledTableCell>
                              <StyledTableCell>CPF</StyledTableCell>
                              <StyledTableCell>CNPJ</StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                              {/* <StyledTableCell align="right">
                                Deletar
                              </StyledTableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {contas.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.titular}</StyledTableCell>
                                <StyledTableCell>
                                  {row.banco.nome}
                                </StyledTableCell>
                                <StyledTableCell>{row.conta}</StyledTableCell>
                                <StyledTableCell>{row.agencia}</StyledTableCell>
                                <StyledTableCell>
                                  {getTipo(row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {formatCPF(row.cpf)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {formatCNPJ(row.cnpj)}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        editarConta(
                                          row.id,
                                          row.titular,
                                          row.banco,
                                          row.conta,
                                          row.agencia,
                                          row.tipo,
                                          row.cpf,
                                          row.cnpj,
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        deletarConta(
                                          row.id,
                                          row.titular,
                                          row.banco,
                                          row.conta,
                                          row.agencia,
                                          row.tipo,
                                          row.cpf,
                                          row.cnpj,
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="2em" />
                      {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Funcionários Fornecedor
                        </Typography>
                        <Button
                          onClick={() => criarFuncionario()}
                          variant="contained"
                          color="primary"
                        >
                          Adicionar
                        </Button>
                      </div>
                      <Box mt="2em" /> */}
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Nome</StyledTableCell>
                              <StyledTableCell>Função</StyledTableCell>
                              <StyledTableCell>Telefone</StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {funcionarios.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.nome}</StyledTableCell>
                                <StyledTableCell>
                                  {row.funcao.nome}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {formatTelefone(row.telefone)}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        editarFuncionario(
                                          row.id,
                                          row.nome,
                                          row.funcao,
                                          row.telefone,
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        deletarFuncionario(
                                          row.id,
                                          row.nome,
                                          row.funcao,
                                          row.telefone,
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    {/* ssdsds */}
                    <TabPanel value={value} index={3}>
                      <Box mt="2em" />
                      {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Chaves Pix
                        </Typography>
                        <Button
                          onClick={() => criarPix()}
                          variant="contained"
                          color="primary"
                        >
                          Adicionar
                        </Button>
                      </div>
                      <Box mt="2em" /> */}
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Tipo</StyledTableCell>
                              <StyledTableCell>Chave</StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dadosPix.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {getLabelTipo(row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {getValorFormatado(row.valor, row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        editarPix(row.id, row.valor, row.tipo)
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        deletarPix(row.id, row.valor, row.tipo)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <Box mt="2em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Dias</StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {prazosParcelas
                              .sort((a, b) => a.dias - b.dias)
                              .map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell>{row.dias}</StyledTableCell>
                                  <StyledTableCell align="right">
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          editarPrazoParcela(row.id, row.dias)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          deletarPrazoParcela(row.id, row.dias)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>

                    {isComprador ? (
                      <TabPanel value={value} index={5}>
                        <Box mt="2em" />
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Tipo</StyledTableCell>
                                <StyledTableCell>Contato</StyledTableCell>
                                <StyledTableCell align="right">
                                  Ações
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {contatos.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell>
                                    {getLabelTipoContato(row.tipo)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {getValorFormatadoContato(
                                      row.valor,
                                      row.tipo,
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          editarContato(
                                            row.id,
                                            row.valor,
                                            row.tipo,
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="editar"
                                        size="small"
                                        onClick={() =>
                                          deletarContato(
                                            row.id,
                                            row.valor,
                                            row.tipo,
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    ) : null}
                    <Zoom
                      key="contatos"
                      in={value === 5}
                      timeout={transitionDuration}
                      style={{
                        transitionDelay: `${
                          value === 5 ? transitionDuration.exit : 0
                        }ms`,
                      }}
                      unmountOnExit
                    >
                      <Fab
                        aria-label="Adicionar"
                        className={classes.fab}
                        color="primary"
                        onClick={criarContato}
                      >
                        <AddIcon />
                      </Fab>
                    </Zoom>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {fabs.map((fab, index) => (
            <Zoom
              key={fab.key}
              in={value === fab.value}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  value === index ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Fab
                aria-label="Adicionar"
                className={classes.fab}
                color="primary"
                onClick={fab.onClick}
              >
                <AddIcon />
              </Fab>
            </Zoom>
          ))}
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleSalvar();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            {id > 0 ? 'Salvar (F8)' : 'Cadastrar (F8)'}
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
      <DialogoCreateEditConta
        ref={refDialogoContas}
        handleClose={() => {}}
        handleSave={handleSave}
      />
      <DialogoDeletarConta
        ref={refDialogoDeletarConta}
        handleClose={() => {}}
        handleDelete={handleDeletarConta}
      />
      <DialogoCreateEditFuncionario
        ref={refDialogoFuncionario}
        handleClose={() => {}}
        handleSave={handleSaveFuncionario}
      />
      <DialogoDeletarFuncionario
        ref={refDialogoDeletarFuncionario}
        handleClose={() => {}}
        handleDelete={handleDeletarFuncionarios}
      />
      {/* /// */}
      <DialogoCreateEditPix
        ref={refDialogoPix}
        handleClose={() => {}}
        handleSave={handleSavePix}
      />
      <DialogoDeletarPix
        ref={refDialogoDeletarPix}
        handleClose={() => {}}
        handleDelete={handleDeletarPix}
      />
      {/* /// */}
      <DialogoCreateEditPrazoParcela
        ref={refDialogoPrazoParcela}
        handleClose={() => {}}
        handleSave={handleSavePrazoParcela}
      />
      <DialogoDeletarPrazoParcela
        ref={refDialogoDeletarPrazoParcela}
        handleClose={() => {}}
        handleDelete={handleDeletarPrazoParcela}
      />
      <DialogoSenha
        ref={refDialogoSenha}
        handleCancelar={() => {}}
        handleSalvar={() => {
          setIsChangeTipo(true);
        }}
        title="Autorização mudar Tipo de Fornecedor"
        enqueueSnackbar={enqueueSnackbar}
        mensagemSucesso="Mudança do Tipo de Fornecedor feita com sucesso!!"
      />
      <DialogoCreateEditContato
        ref={refDialogoContato}
        handleClose={() => {}}
        handleSave={handleSaveContato}
      />
      <DialogoDeletarContato
        ref={refDialogoDeletarContato}
        handleClose={() => {}}
        handleDelete={handleDeletarContato}
      />
    </Dialog>
  );
});

export default SidebarInputs;
