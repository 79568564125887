import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  BooleanInput,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';

import {
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  STATUS_ATIVO,
} from '../../utils';

export const choicesTurno = [
  { id: TURNO_MANHA, name: 'Manhã' },
  { id: TURNO_NOITE, name: 'Noite' },
  { id: TURNO_12_HORAS, name: '12H' },
];

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Descrição" source="nome" />
    <BooleanInput label="Em uso" source="usado" />
    <BooleanInput label="Travado para Uso" source="is_travado" />
    <SelectInput
      label="Turno"
      source="turno"
      choices={choicesTurno}
      allowEmpty={false}
    />
    <BooleanInput label="Funcionário Interno" source="is_funcionario_interno" />
    <ReferenceInput
      label="Função"
      source="funcao_colaborador_id"
      reference="funcoes_colaboradores"
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Grupo"
      source="grupo_colaborador_id"
      reference="grupos_colaborador"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={30}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    {/* <ReferenceInput
      fullWidth
      label="Loja"
      source="empresa_id"
      reference="empresa-loja"
      sort={{ field: 'nome_fantasia', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome_fantasia: `${searchText}` })}
      perPage={30}
    >
      <AutocompleteInput optionText="nome_fantasia" />
    </ReferenceInput> */}
  </FilterAdmin>
);

export default Filter;
