import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  PesoInput,
  PrecoInput,
  DialogoProduto2 as DialogoProduto,
  PorcentagemInput,
  DateInput,
  SelectInput,
  SwitchInput,
} from '../../../Components';
import DialogoGrafico from '../../../Components/Dialogos/DialogoCompra/Pages/InicioFrenteCaixaCompra/DialogoGrafico';
import TabelaPrecos from '../../../Components/Dialogos/DialogoCompra/Pages/InicioFrenteCaixaCompra/DialogoPrecificacaoNova/TabelaPrecos';
import { updateAPI, getListAllAPI } from '../../../services';
import {
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_FIXO,
  ceil10,
  round10,
  REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
  REGIME_TRIBUTARIO_LUCRO_REAL,
  REGIME_TRIBUTARIO_MEI,
  REGIME_TRIBUTARIO_PRODUTOR_RURAL,
  REGIME_TRIBUTARIO_SEM_NFE,
  REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
  getCustoFinal,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}))(TableCell);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

const BACKGROUND_PREVISAO_LUCRO = '#92d050';
const BACKGROUND_PRECO_VENDA = '#FFF176';
const BACKGROUND_CREDITO_COMPRA = '#e2efd9';
const BACKGROUND_DEBITO_VENDA = '#fef2cb';
const BACKGROUND_ANALISE_FLUXO_CAIXA = '#fbe4d5';
const BACKGROUND_CUSTOS_ADICIONAIS = '#d6dce4';

const DialogoBoletos = forwardRef(
  (
    {
      handleClose,
      quantidadeComprada,
      produto,
      fornecedor,
      config,
      dadosPrecificacaoAnterior,
      valorNegociadoComAIndustria,
    },
    ref,
  ) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const [margemDeLucro, setMargemDeLucro] = useState(0);
    const [isMargemCartao, setIsMargemCartao] = useState(true);

    const refDialogoGrafico = useRef(null);

    const { enqueueSnackbar } = useSnackbar();

    useImperativeHandle(ref, () => ({
      handleOpen(isMargemCartao = true) {
        setOpen(true);
        setMargemDeLucro(produto ? produto.taxa_de_lucro : 0);
        setIsMargemCartao(!isMargemCartao);
      },
    }));

    const handleCloseDialog = (dadosRetorno = null, isMargemCartao) => {
      setOpen(false);
      handleClose(dadosRetorno, !isMargemCartao);
    };

    /*function getDadosImpostosComputado(repasseDeSt) {
      if (fornecedor && produto && config) {
        const isSt = repasseDeSt > 0;
        switch (fornecedor.regime_tributario) {
          case REGIME_TRIBUTARIO_PRODUTOR_RURAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado:
                config.cidade.estado.icms_ai_compra,
              pisCompraPorcentagemComputado: 0,
              pisVendaPorcentagemComputado: produto.dadosImposto[0].pis,
              cofinsCompraPorcentagemComputado: 0,
              cofinsVendaPorcentagemComputado: produto.dadosImposto[0].cofins,
              isFronteira: false,
              isAntecipacao: false,
            };
          }
          case REGIME_TRIBUTARIO_MEI: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado: !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: produto.dadosImposto[0].pis,
              pisVendaPorcentagemComputado: produto.dadosImposto[0].pis,
              cofinsCompraPorcentagemComputado: produto.dadosImposto[0].cofins,
              cofinsVendaPorcentagemComputado: produto.dadosImposto[0].cofins,
              isFronteira: !isSt,
              isAntecipacao: !isSt,
            };
          }
          case REGIME_TRIBUTARIO_SIMPLES_NACIONAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado: !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: produto.dadosImposto[0].pis,
              pisVendaPorcentagemComputado: produto.dadosImposto[0].pis,
              cofinsCompraPorcentagemComputado: produto.dadosImposto[0].cofins,
              cofinsVendaPorcentagemComputado: produto.dadosImposto[0].cofins,
              isFronteira: !isSt,
              isAntecipacao: !isSt,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO: {
            return {
              aliquotaInternaCompraPorcentagemComputado: !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,
              aliquotaInternaVendaPorcentagemComputado: !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: produto.dadosImposto[0].pis,
              pisVendaPorcentagemComputado: produto.dadosImposto[0].pis,
              cofinsCompraPorcentagemComputado: produto.dadosImposto[0].cofins,
              cofinsVendaPorcentagemComputado: produto.dadosImposto[0].cofins,
              isFronteira: !isSt,
              isAntecipacao: !isSt,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_REAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,
              aliquotaInternaVendaPorcentagemComputado: !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: produto.dadosImposto[0].pis,
              pisVendaPorcentagemComputado: produto.dadosImposto[0].pis,
              cofinsCompraPorcentagemComputado: produto.dadosImposto[0].cofins,
              cofinsVendaPorcentagemComputado: produto.dadosImposto[0].cofins,
              isFronteira: !isSt,
              isAntecipacao: !isSt,
            };
          }
          case REGIME_TRIBUTARIO_SEM_NFE: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado: 0,
              pisCompraPorcentagemComputado: 0,
              pisVendaPorcentagemComputado: 0,
              cofinsCompraPorcentagemComputado: 0,
              cofinsVendaPorcentagemComputado: 0,
              isFronteira: false,
              isAntecipacao: false,
            };
          }
          default: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado: 0,
              pisCompraPorcentagemComputado: 0,
              pisVendaPorcentagemComputado: 0,
              cofinsCompraPorcentagemComputado: 0,
              cofinsVendaPorcentagemComputado: 0,
            };
          }
        }
      } else {
        return {
          aliquotaInternaCompraPorcentagemComputado: 0,
          aliquotaInternaVendaPorcentagemComputado: 0,
          pisCompraPorcentagemComputado: 0,
          pisVendaPorcentagemComputado: 0,
          cofinsCompraPorcentagemComputado: 0,
          cofinsVendaPorcentagemComputado: 0,
        };
      }
    }*/

    function getDadosEspeciais(produto) {
      return {
        pis_credito: produto.dadosImposto[0].pis_credito,
        is_caso_especial_pis_credito:
          produto.dadosImposto[0].is_caso_especial_pis_credito,
        pis_debito: produto.dadosImposto[0].pis_debito,
        is_caso_especial_pis_debito:
          produto.dadosImposto[0].is_caso_especial_pis_debito,
        cofins_credito: produto.dadosImposto[0].cofins_credito,
        is_caso_especial_cofins_credito:
          produto.dadosImposto[0].is_caso_especial_cofins_credito,
        cofins_debito: produto.dadosImposto[0].cofins_debito,
        is_caso_especial_cofins_debito:
          produto.dadosImposto[0].is_caso_especial_cofins_debito,
        ipi_credito: produto.dadosImposto[0].ipi_credito,
        is_caso_especial_ipi_credito:
          produto.dadosImposto[0].is_caso_especial_ipi_credito,
        ipi_debito: produto.dadosImposto[0].ipi_debito,
        is_caso_especial_ipi_debito:
          produto.dadosImposto[0].is_caso_especial_ipi_debito,
        icms_normal_credito: produto.dadosImposto[0].icms_normal_credito,
        is_caso_especial_icms_normal_credito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_credito,
        icms_normal_debito: produto.dadosImposto[0].icms_normal_debito,
        is_caso_especial_icms_normal_debito:
          produto.dadosImposto[0].is_caso_especial_icms_normal_debito,
        icms_fronteira: produto.dadosImposto[0].icms_fronteira,
        is_caso_especial_icms_fronteira:
          produto.dadosImposto[0].is_caso_especial_icms_fronteira,
        icms_antecipacao: produto.dadosImposto[0].icms_antecipacao,
        is_caso_especial_icms_antecipacao:
          produto.dadosImposto[0].is_caso_especial_icms_antecipacao,
      };
    }

    function getDadosImpostosComputado(repasseDeStComputado) {
      if (fornecedor && produto && config) {
        const isSt = repasseDeStComputado > 0;
        const {
          pis_credito,
          is_caso_especial_pis_credito,
          pis_debito,
          is_caso_especial_pis_debito,
          cofins_credito,
          is_caso_especial_cofins_credito,
          cofins_debito,
          is_caso_especial_cofins_debito,
          icms_normal_credito,
          is_caso_especial_icms_normal_credito,
          icms_normal_debito,
          is_caso_especial_icms_normal_debito,
          icms_fronteira,
          is_caso_especial_icms_fronteira,
          icms_antecipacao,
          is_caso_especial_icms_antecipacao,
        } = getDadosEspeciais(produto);
        switch (fornecedor.regime_tributario) {
          case REGIME_TRIBUTARIO_PRODUTOR_RURAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : config.cidade.estado.icms_ai_compra,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : 0,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : 0,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : false,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : false,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_MEI: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_SIMPLES_NACIONAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_LUCRO_REAL: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : !isSt
                ? fornecedor.estado.icms_ai_compra
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : !isSt
                ? config.cidade.estado.icms_ai_compra
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : config.pis,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : config.pis,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : config.cofins,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : config.cofins,
              isFronteira: is_caso_especial_icms_fronteira ? true : !isSt,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : !isSt,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          case REGIME_TRIBUTARIO_SEM_NFE: {
            return {
              aliquotaInternaCompraPorcentagemComputado: is_caso_especial_icms_normal_credito
                ? icms_normal_credito
                : 0,
              aliquotaInternaVendaPorcentagemComputado: is_caso_especial_icms_normal_debito
                ? icms_normal_debito
                : 0,
              pisCompraPorcentagemComputado: is_caso_especial_pis_credito
                ? pis_credito
                : 0,
              pisVendaPorcentagemComputado: is_caso_especial_pis_debito
                ? pis_debito
                : 0,
              cofinsCompraPorcentagemComputado: is_caso_especial_cofins_credito
                ? cofins_credito
                : 0,
              cofinsVendaPorcentagemComputado: is_caso_especial_cofins_debito
                ? cofins_debito
                : 0,
              isFronteira: is_caso_especial_icms_fronteira ? true : false,
              isAntecipacao: is_caso_especial_icms_antecipacao ? true : false,
              icmsFronteiraPorcentagemInput: is_caso_especial_icms_fronteira
                ? icms_fronteira
                : -10,
              icmsAntecipacaoPorcentagemInput: is_caso_especial_icms_antecipacao
                ? icms_antecipacao
                : config.icms_antecipacao,
            };
          }
          default: {
            return {
              aliquotaInternaCompraPorcentagemComputado: 0,
              aliquotaInternaVendaPorcentagemComputado: 0,
              pisCompraPorcentagemComputado: 0,
              pisVendaPorcentagemComputado: 0,
              cofinsCompraPorcentagemComputado: 0,
              cofinsVendaPorcentagemComputado: 0,
            };
          }
        }
      } else {
        return {
          aliquotaInternaCompraPorcentagemComputado: 0,
          aliquotaInternaVendaPorcentagemComputado: 0,
          pisCompraPorcentagemComputado: 0,
          pisVendaPorcentagemComputado: 0,
          cofinsCompraPorcentagemComputado: 0,
          cofinsVendaPorcentagemComputado: 0,
        };
      }
    }

    function getPorcentagemCustoOperacional(produto) {
      if (produto) {
        return produto.porcentagem_custo_operacional / 100;
      }
      return 1;
    }

    function calculaDados() {
      if (fornecedor && produto && config && quantidadeComprada) {
        const estadoOrigem = fornecedor ? fornecedor.estado.nome : '';
        const estadoDestino = config ? config.cidade.estado.nome : '';
        let repasseDoIpi = 0;
        let repasseDoFreteUnitario = 0;
        let repasseDeSt = 0;
        let repasseDeSeguros = 0;
        let repasseDeOutrasDespesas = 0;

        //
        let descontoNfe = 0;
        let descontoBoleto = 0;
        let descontoBonificacao = 0;
        let descontoBacklight = 0;
        let descontoJornalOfertas = 0;
        let descontoDescargoMercadorias = 0;
        //
        if (dadosPrecificacaoAnterior) {
          repasseDoIpi = dadosPrecificacaoAnterior.repasse_do_ipi;
          repasseDoFreteUnitario = dadosPrecificacaoAnterior.repasse_do_frete;
          repasseDeSt = dadosPrecificacaoAnterior.repasse_de_st;
          repasseDeSeguros = dadosPrecificacaoAnterior.repasse_do_seguro;
          repasseDeOutrasDespesas =
            dadosPrecificacaoAnterior.repasse_de_outras_despesas;

          //
          descontoNfe = dadosPrecificacaoAnterior.desconto_em_nfe;
          descontoBoleto = dadosPrecificacaoAnterior.desconto_em_boleto;
          descontoBonificacao =
            dadosPrecificacaoAnterior.desconto_em_bonificacao;
          descontoBacklight =
            dadosPrecificacaoAnterior.desconto_em_backlight_e_ponto_extra;
          descontoJornalOfertas =
            dadosPrecificacaoAnterior.desconto_em_jornal_de_ofertas;
          descontoDescargoMercadorias =
            dadosPrecificacaoAnterior.desconto_em_descargo_de_mercadorias;
        }
        const {
          aliquotaInternaCompraPorcentagemComputado: aliquotaInternaCompraPorcentagem,
          aliquotaInternaVendaPorcentagemComputado: aliquotaInternaVendaPorcentagem,
          cofinsCompraPorcentagemComputado: cofinsCompraPorcentagem,
          cofinsVendaPorcentagemComputado: cofinsVendaPorcentagem,
          pisCompraPorcentagemComputado: pisCompraPorcentagem,
          pisVendaPorcentagemComputado: pisVendaPorcentagem,
          isAntecipacao,
          isFronteira,
          icmsFronteiraPorcentagemInput,
          icmsAntecipacaoPorcentagemInput,
        } = getDadosImpostosComputado(repasseDeSt);

        const irpjPorcentagemLb = config ? config.irpj : 0;
        const csllPorcentagemLb = config ? config.csll : 0;
        const lucroBrutoPorcentagem = margemDeLucro;

        const agregadoDoCnaePorcentagem = 0;
        const mvaDaPauta = 0;

        const margemDeCustoOperacionalPorcentagem = config
          ? config.pcf * getPorcentagemCustoOperacional(produto)
          : 0;
        const margemDeCustoDeCartaoPorcentagem = !isMargemCartao
          ? config
            ? config.ppc
            : 0
          : 0;

        const margemDeAvariaPorcentagem = config ? config.pparf : 0;

        const incluirIcmsDeFronteira = config
          ? config.incluir_icms_fronteira_na_precificao
          : false;
        const incluirIcmsDeAntecipacao = config
          ? config.incluir_icms_antecipacao_na_precificacao
          : false;

        const icmsAntecipacaoPorcentagem =
          estadoOrigem === estadoDestino
            ? isAntecipacao
              ? icmsAntecipacaoPorcentagemInput
              : 0
            : 0; //entrada

        const baseCalculoIcmsUnitaria =
          valorNegociadoComAIndustria +
          repasseDoFreteUnitario +
          repasseDeSeguros +
          repasseDeOutrasDespesas -
          descontoNfe;

        /*       const custoFinalDoProduto =
          valorNegociadoComAIndustria +
          repasseDoIpi +
          repasseDoFreteUnitario +
          repasseDeSt +
          repasseDeSeguros +
          repasseDeOutrasDespesas; */

        const custoFinalDoProduto =
          baseCalculoIcmsUnitaria + repasseDoIpi + repasseDeSt;

        const aliquotaInternaCompra =
          baseCalculoIcmsUnitaria * (aliquotaInternaCompraPorcentagem / 100);

        const pisCompra =
          baseCalculoIcmsUnitaria * (pisCompraPorcentagem / 100);

        const cofinsCompra =
          baseCalculoIcmsUnitaria * (cofinsCompraPorcentagem / 100);

        const custoLivreDeImpostoPorcentagem =
          custoFinalDoProduto -
          aliquotaInternaCompra -
          pisCompra -
          cofinsCompra;

        //const pisVendaPorcentagem = pisCompraPorcentagem;

        //const cofinsVendaPorcentagem = cofinsCompraPorcentagem;

        let custosAMais = 0;

        //if (isAntecipacao && incluirIcmsDeAntecipacao) {
        /*if (false) {
        custosAMais += icmsAntecipacaoPorcentagem;
        }*/

        const calculoDaPautaDeIcmsDeFronteira =
          mvaDaPauta > 0
            ? mvaDaPauta * aliquotaInternaVendaPorcentagem -
              aliquotaInternaCompra
            : 0;

        const icmsAntecipacao =
          (icmsAntecipacaoPorcentagem / 100) * baseCalculoIcmsUnitaria;
        const icmsAntecipacaoTotal = icmsAntecipacao * quantidadeComprada;

        let icmsAntecipacaoVenda = 0;

        if (isAntecipacao && incluirIcmsDeAntecipacao) {
          custosAMais += icmsAntecipacao;
          icmsAntecipacaoVenda = icmsAntecipacao;
        }

        let fronteira = 0;
        let fronteiraPorcentagem = 0;
        let icmsDeFronteiraAdotado = 0;
        let icmsDeFronteiraAdotadoPorcentagem = 0;
        let icmsDeFronteiraAdotadoTotal = 0;

        if (icmsFronteiraPorcentagemInput < 0) {
          fronteira = isFronteira
            ? estadoOrigem === estadoDestino
              ? 0
              : ((custoFinalDoProduto - aliquotaInternaCompra) /
                  (1 - aliquotaInternaVendaPorcentagem / 100)) *
                  (1 + agregadoDoCnaePorcentagem / 100) *
                  (aliquotaInternaVendaPorcentagem / 100) -
                aliquotaInternaCompra
            : 0;

          fronteiraPorcentagem =
            estadoOrigem === estadoDestino
              ? 0
              : (fronteira / baseCalculoIcmsUnitaria) * 100;

          icmsDeFronteiraAdotado = isFronteira
            ? fronteira > calculoDaPautaDeIcmsDeFronteira
              ? fronteira
              : calculoDaPautaDeIcmsDeFronteira
            : 0;

          icmsDeFronteiraAdotadoPorcentagem =
            (icmsDeFronteiraAdotado / baseCalculoIcmsUnitaria) * 100;
          icmsDeFronteiraAdotadoTotal =
            icmsDeFronteiraAdotado * quantidadeComprada;
        } else {
          fronteiraPorcentagem =
            estadoOrigem === estadoDestino ? 0 : icmsFronteiraPorcentagemInput;

          fronteira = (fronteiraPorcentagem / 100) * baseCalculoIcmsUnitaria;

          icmsDeFronteiraAdotado = fronteira;

          icmsDeFronteiraAdotadoPorcentagem = fronteiraPorcentagem;

          icmsDeFronteiraAdotadoTotal =
            icmsDeFronteiraAdotado * quantidadeComprada;
        }

        let icmsDeFronteiraVenda = 0;

        if (isFronteira && incluirIcmsDeFronteira) {
          custosAMais += icmsDeFronteiraAdotado;
          icmsDeFronteiraVenda = icmsDeFronteiraAdotado;
        }

        //if (isFronteira && incluirIcmsDeFronteira) {
        /*if (false) {
        custosAMais += icmsDeFronteiraAdotadoPorcentagem;
       }*/

        const markup =
          (aliquotaInternaVendaPorcentagem +
            pisVendaPorcentagem +
            cofinsVendaPorcentagem +
            lucroBrutoPorcentagem +
            margemDeCustoOperacionalPorcentagem +
            margemDeCustoDeCartaoPorcentagem +
            margemDeAvariaPorcentagem) /
          100;

        const precoVenda =
          (custoLivreDeImpostoPorcentagem -
            (descontoBacklight +
              descontoBoleto +
              descontoBonificacao +
              descontoDescargoMercadorias +
              descontoJornalOfertas) +
            custosAMais) /
          (1 - markup);

        /* const precoVenda =
          (custoLivreDeImpostoPorcentagem + custosAMais) /
          (1 -
            (aliquotaInternaVendaPorcentagem +
              pisVendaPorcentagem +
              cofinsVendaPorcentagem +
              lucroBrutoPorcentagem +
              margemDeCustoOperacionalPorcentagem +
              margemDeCustoDeCartaoPorcentagem) /
              100); */
        const precoVendaTotal = precoVenda * quantidadeComprada;

        const valorNegociadoComAIndustriaPorcentagem =
          (valorNegociadoComAIndustria / precoVenda) * 100;
        const valorNegociadoComAIndustriaTotal =
          valorNegociadoComAIndustria * quantidadeComprada;

        const lucroLiquidoPorcentagem =
          lucroBrutoPorcentagem *
          (1 - (irpjPorcentagemLb + csllPorcentagemLb) / 100);
        const lucroLiquido = precoVenda * (lucroLiquidoPorcentagem / 100);
        const lucroLiquidoTotal = lucroLiquido * quantidadeComprada;

        const lucroBruto = (lucroBrutoPorcentagem / 100) * precoVenda;

        const irpj = (irpjPorcentagemLb / 100) * lucroBruto;
        const irpjPorcentagemPv = (irpj / precoVenda) * 100;
        const irpjTotal = irpj * quantidadeComprada;

        const csll = (csllPorcentagemLb / 100) * lucroBruto;
        const csllPorcentagemPv = (csll / precoVenda) * 100;
        const csllTotal = csll * quantidadeComprada;

        const lucroBrutoTotal = lucroBruto * quantidadeComprada;

        const aliquotaInternaCompraTotal =
          aliquotaInternaCompra * quantidadeComprada;

        const pisCompraTotal = pisCompra * quantidadeComprada;

        const cofinsCompraTotal = cofinsCompra * quantidadeComprada;

        const totalDeCreditosCompra =
          aliquotaInternaCompra + pisCompra + cofinsCompra;
        const totalDeCreditosCompraPorcentagem =
          (totalDeCreditosCompra / precoVenda) * 100;
        const totalDeCreditosCompraTotal =
          totalDeCreditosCompra * quantidadeComprada;

        const aliquotaInternaVenda =
          (aliquotaInternaVendaPorcentagem / 100) * precoVenda;
        const aliquotaInternaVendaTotal =
          aliquotaInternaVenda * quantidadeComprada;

        const pisVenda = (pisVendaPorcentagem / 100) * precoVenda;
        const pisVendaTotal = pisVenda * quantidadeComprada;

        const cofinsVenda = (cofinsVendaPorcentagem / 100) * precoVenda;
        const cofinsVendaTotal = cofinsVenda * quantidadeComprada;

        const totalDeDebitosVenda =
          aliquotaInternaVenda + pisVenda + cofinsVenda;
        const totalDeDebitosVendaPorcentagem =
          (totalDeDebitosVenda / precoVenda) * 100;
        const totalDeDebitosVendaTotal =
          totalDeDebitosVenda * quantidadeComprada;

        const totalDeIcmsAntecipado = icmsAntecipacao + icmsDeFronteiraAdotado;
        const totalDeIcmsAntecipadoPorcentagem =
          (totalDeIcmsAntecipado / precoVenda) * 100;
        const totalDeIcmsAntecipadoTotal =
          totalDeIcmsAntecipado * quantidadeComprada;

        const diferencaDeIcms = aliquotaInternaVenda - aliquotaInternaCompra;
        const diferencaDeIcmsPorcentagem = (diferencaDeIcms / precoVenda) * 100;
        const diferencaDeIcmsTotal = diferencaDeIcms * quantidadeComprada;

        const diferencaDePis = pisVenda - pisCompra;
        const diferencaDePisPorcentagem = (diferencaDePis / precoVenda) * 100;
        const diferencaDePisTotal = diferencaDePis * quantidadeComprada;

        const diferencaDeCofins = cofinsVenda - cofinsCompra;
        const diferencaDeCofinsPorcentagem =
          (diferencaDeCofins / precoVenda) * 100;
        const diferencaDeCofinsTotal = diferencaDeCofins * quantidadeComprada;

        const tributacaoEfetiva = totalDeDebitosVenda - totalDeCreditosCompra;
        const tributacaoEfetivaPorcentagem =
          (tributacaoEfetiva / precoVenda) * 100;
        const tributacaoEfetivaTotal = tributacaoEfetiva * quantidadeComprada;

        const resultadoDoFluxoDeCaixa =
          tributacaoEfetiva + totalDeIcmsAntecipado;
        const resultadoDoFluxoDeCaixaPorcentagem =
          (resultadoDoFluxoDeCaixa / precoVenda) * 100;
        const resultadoDoFluxoDeCaixaTotal =
          resultadoDoFluxoDeCaixa * quantidadeComprada;

        const margemDeCustoOperacional =
          (margemDeCustoOperacionalPorcentagem / 100) * precoVenda;
        const margemDeCustoOperacionalTotal =
          margemDeCustoOperacional * quantidadeComprada;

        const margemDeCustoDeCartao =
          (margemDeCustoDeCartaoPorcentagem / 100) * precoVenda;
        const margemDeCustoDeCartaoTotal =
          margemDeCustoDeCartao * quantidadeComprada;

        const margemDeAvaria = (margemDeAvariaPorcentagem / 100) * precoVenda;
        const margemDeAvariaTotal = margemDeAvaria * quantidadeComprada;

        const repasseDoIpiPorcentagem =
          (repasseDoIpi / valorNegociadoComAIndustria) * 100;
        const repasseDoIpiTotal = repasseDoIpi * quantidadeComprada;

        const repasseDoFreteUnitarioPorcentagem =
          (repasseDoFreteUnitario / valorNegociadoComAIndustria) * 100;
        const repasseDoFreteUnitarioTotal =
          repasseDoFreteUnitario * quantidadeComprada;

        const repasseDeStPorcentagem =
          (repasseDeSt / valorNegociadoComAIndustria) * 100;
        const repasseDeStTotal = repasseDeSt * quantidadeComprada;

        const repasseDeSegurosPorcentagem =
          (repasseDeSeguros / valorNegociadoComAIndustria) * 100;
        const repasseDeSegurosTotal = repasseDeSeguros * quantidadeComprada;

        const repasseDeOutrasDespesasPorcentagem =
          (repasseDeOutrasDespesas / valorNegociadoComAIndustria) * 100;
        const repasseDeOutrasDespesasTotal =
          repasseDeOutrasDespesas * quantidadeComprada;

        const totalDeCustosDeRepasses =
          repasseDoIpi +
          repasseDoFreteUnitario +
          repasseDeSt +
          repasseDeSeguros +
          repasseDeOutrasDespesas;
        const totalDeCustosDeRepassesPorcentagem =
          (totalDeCustosDeRepasses / precoVenda) * 100;
        const totalDeCustosDeRepassesTotal =
          totalDeCustosDeRepasses * quantidadeComprada;

        const custoFinalDoProdutoPorcentagem =
          (custoFinalDoProduto / precoVenda) * 100;
        const custoFinalDoProdutoTotal =
          custoFinalDoProduto * quantidadeComprada;

        ///

        const icmsAntecipacaoPorcentagemVenda =
          (icmsAntecipacaoVenda / precoVenda) * 100;

        const icmsDeFronteiraAdotadoPorcentagemVenda =
          (icmsDeFronteiraVenda / precoVenda) * 100;

        ///

        const conferenciaDoGraficoDeSecaoCircular =
          valorNegociadoComAIndustriaPorcentagem +
          margemDeCustoOperacionalPorcentagem +
          diferencaDeIcmsPorcentagem +
          lucroBrutoPorcentagem +
          diferencaDeCofinsPorcentagem +
          margemDeCustoDeCartaoPorcentagem +
          diferencaDePisPorcentagem +
          totalDeCustosDeRepassesPorcentagem +
          icmsAntecipacaoPorcentagemVenda +
          icmsDeFronteiraAdotadoPorcentagemVenda;

        const margemTotal = precoVenda - valorNegociadoComAIndustria;
        const margemTotalPorcentagem =
          (margemTotal / valorNegociadoComAIndustria) * 100;
        const margemTotalTotal = margemTotal * quantidadeComprada;

        const dataDeValidade = '';

        ////////////

        const repasseDoIpiPorcentagemVenda = repasseDoIpi;

        const repasseDoFreteUnitarioPorcentagemVenda = repasseDoFreteUnitario;

        const repasseDeStPorcentagemVenda = repasseDeSt;

        const repasseDeSegurosPorcentagemVenda = repasseDeSeguros;

        const repasseDeOutrasDespesasPorcentagemVenda = repasseDeOutrasDespesas;

        /////////////

        ///////////////////////////////////////////

        const descontoNfePorcentagem =
          (descontoNfe / valorNegociadoComAIndustria) * 100;
        const descontoNfeTotal = descontoNfe * quantidadeComprada;

        const descontoBoletoPorcentagem =
          (descontoBoleto / valorNegociadoComAIndustria) * 100;
        const descontoBoletoTotal = descontoBoleto * quantidadeComprada;

        const descontoBonificacaoPorcentagem =
          (descontoBonificacao / valorNegociadoComAIndustria) * 100;
        const descontoBonificacaoTotal =
          descontoBonificacao * quantidadeComprada;

        const descontoJornalOfertasPorcentagem =
          (descontoJornalOfertas / valorNegociadoComAIndustria) * 100;
        const descontoJornalOfertasTotal =
          descontoJornalOfertas * quantidadeComprada;

        const descontoDescargoMercadoriasPorcentagem =
          (descontoDescargoMercadorias / valorNegociadoComAIndustria) * 100;
        const descontoDescargoMercadoriasTotal =
          descontoDescargoMercadorias * quantidadeComprada;

        const descontoBacklightPorcentagem =
          (descontoBacklight / valorNegociadoComAIndustria) * 100;
        const descontoBacklightTotal = descontoBacklight * quantidadeComprada;

        ///////////////////////////////////////////

        return {
          baseCalculoIcmsUnitaria,
          estadoOrigem,
          estadoDestino,
          valorNegociadoComAIndustriaPorcentagem,
          valorNegociadoComAIndustria,
          valorNegociadoComAIndustriaTotal,
          custoLivreDeImpostoPorcentagem,
          lucroLiquidoPorcentagem,
          lucroLiquido,
          lucroLiquidoTotal,
          irpjPorcentagemPv,
          irpjPorcentagemLb,
          irpj,
          irpjTotal,
          csllPorcentagemPv,
          csllPorcentagemLb,
          csll,
          csllTotal,
          lucroBrutoPorcentagem,
          lucroBruto,
          lucroBrutoTotal,
          precoVenda,
          precoVendaTotal,
          aliquotaInternaCompraPorcentagem,
          aliquotaInternaCompra,
          aliquotaInternaCompraTotal,
          pisCompraPorcentagem,
          pisCompra,
          pisCompraTotal,
          cofinsCompraPorcentagem,
          cofinsCompra,
          cofinsCompraTotal,
          totalDeCreditosCompraPorcentagem,
          totalDeCreditosCompra,
          totalDeCreditosCompraTotal,
          aliquotaInternaVendaPorcentagem,
          aliquotaInternaVenda,
          aliquotaInternaVendaTotal,
          pisVendaPorcentagem,
          pisVenda,
          pisVendaTotal,
          cofinsVendaPorcentagem,
          cofinsVenda,
          cofinsVendaTotal,
          totalDeDebitosVendaPorcentagem,
          totalDeDebitosVenda,
          totalDeDebitosVendaTotal,
          icmsAntecipacaoPorcentagem,
          icmsAntecipacao,
          icmsAntecipacaoTotal,
          agregadoDoCnaePorcentagem,
          mvaDaPauta,
          calculoDaPautaDeIcmsDeFronteira,
          fronteiraPorcentagem,
          fronteira,
          icmsDeFronteiraAdotadoPorcentagem,
          icmsDeFronteiraAdotado,
          icmsDeFronteiraAdotadoTotal,
          totalDeIcmsAntecipadoPorcentagem,
          totalDeIcmsAntecipado,
          totalDeIcmsAntecipadoTotal,
          diferencaDeIcmsPorcentagem,
          diferencaDeIcms,
          diferencaDeIcmsTotal,
          diferencaDePisPorcentagem,
          diferencaDePis,
          diferencaDePisTotal,
          diferencaDeCofinsPorcentagem,
          diferencaDeCofins,
          diferencaDeCofinsTotal,
          tributacaoEfetivaPorcentagem,
          tributacaoEfetiva,
          tributacaoEfetivaTotal,
          resultadoDoFluxoDeCaixaPorcentagem,
          resultadoDoFluxoDeCaixa,
          resultadoDoFluxoDeCaixaTotal,
          margemDeCustoOperacionalPorcentagem,
          margemDeCustoOperacional,
          margemDeCustoOperacionalTotal,
          margemDeCustoDeCartaoPorcentagem,
          margemDeCustoDeCartao,
          margemDeCustoDeCartaoTotal,
          repasseDoIpiPorcentagem,
          repasseDoIpi,
          repasseDoIpiTotal,
          repasseDoFreteUnitarioPorcentagem,
          repasseDoFreteUnitario,
          repasseDoFreteUnitarioTotal,
          repasseDeStPorcentagem,
          repasseDeSt,
          repasseDeStTotal,
          repasseDeSegurosPorcentagem,
          repasseDeSeguros,
          repasseDeSegurosTotal,
          repasseDeOutrasDespesasPorcentagem,
          repasseDeOutrasDespesas,
          repasseDeOutrasDespesasTotal,
          totalDeCustosDeRepassesPorcentagem,
          totalDeCustosDeRepasses,
          totalDeCustosDeRepassesTotal,
          custoFinalDoProdutoPorcentagem,
          custoFinalDoProduto,
          custoFinalDoProdutoTotal,
          conferenciaDoGraficoDeSecaoCircular,
          margemTotalPorcentagem,
          margemTotal,
          margemTotalTotal,
          dataDeValidade,
          quantidadeComprada,
          //
          repasseDoIpiPorcentagemVenda,
          repasseDoFreteUnitarioPorcentagemVenda,
          repasseDeStPorcentagemVenda,
          repasseDeSegurosPorcentagemVenda,
          repasseDeOutrasDespesasPorcentagemVenda,
          //
          icmsAntecipacaoPorcentagemVenda: icmsAntecipacaoVenda,
          icmsDeFronteiraAdotadoPorcentagemVenda: icmsDeFronteiraVenda,
          //
          descontoBacklight,
          descontoBoleto,
          descontoBonificacao,
          descontoDescargoMercadorias,
          descontoJornalOfertas,
          descontoNfe,
          //
          descontoNfePorcentagem,
          descontoNfeTotal,
          descontoBoletoPorcentagem,
          descontoBoletoTotal,
          descontoBonificacaoPorcentagem,
          descontoBonificacaoTotal,
          descontoJornalOfertasPorcentagem,
          descontoJornalOfertasTotal,
          descontoDescargoMercadoriasPorcentagem,
          descontoDescargoMercadoriasTotal,
          descontoBacklightPorcentagem,
          descontoBacklightTotal,
          descontoTotal:
            descontoBacklight +
            descontoBoleto +
            descontoBonificacao +
            descontoDescargoMercadorias +
            descontoJornalOfertas +
            descontoNfe,
          ////
          descontoTotalTotal:
            descontoNfeTotal +
            descontoBoletoTotal +
            descontoBonificacaoTotal +
            descontoJornalOfertasTotal +
            descontoDescargoMercadoriasTotal +
            descontoBacklightTotal,
          //
          descontoPorcentagem:
            descontoNfePorcentagem +
            descontoBoletoPorcentagem +
            descontoBonificacaoPorcentagem +
            descontoJornalOfertasPorcentagem +
            descontoDescargoMercadoriasPorcentagem +
            descontoBacklightPorcentagem,
          //
          margemDeAvaria,
          margemDeAvariaTotal,
          margemDeAvariaPorcentagem,
        };
      }

      return {
        estadoOrigem: '',
        estadoDestino: '',
        valorNegociadoComAIndustriaPorcentagem: 0,
        valorNegociadoComAIndustria: 0,
        valorNegociadoComAIndustriaTotal: 0,
        custoLivreDeImpostoPorcentagem: 0,
        lucroLiquidoPorcentagem: 0,
        lucroLiquido: 0,
        lucroLiquidoTotal: 0,
        irpjPorcentagemPv: 0,
        irpjPorcentagemLb: 0,
        irpj: 0,
        irpjTotal: 0,
        csllPorcentagemPv: 0,
        csllPorcentagemLb: 0,
        csll: 0,
        csllTotal: 0,
        lucroBrutoPorcentagem: 0,
        lucroBruto: 0,
        lucroBrutoTotal: 0,
        precoVenda: 0,
        precoVendaTotal: 0,
        aliquotaInternaCompraPorcentagem: 0,
        aliquotaInternaCompra: 0,
        aliquotaInternaCompraTotal: 0,
        pisCompraPorcentagem: 0,
        pisCompra: 0,
        pisCompraTotal: 0,
        cofinsCompraPorcentagem: 0,
        cofinsCompra: 0,
        cofinsCompraTotal: 0,
        totalDeCreditosCompraPorcentagem: 0,
        totalDeCreditosCompra: 0,
        totalDeCreditosCompraTotal: 0,
        aliquotaInternaVendaPorcentagem: 0,
        aliquotaInternaVenda: 0,
        aliquotaInternaVendaTotal: 0,
        pisVendaPorcentagem: 0,
        pisVenda: 0,
        pisVendaTotal: 0,
        cofinsVendaPorcentagem: 0,
        cofinsVenda: 0,
        cofinsVendaTotal: 0,
        totalDeDebitosVendaPorcentagem: 0,
        totalDeDebitosVenda: 0,
        totalDeDebitosVendaTotal: 0,
        icmsAntecipacaoPorcentagem: 0,
        icmsAntecipacao: 0,
        icmsAntecipacaoTotal: 0,
        agregadoDoCnaePorcentagem: 0,
        mvaDaPauta: 0,
        calculoDaPautaDeIcmsDeFronteira: 0,
        fronteiraPorcentagem: 0,
        fronteira: 0,
        icmsDeFronteiraAdotadoPorcentagem: 0,
        icmsDeFronteiraAdotado: 0,
        icmsDeFronteiraAdotadoTotal: 0,
        totalDeIcmsAntecipadoPorcentagem: 0,
        totalDeIcmsAntecipado: 0,
        totalDeIcmsAntecipadoTotal: 0,
        diferencaDeIcmsPorcentagem: 0,
        diferencaDeIcms: 0,
        diferencaDeIcmsTotal: 0,
        diferencaDePisPorcentagem: 0,
        diferencaDePis: 0,
        diferencaDePisTotal: 0,
        diferencaDeCofinsPorcentagem: 0,
        diferencaDeCofins: 0,
        diferencaDeCofinsTotal: 0,
        tributacaoEfetivaPorcentagem: 0,
        tributacaoEfetiva: 0,
        tributacaoEfetivaTotal: 0,
        resultadoDoFluxoDeCaixaPorcentagem: 0,
        resultadoDoFluxoDeCaixa: 0,
        resultadoDoFluxoDeCaixaTotal: 0,
        margemDeCustoOperacionalPorcentagem: 0,
        margemDeCustoOperacional: 0,
        margemDeCustoOperacionalTotal: 0,
        margemDeCustoDeCartaoPorcentagem: 0,
        margemDeCustoDeCartao: 0,
        margemDeCustoDeCartaoTotal: 0,
        repasseDoIpiPorcentagem: 0,
        repasseDoIpi: 0,
        repasseDoIpiTotal: 0,
        repasseDoFreteUnitarioPorcentagem: 0,
        repasseDoFreteUnitario: 0,
        repasseDoFreteUnitarioTotal: 0,
        repasseDeStPorcentagem: 0,
        repasseDeSt: 0,
        repasseDeStTotal: 0,
        repasseDeSegurosPorcentagem: 0,
        repasseDeSeguros: 0,
        repasseDeSegurosTotal: 0,
        repasseDeOutrasDespesasPorcentagem: 0,
        repasseDeOutrasDespesas: 0,
        repasseDeOutrasDespesasTotal: 0,
        totalDeCustosDeRepassesPorcentagem: 0,
        totalDeCustosDeRepasses: 0,
        totalDeCustosDeRepassesTotal: 0,
        custoFinalDoProdutoPorcentagem: 0,
        custoFinalDoProduto: 0,
        custoFinalDoProdutoTotal: 0,
        conferenciaDoGraficoDeSecaoCircular: 0,
        margemTotalPorcentagem: 0,
        margemTotal: 0,
        margemTotalTotal: 0,
        dataDeValidade: '',
        quantidadeComprada: 0,
        //
        repasseDoIpiPorcentagemVenda: 0,
        repasseDoFreteUnitarioPorcentagemVenda: 0,
        repasseDeStPorcentagemVenda: 0,
        repasseDeSegurosPorcentagemVenda: 0,
        repasseDeOutrasDespesasPorcentagemVenda: 0,
        //
        icmsAntecipacaoPorcentagemVenda: 0,
        icmsDeFronteiraAdotadoPorcentagemVenda: 0,
      };
    }

    function formataDados() {
      const dados = calculaDados();
      return {
        estadoOrigem: dados.estadoOrigem,
        estadoDestino: dados.estadoDestino,
        valorNegociadoComAIndustriaPorcentagem: formatPorcentagem(
          dados.valorNegociadoComAIndustriaPorcentagem,
        ),
        valorNegociadoComAIndustria: formatMoeda(
          dados.valorNegociadoComAIndustria,
        ),
        valorNegociadoComAIndustriaTotal: formatMoeda(
          dados.valorNegociadoComAIndustriaTotal,
        ),
        custoLivreDeImpostoPorcentagem: formatMoeda(
          dados.custoLivreDeImpostoPorcentagem,
        ),
        lucroLiquidoPorcentagem: formatPorcentagem(
          dados.lucroLiquidoPorcentagem,
        ),
        lucroLiquido: formatMoeda(dados.lucroLiquido),
        lucroLiquidoTotal: formatMoeda(dados.lucroLiquidoTotal),
        irpjPorcentagemPv: formatPorcentagem(dados.irpjPorcentagemPv),
        irpjPorcentagemLb: formatPorcentagem(dados.irpjPorcentagemLb),
        irpj: formatMoeda(dados.irpj),
        irpjTotal: formatMoeda(dados.irpjTotal),
        csllPorcentagemPv: formatPorcentagem(dados.csllPorcentagemPv),
        csllPorcentagemLb: formatPorcentagem(dados.csllPorcentagemLb),
        csll: formatMoeda(dados.csll),
        csllTotal: formatMoeda(dados.csllTotal),
        lucroBrutoPorcentagem: formatPorcentagem(dados.lucroBrutoPorcentagem),
        lucroBruto: formatMoeda(dados.lucroBruto),
        lucroBrutoTotal: formatMoeda(dados.lucroBrutoTotal),
        precoVenda: formatMoeda(dados.precoVenda),
        precoVendaTotal: formatMoeda(dados.precoVendaTotal),
        aliquotaInternaCompraPorcentagem: formatPorcentagem(
          dados.aliquotaInternaCompraPorcentagem,
        ),
        aliquotaInternaCompra: formatMoeda(dados.aliquotaInternaCompra),
        aliquotaInternaCompraTotal: formatMoeda(
          dados.aliquotaInternaCompraTotal,
        ),
        pisCompraPorcentagem: formatPorcentagem(dados.pisCompraPorcentagem),
        pisCompra: formatMoeda(dados.pisCompra),
        pisCompraTotal: formatMoeda(dados.pisCompraTotal),
        cofinsCompraPorcentagem: formatPorcentagem(
          dados.cofinsCompraPorcentagem,
        ),
        cofinsCompra: formatMoeda(dados.cofinsCompra),
        cofinsCompraTotal: formatMoeda(dados.cofinsCompraTotal),
        totalDeCreditosCompraPorcentagem: formatPorcentagem(
          dados.totalDeCreditosCompraPorcentagem,
        ),
        totalDeCreditosCompra: formatMoeda(dados.totalDeCreditosCompra),
        totalDeCreditosCompraTotal: formatMoeda(
          dados.totalDeCreditosCompraTotal,
        ),
        aliquotaInternaVendaPorcentagem: formatPorcentagem(
          dados.aliquotaInternaVendaPorcentagem,
        ),
        aliquotaInternaVenda: formatMoeda(dados.aliquotaInternaVenda),
        aliquotaInternaVendaTotal: formatMoeda(dados.aliquotaInternaVendaTotal),
        pisVendaPorcentagem: formatPorcentagem(dados.pisVendaPorcentagem),
        pisVenda: formatMoeda(dados.pisVenda),
        pisVendaTotal: formatMoeda(dados.pisVendaTotal),
        cofinsVendaPorcentagem: formatPorcentagem(dados.cofinsVendaPorcentagem),
        cofinsVenda: formatMoeda(dados.cofinsVenda),
        cofinsVendaTotal: formatMoeda(dados.cofinsVendaTotal),
        totalDeDebitosVendaPorcentagem: formatPorcentagem(
          dados.totalDeDebitosVendaPorcentagem,
        ),
        totalDeDebitosVenda: formatMoeda(dados.totalDeDebitosVenda),
        totalDeDebitosVendaTotal: formatMoeda(dados.totalDeDebitosVendaTotal),
        icmsAntecipacaoPorcentagem: formatPorcentagem(
          dados.icmsAntecipacaoPorcentagem,
        ),
        icmsAntecipacao: formatMoeda(dados.icmsAntecipacao),
        icmsAntecipacaoTotal: formatMoeda(dados.icmsAntecipacaoTotal),
        agregadoDoCnaePorcentagem: formatPorcentagem(
          dados.agregadoDoCnaePorcentagem,
        ),
        mvaDaPauta: formatMoeda(dados.mvaDaPauta),
        calculoDaPautaDeIcmsDeFronteira: formatMoeda(
          dados.calculoDaPautaDeIcmsDeFronteira,
        ),
        fronteiraPorcentagem: formatPorcentagem(dados.fronteiraPorcentagem),
        fronteira: formatMoeda(dados.fronteira),
        icmsDeFronteiraAdotadoPorcentagem: formatPorcentagem(
          dados.icmsDeFronteiraAdotadoPorcentagem,
        ),
        icmsDeFronteiraAdotado: formatMoeda(dados.icmsDeFronteiraAdotado),
        icmsDeFronteiraAdotadoTotal: formatMoeda(
          dados.icmsDeFronteiraAdotadoTotal,
        ),
        totalDeIcmsAntecipadoPorcentagem: formatPorcentagem(
          dados.totalDeIcmsAntecipadoPorcentagem,
        ),
        totalDeIcmsAntecipado: formatMoeda(dados.totalDeIcmsAntecipado),
        totalDeIcmsAntecipadoTotal: formatMoeda(
          dados.totalDeIcmsAntecipadoTotal,
        ),
        diferencaDeIcmsPorcentagem: formatPorcentagem(
          dados.diferencaDeIcmsPorcentagem,
        ),
        diferencaDeIcms: formatMoeda(dados.diferencaDeIcms),
        diferencaDeIcmsTotal: formatMoeda(dados.diferencaDeIcmsTotal),
        diferencaDePisPorcentagem: formatPorcentagem(
          dados.diferencaDePisPorcentagem,
        ),
        diferencaDePis: formatMoeda(dados.diferencaDePis),
        diferencaDePisTotal: formatMoeda(dados.diferencaDePisTotal),
        diferencaDeCofinsPorcentagem: formatPorcentagem(
          dados.diferencaDeCofinsPorcentagem,
        ),
        diferencaDeCofins: formatMoeda(dados.diferencaDeCofins),
        diferencaDeCofinsTotal: formatMoeda(dados.diferencaDeCofinsTotal),
        tributacaoEfetivaPorcentagem: formatPorcentagem(
          dados.tributacaoEfetivaPorcentagem,
        ),
        tributacaoEfetiva: formatMoeda(dados.tributacaoEfetiva),
        tributacaoEfetivaTotal: formatMoeda(dados.tributacaoEfetivaTotal),
        resultadoDoFluxoDeCaixaPorcentagem: formatPorcentagem(
          dados.resultadoDoFluxoDeCaixaPorcentagem,
        ),
        resultadoDoFluxoDeCaixa: formatMoeda(dados.resultadoDoFluxoDeCaixa),
        resultadoDoFluxoDeCaixaTotal: formatMoeda(
          dados.resultadoDoFluxoDeCaixaTotal,
        ),
        margemDeCustoOperacionalPorcentagem: formatPorcentagem(
          dados.margemDeCustoOperacionalPorcentagem,
        ),
        margemDeCustoOperacional: formatMoeda(dados.margemDeCustoOperacional),
        margemDeCustoOperacionalTotal: formatMoeda(
          dados.margemDeCustoOperacionalTotal,
        ),
        margemDeCustoDeCartaoPorcentagem: formatPorcentagem(
          dados.margemDeCustoDeCartaoPorcentagem,
        ),
        margemDeCustoDeCartao: formatMoeda(dados.margemDeCustoDeCartao),
        margemDeCustoDeCartaoTotal: formatMoeda(
          dados.margemDeCustoDeCartaoTotal,
        ),
        repasseDoIpiPorcentagem: formatPorcentagem(
          dados.repasseDoIpiPorcentagem,
        ),
        repasseDoIpi: formatMoeda(dados.repasseDoIpi),
        repasseDoIpiTotal: formatMoeda(dados.repasseDoIpiTotal),
        repasseDoFreteUnitarioPorcentagem: formatPorcentagem(
          dados.repasseDoFreteUnitarioPorcentagem,
        ),
        repasseDoFreteUnitario: formatMoeda(dados.repasseDoFreteUnitario),
        repasseDoFreteUnitarioTotal: formatMoeda(
          dados.repasseDoFreteUnitarioTotal,
        ),
        repasseDeStPorcentagem: formatPorcentagem(dados.repasseDeStPorcentagem),
        repasseDeSt: formatMoeda(dados.repasseDeSt),
        repasseDeStTotal: formatMoeda(dados.repasseDeStTotal),
        repasseDeSegurosPorcentagem: formatPorcentagem(
          dados.repasseDeSegurosPorcentagem,
        ),
        repasseDeSeguros: formatMoeda(dados.repasseDeSeguros),
        repasseDeSegurosTotal: formatMoeda(dados.repasseDeSegurosTotal),
        repasseDeOutrasDespesasPorcentagem: formatPorcentagem(
          dados.repasseDeOutrasDespesasPorcentagem,
        ),
        repasseDeOutrasDespesas: formatMoeda(dados.repasseDeOutrasDespesas),
        repasseDeOutrasDespesasTotal: formatMoeda(
          dados.repasseDeOutrasDespesasTotal,
        ),
        totalDeCustosDeRepassesPorcentagem: formatPorcentagem(
          dados.totalDeCustosDeRepassesPorcentagem,
        ),
        totalDeCustosDeRepasses: formatMoeda(dados.totalDeCustosDeRepasses),
        totalDeCustosDeRepassesTotal: formatMoeda(
          dados.totalDeCustosDeRepassesTotal,
        ),
        custoFinalDoProdutoPorcentagem: formatPorcentagem(
          dados.custoFinalDoProdutoPorcentagem,
        ),
        custoFinalDoProduto: formatMoeda(dados.custoFinalDoProduto),
        custoFinalDoProdutoTotal: formatMoeda(dados.custoFinalDoProdutoTotal),
        conferenciaDoGraficoDeSecaoCircular: formatPorcentagem(
          dados.conferenciaDoGraficoDeSecaoCircular,
        ),
        margemTotalPorcentagem: formatPorcentagem(dados.margemTotalPorcentagem),
        margemTotal: formatMoeda(dados.margemTotal),
        margemTotalTotal: formatMoeda(dados.margemTotalTotal),
        dataDeValidade: dados.dataDeValidade,
        quantidadeComprada: dados.quantidadeComprada,
        dadosOriginais: dados,
      };
    }

    const linhas = [];
    linhas.push(formataDados());

    const {
      estadoOrigem,
      estadoDestino,
      valorNegociadoComAIndustriaPorcentagem,
      //valorNegociadoComAIndustria,
      valorNegociadoComAIndustriaTotal,
      custoLivreDeImpostoPorcentagem,
      lucroLiquidoPorcentagem,
      lucroLiquido,
      lucroLiquidoTotal,
      irpjPorcentagemPv,
      irpjPorcentagemLb,
      irpj,
      irpjTotal,
      csllPorcentagemPv,
      csllPorcentagemLb,
      csll,
      csllTotal,
      lucroBrutoPorcentagem,
      lucroBruto,
      lucroBrutoTotal,
      precoVenda,
      precoVendaTotal,
      aliquotaInternaCompraPorcentagem,
      aliquotaInternaCompra,
      aliquotaInternaCompraTotal,
      pisCompraPorcentagem,
      pisCompra,
      pisCompraTotal,
      cofinsCompraPorcentagem,
      cofinsCompra,
      cofinsCompraTotal,
      totalDeCreditosCompraPorcentagem,
      totalDeCreditosCompra,
      totalDeCreditosCompraTotal,
      aliquotaInternaVendaPorcentagem,
      aliquotaInternaVenda,
      aliquotaInternaVendaTotal,
      pisVendaPorcentagem,
      pisVenda,
      pisVendaTotal,
      cofinsVendaPorcentagem,
      cofinsVenda,
      cofinsVendaTotal,
      totalDeDebitosVendaPorcentagem,
      totalDeDebitosVenda,
      totalDeDebitosVendaTotal,
      icmsAntecipacaoPorcentagem,
      icmsAntecipacao,
      icmsAntecipacaoTotal,
      agregadoDoCnaePorcentagem,
      mvaDaPauta,
      calculoDaPautaDeIcmsDeFronteira,
      fronteiraPorcentagem,
      fronteira,
      icmsDeFronteiraAdotadoPorcentagem,
      icmsDeFronteiraAdotado,
      icmsDeFronteiraAdotadoTotal,
      totalDeIcmsAntecipadoPorcentagem,
      totalDeIcmsAntecipado,
      totalDeIcmsAntecipadoTotal,
      diferencaDeIcmsPorcentagem,
      diferencaDeIcms,
      diferencaDeIcmsTotal,
      diferencaDePisPorcentagem,
      diferencaDePis,
      diferencaDePisTotal,
      diferencaDeCofinsPorcentagem,
      diferencaDeCofins,
      diferencaDeCofinsTotal,
      tributacaoEfetivaPorcentagem,
      tributacaoEfetiva,
      tributacaoEfetivaTotal,
      resultadoDoFluxoDeCaixaPorcentagem,
      resultadoDoFluxoDeCaixa,
      resultadoDoFluxoDeCaixaTotal,
      margemDeCustoOperacionalPorcentagem,
      margemDeCustoOperacional,
      margemDeCustoOperacionalTotal,
      margemDeCustoDeCartaoPorcentagem,
      margemDeCustoDeCartao,
      margemDeCustoDeCartaoTotal,
      repasseDoIpiPorcentagem,
      repasseDoIpi,
      repasseDoIpiTotal,
      repasseDoFreteUnitarioPorcentagem,
      repasseDoFreteUnitario,
      repasseDoFreteUnitarioTotal,
      repasseDeStPorcentagem,
      repasseDeSt,
      repasseDeStTotal,
      repasseDeSegurosPorcentagem,
      repasseDeSeguros,
      repasseDeSegurosTotal,
      repasseDeOutrasDespesasPorcentagem,
      repasseDeOutrasDespesas,
      repasseDeOutrasDespesasTotal,
      totalDeCustosDeRepassesPorcentagem,
      totalDeCustosDeRepasses,
      totalDeCustosDeRepassesTotal,
      custoFinalDoProdutoPorcentagem,
      custoFinalDoProduto,
      custoFinalDoProdutoTotal,
      conferenciaDoGraficoDeSecaoCircular,
      margemTotalPorcentagem,
      margemTotal,
      margemTotalTotal,
      dataDeValidade,
      //quantidadeComprada,
      dadosOriginais,
    } = linhas[0];

    function abrirGrafico(dados) {
      if (refDialogoGrafico.current) {
        refDialogoGrafico.current.handleOpen(
          [
            {
              label: 'Valor Negociado com a indústria',
              backgroundColor: 'rgba(68, 114, 196, 1)',
              borderColor: 'rgba(68, 114, 196, 1)',
              valor: ceil10(dados.valorNegociadoComAIndustria, -2),
            },
            {
              label: 'Margem de Custo Operacional',
              backgroundColor: 'rgba(237, 125, 49, 1)',
              borderColor: 'rgba(237, 125, 49, 1)',
              valor: ceil10(dados.margemDeCustoOperacional, -2),
            },
            {
              label: 'Diferença de ICMS',
              backgroundColor: 'rgba(165, 165, 165, 1)',
              borderColor: 'rgba(165, 165, 165, 1)',
              valor: ceil10(dados.diferencaDeIcms, -2),
            },
            {
              label: 'Lucro Líquido',
              backgroundColor: 'rgba(91, 155, 213, 1)',
              borderColor: 'rgba(91, 155, 213, 1)',
              valor: ceil10(dados.lucroBruto, -2),
            },
            {
              label: 'Diferença de COFINS',
              backgroundColor: 'rgba(112, 173, 71, 1)',
              borderColor: 'rgba(112, 173, 71, 1)',
              valor: ceil10(dados.diferencaDeCofins, -2),
            },
            {
              label: 'Margem de Custo de Cartão',
              backgroundColor: 'rgba(10, 224, 196, 1)',
              borderColor: 'rgba(10, 224, 196, 1)',
              valor: ceil10(dados.margemDeCustoDeCartao, -2),
            },
            {
              label: 'Diferença de PIS',
              backgroundColor: 'rgba(7, 15, 49, 1)',
              borderColor: 'rgba(7, 15, 49, 1)',
              valor: ceil10(dados.diferencaDePis, -2),
            },
            {
              label: 'Repasse do IPI',
              backgroundColor: 'rgba(200, 165, 165, 1)',
              borderColor: 'rgba(200, 165, 165, 1)',
              valor: ceil10(dados.repasseDoIpiPorcentagemVenda, -2),
            },
            {
              label: 'Repasse do Frete',
              backgroundColor: 'rgba(90, 1, 213, 1)',
              borderColor: 'rgba(90, 1, 213, 1)',
              valor: ceil10(dados.repasseDoFreteUnitarioPorcentagemVenda, -2),
            },
            {
              label: 'Repasse do ST',
              backgroundColor: 'rgba(200, 0, 0, 1)',
              borderColor: 'rgba(200, 0, 0, 1)',
              valor: ceil10(dados.repasseDeStPorcentagemVenda, -2),
            },
            {
              label: 'Repasse do Seguro',
              backgroundColor: 'rgba(60, 60, 6, 1)',
              borderColor: 'rgba(60, 60, 6, 1)',
              valor: ceil10(dados.repasseDeSegurosPorcentagemVenda, -2),
            },
            {
              label: 'Repasse de Outras Despesas',
              backgroundColor: 'rgba(5, 5, 5, 1)',
              borderColor: 'rgba(5, 5, 5, 1)',
              valor: ceil10(dados.repasseDeOutrasDespesasPorcentagemVenda, -2),
            },
            {
              label: 'ICMS de Fronteira',
              backgroundColor: 'rgba(100, 50, 100, 1)',
              borderColor: 'rgba(100, 50, 100)',
              valor: ceil10(dados.icmsDeFronteiraAdotadoPorcentagemVenda, -2),
            },
            {
              label: 'ICMS de Antecipação',
              backgroundColor: 'rgba(10, 20, 250, 1)',
              borderColor: 'rgba(10, 20, 250, 1)',
              valor: ceil10(dados.icmsAntecipacaoPorcentagemVenda, -2),
            },
            {
              label: 'Desconto Total',
              backgroundColor: 'rgba(37, 205, 200, 1)',
              borderColor: 'rgba(37, 205, 200, 1)',
              valor: ceil10(-1 * dados.descontoTotal, -2),
            },
          ],
          dados.precoVenda,
        );
      }
    }

    async function handleSalvar() {
      if (produto) {
        try {
          enqueueSnackbar('Produto atualizado com sucesso!', {
            variant: 'success',
          });
          handleCloseDialog(
            {
              valor_negociado_com_a_industria:
                linhas[0].dadosOriginais.valorNegociadoComAIndustria,
              custo_operacional:
                linhas[0].dadosOriginais.margemDeCustoOperacional,
              diferenca_de_icms: linhas[0].dadosOriginais.diferencaDeIcms,
              lucro_bruto: linhas[0].dadosOriginais.lucroBruto,
              diferenca_de_cofins: linhas[0].dadosOriginais.diferencaDeCofins,
              custo_de_cartao: linhas[0].dadosOriginais.margemDeCustoDeCartao,
              difereca_de_pis: linhas[0].dadosOriginais.diferencaDePis,
              repasse_do_ipi:
                linhas[0].dadosOriginais.repasseDoIpiPorcentagemVenda,
              repasse_do_frete:
                linhas[0].dadosOriginais.repasseDoFreteUnitarioPorcentagemVenda,
              repasse_de_st:
                linhas[0].dadosOriginais.repasseDeStPorcentagemVenda,
              repasse_do_seguro:
                linhas[0].dadosOriginais.repasseDeSegurosPorcentagemVenda,
              repasse_de_outras_despesas:
                linhas[0].dadosOriginais
                  .repasseDeOutrasDespesasPorcentagemVenda,
              custo_final_do_produto:
                linhas[0].dadosOriginais.custoFinalDoProduto,
              preco_de_venda: linhas[0].dadosOriginais.precoVenda,
              repasse_do_ipi_distribuido: 0,
              repasse_do_frete_distribuido: 0,
              repasse_de_st_distribuido: 0,
              repasse_do_seguro_distribuido: 0,
              repasse_de_outras_despesas_distribuido: 0,
              //
              taxa_irpj: linhas[0].dadosOriginais.irpjPorcentagemLb, //
              taxa_csll: linhas[0].dadosOriginais.csllPorcentagemLb, //
              taxa_lucro_bruto: linhas[0].dadosOriginais.lucroBrutoPorcentagem, //
              taxa_lucro_liquido:
                linhas[0].dadosOriginais.lucroLiquidoPorcentagem, //
              taxa_pis_compra: linhas[0].dadosOriginais.pisCompraPorcentagem, //
              taxa_pis_venda: linhas[0].dadosOriginais.pisVendaPorcentagem, //
              taxa_cofins_compra:
                linhas[0].dadosOriginais.cofinsCompraPorcentagem, //
              taxa_cofins_venda:
                linhas[0].dadosOriginais.cofinsVendaPorcentagem, //
              taxa_icms_antecipacao:
                linhas[0].dadosOriginais.icmsAntecipacaoPorcentagem, //
              taxa_icms_compra:
                linhas[0].dadosOriginais.aliquotaInternaCompraPorcentagem, //
              taxa_icms_venda:
                linhas[0].dadosOriginais.aliquotaInternaVendaPorcentagem, //
              agregado_do_cnae:
                linhas[0].dadosOriginais.agregadoDoCnaePorcentagem, //
              mva_da_pauta: linhas[0].dadosOriginais.mvaDaPauta, //
              icms_compra: linhas[0].dadosOriginais.aliquotaInternaCompra, //
              icms_venda: linhas[0].dadosOriginais.aliquotaInternaVenda,
              icms_fronteira: linhas[0].dadosOriginais.icmsDeFronteiraAdotado, //
              icms_antecipacao: linhas[0].dadosOriginais.icmsAntecipacao, //
              pis_compra: linhas[0].dadosOriginais.pisCompra, //
              pis_venda: linhas[0].dadosOriginais.pisVenda, //
              cofins_compra: linhas[0].dadosOriginais.cofinsCompra, //
              cofins_venda: linhas[0].dadosOriginais.cofinsVenda, //
              lucro_liquido: linhas[0].dadosOriginais.lucroLiquido, //
              irpj: linhas[0].dadosOriginais.irpj, //
              csll: linhas[0].dadosOriginais.csll, //
              //
              taxa_custo_operacional:
                linhas[0].dadosOriginais.margemDeCustoOperacionalPorcentagem,
              taxa_custo_de_cartao:
                linhas[0].dadosOriginais.margemDeCustoDeCartaoPorcentagem,
              ///////
              desconto_em_nfe: linhas[0].dadosOriginais.descontoNfe,
              desconto_em_boleto: linhas[0].dadosOriginais.descontoBoleto,
              desconto_em_bonificacao:
                linhas[0].dadosOriginais.descontoBonificacao,
              desconto_em_backlight_e_ponto_extra:
                linhas[0].dadosOriginais.descontoBacklight,
              desconto_em_jornal_de_ofertas:
                linhas[0].dadosOriginais.descontoJornalOfertas,
              desconto_em_descargo_de_mercadorias:
                linhas[0].dadosOriginais.descontoDescargoMercadorias,
              ////////////////
              taxa_avarias: linhas[0].dadosOriginais.margemDeAvariaPorcentagem,
              custo_avarias: linhas[0].dadosOriginais.margemDeAvaria,
            },
            isMargemCartao,
          );
        } catch (erros) {
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        enqueueSnackbar('Ocorreu um erro ao carregar os dados', {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    function validateMargemDeLucro() {
      let error = '';
      if (margemDeLucro < 0 || Number.isNaN(margemDeLucro)) {
        error = 'Este campo é obrigatório';
        //} else if (margemDeLucro > (produto ? produto.taxa_de_lucro : 0)) {
      } /*else if (margemDeLucro > (produto ? produto.taxa_de_lucro : 0)) {
        error = `A margem de Lucro não pode ser maior que a margem inicial ${
          produto ? produto.taxa_de_lucro : 0
        }%`;
      }*/
      return error;
    }

    const precoVendaMinimoAceitavel = getCustoFinal(dadosOriginais);

    function validatePrecoVenda() {
      let error = '';
      if (produto) {
        const precoPrecificado = dadosOriginais.precoVenda;
        if (precoPrecificado <= 0) {
          error = 'O preço de Venda não pode ser negativo';
        } /* else if (precoPrecificado < precoVendaMinimoAceitavel) {
          error = `O preço de Venda deve ser maior que o mínimo aceitável ${formatMoeda(
            precoVendaMinimoAceitavel,
          )}`;
        } */
      } else {
        error = 'A informação do produto é obrigatória';
      }
      return error;
    }

    function getErros() {
      const errosImpostos = [''];
      errosImpostos[0] = validateMargemDeLucro();
      errosImpostos[1] = validatePrecoVenda();

      return errosImpostos;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    /*function retornaDados() {
      if (linhas.length > 0) {
        return linhas[0];
      }
      return {};
    }

    const {
      valorNegociadoComAIndustriaPorcentagem,
      valorNegociadoComAIndustria,
      valorNegociadoComAIndustriaTotal,
      custoLivreDeImpostoPorcentagem,
      lucroLiquidoPorcentagem,
      lucroLiquido,
      lucroLiquidoTotal,
      irpjPorcentagemPv,
      irpjPorcentagemLb,
      irpj,
      irpjTotal,
      csllPorcentagemPv,
      csllPorcentagemLb,
      csll,
      csllTotal,
      lucroBrutoPorcentagem,
      lucroBruto,
      lucroBrutoTotal,
      precoVenda,
      precoVendaTotal,
      estadoOrigem,
      aliquotaInternaCompraPorcentagem,
      aliquotaInternaCompra,
      aliquotaInternaCompraTotal,
      pisCompraPorcentagem,
      pisCompra,
      pisCompraTotal,
      cofinsCompraPorcentagem,
      cofinsCompra,
      cofinsCompraTotal,
      totalDeCreditosCompraPorcentagem,
      totalDeCreditosCompra,
      totalDeCreditosCompraTotal,
      aliquotaInternaVendaPorcentagem,
      aliquotaInternaVenda,
      aliquotaInternaVendaTotal,
      pisVendaPorcentagem,
      pisVenda,
      pisVendaTotal,
      cofinsVendaPorcentagem,
      cofinsVenda,
      cofinsVendaTotal,
      totalDeDebitosVendaPorcentagem,
      totalDeDebitosVenda,
      totalDeDebitosVendaTotal,
      icmsAntecipacaoPorcentagem,
      icmsAntecipacao,
      icmsAntecipacaoTotal,
      agregadoDoCnaePorcentagem,
      mvaDaPauta,
      calculoDaPautaDeIcmsDeFronteira,
      fronteiraPorcentagem,
      fronteira,
      icmsDeFronteiraAdotadoPorcentagem,
      icmsDeFronteiraAdotado,
      icmsDeFronteiraAdotadoTotal,
      totalDeIcmsAntecipadoPorcentagem,
      totalDeIcmsAntecipado,
      totalDeIcmsAntecipadoTotal,
      diferencaDeIcmsPorcentagem,
      diferencaDeIcms,
      diferencaDeIcmsTotal,
      diferencaDePisPorcentagem,
      diferencaDePis,
      diferencaDePisTotal,
      diferencaDeCofinsPorcentagem,
      diferencaDeCofins,
      diferencaDeCofinsTotal,
      tributacaoEfetivaPorcentagem,
      tributacaoEfetiva,
      tributacaoEfetivaTotal,
      resultadoDoFluxoDeCaixaPorcentagem,
      resultadoDoFluxoDeCaixa,
      resultadoDoFluxoDeCaixaTotal,
      margemDeCustoOperacionalPorcentagem,
      margemDeCustoOperacional,
      margemDeCustoOperacionalTotal,
      margemDeCustoDeCartaoPorcentagem,
      margemDeCustoDeCartao,
      margemDeCustoDeCartaoTotal,
      repasseDoIpiPorcentagem,
      repasseDoIpi,
      repasseDoIpiTotal,
      repasseDoFreteUnitarioPorcentagem,
      repasseDoFreteUnitario,
      repasseDoFreteUnitarioTotal,
      repasseDeStPorcentagem,
      repasseDeSt,
      repasseDeStTotal,
      repasseDeSegurosPorcentagem,
      repasseDeSeguros,
      repasseDeSegurosTotal,
      repasseDeOutrasDespesasPorcentagem,
      repasseDeOutrasDespesas,
      repasseDeOutrasDespesasTotal,
      totalDeCustosDeRepassesPorcentagem,
      totalDeCustosDeRepasses,
      totalDeCustosDeRepassesTotal,
      custoFinalDoProdutoPorcentagem,
      custoFinalDoProduto,
      custoFinalDoProdutoTotal,
      conferenciaDoGraficoDeSecaoCircular,
      margemTotalPorcentagem,
      margemTotal,
      margemTotalTotal,
      dataDeValidade,
      quantidadeComprada,
      dadosOriginais,
    } = retornaDados();*/

    return (
      <div>
        <Dialog
          open={open}
          onClose={() => handleCloseDialog()}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={() => handleCloseDialog()}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Precificação - ${produto ? produto.nome : ''}`}
          </DialogTitle>
          <DialogContent dividers>
            <div className={classes.root}>
              {/* <Typography
                variant="h4"
                gutterBottom
                style={{ marginTop: '10px', textAlign: 'center' }}
              >
                Informações
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div style={{ flex: 1 }}>
                  <PrecoInput
                    name="preco_venda_original"
                    label="Preço de Venda Atual"
                    handleEnter={() => {}}
                    handleKey={() => {}}
                    value={
                      Number.isNaN(produto ? produto.preco_venda : 0)
                        ? ''
                        : String(produto ? produto.preco_venda : 0)
                    }
                    onChange={(value) => {}}
                    fullWidth
                    escala={2}
                    disabled
                  />
                </div>
              </Box> */}
              <Typography
                variant="h4"
                gutterBottom
                style={{ marginTop: '10px', textAlign: 'center' }}
              >
                Dados de Entrada
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <PorcentagemInput
                      name="taxa_lucro"
                      label="Margem de Lucro Bruto"
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      value={
                        Number.isNaN(margemDeLucro) ? '' : String(margemDeLucro)
                      }
                      onChange={(value) => {
                        setMargemDeLucro(parseFloat(value.target.value));
                      }}
                      fullWidth
                      escala={2}
                      error={erros[0] !== ''}
                      helperText={erros[0]}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      //label="Incluir Margem de Cartão"
                      label="Mudar pra Liberar Venda em Dinheiro / PIX à Distância"
                      checked={isMargemCartao}
                      onChange={(e) => setIsMargemCartao(e.target.checked)}
                    />
                  </div>
                </div>
              </Box>
              {/*<Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: '10px', marginBottom: '20px' }}
              >
                Valores Cálculados
              </Typography>
            <Box
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginBottom: '25px',
                }}
              >
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Preço de Venda:
                  </span>
                  {`${formatMoeda(linhas[0].dadosOriginais.precoVenda)}`}
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Custo Final do Produto:
                  </span>
                  {`${formatMoeda(
                    linhas[0].dadosOriginais.custoFinalDoProduto,
                  )}`}
                </div>
                  </Box>*/}

              {/*            <div style={{ overflowX: 'scroll' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ marginTop: '10px' }}
                  >
                    Análise
                  </Typography>
                  {linhas.length > 0 ? (
                    <Tooltip title="Gráfico de pizza">
                      <IconButton
                        aria-label="delete"
                        onClick={() => abrirGrafico(linhas[0].dadosOriginais)}
                      >
                        <DataUsageIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </div>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={8}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        Geral
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={2}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Preço de Venda Calculado
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                        }}
                      >
                        Valor Negociado com a indústria
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Custo final do produto
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'yellow',
                          fontSize: '30px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        {precoVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {precoVendaTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {valorNegociadoComAIndustriaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {valorNegociadoComAIndustria}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {valorNegociadoComAIndustriaTotal}
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoFinalDoProdutoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'yellow',
                          fontSize: '30px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        {custoFinalDoProduto}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoFinalDoProdutoTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                        }}
                      >
                        Conferência do Gráfico de Seção Circular
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                        }}
                      >
                        Margem Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PRECO_VENDA,
                        }}
                      >
                        Quantidade Comprada
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {conferenciaDoGraficoDeSecaoCircular}
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemTotalPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemTotalTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {quantidadeComprada}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={15}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        PREVISÃO DE LUCRO
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        <Tooltip title="Custo Livre de Impostos">
                          <div>CLI</div>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        Lucro Líquido
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={4}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        IRPJ
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={4}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        CSLL
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_PREVISAO_LUCRO,
                        }}
                      >
                        Lucro Bruto
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % PV
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % do LB
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % PV
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % do LB
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        % do LB
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoLivreDeImpostoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroLiquidoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroLiquido}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroLiquidoTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {irpjPorcentagemPv}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {irpjPorcentagemLb}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {irpj}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {irpjTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {csllPorcentagemPv}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {csllPorcentagemLb}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {csll}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {csllTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroBrutoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroBruto}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {lucroBrutoTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={13}
                        rowSpan={1}
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        Crédito de Compra
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={4}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        ALÍQUOTA INTERNA / ICMS NORMAL
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        PIS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        COFINS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CREDITO_COMPRA,
                        }}
                      >
                        TOTAL DE CRÉDITOS
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        Estado
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {estadoOrigem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaCompraTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisCompraTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsCompraTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCreditosCompraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCreditosCompra}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCreditosCompraTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={13}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        Débito de Venda
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        ALÍQUOTA INTERNA / ICMS NORMAL
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        PIS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        CONFINS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_DEBITO_VENDA,
                        }}
                      >
                        TOTAL DE DÉBITOS
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaVendaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {aliquotaInternaVendaTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisVendaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {pisVendaTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsVendaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {cofinsVendaTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeDebitosVendaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeDebitosVenda}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeDebitosVendaTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={15}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        Análise de Fluxo de Caixa
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        <Tooltip
                          title="Usado em caso de compras de dentro do
                        estado. Havendo Antecipação não pode existir Fronteira."
                        >
                          <div>ICMS ANTECIPAÇÃO</div>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={8}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        ICMS de Fronteira
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={2}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        <Tooltip
                          title="A SER COLOCADO COMO CRÉDITO NO
                          ICMS NORMAL DO PRÓXIMO MÊS"
                        >
                          <div>TOTAL DE ICMS ANTECIPADO</div>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        <Tooltip
                          title="30% para produtos normais e 35% para
                        derivados do leite"
                        >
                          <div>Agregado do CNAE</div>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        MVA da Pauta
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Cálculo da Pauta de ICMS de Fronteira
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={2}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        <Tooltip
                          title="Usado em caso de compras de fora do estado.
                          Havendo Fronteira não pode haver Antecipação."
                        >
                          <div>FRONTEIRA</div>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        ICMS de Fronteira Adotado
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsAntecipacaoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsAntecipacao}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsAntecipacaoTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {agregadoDoCnaePorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {mvaDaPauta}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {calculoDaPautaDeIcmsDeFronteira}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {fronteiraPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {fronteira}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsDeFronteiraAdotadoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsDeFronteiraAdotado}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {icmsDeFronteiraAdotadoTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeIcmsAntecipadoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeIcmsAntecipado}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeIcmsAntecipadoTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Diferença de ICMS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Diferença de PIS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        Diferença de COFINS
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        TRIBUTAÇÃO EFETIVA
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_ANALISE_FLUXO_CAIXA,
                        }}
                      >
                        RESULTADO DO FLUXO DE CAIXA
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeIcmsPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeIcms}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeIcmsTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDePisPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDePis}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDePisTotal}
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeCofinsPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeCofins}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {diferencaDeCofinsTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {tributacaoEfetivaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {tributacaoEfetiva}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {tributacaoEfetivaTotal}
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {resultadoDoFluxoDeCaixaPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {resultadoDoFluxoDeCaixa}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {resultadoDoFluxoDeCaixaTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={27}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        Custos Adicionais
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse do IPI
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse de Frete Unitário
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        <Tooltip title="Substituição Tributária">
                          <div>Repasse de ST</div>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse de Seguros
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Repasse de Outras Despesas
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoIpiPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoIpi}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoIpiTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoFreteUnitarioPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoFreteUnitario}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDoFreteUnitarioTotal}
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeStPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeSt}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeStTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeSegurosPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeSeguros}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeSegurosTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeOutrasDespesasPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeOutrasDespesas}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {repasseDeOutrasDespesasTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Margem de Custo operacional
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Margem de Custo de cartão
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Total de Custos de Repasses
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={3}
                        rowSpan={1}
                        style={{
                          backgroundColor: BACKGROUND_CUSTOS_ADICIONAIS,
                        }}
                      >
                        Custo final do produto
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        R$ Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoOperacionalPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoOperacional}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoOperacionalTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoDeCartaoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoDeCartao}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {margemDeCustoDeCartaoTotal}
                      </StyledTableCell>

                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCustosDeRepassesPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCustosDeRepasses}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {totalDeCustosDeRepassesTotal}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoFinalDoProdutoPorcentagem}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoFinalDoProduto}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={1}
                        rowSpan={1}
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {custoFinalDoProdutoTotal}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div> */}

              <Typography
                variant="h4"
                gutterBottom
                style={{ marginTop: '10px', textAlign: 'center' }}
              >
                Análise
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TabelaPrecos
                  produto={produto}
                  quantidade={dadosOriginais.quantidadeComprada}
                  precoCompra={dadosOriginais.valorNegociadoComAIndustria}
                  precoVendaPrecificado={dadosOriginais.precoVenda}
                  precoAnteriorVenda={produto ? produto.preco_venda : 0}
                  dadosComplementaresNota={null}
                  margemContribuicao={
                    dadosOriginais.precoVenda -
                    dadosOriginais.custoFinalDoProduto
                  }
                  custoFinalProduto={dadosOriginais.custoFinalDoProduto}
                  margemLucroBrutoPorecentagem={
                    dadosOriginais.lucroBrutoPorcentagem
                  }
                  margemLucroBruto={
                    (dadosOriginais.lucroBrutoPorcentagem *
                      dadosOriginais.precoVenda) /
                    100
                  }
                  ofertaZerarEstoque={0}
                  ofertaJornal={0}
                  repasseDoIpi={dadosOriginais.repasseDoIpi}
                  repasseDoFreteUnitario={dadosOriginais.repasseDoFreteUnitario}
                  repasseDeSt={dadosOriginais.repasseDeSt}
                  repasseDeSeguros={dadosOriginais.repasseDeSeguros}
                  repasseDeOutrasDespesas={
                    dadosOriginais.repasseDeOutrasDespesas
                  }
                  //
                  irpj={dadosOriginais.irpj} // ok
                  csll={dadosOriginais.csll} // ok
                  margemLucroLiquido={dadosOriginais.lucroLiquido} // ok
                  custoLivreDeImpostos={
                    dadosOriginais.custoLivreDeImpostoPorcentagem
                  } // ok
                  baseDeCalculoIcms={dadosOriginais.baseCalculoIcmsUnitaria} // ok
                  icmsNormalCredito={dadosOriginais.aliquotaInternaCompra} // ok
                  pisCredito={dadosOriginais.pisCompra} // ok
                  cofinsCredito={dadosOriginais.cofinsCompra} // ok
                  antecipacaoIcmsNormal={dadosOriginais.icmsAntecipacao} // ok
                  agredadoCnae={dadosOriginais.agregadoDoCnaePorcentagem} // ok
                  mvaPauta={dadosOriginais.mvaDaPauta} // ok
                  calculoPautaIcmsFronteira={
                    dadosOriginais.calculoDaPautaDeIcmsDeFronteira
                  } // ok
                  icmsFronteiraCalculado={dadosOriginais.fronteira} // ok
                  icmsFronteriaAdotado={dadosOriginais.icmsDeFronteiraAdotado} // ok
                  icmsNormalDebito={dadosOriginais.aliquotaInternaVenda} // ok
                  pisDebito={dadosOriginais.pisVenda} // ok
                  cofinsDebito={dadosOriginais.cofinsVenda} // ok
                  diferencaIcmsNormal={dadosOriginais.diferencaDeIcms} // ok
                  diferencaPis={dadosOriginais.diferencaDePis} // ok
                  diferencaCofins={dadosOriginais.diferencaDeCofins} // ok
                  tributacaoEfetiva={dadosOriginais.tributacaoEfetiva} // ok
                  resultadoFluxoCaixa={dadosOriginais.resultadoDoFluxoDeCaixa} // ok
                  custoOperacional={dadosOriginais.margemDeCustoOperacional} // ok
                  cartaoCredito={dadosOriginais.margemDeCustoDeCartao} // ok
                  margemCustoOperacional={
                    dadosOriginais.margemDeCustoOperacionalPorcentagem
                  } // ok
                  margemCartaoCredito={
                    dadosOriginais.margemDeCustoDeCartaoPorcentagem
                  } // ok
                  //
                  descontoNfe={dadosOriginais.descontoNfe} // ok
                  descontoBoleto={dadosOriginais.descontoBoleto} // ok
                  descontoBonificacao={dadosOriginais.descontoBonificacao} // ok
                  descontoBacklight={dadosOriginais.descontoBacklight} // ok
                  descontoJornalOfertas={dadosOriginais.descontoJornalOfertas} // ok
                  descontoDescargoMercadorias={
                    dadosOriginais.descontoDescargoMercadorias
                  } // ok
                  //
                  taxaIcmsEntrada={
                    dadosOriginais.aliquotaInternaCompraPorcentagem
                  } // ok
                  taxaPisEntrada={dadosOriginais.pisCompraPorcentagem} // ok
                  taxaCofinsEntrada={dadosOriginais.cofinsCompraPorcentagem} // ok
                  //
                  taxaIcmsAntecipacao={
                    dadosOriginais.icmsAntecipacaoPorcentagem
                  } // ok
                  taxaIcmsSaida={dadosOriginais.aliquotaInternaVendaPorcentagem} // ok
                  taxaPisSaida={dadosOriginais.pisVendaPorcentagem} // ok
                  taxaCofinsSaida={dadosOriginais.cofinsVendaPorcentagem} // ok
                  markup={10}
                  //
                  custoAvarias={dadosOriginais.margemDeAvaria} // ok
                  margemCustoAvarias={dadosOriginais.margemDeAvariaPorcentagem} // ok
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleCloseDialog()}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <DialogoGrafico ref={refDialogoGrafico} />
      </div>
    );
  },
);

export default DialogoBoletos;
