import React, { useState } from 'react';
import {
  Equation,
  EquationContext,
  EquationEvaluate,
  EquationOptions,
  defaultErrorHandler,
} from 'react-equation';

import {
  withStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { defaultVariables, defaultFunctions } from 'equation-resolver';

import {
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  formatPeso,
  formatMoeda,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
  formatPreco,
  formatPorcentagem,
} from '../../../../../../utils';

function formatPreco2(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? valor % 1 === 0
      ? valor
      : `${valor.toLocaleString('de-DE', {
          minimumFractionDigits: casasDecimais,
          maximumFractionDigits: casasDecimais,
        })}`
    : '';
}

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: '#f5f5f9',
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 'none',
    fontSize: theme.typography.pxToRem(12),
    border: '3px solid black',
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    //width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '7px',
    border: '1px solid black',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    width: 'min-content',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '7px',
    border: '1px solid black',
    width: 'min-content',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    //minWidth: 650,
  },
  paper: {
    height: 300,
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'auto',
  },
}));

function getPorcentagens(obj, campos, divisor) {
  const obj2 = { ...obj };
  for (let i = 0; i < campos.length; i++) {
    obj2[campos[i]] = (obj2[campos[i]] / divisor) * 100;
  }
  return obj2;
}

const BACKGROUND_GRUPO_VENDA = '#ffff0075';
const BACKGROUND_GRUPO_LUCRO = '#92d050a7';
const BACKGROUND_GRUPO_ENTRADA = '#c4ecac';
const BACKGROUND_GRUPO_CREDITO_ENTRADA = '#a0d7d6ac';
const BACKGROUND_GRUPO_CREDITOS_ADICIONAIS = '#a0d7d662';
const BACKGROUND_GRUPO_DEBITO_SAIDA = '#fef2cb';
const BACKGROUND_GRUPO_ANALISE_FLUXO_CAIXA = '#fbe4d5';
const BACKGROUND_GRUPO_REPASSES = '#de74518b';
const BACKGROUND_GRUPO_DESCONTOS = '#13d45a70';

function getLabelComVarivel(label, variavel, subVariavel) {
  return (
    <>
      {variavel ? (
        <p>
          {`${label} (`} <span style={{ color: 'red' }}>{`${variavel}`}</span>{' '}
          <sub style={{ color: 'red' }}>{`${subVariavel}`}</sub> )
        </p>
      ) : (
        <p>{`${label}`}</p>
      )}
    </>
  );
}

function getDadosKpi(dados) {
  const {
    valor,
    valorNota,
    produto,
    formatador = formatMoeda,
    isQtde = true,
    qtde,
  } = dados;

  const formatador2 = isQtde ? (value) => value : formatMoeda;

  if (produto) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        const unidade = valor ? formatador2(valor) : '-';
        let caixariaNivel1 = '-';
        let caixariaNivel2 = '-';
        let caixariaNivel3 = '-';
        let total = valor * qtde;
        total = isQtde ? '-' : total ? formatador2(total) : '-';
        const isFatorQtde = isQtde ? -1 : 1;

        caixariaNivel1 =
          produto.unidades_caixa_nivel_1 > 0 && valor > 0
            ? formatador2(valor * produto.unidades_caixa_nivel_1 ** isFatorQtde)
            : '-';

        caixariaNivel2 =
          produto.unidades_caixa_nivel_2 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel3 =
          produto.unidades_caixa_nivel_3 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3) **
                    isFatorQtde,
              )
            : '-';
        return {
          peso: '-',
          unidade,
          nota: valorNota,
          caixariaNivel1,
          caixariaNivel2,
          caixariaNivel3,
          total,
        };
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        const peso = valor
          ? isQtde
            ? `${formatPeso(valor)}`
            : formatador2(valor)
          : '-';
        let caixariaNivel1 = '-';
        let caixariaNivel2 = '-';
        let caixariaNivel3 = '-';
        let total = valor * qtde;
        total = isQtde ? '-' : total ? formatador2(total) : '-';
        const isFatorQtde = isQtde ? -1 : 1;

        caixariaNivel1 =
          produto.peso_caixa_nivel_1 > 0 && valor > 0
            ? formatador2(valor * produto.peso_caixa_nivel_1 ** isFatorQtde)
            : '-';

        caixariaNivel2 =
          produto.unidades_caixa_nivel_2 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel3 =
          produto.unidades_caixa_nivel_3 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.peso_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3) **
                    isFatorQtde,
              )
            : '-';
        return {
          peso,
          unidade: '-',
          nota: valorNota,
          caixariaNivel1,
          caixariaNivel2,
          caixariaNivel3,
          total,
        };
      }
      case POR_PESO_VARIAVEL: {
        return {
          peso: '-',
          unidade: '-',
          nota: '-',
          caixariaNivel1: '-',
          caixariaNivel2: '-',
          caixariaNivel3: '-',
          total: '-',
        };
      }
      case POR_PESO_FIXO: {
        const peso = valor
          ? isQtde
            ? `${formatPeso(valor * produto.peso_medio)}`
            : formatador2(valor)
          : '-';
        const unidade = valor
          ? isQtde
            ? `${valor}`
            : formatador2(valor * produto.peso_medio)
          : '-';
        let caixariaNivel1 = '-';
        let caixariaNivel2 = '-';
        let caixariaNivel3 = '-';
        let total = valor * produto.peso_medio * qtde;
        total = isQtde ? '-' : total ? formatador2(total) : '-';
        const isFatorQtde = isQtde ? -1 : 1;
        const isFatorPesoMedio = isQtde ? 1 : produto.peso_medio;

        caixariaNivel1 =
          produto.unidades_caixa_nivel_1 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 * isFatorPesoMedio) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel2 =
          produto.unidades_caixa_nivel_2 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    isFatorPesoMedio) **
                    isFatorQtde,
              )
            : '-';

        caixariaNivel3 =
          produto.unidades_caixa_nivel_3 > 0 && valor > 0
            ? formatador2(
                valor *
                  (produto.unidades_caixa_nivel_1 *
                    produto.unidades_caixa_nivel_2 *
                    produto.unidades_caixa_nivel_3 *
                    isFatorPesoMedio) **
                    isFatorQtde,
              )
            : '-';
        return {
          peso,
          unidade,
          nota: valorNota,
          caixariaNivel1,
          caixariaNivel2,
          caixariaNivel3,
          total,
        };
      }
    }
  }
  return {
    peso: '-',
    unidade: '-',
    nota: '-',
    caixariaNivel1: '-',
    caixariaNivel2: '-',
    caixariaNivel3: '-',
    total: '-',
  };
}

function RowKpi({
  label,
  name,
  valor,
  valorNota,
  produto,
  isQtde,
  qtde,
  isDadosComplementares,
  color,
  nRows = 0,
  labelGrupo = '',
  porcentagemCompra = '-',
  porcentagemVenda = '-',
  minimizado,
  onChangeMinimizado = () => {},
  negrito,
  corGrupo,
  totalVisivel = true,
  dica,
}) {
  const {
    caixariaNivel1,
    caixariaNivel2,
    caixariaNivel3,
    nota,
    peso,
    unidade,
    total,
  } = getDadosKpi({
    valor,
    valorNota,
    produto,
    isQtde,
    qtde,
  });

  const style = negrito
    ? {
        backgroundColor: color ? color : corGrupo ? corGrupo : 'white',
        fontWeight: 'bold',
      }
    : {
        backgroundColor: color ? color : corGrupo ? corGrupo : 'white',
      };

  return (
    <>
      {!minimizado ? (
        <StyledTableRow key={name}>
          {nRows ? (
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={{
                backgroundColor: corGrupo ? corGrupo : 'white',
              }}
              rowSpan={nRows}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                    display: 'flex',
                    margin: '0 auto',
                  }}
                >
                  {labelGrupo}
                </span>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onChangeMinimizado()}
                >
                  <RemoveCircleIcon color="error" />
                </IconButton>
              </div>
            </StyledTableCell>
          ) : null}
          {dica ? (
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={style}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {label}
                <HtmlTooltip title={dica} arrow>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    style={{ marginLeft: '10px' }}
                  >
                    <HelpIcon />
                  </IconButton>
                </HtmlTooltip>
              </div>
            </StyledTableCell>
          ) : (
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={style}
            >
              {label}
            </StyledTableCell>
          )}

          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {peso}
          </StyledTableCell>
          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {unidade}
          </StyledTableCell>
          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {caixariaNivel1}
          </StyledTableCell>
          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {caixariaNivel2}
          </StyledTableCell>
          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {caixariaNivel3}
          </StyledTableCell>
          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {totalVisivel ? total : '-'}
          </StyledTableCell>

          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {porcentagemCompra}
          </StyledTableCell>
          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={style}
          >
            {porcentagemVenda}
          </StyledTableCell>
          {isDadosComplementares ? (
            <StyledTableCell
              component="th"
              scope="row"
              align="center"
              style={style}
            >
              {nota ? `${nota}` : '-'}
            </StyledTableCell>
          ) : null}
        </StyledTableRow>
      ) : nRows !== 0 ? (
        <StyledTableRow key={name}>
          <StyledTableCell
            component="th"
            scope="row"
            align="center"
            style={{
              backgroundColor: corGrupo ? corGrupo : 'white',
            }}
            colSpan={isDadosComplementares ? 11 : 10}
          >
            <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {labelGrupo}
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => onChangeMinimizado()}
              >
                <AddCircleIcon color="error" />
              </IconButton>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      ) : null}
    </>
  );
}

function GrupoRowKpi({
  label,
  dataRows,
  produto,
  quantidade,
  isDadosComplementares,
  minimizadoDefault = true,
  corGrupo = 'white',
}) {
  const [minimizado, setMinimizado] = useState(minimizadoDefault);
  const nRows = dataRows.length;
  return (
    <>
      {dataRows.map((item, index) => (
        <RowKpi
          name={item.name}
          label={item.label}
          valor={item.valor}
          color={item.color}
          valorNota={item.valorNota}
          produto={produto}
          isQtde={item.isQtde}
          qtde={quantidade}
          isDadosComplementares={isDadosComplementares}
          nRows={index === 0 ? nRows : 0}
          labelGrupo={index === 0 ? label : ''}
          porcentagemCompra={item.porcentagemCompra}
          porcentagemVenda={item.porcentagemVenda}
          minimizado={minimizado}
          onChangeMinimizado={() => setMinimizado(!minimizado)}
          negrito={item.negrito ? true : false}
          corGrupo={corGrupo}
          totalVisivel={item.totalVisivel}
          dica={item.dica}
        />
      ))}
    </>
  );
}

export default function Tabela({
  produto,
  quantidade,
  precoCompra,
  precoVendaPrecificado,
  dadosComplementaresNota,
  fatorPesoMedio,
  baseDeCalculo,
  margemContribuicao,
  margemLucroBruto,
  custoFinalProduto,
  ofertaZerarEstoque,
  ofertaJornal,
  repasseDoIpi,
  repasseDoFreteUnitario,
  repasseDeSt,
  repasseDeSeguros,
  repasseDeOutrasDespesas,
  precoAnteriorVenda,
  margemLucroBrutoPorecentagem,
  //
  irpj,
  csll,
  margemLucroLiquido,
  custoLivreDeImpostos,
  baseDeCalculoIcms,
  icmsNormalCredito,
  pisCredito,
  cofinsCredito,
  //totalCredito,
  antecipacaoIcmsNormal,
  agredadoCnae,
  mvaPauta,
  calculoPautaIcmsFronteira,
  icmsFronteiraCalculado,
  icmsFronteriaAdotado,
  icmsNormalDebito,
  pisDebito,
  cofinsDebito,
  //totalDebitos,
  diferencaIcmsNormal,
  diferencaPis,
  diferencaCofins,
  tributacaoEfetiva,
  resultadoFluxoCaixa,
  custoOperacional,
  cartaoCredito,

  margemCustoOperacional,
  margemCartaoCredito,
  //
  descontoNfe,
  descontoBoleto,
  descontoBonificacao,
  descontoBacklight,
  descontoJornalOfertas,
  descontoDescargoMercadorias,
  //
  taxaIcmsEntrada,
  taxaPisEntrada,
  taxaCofinsEntrada,
  //
  taxaIcmsAntecipacao,
  taxaIcmsSaida,
  taxaPisSaida,
  taxaCofinsSaida,
  //
  custoAvarias,
  margemCustoAvarias,

  precoMinimoAceitavel = 0,
}) {
  let unidades_caixa_nivel_1 = '';
  let unidades_caixa_nivel_2 = '';
  let unidades_caixa_nivel_3 = '';
  let peso_medio = '';

  let unidadesCaixariaNivel1 = 0;
  let unidadesCaixariaNivel2 = 0;
  let unidadesCaixariaNivel3 = 0;

  if (produto) {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.peso_caixa_nivel_1 > 0) {
        unidades_caixa_nivel_1 = `(${formatPeso(produto.peso_caixa_nivel_1)})`;
        unidadesCaixariaNivel1 = `(${formatPeso(produto.peso_caixa_nivel_1)})`;
      }
      if (produto.unidades_caixa_nivel_2 > 0) {
        unidades_caixa_nivel_2 = `(${
          produto.unidades_caixa_nivel_2
        } Cx1 = ${formatPeso(
          produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
        )})`;
        unidadesCaixariaNivel2 = produto.unidades_caixa_nivel_2;
      }
      if (produto.unidades_caixa_nivel_3 > 0) {
        unidades_caixa_nivel_3 = `(${
          produto.unidades_caixa_nivel_3
        } Cx2 = ${formatPeso(
          produto.peso_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3,
        )})`;
        unidadesCaixariaNivel3 = produto.unidades_caixa_nivel_3;
      }
    } else if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.unidades_caixa_nivel_1 > 0) {
        unidades_caixa_nivel_1 = `(${produto.unidades_caixa_nivel_1} Un)`;
        unidadesCaixariaNivel1 = produto.unidades_caixa_nivel_1;
      }
      if (produto.unidades_caixa_nivel_2 > 0) {
        unidades_caixa_nivel_2 = `(${produto.unidades_caixa_nivel_2} Cx1 = ${
          produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
        } Un)`;
        unidadesCaixariaNivel2 = produto.unidades_caixa_nivel_2;
      }
      if (produto.unidades_caixa_nivel_3 > 0) {
        unidades_caixa_nivel_3 = `(${produto.unidades_caixa_nivel_3} Cx2 = ${
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        } Un)`;
        unidadesCaixariaNivel3 = produto.unidades_caixa_nivel_3;
      }
    } else if (produto.unidade.tipo === POR_PESO_FIXO) {
      peso_medio = `(${formatPeso(produto.peso_medio)})`;
      if (produto.unidades_caixa_nivel_1 > 0) {
        unidades_caixa_nivel_1 = `(${
          produto.unidades_caixa_nivel_1
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 * produto.peso_medio,
        )})`;
        unidadesCaixariaNivel1 = produto.unidades_caixa_nivel_1;
      }
      if (produto.unidades_caixa_nivel_2 > 0) {
        unidades_caixa_nivel_2 = `(${produto.unidades_caixa_nivel_2} Cx1 = ${
          produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.peso_medio,
        )})`;
        unidadesCaixariaNivel2 = produto.unidades_caixa_nivel_2;
      }
      if (produto.unidades_caixa_nivel_3 > 0) {
        unidades_caixa_nivel_3 = `(${produto.unidades_caixa_nivel_3} Cx2 = ${
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        } Un = ${formatPeso(
          produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3 *
            produto.peso_medio,
        )})`;
        unidadesCaixariaNivel3 = produto.unidades_caixa_nivel_3;
      }
    }
  }

  const color = 'blue';
  const corLetra = 'white';

  const nomeProduto = produto ? produto.nome : '-';
  const margemLucroBruto1 = formatPorcentagem(margemLucroBrutoPorecentagem);
  /*  const baseDeCalculoIcms = formatMoeda(baseDeCalculo); */
  const pp = produto
    ? produto.unidade.tipo === POR_PESO_FIXO
      ? `${formatPeso(produto.peso_medio)}`
      : '-'
    : '-';

  const irpj2 = irpj;
  const csll2 = csll;
  const margemLucroLiquido2 = margemLucroLiquido;
  //
  const custoLivreDeImpostos2 = custoLivreDeImpostos;
  const baseDeCalculoIcms2 = baseDeCalculoIcms;
  //
  const icmsNormalCredito2 = icmsNormalCredito;
  const pisCredito2 = pisCredito;
  const cofinsCredito2 = cofinsCredito;
  const totalCredito = icmsNormalCredito + pisCredito + cofinsCredito2;
  //
  const antecipacaoIcmsNormal2 = antecipacaoIcmsNormal;
  const agredadoCnae2 = agredadoCnae;
  const mvaPauta2 = mvaPauta;
  const calculoPautaIcmsFronteira2 = calculoPautaIcmsFronteira;
  const icmsFronteiraCalculado2 = icmsFronteiraCalculado;
  const icmsFronteriaAdotado2 = icmsFronteriaAdotado;
  //
  const icmsNormalDebito2 = icmsNormalDebito;
  const pisDebito2 = pisDebito;
  const cofinsDebito2 = cofinsDebito;
  const totalDebitos = icmsNormalDebito + pisDebito + cofinsDebito;
  //
  const diferencaIcmsNormal2 = diferencaIcmsNormal;
  const diferencaPis2 = diferencaPis;
  const diferencaCofins2 = diferencaCofins;
  const tributacaoEfetiva2 = tributacaoEfetiva;
  const resultadoFluxoCaixa2 = resultadoFluxoCaixa;
  //
  const custoOperacional2 = custoOperacional;
  const cartaoCredito2 = cartaoCredito;
  const custoAvarias2 = custoAvarias;
  const totalRepasses =
    repasseDoIpi +
    repasseDoFreteUnitario +
    repasseDeSt +
    repasseDeSeguros +
    repasseDeOutrasDespesas;
  //
  const margemCustoOperacional2 = margemCustoOperacional;
  const margemCartaoCredito2 = margemCartaoCredito;
  const margemCustoAvarias2 = margemCustoAvarias;

  const descontoNfe2 = descontoNfe;
  const descontoBoleto2 = descontoBoleto;
  const descontoBonificacao2 = descontoBonificacao;
  const descontoBacklight2 = descontoBacklight;
  const descontoJornalOfertas2 = descontoJornalOfertas;
  const descontoDescargoMercadorias2 = descontoDescargoMercadorias;
  const totalDescontos =
    descontoNfe +
    descontoBoleto +
    descontoBonificacao +
    descontoBacklight +
    descontoJornalOfertas +
    descontoDescargoMercadorias;

  const porcentagensCompra = getPorcentagens(
    { margemContribuicao },
    ['margemContribuicao'],
    custoFinalProduto,
  );

  const porcentagemCompraMargemContttribuicao =
    porcentagensCompra.margemContribuicao;
  const porcentagemCompraIcmsEntrada = taxaIcmsEntrada;
  const porcentagemCompraPisEntrada = taxaPisEntrada;
  const porcentagemCompraCofinsEntrada = taxaCofinsEntrada;
  const porcentagemCompraTotalEntrada =
    porcentagemCompraIcmsEntrada +
    porcentagemCompraPisEntrada +
    porcentagemCompraCofinsEntrada;

  ////////////////////////

  ////////////////////////

  const porcentagensVenda = getPorcentagens(
    {
      margemContribuicao,
      precoCompra,
      baseDeCalculoIcms2,
      custoFinalProduto,
      custoLivreDeImpostos2,
      diferencaIcmsNormal2,
      diferencaPis2,
      diferencaCofins2,
      tributacaoEfetiva2,
      resultadoFluxoCaixa2,
      repasseDoIpi,
      repasseDeSt,
      repasseDeOutrasDespesas,
      repasseDoFreteUnitario,
      repasseDeSeguros,
    },
    [
      'margemContribuicao',
      'precoCompra',
      'baseDeCalculoIcms2',
      'custoFinalProduto',
      'custoLivreDeImpostos2',
      'diferencaIcmsNormal2',
      'diferencaPis2',
      'diferencaCofins2',
      'tributacaoEfetiva2',
      'resultadoFluxoCaixa2',
      'repasseDoIpi',
      'repasseDeSt',
      'repasseDeOutrasDespesas',
      'repasseDoFreteUnitario',
      'repasseDeSeguros',
    ],
    precoVendaPrecificado,
  );

  const porcentagemVendaMargemContribuicao =
    porcentagensVenda.margemContribuicao;
  const porcentagemVendaPrecoCompra = porcentagensVenda.precoCompra;
  const porcentagemVendaBaseCalculo = porcentagensVenda.baseDeCalculoIcms2;
  const porcentagemVendaCustoFinalProduto = porcentagensVenda.custoFinalProduto;
  const porcentagemVendaCustoLivreImpostos =
    porcentagensVenda.custoLivreDeImpostos2;
  const porcentagemVendaDiferencaIcmsNormal =
    porcentagensVenda.diferencaIcmsNormal2;
  const porcentagemVendaDiferencaPis = porcentagensVenda.diferencaPis2;
  const porcentagemVendaDiferencaCofins = porcentagensVenda.diferencaCofins2;
  const porcentagemVendaTributacaoEfetiva =
    porcentagensVenda.tributacaoEfetiva2;
  const porcentagemVendaResultadoFluxoCaixa =
    porcentagensVenda.resultadoFluxoCaixa2;
  const porcentagemVendaRepasseDoIpi = porcentagensVenda.repasseDoIpi;
  const porcentagemVendaRepasseDoSt = porcentagensVenda.repasseDeSt;
  const porcentagemVendaRepasseDoOutrasDespesas =
    porcentagensVenda.repasseDeOutrasDespesas;
  const porcentagemVendaRepasseDoFreteUnitario =
    porcentagensVenda.repasseDoFreteUnitario;
  const porcentagemVendaRepasseSeguros = porcentagensVenda.repasseDeSeguros;

  const porcentagemVendaRepasseTotal =
    porcentagensVenda.repasseDoIpi +
    porcentagensVenda.repasseDeSt +
    porcentagensVenda.repasseDeOutrasDespesas +
    porcentagensVenda.repasseDoFreteUnitario +
    porcentagensVenda.repasseDeSeguros;

  const porcentagemVendaMargemLucroBruto = margemLucroBrutoPorecentagem;
  const porcentagemVendaIcmsAntecipacao = taxaIcmsAntecipacao;
  const porcentagemVendaIcms = taxaIcmsSaida;
  const porcentagemVendaPis = taxaPisSaida;
  const porcentagemVendaCofins = taxaCofinsSaida;
  const porcentagemVendaTotaisDebitos =
    porcentagemVendaIcms + porcentagemVendaPis + porcentagemVendaCofins;

  return (
    <Table aria-label="a dense table" style={{ width: 'min-content' }}>
      <TableHead>
        {/*<TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={5}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Nome do Produto:
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            colSpan={9}
          >
            {nomeProduto}
          </StyledTableCell>
        </TableRow>*/}
        {/*<TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Margem de Lucro Bruto (%)
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {margemLucroBruto1}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            colSpan={4}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={3}
            colSpan={4}
          />
        </TableRow>*/}
        <TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={3}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={2}
          />
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            rowSpan={2}
          >
            Caixarias
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            PP(KG)
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Qtde
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Qtde
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Qtde
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            colSpan={4}
            rowSpan={2}
          />
        </TableRow>
        <TableRow>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {pp}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {unidadesCaixariaNivel1}
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {unidadesCaixariaNivel2}
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {unidadesCaixariaNivel3}
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            Descrição
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            KG
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            UN
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            CX1
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            CX2
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            CX3
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            Total
          </StyledTableCell>

          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            % Compra
          </StyledTableCell>
          <StyledTableCell
            align="left"
            style={{
              backgroundColor: color,
              fontWeight: 'bold',
              fontSize: '20px',
              color: corLetra,
            }}
          >
            % Venda
          </StyledTableCell>
          {dadosComplementaresNota ? (
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: color,
                fontWeight: 'bold',
                fontSize: '20px',
                color: corLetra,
              }}
            >
              NFe
            </StyledTableCell>
          ) : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {/* <StyledTableCell
          align="left"
          style={{
            backgroundColor: color,
            fontWeight: 'bold',
            fontSize: '20px',
            color: corLetra,
            display: 'flex',
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
            }}
          >
            CODINOME
          </span>
        </StyledTableCell> */}
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_VENDA}
          label="Venda"
          minimizadoDefault={false}
          dataRows={[
            {
              name: 'preco_venda',
              label: 'Preço de Venda Precificado Agora',
              valor: precoVendaPrecificado,
              valorNota: null,
              isQtde: false,
              color: 'yellow',
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'preco_venda_anterior',
              label: 'Preço Anterior de Venda',
              valor: precoAnteriorVenda,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'preco_jornal_ofertas',
              label: 'Preço de Venda no Jornal de Ofertas',
              valor: ofertaJornal,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'preço_oferta_zerar_estoque',
              label: 'Preço de Venda em Oferta de Zerar Estoque',
              valor: ofertaZerarEstoque,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_LUCRO}
          label="Lucro"
          dataRows={[
            {
              name: 'margem_contribuicao',
              label: 'Margem de Contribuição (R$)',
              valor: margemContribuicao,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: formatPorcentagem(
                porcentagemCompraMargemContttribuicao,
              ),
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaMargemContribuicao,
              ),
            },
            {
              name: 'margem_lucro_bruto',
              label: 'Margem de Lucro Bruto (R$)',
              valor: margemLucroBruto,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaMargemLucroBruto,
              ),
            },
            {
              name: 'irpj',
              label: 'IRPJ',
              valor: irpj2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'csll',
              label: 'CSLL',
              valor: csll2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'margem_lucro_liquido',
              label: 'Margem de Lucro Líquido (R$)',
              valor: margemLucroLiquido2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_ENTRADA}
          label="Entrada"
          dataRows={[
            {
              name: 'quantidade_comprada',
              label: 'Quantidade Comprada',
              valor: quantidade,
              /* valorNota: dadosComplementaresNota
                ? `${dadosComplementaresNota.qCom} ${dadosComplementaresNota.uCom}`
                : null, */
              valorNota: dadosComplementaresNota
                ? `${dadosComplementaresNota.quantidadeCompradaFormatado}`
                : null,
              isQtde: true,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'valor_negociado_com_a_industria',
              label: 'Preço do Produto Integral',
              valor: precoCompra,
              /* valorNota: dadosComplementaresNota
                ? `${formatMoeda(dadosComplementaresNota.vUnCom)} / ${
                    dadosComplementaresNota.uCom
                  }`
                : null, */
              valorNota: dadosComplementaresNota
                ? `${formatMoeda(
                    dadosComplementaresNota.valorUnitarioComprado,
                  )}`
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaPrecoCompra),
            },
            {
              name: 'base_calculo_icms',
              label: 'Base de Cálculo do ICMS',
              valor: baseDeCalculoIcms2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaBaseCalculo),
            },
            {
              name: 'custo_final_produto',
              label: 'Custo Final do Produto',
              valor: custoFinalProduto,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaCustoFinalProduto,
              ),
            },
            {
              name: 'custo_livre_de_impostos',
              label: 'Custo Livre de Impostos',
              valor: custoLivreDeImpostos2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaCustoLivreImpostos,
              ),
            },
            {
              name: 'preco_venda_minimo_aceitavel',
              label: getLabelComVarivel(
                'Preço de Venda Mínimo Aceitável',
                'Custo',
                'final',
              ),
              valor: precoMinimoAceitavel,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              dica: (
                <div>
                  <p>
                    <Equation
                      value={`V_TP + F_rete + S_eguro + O_D + IPI + ST - D_NFe - D_B - D_BO - D_J - D_D - D_Bon + ICMS_F + ICMS_Ant = ${precoCompra} + ${repasseDoFreteUnitario} + ${repasseDeSeguros} + ${repasseDeOutrasDespesas} + ${repasseDoIpi} + ${repasseDeSt} - ${descontoNfe2}  - ${descontoBacklight}  - ${descontoBoleto}  - ${descontoJornalOfertas}  - ${descontoDescargoMercadorias}  - ${descontoBonificacao} + ${icmsFronteriaAdotado} + ${antecipacaoIcmsNormal} = ${precoMinimoAceitavel}`}
                      errorHandler={defaultErrorHandler}
                    />
                  </p>
                </div>
              ),
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        {/*        <GrupoRowKpi
          label=""
          dataRows={[
            {
              name: '',
              label: '',
              valor: 0,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        /> */}
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_CREDITO_ENTRADA}
          label="Crédito de Entrada"
          dataRows={[
            {
              name: 'icms_normal',
              label: 'ICMS Normal / Alíquota Interna',
              valor: icmsNormalCredito2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: formatPorcentagem(
                porcentagemCompraIcmsEntrada,
              ),
              porcentagemVenda: '-',
            },
            {
              name: 'pis',
              label: 'PIS',
              valor: pisCredito2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: formatPorcentagem(porcentagemCompraPisEntrada),
              porcentagemVenda: '-',
            },
            {
              name: 'cofins',
              label: 'COFINS',
              valor: cofinsCredito2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: formatPorcentagem(
                porcentagemCompraCofinsEntrada,
              ),
              porcentagemVenda: '-',
            },
            {
              name: 'total_creditos_entrada',
              label: 'Total de Créditos de Entrada',
              valor: totalCredito,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: formatPorcentagem(
                porcentagemCompraTotalEntrada,
              ),
              porcentagemVenda: '-',
              negrito: true,
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_CREDITOS_ADICIONAIS}
          label="Créditos Adicionais"
          dataRows={[
            {
              name: 'antecipacao_de_icms_normal',
              label: 'Antecipação de ICMS Normal / Alíquota Interna',
              valor: antecipacaoIcmsNormal2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: formatPorcentagem(
                porcentagemVendaIcmsAntecipacao,
              ),
              porcentagemVenda: '-',
            },
            {
              name: 'agregado_cnae',
              label: 'Agregado do CNAE',
              valor: agredadoCnae2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'mva_da_pauta',
              label: 'MVA da Pauta',
              valor: mvaPauta2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'calculo_da_pauta_icms_fronteira',
              label: 'Cálculo da Pauta de ICMS de Fronteira',
              valor: calculoPautaIcmsFronteira2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'icms_de_fronteira_calculado',
              label: 'ICMS de Fronteira Calculado',
              valor: icmsFronteiraCalculado2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              totalVisivel: false,
            },
            {
              name: 'icms_de_fronteira_adotado',
              label: 'ICMS de Fronteira Adotado',
              valor: icmsFronteriaAdotado2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_DEBITO_SAIDA}
          label="Débito de Saída"
          dataRows={[
            {
              name: 'icms_normal',
              label: 'ICMS Normal / Alíquota Interna',
              valor: icmsNormalDebito2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaIcms),
            },
            {
              name: 'pis',
              label: 'PIS',
              valor: pisDebito2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaPis),
            },
            {
              name: 'cofins',
              label: 'COFINS',
              valor: cofinsDebito2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaCofins),
            },
            {
              name: 'total_debitos_saida',
              label: 'Total de Débitos de Saída',
              valor: totalDebitos,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaTotaisDebitos,
              ),
              negrito: true,
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_ANALISE_FLUXO_CAIXA}
          label="Análise de Fluxo de Caixa"
          dataRows={[
            {
              name: 'diferenca_icms_normal',
              label: 'Diferença de ICMS Normal / Alíquota Interna',
              valor: diferencaIcmsNormal2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaDiferencaIcmsNormal,
              ),
            },
            {
              name: 'diferenca_pis',
              label: 'Diferença de PIS',
              valor: diferencaPis2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaDiferencaPis),
            },
            {
              name: 'diferenca_cofins',
              label: 'Diferença de COFINS',
              valor: diferencaCofins2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaDiferencaCofins,
              ),
            },
            {
              name: 'tributacao_efetiva',
              label: 'Tributação Efetiva',
              valor: tributacaoEfetiva2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaTributacaoEfetiva,
              ),
            },
            {
              name: 'resultado_fluxo_caixa',
              label: 'Resultado do Fluxo de Caixa',
              valor: resultadoFluxoCaixa2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaResultadoFluxoCaixa,
              ),
              negrito: true,
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_REPASSES}
          label="Repasses"
          dataRows={[
            {
              name: 'repasse_ipi',
              label: 'Repasse de IPI',
              valor: repasseDoIpi,
              /* valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vIPI
                  ? `${formatMoeda(dadosComplementaresNota.vIPI)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null, */
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vIPI
                  ? `${formatMoeda(dadosComplementaresNota.vIPI)}`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaRepasseDoIpi),
            },
            {
              name: 'repasse_st',
              label: 'Repasse de Substituição Tributária',
              valor: repasseDeSt,
              /* valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vICMSST
                  ? `${formatMoeda(dadosComplementaresNota.vICMSST)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null, */
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vICMSST
                  ? `${formatMoeda(dadosComplementaresNota.vICMSST)}`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaRepasseDoSt),
            },
            {
              name: 'repasse_outras_despesas',
              label: 'Repasse de Outras Despesas',
              valor: repasseDeOutrasDespesas,
              /* valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vOutro
                  ? `${formatMoeda(dadosComplementaresNota.vOutro)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null, */
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vOutro
                  ? `${formatMoeda(dadosComplementaresNota.vOutro)}`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaRepasseDoOutrasDespesas,
              ),
            },
            {
              name: 'repasse_frete_unitario',
              label: 'Repasse de Frete Unitário',
              valor: repasseDoFreteUnitario,
              /* valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vFrete
                  ? `${formatMoeda(dadosComplementaresNota.vFrete)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null, */
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vFrete
                  ? `${formatMoeda(dadosComplementaresNota.vFrete)}`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaRepasseDoFreteUnitario,
              ),
            },
            {
              name: 'repasse_seguros',
              label: 'Repasse de Seguros',
              valor: repasseDeSeguros,
              /* valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vSeg
                  ? `${formatMoeda(dadosComplementaresNota.vSeg)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null, */
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vSeg
                  ? `${formatMoeda(dadosComplementaresNota.vSeg)}`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(
                porcentagemVendaRepasseSeguros,
              ),
            },
            {
              name: 'total_de_repasses',
              label: 'Total de Repasses',
              valor: totalRepasses,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(porcentagemVendaRepasseTotal),
              negrito: true,
            },
            {
              name: 'margem_custo_operacional',
              label: 'Margem de Custo Operacional',
              valor: custoOperacional2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(margemCustoOperacional2),
            },
            {
              name: 'margem_cartao_credito',
              label: 'Margem de Cartão de Crédito',
              valor: cartaoCredito2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(margemCartaoCredito2),
            },
            {
              name: 'margem_avarias',
              label: 'Margem de Custo de Avarias, Roubos e Furtos',
              valor: custoAvarias2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: formatPorcentagem(margemCustoAvarias2),
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
        <GrupoRowKpi
          corGrupo={BACKGROUND_GRUPO_DESCONTOS}
          label="Descontos"
          dataRows={[
            {
              name: 'desconto_nfe',
              label: 'Desconto em Nfe',
              valor: descontoNfe2,
              /* valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vDesc
                  ? `${formatMoeda(dadosComplementaresNota.vDesc)} / ${
                      dadosComplementaresNota.uCom
                    }`
                  : null
                : null, */
              valorNota: dadosComplementaresNota
                ? dadosComplementaresNota.vDesc
                  ? `${formatMoeda(dadosComplementaresNota.vDesc)}`
                  : null
                : null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'desconto_boleto',
              label: 'Desconto em Boleto',
              valor: descontoBoleto2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'desconto_bonificacao',
              label: 'Desconto em Bonificação',
              valor: descontoBonificacao2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'desconto_backlight',
              label: 'Desconto em Backlight',
              valor: descontoBacklight2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'desconto_jornal_ofertas',
              label: 'Desconto em Jornal de Ofertas',
              valor: descontoJornalOfertas2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'desconto_descargo_mercadorias',
              label: 'Desconto em Descargo de Mercadorias',
              valor: descontoJornalOfertas2,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
            },
            {
              name: 'total_de_descontos',
              label: 'Total de Descontos',
              valor: totalDescontos,
              valorNota: null,
              isQtde: false,
              porcentagemCompra: '-',
              porcentagemVenda: '-',
              negrito: true,
            },
          ]}
          quantidade={quantidade}
          produto={produto}
          isDadosComplementares={dadosComplementaresNota ? true : false}
        />
      </TableBody>
    </Table>
  );
}
