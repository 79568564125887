import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  DateInput,
  TextInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  CepInput,
  IEInput,
  AutoCompleteRemoto,
  IntegerInput,
  PrecoInput,
  SelectInput,
} from '../../../Components';
import {
  createAPI,
  updateAPI,
  getListAllAPI,
  getUserId,
  api,
} from '../../../services';
import { SECONDARY_COLOR } from '../../../utils';
import DialogoCreateEditContato from './DialogoCreateEditContato';
import DialogoDeletarContato from './DialogoDeletarContato';
import { getLabelTipo, getValorFormatado } from './utils';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(-1);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');

  const [contatos, setContatos] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = React.useState(0);

  const theme = useTheme();

  const refDialogoContato = useRef(null);
  const refDialogoDeletarContato = useRef(null);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateCpf() {
    let error = '';
    if (cpf) {
      if (cpf.length < 11) error = 'O CPF deve ter 11 dígitos';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateNome();
    errosOld[1] = validateCpf();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function reiniciar() {
    setId(-1);
    setNome('');
    setCpf('');
    setContatos([]);
    setValue(0);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'representantes-fornecedores',
        ['id', 'asc'],
        { id: [id] },
        ['contatos'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCpf(data.data[0].cpf);
        setContatos(data.data[0].contatos ? data.data[0].contatos : []);
        setValue(0);
        setCarregando(false);
      } else {
        throw 'Este Representante de Fornecedor não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const fornecedorCriado = await createAPI(
            'representantes-fornecedores',
            {
              nome,
              cpf,
              contatos,
            },
          );
          setCarregando(false);
          enqueueSnackbar('Representante de Fornecedor criado com sucesso!', {
            variant: 'success',
          });
          idLocal = fornecedorCriado.data ? fornecedorCriado.data.id : -1;
        } else {
          await updateAPI('representantes-fornecedores', id, {
            nome,
            cpf,
            contatos,
          });
          setCarregando(false);
          enqueueSnackbar(
            'Representante de Fornecedor atualizado com sucesso!',
            {
              variant: 'success',
            },
          );
          idLocal = id;
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(tipo = 1) {
      setOpen(true);
      setCarregando(true);
      reiniciar();
    },
    handleEdit(id) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      getDataResource(id);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function criarContato() {
    if (refDialogoContato.current) {
      refDialogoContato.current.handleCreate();
    }
  }

  function editarContato(id, valor, tipo) {
    if (refDialogoContato.current) {
      refDialogoContato.current.handleEdit(id, valor, tipo);
    }
  }

  function handleSaveContato(id, valor, tipo) {
    const newArray = contatos.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          valor,
          tipo,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: contatos.length + 1,
        valor,
        tipo,
      });
    }
    setContatos(recalcularIds(newArray));
  }

  function recalcularIds(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i += 1) {
      newArray.push({
        ...arrayOld[i],
        id: i + 1,
      });
    }
    return newArray;
  }

  function deletarContato(id, valor, tipo) {
    if (refDialogoDeletarContato.current) {
      refDialogoDeletarContato.current.handleDelete(id, valor, tipo);
    }
  }

  function handleDeletarContato(id) {
    const newArray = contatos.slice().filter((item) => item.id !== id);
    setContatos(recalcularIds(newArray));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {id > 0
          ? `Editar Representante de Fornecedor`
          : `Novo Representante de Fornecedor`}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Contatos" {...a11yProps(1)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box mt="1em" />
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ color: SECONDARY_COLOR }}
                      >
                        Dados Pessoais
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name="nome"
                            handleEnter={() => {}}
                            label="Nome"
                            handleKey={handleKey}
                            value={nome}
                            onChange={(value) => setNome(value.target.value)}
                            error={erros[0] !== ''}
                            helperText={erros[0]}
                            fullWidth
                            autoFocus
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" display="flex">
                          <CpfInput
                            name="cpf"
                            handleEnter={() => {}}
                            label="CPF"
                            handleKey={handleKey}
                            value={cpf}
                            onChange={(value) => setCpf(value.target.value)}
                            error={erros[1] !== ''}
                            helperText={erros[1]}
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box mt="2em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Tipo</StyledTableCell>
                              <StyledTableCell>Contato</StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {contatos.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {getLabelTipo(row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {getValorFormatado(row.valor, row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        editarContato(
                                          row.id,
                                          row.valor,
                                          row.tipo,
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        deletarContato(
                                          row.id,
                                          row.valor,
                                          row.tipo,
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Zoom
            key="contatos"
            in={value === 1}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === 0 ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab
              aria-label="Adicionar"
              className={classes.fab}
              color="primary"
              onClick={criarContato}
            >
              <AddIcon />
            </Fab>
          </Zoom>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleSalvar();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            {id > 0 ? 'Salvar (F8)' : 'Cadastrar (F8)'}
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
      <DialogoCreateEditContato
        ref={refDialogoContato}
        handleClose={() => {}}
        handleSave={handleSaveContato}
      />
      <DialogoDeletarContato
        ref={refDialogoDeletarContato}
        handleClose={() => {}}
        handleDelete={handleDeletarContato}
      />
    </Dialog>
  );
});

export default SidebarInputs;
