import { useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import { getListAllAPI } from '../../../../services';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  STATUS_ATIVO,
  getSubTotal,
  getTotalCompra,
  searchItemInArray3,
} from '../../../../utils';

function useLotes(
  lotes,
  updateItem,
  repasse_frete,
  repasse_ipi,
  repasse_outras_despesas,
  repasse_seguros,
  repasse_st,
) {
  const { enqueueSnackbar } = useSnackbar();
  const refSidebarInputs = useRef(null);
  const [produto, setProduto] = useState(null);
  const [search, setSearch] = useState('');
  const [subTotal, setSubTotal] = useState('');
  const refSearch = useRef(null);

  useEffect(() => {
    setSubTotal(
      getSubTotal(lotes) +
        repasse_frete +
        repasse_ipi +
        repasse_outras_despesas +
        repasse_seguros +
        repasse_st,
    );
  }, [lotes]);

  async function searchHandle(codigo, id) {
    const produtoData = await getListAllAPI(
      'produtos',
      ['id', 'asc'],
      id
        ? { id }
        : { codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO },
      ['unidade', 'categoria', 'familia'],
    );
    if (produtoData.data.length > 0) {
      const index = searchItemInArray3(produtoData.data[0], lotes);
      produtoData.data[0].unidadesDisponivel += index.unidadesUsadas;
      produtoData.data[0].pesoDisponivel += index.pesoUsado;
      const hideUnidades =
        produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
      setProduto(produtoData.data[0]);
      if (refSidebarInputs.current)
        refSidebarInputs.current.focus(hideUnidades ? 1 : 0);
    } else {
      setProduto(null);
      if (refSearch.current) refSearch.current.focus();
    }
    setSearch('');
  }

  function addNewItemInterno(dados, produto) {
    const {
      unidades,
      peso,
      unitario,
      validade,
      unitarioImposto,
      cg,
      nImpressoes,
      dadosPrecificacao,
      validades,
    } = dados;

    updateItem({
      produto,
      peso: produto.unidade.tipo === POR_UNIDADE ? 0 : peso,
      //total: getTotalCompra(peso, unidades, unitario, produto),
      total: getTotalCompra(peso, unidades, unitarioImposto, produto),
      unidades:
        produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ? 0 : unidades,
      unitario,
      uidd: `${produto.nome}${lotes.length}`,
      idIndicadorChange: -1,
      validade,
      unitarioImposto,
      cg,
      dadosPrecificacao,
      nImpressoes,
      validades,
    });

    setProduto(null);
  }

  function addNewItem(dados) {
    addNewItemInterno(dados, produto);
  }

  return {
    searchHandle,
    addNewItem,
    setSearch,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
  };
}

export default useLotes;
