import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  DateInput,
  TextInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  CepInput,
  IEInput,
  AutoCompleteRemoto,
  IntegerInput,
  PrecoInput,
  SelectInput,
} from '../../../Components';
import {
  createAPI,
  updateAPI,
  getListAllAPI,
  getUserId,
  api,
} from '../../../services';
import { SECONDARY_COLOR, formatCPF } from '../../../utils';
import { getLabelTipo, getValorFormatado } from '../DialogoCreateEdit/utils';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const SidebarInputs = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(-1);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');

  const [contatos, setContatos] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'representantes-fornecedores',
        ['id', 'asc'],
        { id: [id] },
        ['contatos'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCpf(formatCPF(data.data[0].cpf));
        setContatos(data.data[0].contatos ? data.data[0].contatos : []);
        setValue(0);
        setCarregando(false);
      } else {
        throw 'Este Representante de Fornecedor não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        Detalhes Representante de Fornecedores
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Contatos" {...a11yProps(1)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box mt="1em" />

                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            Nome:
                          </span>
                          {nome}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            CPF:
                          </span>
                          {cpf}
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box mt="2em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Tipo</StyledTableCell>
                              <StyledTableCell>Contato</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {contatos.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {getLabelTipo(row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {getValorFormatado(row.valor, row.tipo)}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
