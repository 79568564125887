import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { useListContext } from 'react-admin';

import { Card, CardContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../Components';
import { IntegerInput, PesoInput, SelectInput } from '../../Components/Inputs';
import { getListAllAPI, api, getUserId, updateAPI } from '../../services';
import { POR_PESO_VARIAVEL_SEM_UNIDADE, getNivelCaixaQtde } from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';

const optionsModo = [
  {
    label: 'Produtos com Essa validade estão encerrados',
    value: 0,
  },
  {
    label: 'Registrar Contagem do Estoque Dessa Validade',
    value: 1,
  },
];

function formatarValidade(data) {
  const dia = data.getDate();
  const mes = data.getMonth() + 1;
  const ano = data.getFullYear();

  return `${dia < 10 ? `0${dia}` : `${dia}`}/${
    mes < 10 ? `0${mes}` : `${mes}`
  }/${ano}`;
}

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [produto, setProduto] = useState(null);
    const [validade, setValidade] = useState('');
    const [id, setId] = useState(-1);

    const [unidades, setUnidades] = useState(0);
    const [peso, setPeso] = useState(0);
    const [unidadesCaixa1, setUnidadesCaixa1] = useState(0);
    const [unidadesCaixa2, setUnidadesCaixa2] = useState(0);
    const [unidadesCaixa3, setUnidadesCaixa3] = useState(0);

    const [modo, setModo] = useState(0);

    const [carregando, setCarregando] = useState(false);

    const refDialogoConfirmacao = useRef(null);

    const refs = useRef([]);

    const inputs = [
      {
        nome: 'unidades',
        defaultValue: 0,
        label: 'Unidades',
      },
      {
        nome: 'unidades_caixa_1',
        defaultValue: 0,
        label: 'Unidades',
      },
      {
        nome: 'unidades_caixa_2',
        defaultValue: 0,
        label: 'Unidades',
      },
      {
        nome: 'unidades_caixa_3',
        defaultValue: 0,
        label: 'Unidades',
      },
    ];

    refs.current = inputs.map(
      (ref, index) => (refs.current[index] = React.createRef()),
    );

    const isPorPesoVariavel = produto
      ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
      : false;

    const nivelCaixa = produto
      ? produto.nivelCaixa
        ? produto.nivelCaixa
        : 0
      : 0;

    const classes = useStyles({ cor: '#009FD4' });

    function validateUnidadesBasicas() {
      let error = '';
      if (isPorPesoVariavel) {
        if (peso < 0 || Number.isNaN(peso)) {
          error = 'Este campo é obrigatório';
        }
      } else if (unidades < 0 || Number.isNaN(unidades)) {
        error = 'Este campo é obrigatório';
      }

      return error;
    }

    function validateUnidadesCaixa1() {
      let error = '';
      if (nivelCaixa >= 1) {
        if (unidadesCaixa1 < 0 || Number.isNaN(unidadesCaixa1)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateUnidadesCaixa2() {
      let error = '';
      if (nivelCaixa >= 2) {
        if (unidadesCaixa2 < 0 || Number.isNaN(unidadesCaixa2)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateUnidadesCaixa3() {
      let error = '';
      if (nivelCaixa >= 3) {
        if (unidadesCaixa3 < 0 || Number.isNaN(unidadesCaixa3)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function getErros() {
      const errosOld = ['', '', ''];

      errosOld[0] = validateUnidadesBasicas();
      errosOld[1] = validateUnidadesCaixa1();
      errosOld[2] = validateUnidadesCaixa2();
      errosOld[3] = validateUnidadesCaixa3();

      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'validades-produtos',
          ['id', 'asc'],
          { id: [id] },
          ['produto.unidade'],
        );
        if (data.data.length > 0) {
          setId(data.data[0].id);
          setCarregando(false);
          setUnidadesCaixa1(0);
          setUnidadesCaixa2(0);
          setUnidadesCaixa3(0);
          setUnidades(0);
          setPeso(0);
          setProduto({
            ...data.data[0].produto,
            nivelCaixa: getNivelCaixaQtde(data.data[0].produto),
          });
          setValidade(formatarValidade(new Date(data.data[0].data)));
          setModo(0);
        } else {
          throw 'Esta Validade de Produto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
      },
    }));

    function handleActions(action) {
      switch (action) {
        case FECHAR_VENDA_ACTION:
          handleSalvar();
          break;
        case CANCELAR_VENDA_ACTION:
          handleCloseDialog();
          break;
        default:
          break;
      }
    }

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          position = 1;
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position === -1) {
        refs.current[0].current.focus();
        refs.current[0].current.select();
      } else if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    }

    function handleKey(keyCode, keyName) {
      handleActions(keyName);
    }

    async function handleSalvar() {
      if (modo === 0) {
        openConfirmacao();
      } else if (!erroExistente) {
        setCarregando(true);
        try {
          await updateAPI('validades-produtos', id, {
            user_id: getUserId(),
            unidades_caixa_nivel_1: produto.unidades_caixa_nivel_1,
            unidades_caixa_nivel_2: produto.unidades_caixa_nivel_2,
            unidades_caixa_nivel_3: produto.unidades_caixa_nivel_3,
            peso_caixa_nivel_1: produto.peso_caixa_nivel_1,
            unidades,
            peso,
            numero_caixas_nivel_1: unidadesCaixa1,
            numero_caixas_nivel_2: unidadesCaixa2,
            numero_caixas_nivel_3: unidadesCaixa3,
          });
          setCarregando(false);
          enqueueSnackbar(
            'Conferencia de Validade de Produto criada com sucesso!',
            {
              variant: 'success',
            },
          );
          handleCloseDialog(true);
        } catch (erros) {
          setCarregando(false);
          enqueueSnackbar(`${erros}`, {
            variant: 'error',
          });
          handleCloseDialog();
        }
      } else {
        enqueueSnackbar('Existem erros no formulário!', {
          variant: 'error',
        });
      }
    }

    async function desativar(id) {
      await api.put(`/validades-produtos/status/inativar/${id}/${getUserId()}`);
      handleCloseDialog();
    }

    function handleConfirma(codigo) {
      switch (codigo) {
        case 0:
          desativar(id);
          break;
        default:
          break;
      }
    }

    function openConfirmacao() {
      if (refDialogoConfirmacao.current) {
        refDialogoConfirmacao.current.handleOpen(
          'Marcar os Produtos com esta Validade como encerrados',
          'Tem certeza que deseja Marcar os Produtos com esta Validade como encerrados?',
          0,
        );
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Produto - ${produto ? produto.nome : ''}, Validade - ${validade}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <Box p="1em">
                  {modo === 0 ? (
                    <div>
                      <FormControl
                        variant="outlined"
                        style={{
                          marginTop: '16px',
                        }}
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel shrink id="orig-icms">
                          Opção
                        </InputLabel>
                        <SelectInput
                          labelId="orig-icms"
                          name="turno"
                          handleEnter={() => {}}
                          label="Opção"
                          handleKey={() => {}}
                          value={modo}
                          onChange={(value) => setModo(value.target.value)}
                          options={optionsModo}
                        />
                      </FormControl>
                    </div>
                  ) : (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        {isPorPesoVariavel ? 'Peso' : 'Unidades'}
                      </Typography>
                      <Box display="flex" mb="30px">
                        <Box flex={1} display="flex">
                          {!isPorPesoVariavel ? (
                            <IntegerInput
                              name={inputs[0].nome}
                              ref={refs.current[0]}
                              handleEnter={() => handleNextInput(0)}
                              label="Unidades"
                              handleKey={handleKey}
                              value={
                                Number.isNaN(unidades) ? '' : String(unidades)
                              }
                              onChange={(value) =>
                                setUnidades(parseInt(value.target.value, 10))
                              }
                              error={erros[0] !== ''}
                              helperText={erros[0]}
                              fullWidth
                            />
                          ) : (
                            <PesoInput
                              name={inputs[0].nome}
                              ref={refs.current[0]}
                              handleEnter={() => handleNextInput(0)}
                              label="Peso"
                              handleKey={handleKey}
                              value={Number.isNaN(peso) ? '' : String(peso)}
                              onChange={(value) =>
                                setPeso(parseFloat(value.target.value))
                              }
                              error={erros[0] !== ''}
                              helperText={erros[0]}
                              fullWidth
                            />
                          )}
                        </Box>
                      </Box>
                      {nivelCaixa >= 1 && (
                        <>
                          <Typography variant="h6" gutterBottom>
                            Caixa (Nível 1)
                          </Typography>
                          <Box display="flex" mb="30px">
                            <Box flex={1} display="flex">
                              <IntegerInput
                                name={inputs[1].nome}
                                ref={refs.current[1]}
                                handleEnter={() => handleNextInput(1)}
                                label={inputs[1].label}
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(unidadesCaixa1)
                                    ? ''
                                    : String(unidadesCaixa1)
                                }
                                onChange={(value) =>
                                  setUnidadesCaixa1(
                                    parseInt(value.target.value, 10),
                                  )
                                }
                                error={erros[1] !== ''}
                                helperText={erros[1]}
                                fullWidth
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                      {nivelCaixa >= 2 && (
                        <>
                          <Typography variant="h6" gutterBottom>
                            Caixa (Nível 2)
                          </Typography>
                          <Box display="flex" mb="30px">
                            <Box flex={1} display="flex">
                              <IntegerInput
                                name={inputs[2].nome}
                                ref={refs.current[2]}
                                handleEnter={() => handleNextInput(2)}
                                label={inputs[2].label}
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(unidadesCaixa2)
                                    ? ''
                                    : String(unidadesCaixa2)
                                }
                                onChange={(value) =>
                                  setUnidadesCaixa2(
                                    parseInt(value.target.value, 10),
                                  )
                                }
                                error={erros[2] !== ''}
                                helperText={erros[2]}
                                fullWidth
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                      {nivelCaixa >= 3 && (
                        <>
                          <Typography variant="h6" gutterBottom>
                            Caixa (Nível 3)
                          </Typography>
                          <Box display="flex" mb="30px">
                            <Box flex={1} display="flex">
                              <IntegerInput
                                name={inputs[3].nome}
                                ref={refs.current[3]}
                                handleEnter={() => handleNextInput(3)}
                                label={inputs[3].label}
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(unidadesCaixa3)
                                    ? ''
                                    : String(unidadesCaixa3)
                                }
                                onChange={(value) =>
                                  setUnidadesCaixa3(
                                    parseInt(value.target.value, 10),
                                  )
                                }
                                error={erros[3] !== ''}
                                helperText={erros[3]}
                                fullWidth
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </div>
                  )}
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={() => handleCloseDialog()} color="primary">
              Cancelar (F4)
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
          <DialogoConfirmacao
            ref={refDialogoConfirmacao}
            handleConfirma={handleConfirma}
          />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
