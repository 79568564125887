import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import { useListContext } from 'react-admin';

import { Card, CardContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../Components';
import { IntegerInput, PesoInput, SelectInput } from '../../Components/Inputs';
import {
  getListAllAPI,
  api,
  getUserId,
  updateAPI,
  getListAPI,
} from '../../services';
import {
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getNivelCaixaQtde,
  SECONDARY_COLOR,
  formatPeso,
  formatDateDDMMYYYYHHmm,
} from '../../utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#009FD4',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function formatarValidade(data) {
  const dia = data.getDate();
  const mes = data.getMonth() + 1;
  const ano = data.getFullYear();

  return `${dia < 10 ? `0${dia}` : `${dia}`}/${
    mes < 10 ? `0${mes}` : `${mes}`
  }/${ano}`;
}

const perPage = 10;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [produto, setProduto] = useState(null);
    const [validade, setValidade] = useState('');
    const [id, setId] = useState(-1);

    const [carregando, setCarregando] = useState(false);

    const [conferencias, setConferencias] = useState([]);

    const [total, setTotal] = useState(0);

    const [page, setPage] = useState(0);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
      setConferencias([]);
      setTotal(0);
      setPage(0);
      setProduto(null);
      setValidade('');
      setId(-1);
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'validades-produtos',
          ['id', 'asc'],
          { id: [id] },
          ['produto.unidade'],
        );
        if (data.data.length > 0) {
          setId(data.data[0].id);
          setCarregando(false);
          setProduto({
            ...data.data[0].produto,
            nivelCaixa: getNivelCaixaQtde(data.data[0].produto),
          });
          setValidade(formatarValidade(new Date(data.data[0].data)));
        } else {
          throw 'Esta Validade de Produto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        setCarregando(true);
        getDataResource(id);
        setOpen(true);
      },
    }));

    function montaMensagemEstoque(record) {
      const arr = [];
      arr.push(
        record.numero_caixas_nivel_3
          ? `${record.numero_caixas_nivel_3} CX3`
          : '',
      );
      arr.push(
        record.numero_caixas_nivel_2
          ? `${record.numero_caixas_nivel_2} CX2`
          : '',
      );
      arr.push(
        record.numero_caixas_nivel_1
          ? `${record.numero_caixas_nivel_1} CX1`
          : '',
      );
      arr.push(record.unidades ? `${record.unidades} UN` : '');
      arr.push(record.peso ? `${formatPeso(record.peso)} ` : '');

      return arr.filter((item) => item !== '').join(', ');
    }

    function formatConferencias(conferencias, produto) {
      const dadosFormatados = [];

      if (conferencias) {
        if (conferencias.length > 0) {
          for (let i = 0; i < conferencias.length; i++) {
            dadosFormatados.push({
              id: conferencias[i].id,
              data: formatDateDDMMYYYYHHmm(conferencias[i].data),
              conferente: conferencias[i].verificador.username,
              estoque: montaMensagemEstoque(conferencias[i]),
            });
          }
        }
      }

      return dadosFormatados;
    }

    async function getConferencias() {
      const response = await getListAPI(
        'conferencias-validades-produtos',
        ['data', 'desc'],
        [page + 1, perPage],
        { validade_produto_id: id },
        ['verificador'],
      );
      setTotal(response.total);
      setConferencias(formatConferencias(response.data));
    }

    useEffect(() => {
      getConferencias(page);
    }, [page, id]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Produto - ${produto ? produto.nome : ''}, Validade - ${validade}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                {' '}
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Data</StyledTableCell>
                        <StyledTableCell>Conferente</StyledTableCell>
                        <StyledTableCell>Estoque</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {conferencias.map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{row.data}</StyledTableCell>
                          <StyledTableCell>{row.conferente}</StyledTableCell>
                          <StyledTableCell>{row.estoque}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[perPage]}
                    count={total}
                    rowsPerPage={perPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={() => {}}
                    labelRowsPerPage="Por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${
                        count !== -1 ? count : `more than ${to}`
                      }`
                    }
                  />
                </TableContainer>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
