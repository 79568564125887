import { useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import { api, getListAllAPI, getUserId } from '../../../../services';
import { getTotalCompra } from '../../../../utils';

const toleranciaResto = 10;

function calculaTotais(dados) {
  if (dados) {
    if (dados.dadosPrecificacao) {
      return {
        subtotal_valor_negociado_com_a_industria: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.valor_negociado_com_a_industria,
          dados.produto,
        ),
        subtotal_custo_final: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.custo_final_do_produto,
          dados.produto,
        ),
        subtotal_custo_operacional: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.custo_operacional,
          dados.produto,
        ),
        subtotal_custo_cartao: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.custo_de_cartao,
          dados.produto,
        ),
        subtotal_total_venda: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.preco_de_venda,
          dados.produto,
        ),
        subtotal_icms_compra: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.icms_compra,
          dados.produto,
        ),
        subtotal_icms_venda: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.icms_venda,
          dados.produto,
        ),
        subtotal_pis_compra: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.pis_compra,
          dados.produto,
        ),
        subtotal_pis_venda: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.pis_venda,
          dados.produto,
        ),
        subtotal_cofins_compra: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.cofins_compra,
          dados.produto,
        ),
        subtotal_cofins_venda: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.cofins_venda,
          dados.produto,
        ),
        subtotal_lucro_bruto: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.lucro_bruto,
          dados.produto,
        ),
        subtotal_lucro_liquido: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.lucro_liquido,
          dados.produto,
        ),
        subtotal_icms_antecipacao: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.icms_antecipacao,
          dados.produto,
        ),
        subtotal_icms_fronteira: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.icms_fronteira,
          dados.produto,
        ),
        subtotal_irpj: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.irpj,
          dados.produto,
        ),
        subtotal_csll: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.csll,
          dados.produto,
        ),
        subtotal_repasse_ipi: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.repasse_do_ipi,
          dados.produto,
        ),
        subtotal_repasse_st: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.repasse_de_st,
          dados.produto,
        ),
        subtotal_repasse_seguro: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.repasse_do_seguro,
          dados.produto,
        ),
        subtotal_repasse_frete: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.repasse_do_frete,
          dados.produto,
        ),
        subtotal_repasse_outras_despesas: getTotalCompra(
          dados.peso,
          dados.unidades,
          dados.dadosPrecificacao.repasse_de_outras_despesas,
          dados.produto,
        ),
      };
    }
    return {
      subtotal_custo_operacional: 0,
      subtotal_custo_cartao: 0,
      subtotal_total_venda: 0,
      subtotal_icms_compra: 0,
      subtotal_icms_venda: 0,
      subtotal_pis_compra: 0,
      subtotal_pis_venda: 0,
      subtotal_cofins_compra: 0,
      subtotal_cofins_venda: 0,
      subtotal_lucro_bruto: 0,
      subtotal_lucro_liquido: 0,
      subtotal_icms_antecipacao: 0,
      subtotal_icms_fronteira: 0,
      subtotal_irpj: 0,
      subtotal_csll: 0,
      subtotal_repasse_ipi: 0,
      subtotal_repasse_st: 0,
      subtotal_repasse_seguro: 0,
      subtotal_repasse_frete: 0,
      subtotal_repasse_outras_despesas: 0,
    };
  }
  return {
    subtotal_custo_operacional: 0,
    subtotal_custo_cartao: 0,
    subtotal_total_venda: 0,
    subtotal_icms_compra: 0,
    subtotal_icms_venda: 0,
    subtotal_pis_compra: 0,
    subtotal_pis_venda: 0,
    subtotal_cofins_compra: 0,
    subtotal_cofins_venda: 0,
    subtotal_lucro_bruto: 0,
    subtotal_lucro_liquido: 0,
    subtotal_icms_antecipacao: 0,
    subtotal_icms_fronteira: 0,
    subtotal_irpj: 0,
    subtotal_csll: 0,
    subtotal_repasse_ipi: 0,
    subtotal_repasse_st: 0,
    subtotal_repasse_seguro: 0,
    subtotal_repasse_frete: 0,
    subtotal_repasse_outras_despesas: 0,
  };
}

function calculaTotaisNota(lotes2) {
  const lotes = lotes2.map((item) => calculaTotais(item));
  const totais = {
    custo_operacional: 0,
    custo_cartao: 0,
    total_venda: 0,
    icms_compra: 0,
    icms_venda: 0,
    pis_compra: 0,
    pis_venda: 0,
    cofins_compra: 0,
    cofins_venda: 0,
    lucro_bruto: 0,
    lucro_liquido: 0,
    icms_antecipacao: 0,
    icms_fronteira2: 0,
    repasse_ipi2: 0,
    repasse_st2: 0,
    repasse_seguro2: 0,
    repasse_frete2: 0,
    repasse_outras_despesas2: 0,
    irpj: 0,
    csll: 0,
  };
  for (let i = 0; i < lotes.length; i++) {
    const lote = lotes[i];

    totais.custo_operacional += lote.subtotal_custo_operacional;
    totais.custo_cartao += lote.subtotal_custo_cartao;
    totais.total_venda += lote.subtotal_total_venda;
    totais.icms_compra += lote.subtotal_icms_compra;
    totais.icms_venda += lote.subtotal_icms_venda;
    totais.pis_compra += lote.subtotal_pis_compra;
    totais.pis_venda += lote.subtotal_pis_venda;
    totais.cofins_compra += lote.subtotal_cofins_compra;
    totais.cofins_venda += lote.subtotal_cofins_venda;
    totais.lucro_bruto += lote.subtotal_lucro_bruto;
    totais.lucro_liquido += lote.subtotal_lucro_liquido;
    totais.icms_antecipacao += lote.subtotal_icms_antecipacao;
    totais.icms_fronteira2 += lote.subtotal_icms_fronteira;
    totais.irpj += lote.subtotal_irpj;
    totais.csll += lote.subtotal_csll;
    totais.repasse_ipi2 += lote.subtotal_repasse_ipi;
    totais.repasse_st2 += lote.subtotal_repasse_st;
    totais.repasse_seguro2 += lote.subtotal_repasse_seguro;
    totais.repasse_frete2 += lote.subtotal_repasse_frete;
    totais.repasse_outras_despesas2 += lote.subtotal_repasse_outras_despesas;
  }
  return totais;
}

function useParcelasCompra(
  parcelas,
  itens,
  fornecedor,
  subtotal,
  updateParcela,
  editarModo,
  idEdit,
  numeroNotaFiscal = '',
  loja,
  data_faturamento,
  editarPrazosFornecedor,
  repasse_frete,
  repasse_ipi,
  repasse_outras_despesas,
  repasse_seguros,
  repasse_st,
  serieNotaFiscal = '',
  chaveAcessoNotaFiscal = '',
  isImportacaoXml,
) {
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoNota = useRef(null);
  const [podeFecharCompra, setPodeFecharCompra] = useState(true);

  function getValorRestante() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return subtotal - soma;
  }

  function getTotalParcelas() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return soma;
  }

  const resto = getValorRestante();
  const totalParcelas = getTotalParcelas();

  function getMessagesError() {
    const errors = [];
    if (fornecedor === null) {
      errors.push({
        message: "Campo 'fornecedor' não foi preenchido!",
        type: 'warning',
      });
    }
    if (loja === null) {
      errors.push({
        message: "Campo 'loja' não foi preenchido!",
        type: 'warning',
      });
    }
    if (parcelas.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de parcela!',
        type: 'warning',
      });
    }
    if (numeroNotaFiscal !== '') {
      if (numeroNotaFiscal.length < 9) {
        errors.push({
          message: 'Número de nota fiscal em formato inválido!',
          type: 'warning',
        });
      }
      if (data_faturamento === '') {
        errors.push({
          message: 'Data faturamento inválida!',
          type: 'warning',
        });
      }
    }
    const erroDistribuicao =
      resto > toleranciaResto || resto < -1 * toleranciaResto;
    if (erroDistribuicao) {
      errors.push({
        message:
          'O valor total da compra não foi integralmente distribuído nas parcelas!',
        type: 'warning',
      });
    }
    return errors;
  }

  useEffect(() => {
    if (getMessagesError().length === 0) {
      enqueueSnackbar('Agora você pode finalizar a compra!!!', {
        variant: 'success',
      });
    }
  }, [parcelas]);

  function formatDate(date) {
    if (date === '') {
      return null;
    }
    const dataQuebrada = date.split('-');
    const ano = parseInt(dataQuebrada[0], 10);
    const mes = parseInt(dataQuebrada[1], 10) - 1;
    const dia = parseInt(dataQuebrada[2], 10);
    const dataNova = new Date();
    dataNova.setFullYear(ano);
    dataNova.setMonth(mes);
    dataNova.setDate(dia);
    return dataNova;
  }

  function montarObjVenda(prazos) {
    const listaItens = [];
    const listaParcelas = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_compra: itens[i].unitario,
        preco_compra_imposto: itens[i].unitarioImposto,
        produto_id: itens[i].produto.id,
        validade: itens[i].validade,
        idIndicadorChange: itens[i].idIndicadorChange,
        cg: itens[i].cg,
        nImpressoes: itens[i].nImpressoes,
        dadosPrecificacao: itens[i].dadosPrecificacao,
        validades: itens[i].validades,
        totaisFiscais: itens[i].totaisFiscais,
      });
    }

    const parcelasCopy = [...parcelas];

    parcelasCopy.sort(
      (a, b) =>
        new Date(a.data_pagamento).getTime() -
        new Date(b.data_pagamento).getTime(),
    );

    for (let i = 0; i < parcelasCopy.length; i += 1) {
      listaParcelas.push({
        conta_id: parcelasCopy[i].conta ? parcelasCopy[i].conta.id : null,
        cartao_credito_id: parcelasCopy[i].cartao
          ? parcelasCopy[i].cartao.id
          : null,
        data_pagamento: parcelasCopy[i].data_pagamento,
        valor: parcelasCopy[i].valor,
        status: parcelasCopy[i].status,
        fatura: parcelasCopy[i].fatura,
        idIndicadorChange: parcelasCopy[i].idIndicadorChange,
        isCartao: parcelasCopy[i].isCartao,
        codigo_boleto: parcelasCopy[i].codigo_boleto,
        dias: prazos[i].dias,
        categoria_problema_despesa_id: parcelasCopy[i].problema
          ? parcelasCopy[i].problema.id
          : null,
        descricao_problema: parcelasCopy[i].descricao_problema,
        agendamento_user_id: parcelasCopy[i].agendamento_user_id,
        desconto: parcelasCopy[i].desconto,
      });
    }

    return {
      listaItens,
      listaParcelas,
      data: new Date(),
      fornecedor_id: fornecedor.id,
      sessao_id: 0,
      numero_nota_fiscal: numeroNotaFiscal,
      data_faturamento:
        numeroNotaFiscal === '' ? null : formatDate(data_faturamento),
      loja: loja ? loja.value : 'CF',
      user_id: getUserId(),
      repasse_frete,
      repasse_ipi,
      repasse_outras_despesas,
      repasse_seguros,
      repasse_st,
      ...calculaTotaisNota(itens),
      serie_nota_fiscal: serieNotaFiscal,
      chave_acesso_nota_fiscal: chaveAcessoNotaFiscal,
      is_importacao_xml: isImportacaoXml,
    };
  }

  async function submitCompra(prazos) {
    try {
      const resource = 'compras';
      if (editarModo === false) {
        const objVenda = montarObjVenda(prazos);
        const data = await api.post(`/${resource}/fc`, {
          ...objVenda,
        });
        const { status, message, data: data2 } = data.data;
        if (status) {
          return {
            id: data2.id,
            message: '',
          };
        }
        throw message;
      }
      const objVenda = montarObjVenda(prazos);
      const data = await api.post(`/${resource}/fc/${idEdit}`, {
        ...objVenda,
      });
      const { status, message, data: data2 } = data.data;
      if (status) {
        return {
          id: data2.id,
          message: '',
        };
      }
      throw message;
    } catch (e) {
      return {
        id: -1,
        message: e,
      };
    }
  }

  function handleOpenNota2(idCompra) {
    if (refDialogoNota.current) {
      refDialogoNota.current.handleOpen(idCompra, 'compra');
    }
  }

  async function getNumberPrazosParcelas(fornecedor_id) {
    const data = await getListAllAPI(
      'prazos_parcelas_fornecedores',
      ['dias', 'asc'],
      { fornecedor_id },
      [],
    );
    return data.data;
  }

  async function handleOpenNota() {
    const errors = getMessagesError();
    if (errors.length <= 0 && podeFecharCompra) {
      const numeroPrazosFornecedor = await getNumberPrazosParcelas(
        fornecedor.id,
      );
      if (numeroPrazosFornecedor.length === parcelas.length) {
        setPodeFecharCompra(false);
        const { id, message } = await submitCompra(numeroPrazosFornecedor);
        if (id > 0) {
          handleOpenNota2(id);
        } else {
          setPodeFecharCompra(true);
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } else {
        if (numeroPrazosFornecedor.length < parcelas.length) {
          enqueueSnackbar(
            'Número de parcelas maior que o número de parcelas disponibilizadas pelo fornecedor!',
            {
              variant: 'error',
            },
          );
        } else {
          enqueueSnackbar(
            'Número de parcelas menor que o número de parcelas disponibilizadas pelo fornecedor!',
            {
              variant: 'error',
            },
          );
        }
        editarPrazosFornecedor();
        setPodeFecharCompra(true);
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(errors[i].message, {
          variant: errors[i].type,
        });
      }
    }
  }

  function normalizaItens(itensLocal) {
    const listaItens = [];

    for (let i = 0; i < itensLocal.length; i += 1) {
      listaItens.push({
        peso: itensLocal[i].peso,
        unidades: itensLocal[i].unidades,
        preco_compra: itensLocal[i].unitario,
        preco_compra2: itensLocal[i].unitario,
        produto: itensLocal[i].produto,
        validade: itensLocal[i].validade,
      });
    }

    return listaItens;
  }

  function normalizaParcelas(parcelasLocal) {
    const listaParcelas = [];

    for (let i = 0; i < parcelasLocal.length; i += 1) {
      listaParcelas.push({
        valor: parcelasLocal[i].valor,
        despesa: {
          conta: parcelasLocal[i].conta,
          data_pagamento: parcelasLocal[i].data_pagamento,
          valor: parcelasLocal[i].valor,
          status: parcelasLocal[i].status,
        },
      });
    }

    return listaParcelas;
  }

  function handleOpenNotaLocal() {
    if (refDialogoNota.current) {
      refDialogoNota.current.handleOpenLocal(
        normalizaItens(itens),
        normalizaParcelas(parcelas),
        fornecedor,
        'compra',
      );
    }
  }

  function handleNewItem({
    valor,
    conta,
    data_pagamento,
    status,
    cartao,
    isCartao,
    fatura,
    codigo_boleto,
    problema,
    descricao_problema,
    agendamento_user_id,
    desconto,
  }) {
    updateParcela({
      data_pagamento,
      conta,
      valor,
      uidd: `${isCartao ? cartao.nome : conta.nome}${parcelas.length}`,
      idIndicadorChange: -1,
      status: isCartao ? false : conta.id === 1 ? false : status,
      cartao,
      isCartao,
      fatura,
      codigo_boleto,
      problema,
      descricao_problema,
      agendamento_user_id,
      desconto,
    });
  }

  return {
    resto,
    totalParcelas,
    handleOpenNota,
    refDialogoNota,
    handleNewItem,
    handleOpenNotaLocal,
    enqueueSnackbar,
    podeFecharCompra,
  };
}

export default useParcelasCompra;
