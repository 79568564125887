import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { ceil10, formatMoeda } from '../../../../../../utils';

//ChartJS.plugins.register(ArcElement, Tooltip, Legend);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Grafico = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState(null);
  const [precoVenda, setPrecoVenda] = useState(0);
  const [produto, setProduto] = useState('');

  const classes = useStyles({ cor: '#009FD4' });

  useImperativeHandle(ref, () => ({
    handleOpen(dados, precoVenda, produto) {
      setOpen(true);
      setDados(dados);
      setPrecoVenda(precoVenda);
      setProduto(produto);
    },
  }));

  const handleCloseDialog = () => {
    setOpen(false);
  };

  function formataDados(dados2) {
    if (dados2) {
      const valores = [];
      const labels = [];
      const backgroundColor = [];
      const borderColor = [];

      const dados = [...dados2];

      dados.sort((a, b) => {
        if (a.valor > b.valor) return 1;
        if (a.valor < b.valor) return -1;
        return 0;
      });

      for (let i = 0; i < dados.length; i++) {
        valores.push(dados[i].valor);
        labels.push(dados[i].label);
        backgroundColor.push(dados[i].backgroundColor);
        borderColor.push(dados[i].borderColor);
      }

      return {
        valores,
        labels,
        backgroundColor,
        borderColor,
      };
    }
    return {
      valores: [],
      labels: [],
      backgroundColor: [],
      borderColor: [],
    };
  }

  const { backgroundColor, borderColor, valores, labels } = formataDados(dados);

  function getDados() {
    if (!dados) {
      return [];
    }
    return valores;
  }

  const data = {
    labels,
    datasets: [
      {
        label: '# of Votes',
        data: getDados(),
        backgroundColor,
        borderColor,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.rootDialogo }}
      className={classes.rootDialogo}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{ backgroundColor: '#009FD4', color: 'white' }}
      >
        {`Composição do Preço de Venda ${dados ? precoVenda : ''} ${
          produto ? `(${produto})` : ''
        }`}
      </DialogTitle>
      <DialogContent>
        {dados ? (
          <div>
            <Doughnut
              data={data}
              /*options={{
                parsing: {
                  key: 'nested.value',
                },
              }}*/
            />
          </div>
        ) : (
          'Nenhum gráfico disponível'
        )}
      </DialogContent>
    </Dialog>
  );
});

export default Grafico;
