import React, { forwardRef } from 'react';

import { makeStyles, InputAdornment, TextField } from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const ImputPassword = forwardRef((props, ref) => (
  <TextField
    inputRef={ref}
    id="standard-adornment-password"
    type="password"
    autoComplete="off"
    color="secondary"
    {...props}
    fullWidth
    inputProps={{
      autocomplete: 'new-password',
      form: {
        autocomplete: 'off',
      },
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <VisibilityOff />
        </InputAdornment>
      ),
    }}
  />
));

export default ImputPassword;
