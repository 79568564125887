import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { DialogoConfirmacao } from '../../../..';
import { getListAllAPI } from '../../../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getTotalCompra,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3,
} from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogoPassadorLotes = forwardRef(
  ({ handleClose, handleSalvar }, ref) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    function handleCloseDialog() {
      setOpen(false);
      handleClose();
    }

    function handleSalvarDialog() {
      setOpen(false);
      handleSalvar();
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogContent dividers>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '40px',
                fontWeight: 'bold',
              }}
            >
              TOTAIS PARA VERIFICAÇÃO (em desenvolvimento)
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleCloseDialog()}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Voltar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvarDialog}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                color="primary"
              >
                Prosseguir
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoPassadorLotes;
