import React, { forwardRef } from 'react';

import { InputAdornment, TextField } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

const ImputUsername = forwardRef((props, ref) => (
  <TextField
    inputRef={ref}
    {...props}
    fullWidth
    autoComplete="off"
    color="secondary"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <AccountCircle />
        </InputAdornment>
      ),
    }}
  />
));

export default ImputUsername;
