function calculaMargeamento({
  valorNegociadoComAIndustria,
  irpjPorcentagemLb,
  csllPorcentagemLb,
  precoVenda,
  aliquotaInternaCompraPorcentagem,
  pisCompraPorcentagem,
  cofinsCompraPorcentagem,
  aliquotaInternaVendaPorcentagem,
  margemDeCustoOperacionalPorcentagem,
  margemDeCustoDeCartaoPorcentagem,
  repasseDoIpi,
  repasseDoFreteUnitario,
  repasseDeSt,
  repasseDeSeguros,
  repasseDeOutrasDespesas,
  icmsAntecipacao,
  icmsDeFronteira,
  descontoNfe,
  descontoBoleto,
  descontoBonificacao,
  descontoBacklight,
  descontoJornalOfertas,
  descontoDescargoMercadorias,
  margemDeAvariaPorcentagem,
}) {
  /* const custoFinalDoProduto =
    valorNegociadoComAIndustria +
    repasseDoIpi +
    repasseDoFreteUnitario +
    repasseDeSt +
    repasseDeSeguros +
    repasseDeOutrasDespesas; */

  const baseCalculoIcmsUnitaria =
    valorNegociadoComAIndustria +
    repasseDoFreteUnitario +
    repasseDeSeguros +
    repasseDeOutrasDespesas -
    descontoNfe;

  const custoFinalDoProduto =
    baseCalculoIcmsUnitaria + repasseDoIpi + repasseDeSt;

  const aliquotaInternaCompra =
    baseCalculoIcmsUnitaria * (aliquotaInternaCompraPorcentagem / 100);

  const pisCompra = baseCalculoIcmsUnitaria * (pisCompraPorcentagem / 100);

  const cofinsCompra =
    baseCalculoIcmsUnitaria * (cofinsCompraPorcentagem / 100);

  const custoLivreDeImpostoPorcentagem =
    custoFinalDoProduto - aliquotaInternaCompra - pisCompra - cofinsCompra;

  const custoAMais = icmsAntecipacao + icmsDeFronteira;

  const descontoAdicional =
    descontoBoleto +
    descontoBonificacao +
    descontoBacklight +
    descontoJornalOfertas +
    descontoDescargoMercadorias;

  const lucroBrutoPorcentagem =
    (1 -
      ((pisCompraPorcentagem +
        cofinsCompraPorcentagem +
        aliquotaInternaVendaPorcentagem +
        margemDeCustoOperacionalPorcentagem +
        margemDeCustoDeCartaoPorcentagem +
        margemDeAvariaPorcentagem) /
        100 +
        (custoLivreDeImpostoPorcentagem + custoAMais - descontoAdicional) /
          precoVenda)) *
    100;

  const lucroLiquidoPorcentagem =
    lucroBrutoPorcentagem * (1 - (irpjPorcentagemLb + csllPorcentagemLb) / 100);

  return {
    lucroBrutoPorcentagem,
    lucroLiquido: lucroLiquidoPorcentagem,
  };
}

export { calculaMargeamento };
