import React from 'react';
import {
  Filter as FilterAdmin,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

import { PrecoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Fornecedor"
      source="fornecedor_id"
      reference="fornecedores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Responsavel"
      source="fornecedor_id"
      reference="fornecedores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <BooleanInput label="Pedido Mínimo?" source="is_em_partes" />
    <PrecoInput source="valor" label="Valor" fixedDecimalScale={false} />
  </FilterAdmin>
);

export default Filter;
