import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  ListContextProvider,
  ReferenceField,
  TextField,
  DateField,
} from 'react-admin';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';
import moment from 'moment';

import { DialogoConfirmacao } from '../../Components';
import { api, getUserId } from '../../services';
import { formatPeso } from '../../utils';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DialogoConferencias from './DialogoConferencias';
import DialogoValidade from './DialogoValidade';
import DiasVencimentoField from './DiasVencimentoField';
import Filter from './Filter';

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

function getDias(record) {
  if (record) {
    if (record.data) {
      const dataVencimento = new Date(
        moment(new Date(record.data)).endOf('day').format(),
      );

      const dataAtual = new Date(moment(new Date()).endOf('day').format());

      const delta = dataVencimento.getTime() - dataAtual.getTime();

      return parseInt(delta / (24 * 60 * 60 * 1000), 10);
    }
    return 0;
  }
  return 0;
}

function getBandeira(dias) {
  if (dias >= 0 && dias <= 3) return 'resolver-agora';
  if (dias >= 4 && dias <= 7) return 'resolver-urgente';
  if (dias >= 8 && dias <= 14) return 'resolver-logo';
  if (dias >= 15 && dias <= 21) return 'resolver';
  if (dias >= 22 && dias <= 28) return 'atencao';
  if (dias >= 29 && dias <= 60) return 'nao-e-data-critica';
  return '';
}

const rowStyle = (record, index) => {
  if (record) {
    const dias = getDias(record);
    switch (getBandeira(dias)) {
      case 'resolver-agora':
        return {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          width: '100%',
          heigth: '100%',
          margin: 0,
          padding: 0,
        };
      case 'resolver-urgente':
        return {
          backgroundColor: 'rgba(255, 0, 0, 0.5)',
          color: 'white',
          width: '100%',
          heigth: '100%',
        };
      case 'resolver-logo':
        return {
          backgroundColor: 'rgba(255, 77, 0, 0.5)',
          width: '100%',
          heigth: '100%',
        };
      case 'resolver':
        return {
          backgroundColor: 'rgba(242, 255, 0, 0.5)',
          width: '100%',
          heigth: '100%',
        };
      case 'atencao':
        return {
          backgroundColor: 'rgba(173, 255, 0, 0.5)',
          width: '100%',
          heigth: '100%',
        };
      case 'nao-e-data-critica':
        return { backgroundColor: 'white', width: '100%', heigth: '100%' };
      default:
        return {};
    }
  }
  return {
    backgroundColor: 'white',
  };
};

function montaMensagemEstoque(record) {
  const arr = [];
  arr.push(
    record.numero_caixas_nivel_3 ? `${record.numero_caixas_nivel_3} CX3` : '',
  );
  arr.push(
    record.numero_caixas_nivel_2 ? `${record.numero_caixas_nivel_2} CX2` : '',
  );
  arr.push(
    record.numero_caixas_nivel_1 ? `${record.numero_caixas_nivel_1} CX1` : '',
  );
  arr.push(record.unidades ? `${record.unidades} UN` : '');
  arr.push(record.peso ? `${formatPeso(record.peso)} ` : '');

  return arr.filter((item) => item !== '').join(', ');
}

const PostPanel = ({ id, record, resource }) => (
  <div style={{ display: 'flex' }}>
    {`Estoque da Última Inspeção: ${montaMensagemEstoque(record)}`}
  </div>
);

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoConfirmacao = useRef(null);
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const refDialogoValidade = useRef(null);
  const refDialogoConferencias = useRef(null);

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Colocar na lista novamente',
        'Deseja realmente Colocar na lista novamente?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDesativar({ id }) {
    /* if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Marcar como esgotado',
        'Deseja realmente Marcar como esgotado?',
        { id, codigo: 1 },
      );
    } */
    if (refDialogoValidade.current) {
      refDialogoValidade.current.handleOpen(id);
    }
  }

  function handleShow({ id }) {
    if (refDialogoConferencias.current) {
      refDialogoConferencias.current.handleOpen(id);
    }
  }

  async function ativar(id) {
    await api.put(`/validades-produtos/status/ativar/${id}/${getUserId()}`);
    handleClose();
  }

  async function desativar(id) {
    await api.put(`/validades-produtos/status/inativar/${id}/${getUserId()}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Validades" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props} rowStyle={rowStyle}>
                <ReferenceField
                  label="Produto"
                  source="produto_id"
                  reference="produtos_search_filter"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <DateField source="data" label="Validade" />
                <DiasVencimentoField source="data" label="Dias para Vencer" />
                <ReferenceField
                  label="Criador"
                  source="criador_id"
                  reference="users"
                  sortable={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <DateField
                  source="data_ultima_verificacao"
                  label="Última Inspeção"
                />
                <ReferenceField
                  label="Último a Verificar"
                  source="verificador_id"
                  reference="users"
                  sortable={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleAtivar={() => {}}
                  handleDesativar={handleDesativar}
                  handleShow={handleShow}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props} rowStyle={rowStyle}>
                <ReferenceField
                  label="Produto"
                  source="produto_id"
                  reference="produtos_search_filter"
                  sortable={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <DateField source="data" label="Validade" />
                <DiasVencimentoField source="data" label="Dias para Vencer" />
                <ReferenceField
                  label="Verificador"
                  source="verificador_id"
                  reference="users"
                  sortable={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                {/* <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleAtivar={handleAtivar}
                  handleDesativar={() => {}}
                /> */}
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoValidade ref={refDialogoValidade} handleClose={handleClose} />
      <DialogoConferencias
        ref={refDialogoConferencias}
        handleClose={() => {}}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Validades"
      filterDefaultValues={{
        status: STATUS_ATIVO,
      }}
      component="div"
    />
  </>
);

export default List;
