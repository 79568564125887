import React, {
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Switch, ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';

import imagem from '../../../assets/background.jpg';
import { getListAPI, getListAllAPI } from '../../../services';
import {
  FORNECEDOR_DEFAULT,
  formatLotes,
  formatParcelasCompra,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
} from '../../../utils';
import theme from '../../DialogoEditarVenda/themes/default';
import {
  reducerCompra,
  CompraContext,
  InicioFrenteCaixaCompra,
  FinalizarFrenteCaixaCompra,
  PreInicioFrenteCaixaCompra,
  TipoCompra,
  ImportacaoXML,
  ImportacaoXMLSemArquivo,
} from './Pages';

const TELA_PRE_INICIO_FRENTE_CAIXA = -1;
const TELA_INICIO_FRENTE_CAIXA = 0;
const TELA_FINALIZAR_FRENTE_CAIXA = 1;

const TELA_TIPO_COMPRA = 2;

const TELA_IMPORTACAO_XML = 3;

const TELA_IMPORTACAO_XML_SEM_ARQUIVO = 4;

function SwitchElements({ elementos, value }) {
  const selecionado = elementos[value];
  return <selecionado.elemento {...selecionado.props} />;
}

const DialogoCreateCompra = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [tela, setTela] = useState(TELA_TIPO_COMPRA);
  const { enqueueSnackbar } = useSnackbar();
  const [chaveDeAcesso, setChaveDeAcesso] = useState('');

  const [compra, dispatch] = useReducer(reducerCompra, {
    fornecedor: null,
    lotes: [],
    parcelas: [],
    idEdit: -1,
    numeroNotaFiscal: '',
    data_faturamento: '',
    data_criacao: new Date(),
    loja: {
      nome: 'CARUARU FRIOS',
      value: 'CF',
    },
    repasse_ipi: 0,
    repasse_frete: 0,
    repasse_st: 0,
    repasse_seguros: 0,
    repasse_outras_despesas: 0,
    carregando: true,
    config: null,
    numeroCompra: '-',
    isProdutosSomenteFornecedor: true,
    isIncluiInativos: false,
    serieNotaFiscal: '',
    chaveAcessoNotaFiscal: '',
    isImportacaoXml: false,
    tipo: TIPO_COMPRA_MERCADORIAS_REVENDA,
  });

  function resetar() {
    dispatch({ type: 'RESETAR_TUDO' });
  }

  async function getConfig() {
    const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, [
      'cidade.estado',
      'contaPadraoParcelaCompra',
    ]);
    if (config.data.length <= 0) {
      throw 'Configurações inacessíveis!';
    }
    return config.data[0];
  }

  async function reiniciar() {
    /*const dataFornecedores = await getListAllAPI(
      'fornecedores',
      ['id', 'asc'],
      { nome: FORNECEDOR_DEFAULT },
      ['cidade.estado'],
    );*/

    const config = await getConfig();

    dispatch({
      type: 'PREENCHER_TUDO',
      idEdit: -1,
      lotes: [],
      parcelas: [],
      //fornecedor: dataFornecedores.data[0],
      fornecedor: null,
      numeroNotaFiscal: '',
      data_faturamento: '',
      data_criacao: new Date(),
      loja: {
        nome: 'CARUARU FRIOS',
        value: 'CF',
      },
      repasse_ipi: 0,
      repasse_frete: 0,
      repasse_st: 0,
      repasse_seguros: 0,
      repasse_outras_despesas: 0,
      carregando: false,
      config,
      numeroCompra: '-',
      isProdutosSomenteFornecedor: true,
      isIncluiInativos: false,
      serieNotaFiscal: '',
      chaveAcessoNotaFiscal: '',
      isImportacaoXml: false,
      tipo: TIPO_COMPRA_MERCADORIAS_REVENDA,
    });
  }

  const handleCloseDialog = () => {
    setOpen(false);
    resetar();
    setTela(TELA_PRE_INICIO_FRENTE_CAIXA);
    handleClose();
    dispatch({
      type: 'PREENCHER_TUDO',
      idEdit: -1,
      lotes: [],
      parcelas: [],
      //fornecedor: dataFornecedores.data[0],
      fornecedor: null,
      numeroNotaFiscal: '',
      data_faturamento: '',
      data_criacao: new Date(),
      loja: {
        nome: 'CARUARU FRIOS',
        value: 'CF',
      },
      repasse_ipi: 0,
      repasse_frete: 0,
      repasse_st: 0,
      repasse_seguros: 0,
      repasse_outras_despesas: 0,
      carregando: true,
      config: null,
      numeroCompra: '-',
      isProdutosSomenteFornecedor: true,
      isIncluiInativos: false,
      serieNotaFiscal: '',
      chaveAcessoNotaFiscal: '',
      isImportacaoXml: false,
      tipo: TIPO_COMPRA_MERCADORIAS_REVENDA,
    });
  };

  function irParaTelaInit() {
    handleCloseDialog();
  }

  function irParaTelaFinalizarCompra() {
    setTela(TELA_FINALIZAR_FRENTE_CAIXA);
  }

  function irParaTelaFrentedeCaixa() {
    setTela(TELA_INICIO_FRENTE_CAIXA);
  }

  function irParaTelaPreFrentedeCaixa() {
    setTela(TELA_PRE_INICIO_FRENTE_CAIXA);
  }

  function irParaTelaCarregamentoXml() {
    setTela(TELA_IMPORTACAO_XML);
  }

  function irParaTelaTipoCompra() {
    setTela(TELA_TIPO_COMPRA);
  }

  function format10(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  function formatDate(date) {
    if (date) {
      const dateReal = new Date(date);
      const ano = dateReal.getFullYear();
      const mes = format10(dateReal.getMonth() + 1);
      const dia = format10(dateReal.getDate());
      return `${ano}-${mes}-${dia}`;
    }
    return '';
  }

  async function getDataCompra(idCompra) {
    try {
      setTela(TELA_PRE_INICIO_FRENTE_CAIXA);
      const data = await getListAPI(
        'compras',
        ['id', 'asc'],
        [1, 1],
        { id: [idCompra] },
        [
          'lotes.produto.unidade',
          'lotes.dadosPrecificacao',
          'parcelas.despesa.conta',
          'fornecedor.estado',
          'fornecedor.cidade.estado',
          'fornecedor.prazosFornecedores',
          'parcelas.despesa.fatura.cartao',
          'parcelas.despesa.problema',
        ],
      );
      if (data.data.length <= 0) {
        throw 'Esta compra não existe!';
      }

      const config = await getConfig();

      dispatch({
        type: 'PREENCHER_TUDO',
        idEdit: data.data[0].id,
        lotes: formatLotes(data.data[0].lotes),
        parcelas: formatParcelasCompra(data.data[0].parcelas),
        fornecedor: data.data[0].fornecedor,
        numeroNotaFiscal: data.data[0].numero_nota_fiscal
          ? data.data[0].numero_nota_fiscal
          : '',
        data_faturamento: formatDate(data.data[0].data_faturamento),
        data_criacao: new Date(data.data[0].data),
        loja:
          data.data[0].loja === 'CF'
            ? {
                nome: 'CARUARU FRIOS',
                value: 'CF',
              }
            : {
                nome: 'CASA DA PIZZA',
                value: 'CP',
              },
        repasse_ipi: data.data[0].repasse_ipi,
        repasse_frete: data.data[0].repasse_frete,
        repasse_st: data.data[0].repasse_st,
        repasse_seguros: data.data[0].repasse_seguros,
        repasse_outras_despesas: data.data[0].repasse_outras_despesas,
        carregando: false,
        config,
        numeroCompra: data.data[0].numero,
        isProdutosSomenteFornecedor: true,
        isIncluiInativos: false,
        serieNotaFiscal: data.data[0].serie_nota_fiscal
          ? data.data[0].serie_nota_fiscal
          : '',
        chaveAcessoNotaFiscal: data.data[0].chave_acesso_nota_fiscal
          ? data.data[0].chave_acesso_nota_fiscal
          : '',
        isImportacaoXml: data.data[0].is_importacao_xml
          ? data.data[0].is_importacao_xml
          : false,
        tipo: data.data[0].tipo,
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      irParaTelaInit();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setTela(TELA_TIPO_COMPRA);
      setOpen(true);
    },
    handleEdit(idCompra) {
      getDataCompra(idCompra);
      setOpen(true);
    },
    handleOpenApartirDaNfeCompra(chave) {
      reiniciar();
      setTela(TELA_IMPORTACAO_XML_SEM_ARQUIVO);
      setChaveDeAcesso(chave);
      setOpen(true);
    },
  }));

  function getDadosTamTela() {
    switch (tela) {
      case TELA_PRE_INICIO_FRENTE_CAIXA:
        return {
          fullScreen: true,
        };
      case TELA_INICIO_FRENTE_CAIXA:
        return {
          fullScreen: true,
        };
      case TELA_FINALIZAR_FRENTE_CAIXA:
        return {
          fullScreen: true,
        };
      case TELA_TIPO_COMPRA:
        return {
          fullWidth: true,
          maxWidth: 'sm',
        };
      case TELA_IMPORTACAO_XML:
        return {
          fullScreen: true,
        };
      default:
        return {
          fullScreen: true,
        };
    }
  }

  const dadosTela = getDadosTamTela();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          {...dadosTela}
          /* scroll="body" */
        >
          {/* <DialogTitle
            id="form-dialog-title"
          >
            {`Nova Compra - ${tela === TELA_INICIO_FRENTE_CAIXA ? 'LOTES' : 'PARCELAS'}`}
          </DialogTitle> */}
          <DialogContent
            style={{
              padding: '0',
              margin: '0',
              // height: 'calc(100vh)',
              // backgroundColor: '#f5f5f5',
              /* background: 'url(https://source.unsplash.com/collection/2288687/1600x900)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', */
              backgroundColor: '#ede8e8',
              backgroundImage: `url(${imagem})`,
              backgroundSize: 'cover',
            }}
            dividers
          >
            <CompraContext.Provider
              value={{ compra, dispatch, openMenu: () => {} }}
            >
              {!compra.carregando ? (
                <SwitchElements
                  value={tela}
                  elementos={{
                    [TELA_PRE_INICIO_FRENTE_CAIXA]: {
                      elemento: PreInicioFrenteCaixaCompra,
                      props: {
                        irParaTelaInit,
                        irParaTelaFrentedeCaixa,
                      },
                    },
                    [TELA_INICIO_FRENTE_CAIXA]: {
                      elemento: InicioFrenteCaixaCompra,
                      props: {
                        irParaTelaInit: irParaTelaPreFrentedeCaixa,
                        irParaTelaFinalizarCompra,
                        showAtendente: false,
                      },
                    },
                    [TELA_FINALIZAR_FRENTE_CAIXA]: {
                      elemento: FinalizarFrenteCaixaCompra,
                      props: {
                        irParaTelaInit,
                        irParaTelaFrentedeCaixa,
                        showAtendente: false,
                        editarModo: compra.idEdit > 0,
                      },
                    },
                    [TELA_TIPO_COMPRA]: {
                      elemento: TipoCompra,
                      props: {
                        cancelarCompra: irParaTelaInit,
                        irParaTelaFrentedeCaixa: irParaTelaPreFrentedeCaixa,
                        irParaTelaCarregamentoXml,
                      },
                    },
                    [TELA_IMPORTACAO_XML]: {
                      elemento: ImportacaoXML,
                      props: {
                        cancelarCompra: irParaTelaInit,
                        irParaTelaFrentedeCaixa: irParaTelaPreFrentedeCaixa,
                      },
                    },
                    [TELA_IMPORTACAO_XML_SEM_ARQUIVO]: {
                      elemento: ImportacaoXMLSemArquivo,
                      props: {
                        cancelarCompra: irParaTelaInit,
                        irParaTelaFrentedeCaixa: irParaTelaPreFrentedeCaixa,
                        chaveDeAcesso,
                      },
                    },
                  }}
                />
              ) : (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'red',
                    fontSize: '70px',
                    fontWeight: 'bold',
                  }}
                >
                  Carregando...
                </div>
              )}
            </CompraContext.Provider>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
});

export default DialogoCreateCompra;
