import React, { useState, forwardRef, useImperativeHandle } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { getListAllAPI, api } from '../../../services';
import { SECONDARY_COLOR } from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    //width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    //padding: '7px',
    border: '1px solid black',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //width: 'min-content',
    width: '10px !important',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    //padding: '7px',
    border: '1px solid black',
    //width: 'min-content',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //textAlign: 'center',
    width: '10px !important',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

function getHeaderTabela(columns, class1, class2) {
  return (
    <>
      {columns.map((item, index) => (
        <StyledTableCell
          align="left"
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            wordBreak: 'break-word',
          }}
          className={index === 0 ? class1 : class2}
        >
          {item}
        </StyledTableCell>
      ))}
    </>
  );
}

function LabelHeader({ texto }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {texto.map((item) => (
        <span>{item}</span>
      ))}
    </div>
  );
}

const colunas = [
  {
    label: 'Descrição',
    name: 'descricao',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'R$ / kg',
    name: 'rs_kg',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'R$ / un',
    name: 'rs_un',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'R$ / CX1',
    name: 'rs_cx',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'R$ / CX2',
    name: 'rs_cx',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'R$ / CX3',
    name: 'rs_cx',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'PEDIDO',
    name: 'pedido',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    //label: 'Unidade adotada na compra',
    label: <LabelHeader texto={['Unidade adotada na', 'compra']} />,
    name: 'unidade_adotada_na_compra',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'SUBTOTAL',
    name: 'subtotal',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    //label: 'PESO SUBTOTAL',
    label: <LabelHeader texto={['PESO', 'SUBTOTAL']} />,
    name: 'peso_subtotal',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'Validade',
    name: 'validade',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'PESO UNITÁRIO',
    name: 'peso_unitario',
    enabled: true,
    disabled: false,
    dica: '',
  },
  {
    label: 'REF',
    name: 'ref',
    enabled: true,
    disabled: false,
    dica: '',
  },
  /*  {
    label: 'CAIXARIA',
    name: 'caixaria',
    enabled: true,
    disabled: false,
  },
  {
    label: 'UNIDADE',
    name: 'unidade',
    enabled: true,
    disabled: false,
  },
  {
    label: 'Palet Padrão',
    name: 'palet_padrao',
    enabled: true,
    disabled: false,
  },

  {
    label: 'Referência do Fornecedor',
    name: 'referencia_do_fornecedor',
    enabled: true,
    disabled: false,
  },
  {
    label: 'VTU30   UND / KG',
    name: 'vtu_30',
    enabled: true,
    disabled: false,
  },
  {
    label: 'VMD',
    name: 'vmd',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ESTOQUE MÍNIMO',
    name: 'estoque_minimo',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ESTOQUE MÍNIMO VIA EXPOSIÇÃO GERÊNCIA',
    name: 'estoque_minimo_via_exposicao_gerencia',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ESTOQUE ATUAL',
    name: 'estoque_atual',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ESTOQUE FUTURO NO DIA DA ENTREGA',
    name: 'estoque_futuro_no_dia_da_entrega',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ESTOQUE MÁXIMO (PGTO; ENTREGA)',
    name: 'estoque_maximo_pgto_entrega',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ESTOQUE MÁXIMO VIA MARGEM DE CONTRIBUIÇÃO',
    name: 'estoque_maximo_via_margem_de_contribuicao',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ESTOQUE MÁXIMO VIA DIAS DE VALIDADE DISPONÍVEL',
    name: 'estoque_maximo_via_dias_de_validade_disponivel',
    enabled: true,
    disabled: false,
  },
  {
    label: 'DIAS DE COBERTURA DE ESTOQUE ATUAL',
    name: 'dias_de_cobertura_de_estoque_atual',
    enabled: true,
    disabled: false,
  },
  {
    label: 'COMPRAR',
    name: 'comprar',
    enabled: true,
    disabled: false,
  },
  {
    label: 'Venda (R$)',
    name: 'venda_rs',
    enabled: true,
    disabled: false,
  },
  {
    label: 'MARGEM DE CONTRIBUIÇÃO',
    name: 'margem_de_contribuicao',
    enabled: true,
    disabled: false,
  },
  {
    label: 'ULTIMO PREÇO DE VENDA',
    name: 'ultimo_preco_de_venda',
    enabled: true,
    disabled: false,
  },
  {
    label: 'INTERPRETAÇÃO',
    name: 'interpretacao',
    enabled: true,
    disabled: false,
  },
  {
    label: 'DIAS DE VALIDADE DISPONÍVEL',
    name: 'dias_de_validade_disponivel',
    enabled: true,
    disabled: false,
  }, */
];

const itens = [
  {
    descricao: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    rs_kg: {
      name: 'rs_kg',
      value: 'Mussarela Garanhuns - Barra',
    },
    rs_un: {
      name: 'rs_un',
      value: 'Mussarela Garanhuns - Barra',
    },
    rs_cx: {
      name: 'rs_cx',
      value: 'Mussarela Garanhuns - Barra',
    },
    pedido: {
      name: 'pedido',
      value: 'Mussarela Garanhuns - Barra',
    },
    unidade_adotada_na_compra: {
      name: 'unidade_adotada_na_compra',
      value: 'Mussarela Garanhuns - Barra',
    },
    subtotal: {
      name: 'subtotal',
      value: 'Mussarela Garanhuns - Barra',
    },
    peso_subtotal: {
      name: 'peso_subtotal',
      value: 'Mussarela Garanhuns - Barra',
    },
    peso_unitario: {
      name: 'peso_unitario',
      value: 'Mussarela Garanhuns - Barra',
    },
    caixaria: {
      name: 'caixaria',
      value: 'Mussarela Garanhuns - Barra',
    },
    unidade: {
      name: 'unidade',
      value: 'Mussarela Garanhuns - Barra',
    },
    palet_padrao: {
      name: 'palet_padrao',
      value: 'Mussarela Garanhuns - Barra',
    },
    validade: {
      name: 'validade',
      value: 'Mussarela Garanhuns - Barra',
    },
    referencia_do_fornecedor: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    vtu_30: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    vmd: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    estoque_minimo: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    estoque_minimo_via_exposicao_gerencia: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    estoque_atual: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    estoque_futuro_no_dia_da_entrega: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    estoque_maximo_pgto_entrega: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    estoque_maximo_via_margem_de_contribuicao: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    estoque_maximo_via_dias_de_validade_disponivel: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    dias_de_cobertura_de_estoque_atual: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    comprar: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    venda_rs: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    margem_de_contribuicao: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    ultimo_preco_de_venda: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    interpretacao: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
    dias_de_validade_disponivel: {
      name: 'descricao',
      value: 'Mussarela Garanhuns - Barra',
    },
  },
];

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, cor = 'green' }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(-1);
    const [carregando, setCarregando] = useState(false);
    const [fornecedor, setFornecedor] = useState(null);
    const [data, seData] = useState('08/10/2024');

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      /*  try {
        const data = await getListAllAPI(
          'softwares',
          ['id', 'asc'],
          { id: [id] },
          [],
        );
        if (data.data.length > 0) {
          setCarregando(false);
        } else {
          throw 'Este software não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      } */
      setFornecedor({
        razao_social: 'Seara',
      });
    }

    async function handleSalvar() {
      /*     try {
        if (id <= 0) {
          enqueueSnackbar('Nenhum software aqui!!!', {
            variant: 'error',
          });
        } else {
          await api.delete(`/softwares/${id}`);
          setCarregando(false);
          enqueueSnackbar(`Software excluído com sucesso!`, {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar('Existem registros relacionados a este software!', {
          variant: 'error',
        });
        handleCloseDialog();
      } */

      handleCloseDialog();
    }

    useImperativeHandle(ref, () => ({
      handleOpen(id) {
        //setCarregando(true);
        getDataResource(id);
        setOpen(true);
        setId(id);
      },
    }));

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullScreen
          /* fullWidth
          maxWidth="xs" */
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{
              backgroundColor: SECONDARY_COLOR,
              color: 'white',
            }}
          >
            {`Sugestão de Pré Compra - ${
              fornecedor ? fornecedor.razao_social : ''
            } - ${data}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  padding: '10px',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    overflowX: 'scroll',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Table
                    aria-label="a dense table"
                    style={
                      {
                        /* width: 'min-content', */
                        /* tableLayout: 'fixed'  */
                      }
                    }
                  >
                    <TableHead>
                      <TableRow>
                        {getHeaderTabela(
                          colunas.map((item) => item.label),
                          classes.sticky,
                          classes.sticky2,
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>asaas</TableBody>
                  </Table>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  color="primary"
                  disabled={carregando}
                >
                  Deletar
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
