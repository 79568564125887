import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  AutoCompleteRemoto,
  PesoInput,
  IntegerInput,
  PrecoInput,
  PorcentagemInput,
  TeclaInput,
  SwitchInput,
  CfopInput,
  CstInput,
  NcmInput,
  CestInput,
  SelectInput,
} from '../../Components';
import { api, getListAllAPI, updateAPI } from '../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

function IndicadorTravado({ value, onChange }) {
  const classes = useStyles();
  return value ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title="Travado" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          //classes={{ root: classes.root }}
          size="small"
          onClick={() => onChange(false)}
          style={{ color: 'red' }}
        >
          <LockIcon />
        </IconButton>
      </Tooltip>
      <span style={{ color: 'red' }}>(Travado)</span>
    </div>
  ) : (
    <Tooltip title="Sem Trave" placement="bottom" aria-label="add2">
      <IconButton
        aria-label="edit"
        //classes={{ root: classes.root }}
        size="small"
        onClick={() => onChange(true)}
        style={{ color: 'green' }}
      >
        <LockOpenIcon />
      </IconButton>
    </Tooltip>
  );
}

const optionsCfop = [
  {
    label: '5102 - Venda de mercadoria de terceiros',
    value: '5102',
  },
  {
    label:
      '5405 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído',
    value: '5405',
  },
];

const optionsCstIcms = [
  {
    label: '00 - Tributada integralmente',
    value: '00',
  },
  /*  {
      label: 'Tributada e com cobrança do ICMS por substituição tributária',
      value: '10',
    }, */
  {
    label: '20 - Tributação com redução de base de cálculo',
    value: '20',
  },
  /* {
      label:
        'Tributação Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
      value: '30',
    }, */
  {
    label: '40 - Tributação Isenta',
    value: '40',
  },
  {
    label: '41 - Tributação Não tributada',
    value: '41',
  },
  {
    label: '50 - Tributação Suspensão',
    value: '50',
  },
  /* {
      label: 'Tributação com Diferimento',
      value: '51',
    }, */
  /*  {
      label:
        '60 - Tributação ICMS cobrado anteriormente por substituição tributária',
      value: '60',
    }, */
  /*  {
      label:
        'Tributação ICMS com redução de base de cálculo e cobrança do ICMS por substituição tributária',
      value: '70',
    }, */
  /*  {
      label: 'Tributação ICMS: Outro',
      value: '90',
    }, */
];

const optionsCstIcms2 = [
  /*   {
      label: '00 - Tributada integralmente',
      value: '00',
    }, */
  /*  {
      label: 'Tributada e com cobrança do ICMS por substituição tributária',
      value: '10',
    }, */
  /*   {
      label: '20 - Tributação com redução de base de cálculo',
      value: '20',
    }, */
  /* {
      label:
        'Tributação Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
      value: '30',
    }, */
  /*   {
      label: '40 - Tributação Isenta',
      value: '40',
    },
    {
      label: '41 - Tributação Não tributada',
      value: '41',
    },
    {
      label: '50 - Tributação Suspensão',
      value: '50',
    }, */
  /* {
      label: 'Tributação com Diferimento',
      value: '51',
    }, */
  {
    label:
      '60 - Tributação ICMS cobrado anteriormente por substituição tributária',
    value: '60',
  },
  /*  {
      label:
        'Tributação ICMS com redução de base de cálculo e cobrança do ICMS por substituição tributária',
      value: '70',
    }, */
  /*  {
      label: 'Tributação ICMS: Outro',
      value: '90',
    }, */
];

const optionsCstPis = [
  {
    label:
      '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
    value: '01',
  },
  {
    label:
      '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
    value: '02',
  },
  /* {
      label:
        'Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
      value: '03',
    }, */
  {
    label: '04 - Operação Tributável (tributação monofásica (alíquota zero))',
    value: '04',
  },
  {
    label: '05 - Operação Tributável (Substituição Tributária)',
    value: '05',
  },
  {
    label: '06 - Operação Tributável (alíquota zero)',
    value: '06',
  },
  {
    label: '07 - Operação Isenta da Contribuição',
    value: '07',
  },
  {
    label: '08 - Operação Sem Incidência da Contribuição',
    value: '08',
  },
  {
    label: '09 - Operação com Suspensão da Contribuição',
    value: '09',
  },
];

const optionsCstCofins = [
  {
    label:
      '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
    value: '01',
  },
  {
    label:
      '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
    value: '02',
  },
  /* {
      label:
        'Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
      value: '03',
    }, */
  {
    label: '04 - Operação Tributável (tributação monofásica (alíquota zero))',
    value: '04',
  },
  {
    label: '05 - Operação Tributável (Substituição Tributária)',
    value: '05',
  },
  {
    label: '06 - Operação Tributável (alíquota zero)',
    value: '06',
  },
  {
    label: '07 - Operação Isenta da Contribuição',
    value: '07',
  },
  {
    label: '08 - Operação Sem Incidência da Contribuição',
    value: '08',
  },
  {
    label: '09 - Operação com Suspensão da Contribuição',
    value: '09',
  },
];

const optionsOrigIcms = [
  {
    label: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8;',
    value: '0',
  },
  {
    label: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
    value: '1',
  },
  {
    label:
      '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
    value: '2',
  },
  {
    label:
      '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
    value: '3',
  },
  {
    label:
      '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
    value: '4',
  },
  {
    label:
      '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
    value: '5',
  },
  {
    label:
      '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natura',
    value: '6',
  },
  {
    label:
      '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural',
    value: '7',
  },
  {
    label:
      '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
    value: '8',
  },
];

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ImpostoInput = forwardRef(
  (
    {
      name,
      handleEnter = () => {},
      label,
      handleKey = () => {},
      value,
      onChange,
      error = false,
      helperText = '',
      fullWidth = false,
      autoFocus = false,
      isCasoEspecial,
      disabled = false,
      onChangeIsCasoEspecial,
    },
    ref,
  ) => (
    <PorcentagemInput
      ref={ref}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      escala={2}
      autoFocus={autoFocus}
      handleEnter={handleEnter}
      handleKey={handleKey}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      InputProps={{
        disabled,
        endAdornment: (
          <SwitchInput
            name={`${name}_caso_especial`}
            handleEnter={() => {}}
            label="Caso Especial"
            handleKey={() => {}}
            checked={isCasoEspecial}
            onChange={(value) => {
              onChangeIsCasoEspecial(value.target.checked);
            }}
          />
        ),
      }}
    />
  ),
);

function getDadosImpostosComputados(dados) {
  const {
    cstIcmsEntrada,
    cstIcmsSaida,
    cstPisEntrada,
    cstPisSaida,
    cstCofinsEntrada,
    cstCofinsSaida,
    cest,
  } = dados;

  return {
    isCasoEspecialPisCredito: cstPisEntrada ? cstPisEntrada !== '01' : false,
    isCasoEspecialPisDebito: cstPisSaida ? cstPisSaida !== '01' : false,
    isCasoEspecialCofinsCredito: cstCofinsEntrada
      ? cstCofinsEntrada !== '01'
      : false,
    isCasoEspecialCofinsDebito: cstCofinsSaida
      ? cstCofinsSaida !== '01'
      : false,
    isCasoEspecialIpiCredito: false,
    isCasoEspecialIpiDebito: false,
    isCasoEspecialIcmsNormalCredito: cstIcmsEntrada
      ? cstIcmsEntrada !== '00'
      : false,
    isCasoEspecialIcmsNormalDebito: cstIcmsSaida
      ? cstIcmsSaida !== '00'
      : false,
  };
}

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles({ cor: '#009FD4' });
  const [carregando, setCarregando] = useState(false);
  const [cstIcmsEntrada2, setCstIcmsEntrada] = useState('');
  const [cstIcmsSaida2, setCstIcmsSaida] = useState('');
  const [cstPisEntrada, setCstPisEntrada] = useState('');
  const [cstPisSaida, setCstPisSaida] = useState('');
  const [cstCofinsEntrada, setCstCofinsEntrada] = useState('');
  const [cstCofinsSaida, setCstCofinsSaida] = useState('');
  const [origIcms, setOrigIcms] = useState('');

  const [fcp, setFcp] = useState(0);

  const [pautaFiscalIcms, setPautaFiscalIcms] = useState(0);
  const [rbc, setRbc] = useState(0);

  const [pisCredito, setPisCredito] = useState(0);
  const [pisDebito, setPisDebito] = useState(0);
  const [cofinsCredito, setCofinsCredito] = useState(0);
  const [cofinsDebito, setCofinsDebito] = useState(0);
  const [icmsNormalCredito, setIcmsNormalCredito] = useState(0);
  const [icmsNormalDebito, setIcmsNormalDebito] = useState(0);
  const [icmsFronteira, setIcmsFonteira] = useState(0);
  const [icmsAntecipacao, setIcmsAntecipacao] = useState(0);
  const [
    isCasoEspecialIcmsFronteira,
    setIsCasoEspecialIcmsFronteira,
  ] = useState(false);
  const [
    isCasoEspecialIcmsAntecipacao,
    setIsCasoEspecialIcmsAntecipacao,
  ] = useState(false);
  const [mva, setMva] = useState(0);
  const [configs, setConfigs] = useState(null);
  const [id, setId] = useState(-1);

  ///////////////////////////////////////////////////////

  const [descricaoLeiPis, setDescricaoLeiPis] = useState('');
  const [descricaoLeiCofins, setDescricaoLeiCofins] = useState('');
  const [descricaoLeiIcms, setDescricaoLeiIcms] = useState('');

  ///////////////////////////////////////////////////

  const [pisEntradaTravado, setPisEntradaTravado] = useState(false);
  const [pisSaidaTravado, setPisSaidaTravado] = useState(false);
  const [cofinsEntradaTravado, setCofinsEntradaTravado] = useState(false);
  const [cofinsSaidaTravado, setCofinsSaidaTravado] = useState(false);
  const [icmsEntradaTravado, setIcmsEntradaTravado] = useState(false);
  const [icmsSaidaTravado, setIcmsSaidaTravado] = useState(false);
  const [icmsAntecipacaoTravado, setIcmsAntecipacaoTravado] = useState(false);
  const [icmsFronteiraTravado, setIcmsFronteiraTravado] = useState(false);

  ///////////////////////////////////////////////////////
  const [descricao, setDescricao] = useState('');
  const [ncm, setNcm] = useState('');
  ////////////////////////////////////////////

  const cstIcmsEntrada = cstIcmsEntrada2;
  const cstIcmsSaida = cstIcmsSaida2;

  const {
    isCasoEspecialCofinsCredito,
    isCasoEspecialCofinsDebito,
    isCasoEspecialIcmsNormalCredito,
    isCasoEspecialIcmsNormalDebito,
    isCasoEspecialPisCredito,
    isCasoEspecialPisDebito,
  } = getDadosImpostosComputados({
    cstIcmsEntrada,
    cstIcmsSaida,
    cstPisEntrada,
    cstPisSaida,
    cstCofinsEntrada,
    cstCofinsSaida,
  });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'tabela-ncm',
        ['id', 'asc'],
        { id: [id] },
        [],
      );

      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        ['cidade.estado'],
      );

      if (data.data.length > 0) {
        setConfigs(dataConfig.data[0]);
        setId(data.data[0].id);
        setRbc(data.data[0].rbc);
        setCstPisEntrada(data.data[0].cst_pis_entrada);
        setCstPisSaida(data.data[0].cst_pis_saida);
        setCstCofinsEntrada(data.data[0].cst_cofins_entrada);
        setCstCofinsSaida(data.data[0].cst_cofins_saida);
        setMva(data.data[0].mva);
        setCarregando(false);
        setPisCredito(data.data[0].pis_credito);
        setPisDebito(data.data[0].pis_debito);
        setCofinsCredito(data.data[0].cofins_credito);
        setCofinsDebito(data.data[0].cofins_debito);
        setIcmsNormalCredito(data.data[0].icms_normal_credito);
        setIcmsNormalDebito(data.data[0].icms_normal_debito);
        setIcmsFonteira(data.data[0].icms_fronteira);
        setIsCasoEspecialIcmsFronteira(
          data.data[0].is_caso_especial_icms_fronteira,
        );
        setIcmsAntecipacao(data.data[0].icms_antecipacao);
        setIsCasoEspecialIcmsAntecipacao(
          data.data[0].is_caso_especial_icms_antecipacao,
        );
        setOrigIcms(data.data[0].orig);
        setFcp(data.data[0].fcp ? data.data[0].fcp : 0);
        setPautaFiscalIcms(
          data.data[0].pauta_fiscal ? data.data[0].pauta_fiscal : 0,
        );

        setCstIcmsEntrada(data.data[0].cst_icms_entrada);
        setCstIcmsSaida(data.data[0].cst_icms_saida);

        setDescricao(data.data[0].descricao);
        setNcm(data.data[0].ncm);

        //////////////

        setDescricaoLeiPis(data.data[0].descricao_lei_pis);
        setDescricaoLeiCofins(data.data[0].descricao_lei_cofins);
        setDescricaoLeiIcms(data.data[0].descricao_lei_icms);

        //////////////

        setPisEntradaTravado(data.data[0].pis_entrada_travado ? true : false);
        setPisSaidaTravado(data.data[0].pis_saida_travado ? true : false);
        setCofinsEntradaTravado(
          data.data[0].cofins_entrada_travado ? true : false,
        );
        setCofinsSaidaTravado(data.data[0].cofins_saida_travado ? true : false);
        setIcmsEntradaTravado(data.data[0].icms_entrada_travado ? true : false);
        setIcmsSaidaTravado(data.data[0].icms_saida_travado ? true : false);
        setIcmsAntecipacaoTravado(
          data.data[0].icms_antecipacao_travado ? true : false,
        );
        setIcmsFronteiraTravado(
          data.data[0].icms_fronteira_travado ? true : false,
        );

        ///////////////////
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleUpdate(id) {
      setOpen(true);
      getDataResource(id);
    },
  }));

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          //
        } else {
          const produtoAtualizado = await updateAPI('tabela-ncm', id, {
            rbc,
            cst_pis_entrada: cstPisEntrada,
            cst_pis_saida: cstPisSaida,
            cst_cofins_entrada: cstCofinsEntrada,
            cst_cofins_saida: cstCofinsSaida,
            mva,
            pis_credito: isCasoEspecialPisCredito
              ? cstPisEntrada === '02'
                ? pisCredito
                : 0
              : pisConfig,
            is_caso_especial_pis_credito: isCasoEspecialPisCredito,
            pis_debito: isCasoEspecialPisDebito
              ? cstPisSaida === '02'
                ? pisDebito
                : 0
              : pisConfig,
            is_caso_especial_pis_debito: isCasoEspecialPisDebito,
            cofins_credito: isCasoEspecialCofinsCredito
              ? cstCofinsEntrada === '02'
                ? cofinsCredito
                : 0
              : cofinsConfig,
            is_caso_especial_cofins_credito: isCasoEspecialCofinsCredito,
            cofins_debito: isCasoEspecialCofinsDebito
              ? cstCofinsSaida === '02'
                ? cofinsDebito
                : 0
              : cofinsConfig,
            is_caso_especial_cofins_debito: isCasoEspecialCofinsDebito,
            icms_normal_credito: isCasoEspecialIcmsNormalCredito
              ? cstIcmsEntrada === '20'
                ? icmsNormalCredito
                : 0
              : icms_ai_compraConfig,
            is_caso_especial_icms_normal_credito: isCasoEspecialIcmsNormalCredito,
            icms_normal_debito: isCasoEspecialIcmsNormalDebito
              ? cstIcmsEntrada === '20'
                ? icmsNormalDebito
                : 0
              : icms_ai_compraConfig,
            is_caso_especial_icms_normal_debito: isCasoEspecialIcmsNormalDebito,
            icms_fronteira: icmsFronteira,
            is_caso_especial_icms_fronteira: isCasoEspecialIcmsFronteira,
            icms_antecipacao: icmsAntecipacao,
            is_caso_especial_icms_antecipacao: isCasoEspecialIcmsAntecipacao,
            fcp,
            orig: origIcms,
            pauta_fiscal: pautaFiscalIcms,
            cst_icms_entrada: cstIcmsEntrada,
            cst_icms_saida: cstIcmsSaida,
            //
            descricao_lei_pis: descricaoLeiPis,
            descricao_lei_cofins: descricaoLeiCofins,
            descricao_lei_icms: descricaoLeiIcms,
            //
            pis_entrada_travado: pisEntradaTravado,
            pis_saida_travado: pisSaidaTravado,
            cofins_entrada_travado: cofinsEntradaTravado,
            cofins_saida_travado: cofinsSaidaTravado,
            icms_entrada_travado: icmsEntradaTravado,
            icms_saida_travado: icmsSaidaTravado,
            icms_antecipacao_travado: icmsAntecipacaoTravado,
            icms_fronteira_travado: icmsFronteiraTravado,
          });
          setCarregando(false);
          if (produtoAtualizado.data.id) {
            enqueueSnackbar('Produto atualizado com sucesso!', {
              variant: 'success',
            });
            idLocal = id;
          } else {
            enqueueSnackbar(produtoAtualizado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  //////////////////////////////////////

  function validatePisCredito() {
    let error = '';
    if (isCasoEspecialPisCredito) {
      if (pisCredito < 0 || Number.isNaN(pisCredito)) {
        error = 'Este campo é obrigatório';
      } else if (pisCredito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validatePisDebito() {
    let error = '';
    if (isCasoEspecialPisDebito) {
      if (pisDebito < 0 || Number.isNaN(pisDebito)) {
        error = 'Este campo é obrigatório';
      } else if (pisDebito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateCofinsCredito() {
    let error = '';
    if (isCasoEspecialCofinsCredito) {
      if (cofinsCredito < 0 || Number.isNaN(cofinsCredito)) {
        error = 'Este campo é obrigatório';
      } else if (cofinsCredito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateCofinsDebito() {
    let error = '';
    if (isCasoEspecialCofinsDebito) {
      if (cofinsDebito < 0 || Number.isNaN(cofinsDebito)) {
        error = 'Este campo é obrigatório';
      } else if (cofinsDebito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsNormalCredito() {
    let error = '';
    if (isCasoEspecialIcmsNormalCredito) {
      if (icmsNormalCredito < 0 || Number.isNaN(icmsNormalCredito)) {
        error = 'Este campo é obrigatório';
      } else if (icmsNormalCredito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsNormalDebito() {
    let error = '';
    if (isCasoEspecialIcmsNormalDebito) {
      if (icmsNormalDebito < 0 || Number.isNaN(icmsNormalDebito)) {
        error = 'Este campo é obrigatório';
      } else if (icmsNormalDebito > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsFronteira() {
    let error = '';
    if (isCasoEspecialIcmsFronteira) {
      if (icmsFronteira < 0 || Number.isNaN(icmsFronteira)) {
        error = 'Este campo é obrigatório';
      } else if (icmsFronteira > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateIcmsAntecipacao() {
    let error = '';
    if (isCasoEspecialIcmsAntecipacao) {
      if (icmsAntecipacao < 0 || Number.isNaN(icmsAntecipacao)) {
        error = 'Este campo é obrigatório';
      } else if (icmsAntecipacao > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
    }
    return error;
  }

  function validateRbc() {
    let error = '';
    if (rbc < 0 || Number.isNaN(rbc)) {
      error = 'Este campo é obrigatório';
    } else if (rbc > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateCstPisEntrada() {
    let error = '';
    if (cstPisEntrada) {
      /* if (cstPisEntrada.length < 3)
        error = 'O CST PIS ENTRADA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstPisSaida() {
    let error = '';
    if (cstPisSaida) {
      /* if (cstPisSaida.length < 3) error = 'O CST PIS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstCofinsEntrada() {
    let error = '';
    if (cstCofinsEntrada) {
      /* if (cstCofinsEntrada.length < 3)
        error = 'O CST COFINS ENTRADA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstCofinsSaida() {
    let error = '';
    if (cstCofinsSaida) {
      /* if (cstCofinsSaida.length < 3)
        error = 'O CST COFINS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateMva() {
    let error = '';
    if (mva < 0 || Number.isNaN(mva)) {
      error = 'Informe um valor válido!';
    }
    return error;
  }

  function validateOrigIcms() {
    let error = '';
    if (origIcms) {
      //if (origIcms.length < 1) error = 'Este campo deve ter 1 dígito';
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateFcp() {
    let error = '';
    if (fcp < 0 || Number.isNaN(fcp)) {
      error = 'Este campo é obrigatório';
    } else if (fcp > 5) {
      error = 'Não pode ultrapassar 5%!';
    }
    return error;
  }

  function validatePautaFiscal() {
    let error = '';
    if (pautaFiscalIcms < 0 || Number.isNaN(pautaFiscalIcms)) {
      error = 'Informe um valor válido!';
    }
    return error;
  }

  function validateCstIcmsEntrada() {
    let error = '';
    if (cstIcmsEntrada) {
      /* if (cstCofinsSaida.length < 3)
        error = 'O CST COFINS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateCstIcmsSaida() {
    let error = '';
    if (cstIcmsSaida) {
      /* if (cstCofinsSaida.length < 3)
        error = 'O CST COFINS SAÍDA deve ter 3 dígitos'; */
    } else {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateDescricaoLeiPis() {
    let error = '';
    if (descricaoLeiPis) {
      if (descricaoLeiPis.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function validateDescricaoLeiCofins() {
    let error = '';
    if (descricaoLeiCofins) {
      if (descricaoLeiCofins.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function validateDescricaoLeiIcms() {
    let error = '';
    if (descricaoLeiIcms) {
      if (descricaoLeiIcms.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 200)';
      }
    }
    return error;
  }

  function getErros() {
    const erros = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    erros[0] = validatePisCredito(); //
    erros[1] = validatePisDebito(); //
    erros[2] = validateCofinsCredito(); //
    erros[3] = validateCofinsDebito(); //
    erros[4] = validateIcmsNormalCredito(); //
    erros[5] = validateIcmsNormalDebito(); //
    erros[6] = validateIcmsFronteira(); //
    erros[7] = validateIcmsAntecipacao(); //

    erros[8] = validateRbc();

    erros[9] = validateCstPisEntrada(); //
    erros[10] = validateCstPisSaida(); //
    erros[11] = validateCstCofinsEntrada(); //
    erros[12] = validateCstCofinsSaida(); //
    //erros[20] = validateMva();
    //erros[21] = validateFcp();
    //erros[22] = validateOrigIcms();
    //erros[23] = validatePautaFiscal();
    erros[13] = validateCstIcmsEntrada(); //
    erros[14] = validateCstIcmsSaida(); //

    erros[15] = validateMva();
    erros[16] = validateFcp();
    erros[17] = validateOrigIcms();
    erros[18] = validatePautaFiscal();

    erros[19] = validateDescricaoLeiPis();
    erros[20] = validateDescricaoLeiCofins();
    erros[21] = validateDescricaoLeiIcms();

    return erros;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  //////////////////////////////////////

  function getDadosImpostos() {
    if (configs) {
      return {
        pisConfig: configs.pis,
        cofinsConfig: configs.cofins,
        icms_ai_compraConfig: configs.cidade.estado.icms_ai_compra,
        icms_antecipacaoConfig: configs.icms_antecipacao,
      };
    }
    return {
      pisConfig: 0,
      cofinsConfig: 0,
      icms_ai_compraConfig: 0,
      icms_antecipacaoConfig: 0,
    };
  }

  const {
    pisConfig,
    cofinsConfig,
    icms_ai_compraConfig,
    icms_antecipacaoConfig,
  } = getDadosImpostos();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        //fullWidth
        //maxWidth="sm"
        TransitionComponent={Transition}
        //keepMounted

        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {`Atualizar NCM "${ncm} (${descricao})"`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <Box
            display="flex"
            style={{
              marginTop: '10px',
            }}
          >
            <Box
              flex={2}
              mr="1em"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Box
                flex={1}
                style={{
                  padding: '10px',
                  border: '3px solid red',
                  borderRadius: '10px',
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    textDecoration: 'underline',
                  }}
                >
                  ICMS
                </Typography>

                <Box
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="orig-icms">
                      ORIG
                    </InputLabel>
                    <SelectInput
                      labelId="orig-icms"
                      name="turno"
                      handleEnter={() => {}}
                      label="ORIG"
                      handleKey={() => {}}
                      value={origIcms}
                      onChange={(value) => setOrigIcms(value.target.value)}
                      options={optionsOrigIcms}
                      error={erros[17] !== ''}
                      helperText={erros[17]}
                    />
                  </FormControl>

                  <ImpostoInput
                    handleEnter={() => {}}
                    label="ICMS de Fronteira"
                    handleKey={() => {}}
                    value={
                      Number.isNaN(
                        isCasoEspecialIcmsFronteira
                          ? icmsFronteira
                          : Number.NaN,
                      )
                        ? ''
                        : String(
                            isCasoEspecialIcmsFronteira
                              ? icmsFronteira
                              : Number.NaN,
                          )
                    }
                    onChange={(value) => {
                      if (isCasoEspecialIcmsFronteira) {
                        setIcmsFonteira(parseFloat(value.target.value));
                      }
                    }}
                    error={erros[6] !== ''}
                    helperText={erros[6]}
                    fullWidth
                    autoFocus
                    isCasoEspecial={isCasoEspecialIcmsFronteira}
                    onChangeIsCasoEspecial={(value) => {
                      setIsCasoEspecialIcmsFronteira(value);
                      if (isCasoEspecialIcmsFronteira) {
                        setIcmsFonteira(0);
                      }
                    }}
                    disabled={!isCasoEspecialIcmsFronteira}
                  />
                  <ImpostoInput
                    handleEnter={() => {}}
                    label="ICMS de Antecipação"
                    handleKey={() => {}}
                    value={
                      Number.isNaN(
                        isCasoEspecialIcmsAntecipacao
                          ? icmsAntecipacao
                          : icms_antecipacaoConfig,
                      )
                        ? ''
                        : String(
                            isCasoEspecialIcmsAntecipacao
                              ? icmsAntecipacao
                              : icms_antecipacaoConfig,
                          )
                    }
                    onChange={(value) => {
                      if (isCasoEspecialIcmsAntecipacao) {
                        setIcmsAntecipacao(parseFloat(value.target.value));
                      }
                    }}
                    error={erros[7] !== ''}
                    helperText={erros[7]}
                    fullWidth
                    autoFocus
                    isCasoEspecial={isCasoEspecialIcmsAntecipacao}
                    onChangeIsCasoEspecial={(value) => {
                      setIsCasoEspecialIcmsAntecipacao(value);
                      if (isCasoEspecialIcmsAntecipacao) {
                        setIcmsAntecipacao(icms_antecipacaoConfig);
                      }
                    }}
                    disabled={!isCasoEspecialIcmsAntecipacao}
                  />
                </Box>

                <Box
                  style={{
                    padding: '10px',
                    border: '3px solid blue',
                    borderRadius: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      //gutterBottom
                      style={{
                        color: 'blue',
                        fontWeight: 'bold',
                        //textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      ENTRADA
                    </Typography>
                    <IndicadorTravado
                      value={icmsEntradaTravado}
                      onChange={(value) => {
                        if (value) {
                          setIcmsEntradaTravado(true);
                        } else {
                          setIcmsEntradaTravado(false);
                        }
                      }}
                    />
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="cst-icms-entrada">
                      CST
                    </InputLabel>
                    <SelectInput
                      labelId="cst-icms-entrada"
                      name="turno"
                      handleEnter={() => {}}
                      label="CST"
                      handleKey={() => {}}
                      value={cstIcmsEntrada}
                      onChange={(value) =>
                        setCstIcmsEntrada(value.target.value)
                      }
                      options={
                        cstIcmsEntrada === '60'
                          ? optionsCstIcms2
                          : optionsCstIcms
                      }
                      error={erros[13] !== ''}
                      helperText={erros[13]}
                    />
                  </FormControl>
                  <PorcentagemInput
                    label="Aliquota"
                    value={
                      Number.isNaN(
                        isCasoEspecialIcmsNormalCredito
                          ? cstIcmsEntrada === '20'
                            ? icmsNormalCredito
                            : 0
                          : icms_ai_compraConfig,
                      )
                        ? ''
                        : String(
                            isCasoEspecialIcmsNormalCredito
                              ? cstIcmsEntrada === '20'
                                ? icmsNormalCredito
                                : 0
                              : icms_ai_compraConfig,
                          )
                    }
                    onChange={(value) => {
                      if (cstIcmsEntrada === '20') {
                        setIcmsNormalCredito(parseFloat(value.target.value));
                      }
                    }}
                    escala={2}
                    handleEnter={() => {}}
                    handleKey={() => {}}
                    fullWidth
                    //disabled={!isCasoEspecialIcmsNormalCredito}
                    error={erros[4] !== ''}
                    helperText={erros[4]}
                    disabled={cstIcmsEntrada !== '20'}
                  />
                </Box>
                <Box
                  style={{
                    padding: '10px',
                    border: '3px solid blue',
                    borderRadius: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      //gutterBottom
                      style={{
                        color: 'blue',
                        fontWeight: 'bold',
                        //textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      SAÍDA
                    </Typography>
                    <IndicadorTravado
                      value={icmsSaidaTravado}
                      onChange={(value) => {
                        if (value) {
                          setIcmsSaidaTravado(true);
                        } else {
                          setIcmsSaidaTravado(false);
                        }
                      }}
                    />
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="cst-icms-saida">
                      CST
                    </InputLabel>
                    <SelectInput
                      labelId="cst-icms-saida"
                      name="turno"
                      handleEnter={() => {}}
                      label="CST"
                      handleKey={() => {}}
                      value={cstIcmsSaida}
                      onChange={(value) => setCstIcmsSaida(value.target.value)}
                      options={
                        cstIcmsSaida === '60' ? optionsCstIcms2 : optionsCstIcms
                      }
                      error={erros[14] !== ''}
                      helperText={erros[14]}
                    />
                  </FormControl>
                  <PorcentagemInput
                    label="Aliquota"
                    value={
                      Number.isNaN(
                        isCasoEspecialIcmsNormalDebito
                          ? cstIcmsSaida === '20'
                            ? icmsNormalDebito
                            : 0
                          : icms_ai_compraConfig,
                      )
                        ? ''
                        : String(
                            isCasoEspecialIcmsNormalDebito
                              ? cstIcmsSaida === '20'
                                ? icmsNormalDebito
                                : 0
                              : icms_ai_compraConfig,
                          )
                    }
                    onChange={(value) => {
                      if (cstIcmsSaida === '20') {
                        setIcmsNormalDebito(parseFloat(value.target.value));
                      }
                    }}
                    escala={2}
                    handleEnter={() => {}}
                    handleKey={() => {}}
                    error={erros[5] !== ''}
                    helperText={erros[5]}
                    fullWidth
                    disabled={cstIcmsSaida !== '20'}
                  />

                  {cstIcmsSaida === '20' ? (
                    <PorcentagemInput
                      label="RBC"
                      value={Number.isNaN(rbc) ? '' : String(rbc)}
                      onChange={(value) =>
                        setRbc(parseFloat(value.target.value))
                      }
                      escala={2}
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      error={erros[8] !== ''}
                      helperText={erros[8]}
                      fullWidth
                    />
                  ) : null}
                  {cstIcmsSaida === '60' ? (
                    <PorcentagemInput
                      label="MVA"
                      value={Number.isNaN(mva) ? '' : String(mva)}
                      onChange={(value) =>
                        setMva(parseFloat(value.target.value))
                      }
                      escala={2}
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      error={erros[15] !== ''}
                      helperText={erros[15]}
                      fullWidth
                    />
                  ) : null}
                  {cstIcmsSaida === '60' ? (
                    <PorcentagemInput
                      label="FCP"
                      value={Number.isNaN(fcp) ? '' : String(fcp)}
                      onChange={(value) =>
                        setFcp(parseFloat(value.target.value))
                      }
                      escala={2}
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      error={erros[16] !== ''}
                      helperText={erros[16]}
                      fullWidth
                    />
                  ) : null}
                  {cstIcmsSaida === '60' ? (
                    <PrecoInput
                      handleEnter={() => {}}
                      label="Pauta Fiscal"
                      handleKey={() => {}}
                      value={
                        Number.isNaN(pautaFiscalIcms)
                          ? ''
                          : String(pautaFiscalIcms)
                      }
                      onChange={(value) =>
                        setPautaFiscalIcms(parseFloat(value.target.value))
                      }
                      error={erros[18] !== ''}
                      helperText={erros[18]}
                      fullWidth
                    />
                  ) : null}

                  <TextInput
                    //name={inputsGeral[0].nome}
                    //ref={refsGeral.current[NOME_POSITION]}
                    handleEnter={() => {}}
                    label="Texto Lei"
                    handleKey={() => {}}
                    value={descricaoLeiIcms}
                    onChange={(value) =>
                      setDescricaoLeiIcms(value.target.value)
                    }
                    error={erros[21] !== ''}
                    helperText={erros[21]}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Box>
              </Box>

              <Box
                flex={1}
                style={{
                  padding: '10px',
                  border: '3px solid red',
                  borderRadius: '10px',
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    textDecoration: 'underline',
                  }}
                >
                  PIS
                </Typography>

                <Box
                  style={{
                    padding: '10px',
                    border: '3px solid blue',
                    borderRadius: '10px',
                    marginBottom: '10px',
                  }}
                  //gutterBottom
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      //gutterBottom
                      style={{
                        color: 'blue',
                        fontWeight: 'bold',
                        //textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      ENTRADA
                    </Typography>
                    <IndicadorTravado
                      value={pisEntradaTravado}
                      onChange={(value) => {
                        if (value) {
                          setPisEntradaTravado(true);
                        } else {
                          setPisEntradaTravado(false);
                        }
                      }}
                    />
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="cst-pis-entrada">
                      CST
                    </InputLabel>
                    <SelectInput
                      labelId="cst-pis-entrada"
                      name="turno"
                      handleEnter={() => {}}
                      label="CST"
                      handleKey={() => {}}
                      value={cstPisEntrada}
                      onChange={(value) => setCstPisEntrada(value.target.value)}
                      options={optionsCstPis}
                      error={erros[9] !== ''}
                      helperText={erros[9]}
                    />
                  </FormControl>
                  <PorcentagemInput
                    label="Aliquota"
                    value={
                      Number.isNaN(
                        isCasoEspecialPisCredito
                          ? cstPisEntrada === '02'
                            ? pisCredito
                            : 0
                          : pisConfig,
                      )
                        ? ''
                        : String(
                            isCasoEspecialPisCredito
                              ? cstPisEntrada === '02'
                                ? pisCredito
                                : 0
                              : pisConfig,
                          )
                    }
                    onChange={(value) => {
                      if (cstPisEntrada === '02') {
                        setPisCredito(parseFloat(value.target.value));
                      }
                    }}
                    escala={2}
                    handleEnter={() => {}}
                    handleKey={() => {}}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    fullWidth
                    disabled={cstPisEntrada !== '02'}
                  />
                </Box>
                <Box
                  style={{
                    padding: '10px',
                    border: '3px solid blue',
                    borderRadius: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      //gutterBottom
                      style={{
                        color: 'blue',
                        fontWeight: 'bold',
                        //textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      SAÍDA
                    </Typography>
                    <IndicadorTravado
                      value={pisSaidaTravado}
                      onChange={(value) => {
                        if (value) {
                          setPisSaidaTravado(true);
                        } else {
                          setPisSaidaTravado(false);
                        }
                      }}
                    />
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="cst-pis-saida">
                      CST
                    </InputLabel>
                    <SelectInput
                      labelId="cst-pis-saida"
                      name="turno"
                      handleEnter={() => {}}
                      label="CST"
                      handleKey={() => {}}
                      value={cstPisSaida}
                      onChange={(value) => setCstPisSaida(value.target.value)}
                      options={optionsCstPis}
                      error={erros[10] !== ''}
                      helperText={erros[10]}
                    />
                  </FormControl>
                  <PorcentagemInput
                    label="Aliquota"
                    value={
                      Number.isNaN(
                        isCasoEspecialPisDebito
                          ? cstPisSaida === '02'
                            ? pisDebito
                            : 0
                          : pisConfig,
                      )
                        ? ''
                        : String(
                            isCasoEspecialPisDebito
                              ? cstPisSaida === '02'
                                ? pisDebito
                                : 0
                              : pisConfig,
                          )
                    }
                    onChange={(value) => {
                      if (cstPisSaida === '02') {
                        setPisDebito(parseFloat(value.target.value));
                      }
                    }}
                    escala={2}
                    handleEnter={() => {}}
                    handleKey={() => {}}
                    error={erros[1] !== ''}
                    helperText={erros[1]}
                    fullWidth
                    disabled={cstPisSaida !== '02'}
                  />
                  <TextInput
                    //name={inputsGeral[0].nome}
                    //ref={refsGeral.current[NOME_POSITION]}
                    handleEnter={() => {}}
                    label="Texto Lei"
                    handleKey={() => {}}
                    value={descricaoLeiPis}
                    onChange={(value) => setDescricaoLeiPis(value.target.value)}
                    error={erros[19] !== ''}
                    helperText={erros[19]}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Box>
              </Box>

              <Box
                flex={1}
                style={{
                  padding: '10px',
                  border: '3px solid red',
                  borderRadius: '10px',
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    textDecoration: 'underline',
                  }}
                >
                  COFINS
                </Typography>

                <Box
                  style={{
                    padding: '10px',
                    border: '3px solid blue',
                    borderRadius: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      //gutterBottom
                      style={{
                        color: 'blue',
                        fontWeight: 'bold',
                        //textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      ENTRADA
                    </Typography>
                    <IndicadorTravado
                      value={cofinsEntradaTravado}
                      onChange={(value) => {
                        if (value) {
                          setCofinsEntradaTravado(true);
                        } else {
                          setCofinsEntradaTravado(false);
                        }
                      }}
                    />
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="cst-cofins-entrada">
                      CST
                    </InputLabel>
                    <SelectInput
                      labelId="cst-cofins-entrada"
                      name="turno"
                      handleEnter={() => {}}
                      label="CST"
                      handleKey={() => {}}
                      value={cstCofinsEntrada}
                      onChange={(value) =>
                        setCstCofinsEntrada(value.target.value)
                      }
                      options={optionsCstCofins}
                      error={erros[11] !== ''}
                      helperText={erros[11]}
                    />
                  </FormControl>
                  <PorcentagemInput
                    label="Aliquota"
                    value={
                      Number.isNaN(
                        isCasoEspecialCofinsCredito
                          ? cstCofinsEntrada === '02'
                            ? cofinsCredito
                            : 0
                          : cofinsConfig,
                      )
                        ? ''
                        : String(
                            isCasoEspecialCofinsCredito
                              ? cstCofinsEntrada === '02'
                                ? cofinsCredito
                                : 0
                              : cofinsConfig,
                          )
                    }
                    onChange={(value) => {
                      if (cstCofinsEntrada === '02') {
                        setCofinsCredito(parseFloat(value.target.value));
                      }
                    }}
                    escala={2}
                    handleEnter={() => {}}
                    handleKey={() => {}}
                    error={erros[2] !== ''}
                    helperText={erros[2]}
                    fullWidth
                    disabled={cstCofinsEntrada !== '02'}
                  />
                </Box>

                <Box
                  style={{
                    padding: '10px',
                    border: '3px solid blue',
                    borderRadius: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      //gutterBottom
                      style={{
                        color: 'blue',
                        fontWeight: 'bold',
                        //textAlign: 'center',
                        textDecoration: 'underline',
                      }}
                    >
                      SAÍDA
                    </Typography>
                    <IndicadorTravado
                      value={cofinsSaidaTravado}
                      onChange={(value) => {
                        if (value) {
                          setCofinsSaidaTravado(true);
                        } else {
                          setCofinsSaidaTravado(false);
                        }
                      }}
                    />
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{
                      marginTop: '16px',
                    }}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel shrink id="cst-cofins-saida">
                      CST
                    </InputLabel>
                    <SelectInput
                      labelId="cst-cofins-saida"
                      name="turno"
                      handleEnter={() => {}}
                      label="CST"
                      handleKey={() => {}}
                      value={cstCofinsSaida}
                      onChange={(value) =>
                        setCstCofinsSaida(value.target.value)
                      }
                      options={optionsCstCofins}
                      error={erros[12] !== ''}
                      helperText={erros[12]}
                    />
                  </FormControl>
                  <PorcentagemInput
                    label="Aliquota"
                    value={
                      Number.isNaN(
                        isCasoEspecialCofinsDebito
                          ? cstCofinsSaida === '02'
                            ? cofinsDebito
                            : 0
                          : cofinsConfig,
                      )
                        ? ''
                        : String(
                            isCasoEspecialCofinsDebito
                              ? cstCofinsSaida === '02'
                                ? cofinsDebito
                                : 0
                              : cofinsConfig,
                          )
                    }
                    onChange={(value) => {
                      if (cstCofinsSaida === '02') {
                        setCofinsDebito(parseFloat(value.target.value));
                      }
                    }}
                    escala={2}
                    handleEnter={() => {}}
                    handleKey={() => {}}
                    error={erros[3] !== ''}
                    helperText={erros[3]}
                    fullWidth
                    disabled={cstCofinsSaida !== '02'}
                  />
                  <TextInput
                    //name={inputsGeral[0].nome}
                    //ref={refsGeral.current[NOME_POSITION]}
                    handleEnter={() => {}}
                    label="Texto Lei"
                    handleKey={() => {}}
                    value={descricaoLeiCofins}
                    onChange={(value) =>
                      setDescricaoLeiCofins(value.target.value)
                    }
                    error={erros[20] !== ''}
                    helperText={erros[20]}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={() => handleCloseDialog(-1)} color="primary">
            Cancelar (F4)
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              onClick={() => {
                handleSalvar();
              }}
              disabled={erroExistente || carregando}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
            >
              Cadastrar (F8)
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
