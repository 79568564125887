import {
  formatCPF,
  formatCNPJ,
  PIX_ALEATORIA_OPTION,
  PIX_CNPJ_OPTION,
  PIX_CPF_OPTION,
  PIX_EMAIL_OPTION,
  PIX_TELEFONE_OPTION,
  formatTelefone,
  TIPO_CONTATO_TELEFONE,
} from '../../../utils';

function getValorFormatado(valor, type) {
  switch (type) {
    case TIPO_CONTATO_TELEFONE:
      return formatTelefone(valor);
    default:
      return valor;
  }
}

function getLabelTipo(type) {
  switch (type) {
    case TIPO_CONTATO_TELEFONE:
      return 'Telefone';
    default:
      return '';
  }
}

export { getValorFormatado, getLabelTipo };
