import React from 'react';
import {
  Filter as FilterAdmin,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
} from 'react-admin';

import { PeriodoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils';
import choices from './choices';

function getStyle(value) {
  switch (value) {
    case 'resolver-agora':
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        width: '100%',
        heigth: '100%',
        margin: 0,
        padding: 0,
      };
    case 'resolver-urgente':
      return {
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        color: 'white',
        width: '100%',
        heigth: '100%',
      };
    case 'resolver-logo':
      return {
        backgroundColor: 'rgba(255, 77, 0, 0.5)',
        width: '100%',
        heigth: '100%',
      };
    case 'resolver':
      return {
        backgroundColor: 'rgba(242, 255, 0, 0.5)',
        width: '100%',
        heigth: '100%',
      };
    case 'atencao':
      return {
        backgroundColor: 'rgba(173, 255, 0, 0.5)',
        width: '100%',
        heigth: '100%',
      };
    case 'nao-e-data-critica':
      return { backgroundColor: 'white', width: '100%', heigth: '100%' };
    default:
      return {};
  }
}

const FullNameField = (record) => (
  <span style={getStyle(record ? (record.record ? record.record.id : '') : '')}>
    {record ? (record.record ? record.record.name : '') : ''}
  </span>
);

const Filter = (props) => (
  <FilterAdmin {...props}>
    <ReferenceInput
      fullWidth
      label="Produto"
      source="produto_id"
      reference="produtos_search_filter"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
    <SelectInput
      source="bandeira"
      choices={choices}
      label="Bandeira"
      allowEmpty={false}
      optionText={<FullNameField />}
    />
    <ReferenceInput
      fullWidth
      label="Criador"
      source="criador_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Verificador"
      source="verificador_id"
      reference="users"
      sort={{ field: 'username', order: 'ASC' }}
      filterToQuery={(searchText) => ({ username: `${searchText}` })}
      perPage={10}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <PeriodoInput
      source="periodo_ultima_verificacao"
      label="Período última Verificação"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
  </FilterAdmin>
);

export default Filter;
