import moment from 'moment';

function formatDateDDMMYYYY(date, separator = '/') {
  return date
    ? moment(new Date(date)).format(`DD${separator}MM${separator}YYYY`)
    : '-';
}

function formatDateDDMMYYYYHHmm(
  date,
  separator1 = '/',
  separator2 = '-',
  separator3 = ':',
) {
  return date
    ? moment(new Date(date)).format(
        `DD${separator1}MM${separator1}YYYY ${separator2} HH${separator3}mm`,
      )
    : '-';
}

function formatDateYYYYMMDD(date, separator = '-') {
  return date
    ? moment(new Date(date)).format(`YYYY${separator}MM${separator}DD`)
    : '-';
}

function formatDateHHmm(date, separator = ':') {
  return date ? moment(new Date(date)).format(`HH${separator}mm`) : '-';
}

function adicionaDiasEmData(data, dias) {
  return new Date(moment(new Date(data)).add(dias, 'days').format());
}

function removeDiasEmData(data, dias) {
  return new Date(moment(new Date(data)).subtract(dias, 'days').format());
}

function adicionaMesesEmData(data, meses) {
  return new Date(moment(new Date(data)).add(meses, 'months').format());
}

function removeMesesEmData(data, meses) {
  return new Date(moment(new Date(data)).subtract(meses, 'months').format());
}

export {
  formatDateDDMMYYYY,
  formatDateHHmm,
  formatDateDDMMYYYYHHmm,
  formatDateYYYYMMDD,
  adicionaDiasEmData,
  removeDiasEmData,
  adicionaMesesEmData,
  removeMesesEmData,
};
