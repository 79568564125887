import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import { formatMoeda } from '../../../utils';

const ValorField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const valor = record ? record.record.is_pedido_minimo : false;
  return (
    <>
      {valor ? (
        <Typography
          variant="subtitle1"
          style={{ color: 'green', fontWeight: 'bold' }}
        >
          V
        </Typography>
      ) : (
        <Typography
          variant="subtitle1"
          style={{ color: 'red', fontWeight: 'bold' }}
        >
          X
        </Typography>
      )}
    </>
  );
};

export default ValorField;
