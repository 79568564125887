import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';

import { trunc10 } from './arredondamentos';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  A_VISTA,
  POR_PESO_FIXO,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  POR_PESO_VARIAVEL,
  TIPO_IMPRESSAO_UN,
} from './constants';
import { formatMoeda, formatPeso } from './formats';
import {
  getLabelNivelCaixaria,
  getTotal2,
  getPesoCaixa,
  getUnidadesCaixa,
} from './precoVenda';

export function disablePeso(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) return false;
    return true;
  }
  return false;
}

export function disableUnidades(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return false;
    return true;
  }
  return false;
}

export function toStringItem(rows) {
  const rowsFormated = [];
  for (let i = 0; i < rows.length; i += 1) {
    const unidades = rows[i].unidadesCaixaAparente
      ? rows[i].unidadesCaixaAparente
      : rows[i].unidadesCaixa
      ? rows[i].unidades / rows[i].unidadesCaixa
      : rows[i].unidades;
    const unitario = rows[i].unidadesCaixaAparente
      ? rows[i].unitarioCaixa
      : rows[i].unidadesCaixa
      ? rows[i].unitarioCaixa
      : rows[i].unitario;

    const sufixoUnidade = rows[i].unidadesCaixaAparente
      ? rows[i].pesoCaixa > 0
        ? `${getLabelNivelCaixaria(rows[i].nivelCaixaria)}(${formatPeso(
            rows[i].pesoCaixa,
          )} kg)`
        : `${getLabelNivelCaixaria(rows[i].nivelCaixaria)}(${
            rows[i].unidadesCaixa
          } un)`
      : rows[i].unidadesCaixa
      ? `cx(${rows[i].unidadesCaixa} un)`
      : '';

    rowsFormated.unshift({
      produto: `${rows[i].produto.nome}${
        sufixoUnidade !== '' ? ` - ${sufixoUnidade}` : ''
      }`,
      unidades: unidades || '-',
      peso: disablePeso(rows[i].produto) ? formatPeso(rows[i].peso) : '-',
      unitario: `${formatMoeda(unitario)}`,
      total: formatMoeda(rows[i].total),
      isIntegro: rows[i].isIntegro
        ? 'Item em Perfeito Estado'
        : 'Item Avariado',
      uidd: rows[i].uidd,
      numeroItem: i + 1,
    });
  }
  return rowsFormated;
}

export function toStringParcela(rows) {
  const rowsFormated = [];
  for (let i = 0; i < rows.length; i += 1) {
    rowsFormated.unshift({
      uidd: rows[i].uidd,
      valor: formatMoeda(rows[i].valor),
      tipo_pagamento: rows[i].tipo_pagamento.nome,
      data_pagamento: moment(rows[i].data_pagamento).format('DD/MM/YYYY'),
      valor_recebido:
        rows[i].tipo_pagamento.dinheiro_vivo &&
        rows[i].tipo_pagamento.modalidade === A_VISTA
          ? formatMoeda(rows[i].valor_recebido)
          : '-',
      troco:
        rows[i].tipo_pagamento.dinheiro_vivo &&
        rows[i].tipo_pagamento.modalidade === A_VISTA
          ? formatMoeda(rows[i].troco)
          : '-',
      numeroItem: i + 1,
    });
  }
  return rowsFormated;
}

export function getTotal(peso, unidades, precoUnitario, produto) {
  if (produto.unidade.tipo === POR_UNIDADE) {
    return trunc10(unidades * precoUnitario, -2);
  }
  return trunc10(peso * precoUnitario, -2);
}

export function getSubTotal(itens) {
  let soma = 0;
  for (let i = 0; i < itens.length; i += 1) {
    soma += itens[i].total;
  }
  return soma;
}

export function searchItemInArray(produto, precoUnitario, itens) {
  for (let i = 0; i < itens.length; i += 1) {
    if (
      produto.id === itens[i].produto.id &&
      precoUnitario === itens[i].unitario
    )
      return i;
  }
  return -1;
}

export function renameParcelasUIDD(parcelas) {
  const arrayNew = parcelas.slice();
  for (let i = 0; i < parcelas.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].tipo_pagamento.nome}${i}`;
  }
  return arrayNew;
}

export function renameItensUIDD(itens) {
  const arrayNew = itens.slice();
  for (let i = 0; i < itens.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].produto.nome}${i}`;
  }
  return arrayNew;
}

//////////////////////////////////////////////

export function searchItemInArray3(produto, itens) {
  let unidadesUsadas = 0;
  let pesoUsado = 0;
  for (let i = 0; i < itens.length; i += 1) {
    if (produto.id === itens[i].produto.id && itens[i].idIndicadorChange < 0) {
      unidadesUsadas += itens[i].unidades;
      pesoUsado += itens[i].peso;
    }
  }

  if (produto.unidade.tipo === POR_UNIDADE) {
    pesoUsado = 0;
  } else if (produto.unidade.tipo === POR_PESO_FIXO) {
    pesoUsado = unidadesUsadas * produto.peso_medio;
  }

  return {
    unidadesUsadas,
    pesoUsado,
  };
}

function formatPrecoCompra(valor) {
  return valor !== null && valor !== undefined
    ? valor.toLocaleString('de-DE', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      })
    : '';
}

export function getValueCaixaria(tipo) {
  switch (tipo) {
    case TIPO_UNIDADE_OFERTA_KG:
      return 0;
    case TIPO_UNIDADE_OFERTA_UN:
      return 0;
    case TIPO_UNIDADE_OFERTA_CAIXA:
      return 1;
    case TIPO_UNIDADE_OFERTA_CAIXETA:
      return 2;
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
      return 3;
    default:
      return '';
  }
}

export function getLabelQuantidade(tipo, quantidade) {
  switch (tipo) {
    case TIPO_UNIDADE_OFERTA_KG:
      return formatPeso(quantidade);
    case TIPO_UNIDADE_OFERTA_UN:
      return `${quantidade} un`;
    case TIPO_UNIDADE_OFERTA_CAIXA:
      return `${quantidade} cx1`;
    case TIPO_UNIDADE_OFERTA_CAIXETA:
      return `${quantidade} cx2`;
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
      return `${quantidade} cx3`;
    default:
      return '';
  }
}

function getQuantidades(produto, qtde, tipoUnidadeOferta, precoUnitario) {
  switch (produto.unidade.tipo) {
    case POR_UNIDADE: {
      switch (tipoUnidadeOferta) {
        case TIPO_UNIDADE_OFERTA_UN: {
          return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return {
            unidades: qtde,
            peso: 0,
            precoCaixa: precoUnitario * produto.unidades_caixa_nivel_1,
          };
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return {
            unidades: qtde,
            peso: 0,
            precoCaixa:
              precoUnitario *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2,
          };
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return {
            unidades: qtde,
            peso: 0,
            precoCaixa:
              precoUnitario *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
          };
        }
        default: {
          return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
        }
      }
    }
    case POR_PESO_VARIAVEL_SEM_UNIDADE: {
      switch (tipoUnidadeOferta) {
        case TIPO_UNIDADE_OFERTA_KG: {
          return { unidades: 0, peso: qtde, precoCaixa: precoUnitario };
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return {
            unidades: qtde,
            peso: qtde * produto.peso_caixa_nivel_1,
            precoCaixa: precoUnitario * produto.peso_caixa_nivel_1,
          };
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return {
            unidades: qtde,
            peso:
              qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2,
            precoCaixa:
              precoUnitario *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2,
          };
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return {
            unidades: qtde,
            peso:
              qtde *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            precoCaixa:
              precoUnitario *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
          };
        }
        default: {
          return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
        }
      }
    }
    case POR_PESO_VARIAVEL: {
      return { unidades: 0, peso: 0, precoCaixa: 0 };
    }
    case POR_PESO_FIXO: {
      switch (tipoUnidadeOferta) {
        case TIPO_UNIDADE_OFERTA_UN: {
          return {
            unidades: qtde,
            peso: qtde * produto.peso_medio,
            precoCaixa: precoUnitario,
          };
        }
        case TIPO_UNIDADE_OFERTA_CAIXA: {
          return {
            unidades: qtde,
            peso: qtde * produto.unidades_caixa_nivel_1 * produto.peso_medio,
            precoCaixa:
              precoUnitario *
              produto.unidades_caixa_nivel_1 *
              produto.peso_medio,
          };
        }
        case TIPO_UNIDADE_OFERTA_CAIXETA: {
          return {
            unidades: qtde,
            peso:
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.peso_medio,
            precoCaixa:
              precoUnitario *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.peso_medio,
          };
        }
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
          return {
            unidades: qtde,
            peso:
              qtde *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              produto.peso_medio,
            precoCaixa:
              precoUnitario *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              produto.peso_medio,
          };
        }
        default: {
          return { unidades: qtde, peso: 0, precoCaixa: precoUnitario };
        }
      }
    }
    default:
      return { unidades: 0, peso: 0, precoCaixa: 0 };
  }
}

export function toStringPedidos(rows) {
  const rowsFormated = [];
  for (let i = 0; i < rows.length; i += 1) {
    //////////////////////////////
    const produto = {
      ...rows[i].produto,
      nivelCaixa: getValueCaixaria(rows[i].tipo_unidade),
    };
    const { peso, unidades, precoCaixa } = getQuantidades(
      produto,
      rows[i].quantidade,
      rows[i].tipo_unidade,
      rows[i].valor,
    );

    const { precoCaixa: precoCaixaAtual } = getQuantidades(
      produto,
      rows[i].quantidade,
      rows[i].tipo_unidade,
      produto.preco_venda,
    );

    const { precoCaixa: precoCaixaAprovado } = getQuantidades(
      produto,
      rows[i].quantidade,
      rows[i].tipo_unidade,
      rows[i].valor +
        rows[i].verba_unitaria -
        rows[i].verba_unitaria_solicitado,
    );

    const labelQuantidade = getLabelQuantidade(
      rows[i].tipo_unidade,
      rows[i].quantidade,
    );

    let fator = 1;

    if (produto.unidade.tipo === POR_PESO_FIXO && !produto.nivelCaixa)
      fator = produto.peso_medio;

    const pesoCalculado = getPesoCaixa(produto);

    const unidadesCaixaAparente = produto.nivelCaixa ? unidades : 0;

    const pesoCaixa = pesoCalculado;

    const unidadesCaixa = getUnidadesCaixa(produto);

    const sufixoUnidade = unidadesCaixaAparente
      ? pesoCaixa > 0
        ? `${getLabelNivelCaixaria(produto.nivelCaixaria)}(${formatPeso(
            pesoCaixa,
          )})`
        : `${getLabelNivelCaixaria(produto.nivelCaixaria)}(${unidadesCaixa} un)`
      : unidadesCaixa
      ? `cx(${unidadesCaixa} un)`
      : '';

    const totalSemDesconto = precoCaixaAtual * fator * rows[i].quantidade;
    //const totalComDesconto = precoCaixa * rows[i].quantidade;
    const totalComDesconto = precoCaixaAprovado * fator * rows[i].quantidade;
    //const totalComDesconto = precoCaixa * rows[i].quantidade;
    const totalComDescontoSolicitado = precoCaixa * fator * rows[i].quantidade;

    const desconto_total = totalSemDesconto - totalComDesconto;
    const desconto_total_solicitado =
      totalSemDesconto - totalComDescontoSolicitado;

    //////////////////////////////
    rowsFormated.unshift({
      uidd: rows[i].uidd,
      numeroItem: i + 1,
      idIndicadorChange: rows[i].idIndicadorChange,
      produto: `${rows[i].produto.nome}${
        sufixoUnidade !== '' ? ` - ${sufixoUnidade}` : ''
      }`,
      preco_solicitado: formatMoeda(precoCaixa),
      preco_atual: formatMoeda(precoCaixaAtual),
      preco_aprovado: formatMoeda(precoCaixaAprovado),
      quantidade: labelQuantidade,
      desconto_total: formatMoeda(desconto_total),
      desconto_total_solicitado: formatMoeda(desconto_total_solicitado),
      totalSemDesconto: formatMoeda(totalSemDesconto),
      totalComDesconto: formatMoeda(totalComDesconto),
      totalComDescontoSolicitado: formatMoeda(totalComDescontoSolicitado),
      dadosOriginais: rows[i],
      status_aprovado: rows[i].status_aprovado,
      status_aprovado_volta: rows[i].status_aprovado_volta,
      isModificado: rows[i].isModificado,
      is_fim_negociacao: rows[i].is_fim_negociacao,
    });
  }
  return rowsFormated;
}

function getUnidade(produto) {
  if (produto.unidade.tipo === POR_UNIDADE) return 'Un';
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 'Kg';
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) return 'Un';
    return 'Kg';
  }
  return '';
}

function getFator(produto) {
  if (produto.unidade.tipo === POR_UNIDADE) return 1;
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 1;
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) return produto.peso_medio;
    return 1;
  }
  return '';
}

export function toStringLotes(rows) {
  const rowsFormated = [];
  for (let i = 0; i < rows.length; i += 1) {
    const fator = getFator(rows[i].produto);
    const label_unidade = getUnidade(rows[i].produto);
    rowsFormated.unshift({
      produto: rows[i].produto.nome,
      unidades: disableUnidades(rows[i].produto) ? rows[i].unidades : '-',
      peso: disablePeso(rows[i].produto) ? formatPeso(rows[i].peso) : '-',
      unitario: `${formatPrecoCompra(
        rows[i].unitario * fator,
      )} / ${label_unidade}`,
      unitarioImposto: `${formatPrecoCompra(
        rows[i].unitarioImposto * fator,
      )} / ${label_unidade}`,
      total: formatMoeda(rows[i].total),
      uidd: rows[i].uidd,
      validade: rows[i].validade
        ? moment(rows[i].validade).format('DD/MM/YYYY')
        : '-',
      cg: formatPrecoCompra(rows[i].cg),
      numeroLote: i + 1,
      dadosPrecificacao: rows[i].dadosPrecificacao,
      dadosOriginais: rows[i],
      precoVenda: `${formatMoeda(
        rows[i].dadosPrecificacao.preco_de_venda * fator,
      )} / ${label_unidade}`,
      idIndicadorChange: rows[i].idIndicadorChange,
      nImpressoes: rows[i].nImpressoes,
    });
  }
  return rowsFormated;
}

function getPrazo(prazos, index) {
  let prazo = -1;
  if (prazos.length > 0) {
    if (prazos.length >= index + 1) {
      prazo = prazos[index];
    }
  }
  return prazo;
}

function adicionaCampoDePrazos(rows, fornecedor, dataBase) {
  let prazos = [];

  if (fornecedor) {
    if (fornecedor.prazosFornecedores) {
      if (fornecedor.prazosFornecedores.length > 0) {
        prazos = fornecedor.prazosFornecedores
          .sort((a, b) => a.dias - b.dias)
          .map((item) => item.dias);
      }
    }
  }

  return rows.map((item, index) => {
    let dataProvavel = '-';
    let diferencaDias = '-';
    const prazo = getPrazo(prazos, index);
    if (prazo > 0) {
      dataProvavel = calculaDataProvavel(prazo, dataBase);
      diferencaDias = calculaDiferencaEmDias(
        item.data_pagamento_date,
        dataProvavel,
      );
      dataProvavel = moment(dataProvavel).format('DD/MM/YYYY');
    }
    return {
      ...item,
      data_provavel: dataProvavel,
      diferenca_dias: diferencaDias,
    };
  });
}

function calculaDataProvavel(prazo, dataBase) {
  return new Date(moment(dataBase).add(prazo, 'days').format());
}

function calculaDiferencaEmDias(dataPagamento, dataProvavel) {
  const dataPagamentoFormatada = new Date(
    moment(dataPagamento).endOf('day').format(),
  );

  const dataProvavelFormatada = new Date(
    moment(dataProvavel).endOf('day').format(),
  );

  const delta =
    dataPagamentoFormatada.getTime() - dataProvavelFormatada.getTime();

  return parseInt(delta / (24 * 60 * 60 * 1000), 10);
}

export function toStringParcelaCompra(rows, fornecedor, dataBase) {
  let rowsFormated = [];
  for (let i = 0; i < rows.length; i += 1) {
    rowsFormated.unshift({
      uidd: rows[i].uidd,
      valor: formatMoeda(rows[i].valor),
      codigo_boleto: rows[i].codigo_boleto ? rows[i].codigo_boleto : '',
      conta: rows[i].isCartao ? rows[i].cartao.nome : rows[i].conta.nome,
      data_pagamento: moment(rows[i].data_pagamento).format('DD/MM/YYYY'),
      status: rows[i].isCartao ? '-' : rows[i].status ? 'Pago' : 'Não Pago',
      fatura: rows[i].isCartao ? rows[i].fatura.nome : '-',
      problema: rows[i].problema ? rows[i].problema.nome : '-',
      agendamento: rows[i].agendamento_user_id ? 'Agendado' : 'Não Agendado',
      desconto: formatMoeda(rows[i].desconto),
      numeroParcela: i + 1,
      data_pagamento_time: new Date(rows[i].data_pagamento).getTime(),
      data_pagamento_date: new Date(rows[i].data_pagamento),
    });
  }
  rowsFormated.sort((a, b) => a.data_pagamento_time - b.data_pagamento_time);
  rowsFormated = adicionaCampoDePrazos(rowsFormated, fornecedor, dataBase);
  rowsFormated.sort((a, b) => b.data_pagamento_time - a.data_pagamento_time);
  return rowsFormated;
}

export function getTotalCompra(peso, unidades, precoUnitario, produto) {
  if (produto.unidade.tipo === POR_UNIDADE) {
    return unidades * precoUnitario;
  }
  return peso * precoUnitario;
}

export function renameParcelasCompraUIDD(parcelas) {
  const arrayNew = parcelas.slice();
  for (let i = 0; i < parcelas.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].conta.nome}${i}`;
  }
  return arrayNew;
}

export function formatItens(itensOld) {
  const itensNew = [];
  for (let i = 0; i < itensOld.length; i += 1) {
    const unidades = itensOld[i].unidades_caixa_aparente
      ? itensOld[i].unidades_caixa_aparente
      : itensOld[i].unidades_caixa
      ? itensOld[i].unidades / itensOld[i].unidades_caixa
      : itensOld[i].unidades;
    const unitario = itensOld[i].unidades_caixa_aparente
      ? itensOld[i].preco_venda_caixa
      : itensOld[i].unidades_caixa
      ? itensOld[i].preco_venda_caixa
      : itensOld[i].preco_venda;

    const isNivel = itensOld[i].unidades_caixa_aparente
      ? true
      : !!itensOld[i].unidades_caixa;

    itensNew.push({
      produto: itensOld[i].produto,
      peso: itensOld[i].peso,
      total: getTotal2(itensOld[i].peso, unidades, unitario, {
        ...itensOld[i].produto,
        nivelCaixa: isNivel,
      }),
      unidades: itensOld[i].unidades,
      unitario: itensOld[i].preco_venda,
      uidd: `${itensOld[i].produto.nome}${itensNew.length}`,
      id: itensOld[i].id,
      idIndicadorChange: itensOld[i].id,
      unidadesCaixa: itensOld[i].unidades_caixa,
      unitarioCaixa: itensOld[i].preco_venda_caixa,
      pesoCaixa: itensOld[i].peso_caixa,
      unidadesCaixaAparente: itensOld[i].unidades_caixa_aparente,
      nivelCaixaria: itensOld[i].nivel_caixaria,
      cgp: itensOld[i].nivel_caixaria,
      grupo_oferta_preco_produto_id: itensOld[i].grupo_oferta_preco_produto_id,
      tipo_oferta: itensOld[i].tipo_oferta,
      is_margem_cartao: itensOld[i].is_margem_cartao,
    });
  }
  return itensNew;
}

export function formatParcelas(parcelasOld) {
  const parcelasNew = [];

  for (let i = 0; i < parcelasOld.length; i += 1) {
    parcelasNew.push({
      data_pagamento: parcelasOld[i].data_pagamento,
      tipo_pagamento: parcelasOld[i].tipo_pagamento,
      valor:
        parcelasOld[i].tipo_pagamento_id === 1
          ? parcelasOld[i].valor_real
          : parcelasOld[i].valor,
      uidd: `${parcelasOld[i].tipo_pagamento.nome}${parcelasNew.length}`,
      troco: parcelasOld[i].troco,
      valor_recebido: parcelasOld[i].valor_recebido,
      id: parcelasOld[i].id,
      idIndicadorChange: parcelasOld[i].id,
    });
  }
  return parcelasNew;
}

export function formatParcelasCompra2(parcelasOld) {
  const parcelasNew = [];

  for (let i = 0; i < parcelasOld.length; i += 1) {
    parcelasNew.push({
      data_pagamento: parcelasOld[i].data_pagamento,
      conta: parcelasOld[i].conta,
      valor: parcelasOld[i].valor,
      uidd: `${parcelasOld[i].conta.nome}${parcelasNew.length}`,
      id: parcelasOld[i].id,
      idIndicadorChange: parcelasOld[i].id,
    });
  }
  return parcelasNew;
}

function makeObjFatura(dia_pagamento, mes, ano) {
  const dataPagamentoFatura = new Date();
  dataPagamentoFatura.setDate(dia_pagamento);
  dataPagamentoFatura.setMonth(mes);
  dataPagamentoFatura.setFullYear(ano);
  const descricaoFatura = `${format(
    dataPagamentoFatura,
    "dd 'de' MMM 'de' yyyy",
    {
      locale: ptBR,
    },
  )}`;
  return {
    nome: descricaoFatura,
    ano,
    mes,
  };
}

export function formatParcelasCompra(parcelasOld) {
  const parcelasNew = [];

  for (let i = 0; i < parcelasOld.length; i += 1) {
    parcelasNew.push({
      data_pagamento: parcelasOld[i].despesa.data_pagamento,
      conta: parcelasOld[i].despesa.conta,
      valor: parcelasOld[i].valor,
      uidd: `${parcelasOld[i].despesa.conta.nome}${parcelasNew.length}`,
      status: parcelasOld[i].despesa.status,
      id: parcelasOld[i].id,
      codigo_boleto: parcelasOld[i].despesa.codigo_boleto
        ? parcelasOld[i].despesa.codigo_boleto
        : '',
      idIndicadorChange: parcelasOld[i].id,
      isCartao: !!parcelasOld[i].despesa.fatura,
      fatura: parcelasOld[i].despesa.fatura
        ? makeObjFatura(
            parcelasOld[i].despesa.fatura.cartao.dia_pagamento,
            parcelasOld[i].despesa.fatura.mes,
            parcelasOld[i].despesa.fatura.ano,
          )
        : null,
      cartao: parcelasOld[i].despesa.fatura
        ? parcelasOld[i].despesa.fatura.cartao
        : null,
      problema: parcelasOld[i].despesa.problema,
      descricao_problema: parcelasOld[i].despesa.descricao_problema,
      agendamento_user_id: parcelasOld[i].despesa.agendamento_user_id
        ? parcelasOld[i].despesa.agendamento_user_id
        : null,
      desconto: parcelasOld[i].despesa.desconto,
    });
  }
  return parcelasNew;
}

export function formatLotes(itensOld) {
  const itensNew = [];
  for (let i = 0; i < itensOld.length; i += 1) {
    itensNew.push({
      produto: itensOld[i].produto,
      peso: itensOld[i].peso,
      total: getTotalCompra(
        itensOld[i].peso,
        itensOld[i].unidades,
        itensOld[i].preco_compra_imposto2, //itensOld[i].preco_compra2,
        itensOld[i].produto,
      ),
      cg: itensOld[i].cg,
      unidades: itensOld[i].unidades,
      unitario: itensOld[i].preco_compra2,
      unitarioImposto: itensOld[i].preco_compra_imposto2,
      uidd: `${itensOld[i].produto.nome}${itensNew.length}`,
      validade: itensOld[i].validade,
      id: itensOld[i].id,
      idIndicadorChange: itensOld[i].id,
      nImpressoes: 0,
      dadosPrecificacao: itensOld[i].dadosPrecificacao,
    });
  }
  return itensNew;
}
