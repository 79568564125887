import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI } from '../../../../services';
import {
  formatTelefone,
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  POR_PESO_FIXO,
  TIPO_IMPRESSAO_UN,
} from '../../../../utils';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DEFAULT_ID = -1;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [fornecedor, setFornecedor] = useState({
    nome: '-',
    razaoSocial: '-',
    nomeFantasia: '-',
  });
  const [dataFaturamento, setDataFaturamento] = useState('-');
  const [numeroNotaFiscal, setNumeroNotaFiscal] = useState('');
  const [chaveAcesso, setChaveAcesso] = useState('');
  const [itens, setItens] = useState([]);
  const [totais, setTotais] = useState({
    pis: '-', // ok
    cofins: '-', // ok
    icms: '-', // ok
    total: '-', // ok
    bc_icms: '-', // ok
    repasse_ipi: '-', // ok
    repasse_st: '-', // ok
    repasse_frete: '-', // ok
    repasse_seguro: '-', // ok
    repasse_outras_despesas: '-', // ok
    desconto_nfe: '-', // ok
    isento: false, // ok
  });

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function formatFornecedor(data) {
    let nome = '-';
    let razaoSocial = '-';
    let nomeFantasia = '-';

    if (data) {
      nome = data.nome ? data.nome : '-';
      razaoSocial = data.razao_social ? data.razao_social : '-';
      nomeFantasia = data.nome_fantasia ? data.nome_fantasia : '-';
    }

    return {
      nome,
      razaoSocial,
      nomeFantasia,
    };
  }

  function formatDataFaturamento(data) {
    return data ? moment(new Date(data)).format('DD/MM/YYYY') : '-';
  }

  function formatNumeroNotaFiscal(data) {
    return data || '-';
  }

  function formatItens(data) {
    const vetor = [];
    for (let i = 0; i < data.length; i++) {
      const resource = data[i];

      vetor.push({
        id: resource.id,
        produto: resource.produto.nome,
        pis: formatMoeda(resource.pis),
        cofins: formatMoeda(resource.cofins),
        icms: formatMoeda(resource.icms),
        total: formatMoeda(resource.total),
        bc_icms: formatMoeda(resource.bc_icms),
        repasse_ipi: formatMoeda(resource.repasse_ipi),
        repasse_st: formatMoeda(resource.repasse_st),
        repasse_frete: formatMoeda(resource.repasse_frete),
        repasse_seguro: formatMoeda(resource.repasse_seguro),
        repasse_outras_despesas: formatMoeda(resource.repasse_outras_despesas),
        desconto_nfe: formatMoeda(resource.desconto_nfe),
      });
    }
    return vetor;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'compras-fiscais',
        ['id', 'asc'],
        { id: [id] },
        [
          'fornecedor.cidade.estado',
          'fornecedor.prazosFornecedores',
          'itens.produto.unidade',
        ],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setFornecedor(formatFornecedor(data.data[0].fornecedor));
        setDataFaturamento(
          formatDataFaturamento(data.data[0].data_faturamento),
        );
        setNumeroNotaFiscal(
          formatNumeroNotaFiscal(data.data[0].numero_nota_fiscal),
        );
        setItens(formatItens(data.data[0].itens));
        setValue(0);
        setChaveAcesso(data.data[0].chave_acesso_nota_fiscal);
        setTotais({
          pis: formatMoeda(data.data[0].pis),
          cofins: formatMoeda(data.data[0].cofins),
          icms: formatMoeda(data.data[0].icms),
          total: formatMoeda(data.data[0].total_nfe),
          bc_icms: formatMoeda(data.data[0].bc_icms),
          repasse_ipi: formatMoeda(data.data[0].repasse_ipi),
          repasse_st: formatMoeda(data.data[0].repasse_st),
          repasse_frete: formatMoeda(data.data[0].repasse_frete),
          repasse_seguro: formatMoeda(data.data[0].repasse_seguro),
          repasse_outras_despesas: formatMoeda(
            data.data[0].repasse_outras_despesas,
          ),
          desconto_nfe: formatMoeda(data.data[0].desconto_nfe),
        });
        setCarregando(false);
      } else {
        throw 'Esta compra não existe!';
      }
    } catch (erros) {
      setTotais({});
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">{`Nfe; ${chaveAcesso}`}</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Fornecedor" {...a11yProps(1)} />
                  <Tab label="Itens" {...a11yProps(2)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Data Faturamento:
                          </span>
                          {dataFaturamento}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Número Nota Fiscal:
                          </span>
                          {numeroNotaFiscal}
                        </Box>
                      </Box>

                      <Box>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            margin: '5px 0 15px',
                            textAlign: 'center',
                            color: 'red',
                          }}
                        >
                          Geral
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left">
                                  Campo
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  SubTotal
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow key="1">
                                <StyledTableCell align="left">
                                  Total
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.total}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="1">
                                <StyledTableCell align="left">
                                  PIS
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.pis}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="2">
                                <StyledTableCell align="left">
                                  COFINS
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.cofins}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="3">
                                <StyledTableCell align="left">
                                  ICMS Normal
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.icms}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="4">
                                <StyledTableCell align="left">
                                  BC ICMS
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.bc_icms}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="7">
                                <StyledTableCell align="left">
                                  Repasse IPI
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.repasse_ipi}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="5">
                                <StyledTableCell align="left">
                                  Repasse ST
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.repasse_st}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="6">
                                <StyledTableCell align="left">
                                  Repasse Frete
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.repasse_frete}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="6">
                                <StyledTableCell align="left">
                                  Repasse Seguro
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.repasse_seguro}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="6">
                                <StyledTableCell align="left">
                                  Repasse Outras Despesas
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.repasse_outras_despesas}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow key="6">
                                <StyledTableCell align="left">
                                  Desconto em Nfe
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {totais.desconto_nfe}
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Responsável:
                          </span>
                          {fornecedor.nome}
                        </Box>
                        <Box flex={1}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Razão Social:
                          </span>
                          {fornecedor.razaoSocial}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="20px"
                        fontSize={20}
                      >
                        <Box flex={1}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Nome Fantasia:
                          </span>
                          {fornecedor.nomeFantasia}
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Produto</StyledTableCell>
                              <StyledTableCell>Total</StyledTableCell>
                              <StyledTableCell>PIS</StyledTableCell>
                              <StyledTableCell>COFINS</StyledTableCell>
                              <StyledTableCell>ICMS Normal</StyledTableCell>
                              <StyledTableCell>BC ICMS</StyledTableCell>
                              <StyledTableCell>Repasse IPI</StyledTableCell>
                              <StyledTableCell>Repasse ST</StyledTableCell>
                              <StyledTableCell>Repasse Frete</StyledTableCell>
                              <StyledTableCell>Repasse Seguro</StyledTableCell>
                              <StyledTableCell>
                                Repasse Outras Despesas
                              </StyledTableCell>
                              <StyledTableCell>Desconto em Nfe</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {itens.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.produto}</StyledTableCell>
                                <StyledTableCell>{row.total}</StyledTableCell>
                                <StyledTableCell>{row.pis}</StyledTableCell>
                                <StyledTableCell>{row.cofins}</StyledTableCell>
                                <StyledTableCell>{row.icms}</StyledTableCell>
                                <StyledTableCell>{row.bc_icms}</StyledTableCell>
                                <StyledTableCell>
                                  {row.repasse_ipi}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.repasse_st}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.repasse_frete}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.repasse_seguro}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.repasse_outras_despesas}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.desconto_nfe}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
